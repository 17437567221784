import React, { useState } from "react";
import regExConstants from "@library/common/constants/regEx";

import { TextBox } from "siteComponentsLibrary/Inputs";
import { FormGroup } from "siteComponentsLibrary/Layout";
import { ErrorInline } from "siteComponentsLibrary/Feedback";
import { ButtonLink, Button } from "siteComponentsLibrary/Buttons";

import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";

import { useOutletContext } from "react-router-dom";
import { tidyPostcode } from "@library/common/helpers/misc/postcode";
import _ from "lodash";

const houseNumberMinLength = 1;
const houseNumberMaxLength = 30;
const postcodeMinLength = 3;
const postcodeMaxLength = 8;

const responseMappings = [
	{
		responsePath: "HouseNameOrNumber",
		path: "Risk/Proposer/Address/HouseNameOrNumber",
	},
	{
		responsePath: "AddressLine1",
		path: "Risk/Proposer/Address/AddressLine1",
	},
	{
		responsePath: "AddressLine2",
		path: "Risk/Proposer/Address/AddressLine2",
	},
	{
		responsePath: "AddressLine3",
		path: "Risk/Proposer/Address/AddressLine3",
	},
	{
		responsePath: "AddressLine4",
		path: "Risk/Proposer/Address/AddressLine4",
	},
	{
		responsePath: "Postcode",
		path: "Risk/Proposer/Address/Postcode",
	},
];

const pathHitlist = responseMappings.map((x) => x.path);

const fnIsValidPostcode = (searchPostcode) =>
	searchPostcode !== "" &&
	searchPostcode !== undefined &&
	// regExConstants.postcode.ukPostcode.test(searchPostcode) &&
	searchPostcode.length >= postcodeMinLength &&
	searchPostcode.length <= postcodeMaxLength;

const fnIsValidHouseNumber = (searchHouseNumber) =>
	searchHouseNumber !== "" &&
	searchHouseNumber !== undefined &&
	searchHouseNumber.length >= houseNumberMinLength &&
	searchHouseNumber.length <= houseNumberMaxLength;

const SearchPanel = ({ serviceData }) => {
	const commonData = useOutletContext();

	// Other way of determining an error
	const showErrorStore = useSelector((state) => {
		if (
			!pathHitlist.some(
				(path) => commonData.risk.selectors.metaData(state, path)?.errorShow
			)
		)
			return false;

		return !commonData.risk.selectors.isValid(state, pathHitlist);
	});

	const [showErrorsHouseNumber, setShowErrorsHouseNumber] = useState(false);
	const [showErrorsPostcode, setShowErrorsPostcode] = useState(false);
	const [showErrorMessages, setShowErrorMessages] = useState(false);

	const [houseNumber, setHouseNumber] = useState("");
	const [postcode, setPostcode] = useState("");

	const isValidHouseNumber = fnIsValidHouseNumber(houseNumber);
	const isValidPostcode = fnIsValidPostcode(postcode);

	const errorMessage = (function () {
		const fallbackMsg = "Please correct the errors above.";
		const fnIsMissing = (v) => v === "" || v === undefined;

		if (serviceData.status.isError) {
			return "Sorry, something's gone wrong. Please check your details and try again.";
		}

		if (fnIsMissing(houseNumber) && fnIsMissing(postcode)) {
			return fallbackMsg;
		}

		if (fnIsMissing(houseNumber)) {
			return fallbackMsg;
		}

		if (fnIsMissing(postcode)) {
			return fallbackMsg;
		}

		if (houseNumber.length < houseNumberMinLength) {
			return fallbackMsg;
		}
		if (postcode.length < postcodeMinLength) {
			return `Your postcode must be at least ${postcodeMinLength} characters`;
		}

		if (!isValidHouseNumber) return fallbackMsg;

		if (!isValidPostcode) return fallbackMsg;

		return undefined;
	})();

	const onSearch = async () => {
		setShowErrorsHouseNumber(true);
		setShowErrorsPostcode(true);
		setShowErrorMessages(true);

		if (!isValidPostcode) return;
		if (!isValidHouseNumber) return;

		await serviceData.functions.run(tidyPostcode(postcode), houseNumber);
	};

	return (
		<div className="" data-cy="address:search">
			<div className="row">
				<div className="col col-md-10 col-lg-8 d-flex flex-column flex-md-row gap-0 gap-md-3">
					<FormGroup
						hasError={
							(showErrorsHouseNumber && !isValidHouseNumber) || showErrorStore
						}
						data-cy="form-group:address-number"
						className="flex-fill"
					>
						<TextBox
							value={houseNumber}
							onChange={(value) => {
								setShowErrorsHouseNumber(true);
								setHouseNumber(value);
							}}
							placeholder="House name or number"
							maxLength={houseNumberMaxLength}
							disabled={serviceData.status.isRunning}
							aria-label="House name or number"
							data-cy="text:address-number"
							enableAutoFill={true}
						/>
					</FormGroup>

					<FormGroup
						hasError={
							(showErrorsPostcode && !isValidPostcode) || showErrorStore
						}
						data-cy="form-group:address-postcode"
					>
						<TextBox
							value={postcode}
							onChange={(value) => {
								setShowErrorsPostcode(true);
								setPostcode(
									value ? tidyPostcode(value.toUpperCase()) : undefined
								);
							}}
							// TODO: RETURN Not working properly
							// onKeyPress={(e) => {
							// 	if (e.key === "Enter") {
							// 		const value = e.target.value;
							// 		setShowErrorsPostcode(true);
							// 		setPostcode(
							// 			value ? tidyPostcode(value.toUpperCase()) : undefined
							// 		);
							// 		onSearch();
							// 	}
							// }}
							placeholder="Postcode"
							maxLength={postcodeMaxLength}
							disabled={serviceData.status.isRunning}
							aria-label="Postcode"
							data-cy="text:address-postcode"
							enableAutoFill={true}
						/>
					</FormGroup>
				</div>{" "}
				{/* Col end */}
			</div>{" "}
			{/* Row end */}
			<div className="row">
				<div className="col-12 col-md-4 col-lg-3 order-md-0">
					<Button
						onClick={onSearch}
						data-cy="btn:address-search"
						isLoading={serviceData.status.isRunning}
						className="btn-primary w-100"
					>
						Find my address
					</Button>
				</div>
				{showErrorMessages && errorMessage && (
					<div className="col-12 col-lg-9 order-md-1 mb-2 mb-md-0 d-flex align-items-center">
						<ErrorInline data-cy="feedback:address-error">
							{errorMessage}
						</ErrorInline>
					</div>
				)}
			</div>
		</div>
	);
};

const FoundPanel = ({
	serviceData,
	onEdit = () => {},
	onSearchAgain = () => {},
}) => {
	const commonData = useOutletContext();

	const addressData = useSelector((state) => {
		const fnGetValue = (path) => commonData.risk.selectors.value(state, path);

		return {
			HouseNameOrNumber: fnGetValue("Risk/Proposer/Address/HouseNameOrNumber"),
			AddressLine1: fnGetValue("Risk/Proposer/Address/AddressLine1"),
			AddressLine2: fnGetValue("Risk/Proposer/Address/AddressLine2"),
			AddressLine3: fnGetValue("Risk/Proposer/Address/AddressLine3"),
			AddressLine4: fnGetValue("Risk/Proposer/Address/AddressLine4"),
			Postcode: fnGetValue("Risk/Proposer/Address/Postcode"),
		};
	}, _.isEqual);

	return (
		<div className="row" data-cy="address:found">
			<div className="col-12 col-md-10 col-lg-8">
				<div className="card">
					<div className="card-body">
						<div className="row">
							<div className="col-12 col-sm-6 address-details">
								<div data-cy="found-details-1">
									{addressData["HouseNameOrNumber"]}{" "}
									{addressData["AddressLine1"]}
								</div>
								<div data-cy="found-details-2">
									{addressData["AddressLine2"]}
								</div>
								<div data-cy="found-details-3">
									{addressData["AddressLine3"]}
								</div>
								<div data-cy="found-details-4">
									{addressData["AddressLine4"]}
								</div>
								<div data-cy="found-details-postcode">
									{addressData["Postcode"]}
								</div>
							</div>
							<div className="col-12 col-sm-6 pt-3">
								<div className="row h-100 justify-content-end align-items-end">
									<div className="col-12 col-sm-8 col-md-6">
										<Button
											onClick={() => onEdit()}
											title="Edit"
											className="w-100 btn-primary"
											data-cy="btn:address-edit"
										>
											<span>Edit</span>
											<span className="visually-hidden"> address</span>
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="col-12 pt-2">
				<ButtonLink
					className="btn-link-inline"
					onClick={onSearchAgain}
					data-cy="btn:address-search-again"
				>
					Search for another address
				</ButtonLink>
			</div>
		</div>
	);
};

const EditPanel = ({ serviceData, onSearchAgain }) => {
	const commonData = useOutletContext();
	const { TextBox, FormGroup } = commonData.storeComponents;

	const addressData = useSelector((state) => {
		const fnGetValue = (path) => commonData.risk.selectors.value(state, path);

		return {
			// HouseNameOrNumber: fnGetValue("Risk/Proposer/Address/HouseNameOrNumber"),
			// AddressLine1: fnGetValue("Risk/Proposer/Address/AddressLine1"),
			// AddressLine2: fnGetValue("Risk/Proposer/Address/AddressLine2"),
			// AddressLine3: fnGetValue("Risk/Proposer/Address/AddressLine3"),
			// AddressLine4: fnGetValue("Risk/Proposer/Address/AddressLine4"),
			"Risk/Proposer/Address/Postcode": fnGetValue(
				"Risk/Proposer/Address/Postcode"
			),
		};
	}, _.isEqual);

	return (
		<div className="row" data-cy="address:edit">
			<div className="col-12 mb-3" data-cy="intro-text">
				Please edit your address below, or{" "}
				<ButtonLink
					className="btn-link-inline"
					onClick={onSearchAgain}
					data-cy="btn:address-search-again"
				>
					search again
				</ButtonLink>
				.
			</div>

			{[
				{
					path: "Risk/Proposer/Address/HouseNameOrNumber",
					label: "House name or number",
					dataCy: "house-name",
				},
				{
					path: "Risk/Proposer/Address/AddressLine1",
					label: "Address 1",
					dataCy: "address-line-1",
				},
				{
					path: "Risk/Proposer/Address/AddressLine2",
					label: "Address 2",
					dataCy: "address-line-2",
				},
				{
					path: "Risk/Proposer/Address/AddressLine3",
					label: "Address 3",
					dataCy: "address-line-3",
				},
				{
					path: "Risk/Proposer/Address/AddressLine4",
					label: "Address 4",
					dataCy: "address-line-4",
				},
				{
					path: "Risk/Proposer/Address/Postcode",
					label: "Postcode",
					dataCy: "postcode",
					maxLength: postcodeMaxLength,
					readOnly: true,
				},
			].map((x) => {
				return (
					<div className="col-12 col-md-8 col-lg-7" key={x.path}>
						<FormGroup
							databaseProps={{ path: x.path }}
							label={x.label}
							data-cy={`form-group:${x.dataCy}`}
						>
							{x.readOnly && addressData[x.path]}
							{!x.readOnly && (
								<TextBox
									databaseProps={{ path: x.path }}
									data-cy={`text:${x.dataCy}`}
									maxLength={x.maxLength}
								/>
							)}
						</FormGroup>
					</div>
				);
			})}
		</div>
	);
};

const PickerBase = () => {
	const commonData = useOutletContext();
	const { FormGroup: FormGroupStore } = commonData.storeComponents;

	const [isEditMode, setIsEditMode] = useState(false);

	const fnEdit = () => {
		setIsEditMode(true);
	};
	const fnReset = () => {
		setIsEditMode(false);
		serviceData.functions.reset();
	};

	const serviceData = commonData.hooks.useService(
		async (postcode, housenumber) => {
			const response = await commonData.dictionary.services.lookup.address({
				housenumber: housenumber,
				postcode: postcode,
			});

			if (!response) return undefined;
			return { Response: response };
		},
		{
			responseMappings: responseMappings.map((x) => ({
				...x,
				responsePath: `Response/${x.responsePath}`,
			})),
			fnHasData: (response) => (response?.Postcode ? true : false),
			hasDataSelector: (state) =>
				commonData.risk.selectors.value(state, "Risk/Proposer/Address/Postcode")
					? true
					: false,
		}
	);

	return (
		<div className="address-lookup" data-cy="address-lookup">
			<div className="">
				<h2 className="form-label">Your home address</h2>
			</div>

			{!serviceData.status.isFound && <SearchPanel serviceData={serviceData} />}

			{serviceData.status.isFound && !isEditMode && (
				<FoundPanel
					serviceData={serviceData}
					onEdit={fnEdit}
					onSearchAgain={fnReset}
				/>
			)}

			{serviceData.status.isFound && isEditMode && (
				<EditPanel serviceData={serviceData} onSearchAgain={fnReset} />
			)}

			{serviceData.status.isMissing && <EditPanel serviceData={serviceData} />}
		</div>
	);
};

export default PickerBase;
