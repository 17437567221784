// import { required } from "joi";
// import { isEmpty } from "@library/common/database/rules/helpers";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

export default (args) => {
	const { dataSet, functionArgs } = args;

	if (!dataSet) throw `Error -- missing dataSet`;
	if (!functionArgs) throw `Error -- missing functionArgs`;
	// if (!functionArgs.source) throw `Error -- missing functionArgs source`;
	if (!functionArgs.target) throw `Error -- missing functionArgs target`;

	//  { source: {componentTag, componentSet}, target: { overwrite: false, componentTag, componentSet }}
	const { target } = functionArgs;

	const sourceValue = dataSet.getValue(
		new dataSetArgItem({
			useDefaultComponentSet: true,
			useDefaultComponentTag: true,
		}),
		"value"
	);

	dataSet.setValue(
		new dataSetArgItem({
			...target,
			value: sourceValue,
			key: "value",
		})
	);
};
