import React, { useEffect, useRef, useState } from "react";
import Base from "../Base";

import { Button } from "siteComponentsLibrary/Buttons";

const GenericView = (props) => {
	const {
		children,
		heading = "We're sorry!",
		icon = "icon-error",
		useLayout = false,
		onLoad = async () => {},
	} = props;

	useEffect(() => {
		onLoad();
	}, []);

	return (
		<Base
			heading={heading}
			icon={icon}
			SectionLayout={(function () {
				if (!useLayout) return ({ children }) => <>{children}</>;
				return undefined;
			})()}
		>
			{children}
		</Base>
	);
};

export default GenericView;
