import { EndPointSet } from "../base/endpoints";

const apiKey = "YTiQQKQX83arUYCrGYsiH76HoPiUOiln1jLjMX9r";
const apiStage = "freedom-live";

const salusWebApi = "4qbnjdxz37";
const portalWebApi = "";
const paymentWebApi = "1z6xkavxp8";
const figlWebApi = "y9fblg5g36";

const baseArgs = {
	isProd: true,
	useStrictMode: false,
	google: { gtmId: "GTM-52MX29K" },

	awsConfig: {},
	endPointSets: new EndPointSet({
		default: {
			url: `https://${salusWebApi}.execute-api.eu-west-1.amazonaws.com/${apiStage}`,
			apiKey: apiKey,
		},
		lookup: {
			url: `https://${salusWebApi}.execute-api.eu-west-1.amazonaws.com/${apiStage}/GetFrontEndLists`,
			apiKey: apiKey,
		},
		payment: {
			url: `https://${paymentWebApi}.execute-api.eu-west-1.amazonaws.com/${apiStage}`,
			apiKey: apiKey,
		},
		figlEligibilityData: {
			url: `https://${figlWebApi}.execute-api.eu-west-1.amazonaws.com/${apiStage}`,
			apiKey: apiKey,
		},
		handover: {
			url: `https://zq3ar0ywkj.execute-api.eu-west-1.amazonaws.com/${apiStage}`,
			apiKey: apiKey,
		},
	}),
};
const baseConfig = require("../base").default(baseArgs);

export default baseConfig;
