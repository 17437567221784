// import moment from "moment";
// import { employmentValidations } from "./joi/schema";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";

// NOTE: there is NO commit here

export default (args, dh) => {
	if (!dh) throw `Error in Occupation baseLogic`;
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;
	// const dh = new dataHelper({
	// 	mappings: ["Type", "OccCode", "EmpCode"],
	// 	dataSet,
	// 	functionArgs,
	// });

	// console.log("occupation Logic:",dh.isUpdated("Type"), dh )

	// ** Main update code **
	if (dh.isUpdated("Type")) {
		// dh.runRules("OccCode", "EmpCode");

		switch (dh.getValue("Type")) {
			case "":
			case undefined: {
				dh.setData("visible", "OccCode", false);
				dh.setData("visible", "EmpCode", false);

				if (!dh.isPostRegistration()) {
					dh.setData("value", "OccCode", undefined);
					dh.setData("value", "EmpCode", undefined);
				}
				break;
			}
			case "None": {
				dh.setData("visible", "OccCode", false);
				dh.setData("visible", "EmpCode", false);

				if (!dh.isPostRegistration()) {
					dh.setData("value", "OccCode", "220");
					dh.setData("value", "EmpCode", "186");
				}
				break;
			}
			case "Employee": {
				dh.setData("visible", "OccCode", true);
				dh.setData("visible", "EmpCode", true);

				if (!dh.isPostRegistration()) {
					dh.setData("value", "OccCode", undefined);
					dh.setData("value", "EmpCode", undefined);
				}
				break;
			}
			case "Retired": {
				dh.setData("visible", "OccCode", false);
				dh.setData("visible", "EmpCode", false);

				if (!dh.isPostRegistration()) {
					dh.setData("value", "OccCode", "R09");
					dh.setData("value", "EmpCode", "947");
				}
				break;
			}

			case "UnEmploy": {
				dh.setData("visible", "OccCode", false);
				dh.setData("visible", "EmpCode", false);

				if (!dh.isPostRegistration()) {
					dh.setData("value", "OccCode", "U03");
					dh.setData("value", "EmpCode", "186");
				}
				break;
			}

			case "OtherEuro": {
				dh.setData("visible", "OccCode", false);
				dh.setData("visible", "EmpCode", false);

				if (!dh.isPostRegistration()) {
					dh.setData("value", "OccCode", "43D");
					dh.setData("value", "EmpCode", "949");
				}
				break;
			}
			case "HsePerson": {
				dh.setData("visible", "OccCode", false);
				dh.setData("visible", "EmpCode", false);

				if (!dh.isPostRegistration()) {
					dh.setData("value", "OccCode", "H09");
					dh.setData("value", "EmpCode", "948");
				}
				break;
			}
			case "FullTmEdu": {
				dh.setData("visible", "OccCode", true);
				dh.setData("visible", "EmpCode", false);

				if (!dh.isPostRegistration()) {
					dh.setData("value", "EmpCode", "950");
					dh.setData("value", "OccCode", undefined);
				}
				break;
			}

			default: {
				dh.setData("visible", "OccCode", true);
				dh.setData("visible", "EmpCode", true);

				if (!dh.isPostRegistration()) {
					dh.setData("value", "EmpCode", undefined);
					dh.setData("value", "OccCode", undefined);
				}
			}
		}
	}

	//Error checking
	// const lookupKey = employmentStatusesReverseMapping[dh.getValue("Type")];

	// if (dh.getValue("OccCode")) {
	// 	const curValidList = employmentCodes[lookupKey];
	// 	if (curValidList && !curValidList.includes(dh.getValue("OccCode"))) {
	// 		dh.addError("OccCode", "Please enter a valid employment type");
	// 	} else {
	// 		dh.clearError("OccCode");
	// 	}
	// }

	// if (dh.getValue("EmpCode")) {
	// 	const curValidList = employerCodes[lookupKey];
	// 	if (curValidList && !curValidList.includes(dh.getValue("EmpCode"))) {
	// 		dh.addError("EmpCode", "Please enter a valid occupation type");
	// 	} else {
	// 		dh.clearError("EmpCode");
	// 	}
	// }

	//console.log("Occupation logic", dh.isPostRegistration(), dh);

	// dh.commit();
};
