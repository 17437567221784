import React from "react";
import constants from "libraryConstants/database";

export * from "libraryComponents/Inputs";

// *********************************************************
// **** Custom && Overrides (replaces the export above) ****
// *********************************************************
import * as LibComponents from "libraryComponents/Inputs";

export const CheckboxButton = ({ className, ...otherProps }) => (
	<LibComponents.CheckboxButton
		className={[className, "site-checkbox-button"].filter(Boolean).join(" ")}
		{...otherProps}
	/>
);

export const RadioButtons = ({ className, ...otherProps }) => (
	<LibComponents.RadioButtons
		className={[className, "site-radio-buttons"].filter(Boolean).join(" ")}
		{...otherProps}
	/>
);

export const RadioButtonsWebService = ({ className, ...otherProps }) => (
	<LibComponents.RadioButtonsWebService
		className={[className, "site-radio-buttons"].filter(Boolean).join(" ")}
		{...otherProps}
	/>
);

export const RadioButtonsYesNo = ({ className, ...otherProps }) => (
	<LibComponents.RadioButtons
		className={[className, "site-radio-buttons", "two-buttons"]
			.filter(Boolean)
			.join(" ")}
		{...otherProps}
		itemData={constants.lists.yesNo}
	/>
);
