import React, {
	useState,
	createContext,
	useContext,
	useCallback,
	useEffect,
} from "react";
import { useOutletContext } from "react-router-dom";

import { ExternalLink } from "siteComponentsLibrary/Buttons";
import { PanelSection } from "siteComponentsLibrary/Layout";
import { CheckboxWithLabel } from "siteComponentsLibrary/Inputs";

const Context = createContext();

export const useYourAgreementControls = () => {
	const [isAgree, setIsAgree] = useState(false);
	const [showErrors, setShowErrors] = useState(false);

	const controls = { isAgree, setIsAgree, showErrors, setShowErrors };

	return [Context.Provider, controls];
};

const ViewsGapYourQuoteYourAgreement = (props) => {
	const commonData = useOutletContext();

	const currentQuote = commonData.quote.data.currentQuote;
	const chosenOptions = commonData.quote.data.chosenOptions;

	const isPayMonthly = chosenOptions.paymentMethod === "M";

	// const [Confirmation, setConfirmation] = useState(false);
	// const [ConfirmationShowError, setConfirmationShowError] = useState(false);

	const controls = useContext(Context);

	// Reset "I Agree" if the payment method changes
	useEffect(() => {
		controls.setIsAgree(false);
	}, [chosenOptions.paymentMethod]);

	// console.log("dddd", { controls });
	return (
		<>
			<PanelSection>
				<div data-cy="section:details-and-links">
					I confirm the above details are correct and I've read the{" "}
					<ExternalLink
						href={require("config").default.lookups.links.documents.tobaGap}
						label="Terms of Business"
						data-cy="link:toba"
					/>{" "}
					and{" "}
					<ExternalLink
						href={require("config").default.lookups.links.privacyPolicy}
						label="Privacy Policy"
						data-cy="link:privacy"
					/>
					.
				</div>
				{isPayMonthly && (
					<div data-cy="section:credit-agreement">
						I understand that by paying for my policy on a monthly payment plan
						I am entering into a credit agreement.
					</div>
				)}

				<CheckboxWithLabel
					id="confirm_agree"
					data-cy="checkbox:confirm-agree"
					className="mt-2"
					label={`I agree to the above statement${isPayMonthly ? "s" : ""}`}
					showError={!controls.isAgree && controls.showErrors}
					errorMsg="Please confirm if you wish to proceed."
					isChecked={controls.isAgree}
					onSelect={() => {
						controls.setIsAgree(true);
					}}
					onUnselect={() => {
						controls.setIsAgree(false);
					}}
					// onClick={() => setConfirmationShowError(true)}
				/>
			</PanelSection>
		</>
	);
};

export default ViewsGapYourQuoteYourAgreement;
