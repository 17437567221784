import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import constants from "libraryConstants/database";

import LayoutPageGap from "siteLayouts/Page/Gap";
import { Error } from "siteComponentsLibrary/Feedback";
import { Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { InputGroup } from "siteComponentsLibrary/Inputs";
import { TelephoneNumberSales } from "siteComponents/Misc";

import {
	ButtonLink,
	Button,
	IconButtonLabel,
	ExternalLink,
} from "siteComponentsLibrary/Buttons";

import services from "siteService";
import useLayoutPropsModifer from "../../../useLayoutPropsModifier";

import config from "config";

const ViewsGapRiskEditLanding = (props) => {
	const { layoutProps = {} } = props;

	const commonData = useOutletContext();
	const validationPathArray = require("../../../validationLists").default.start;

	const StartConfirm = useSelector((state) =>
		commonData.risk.selectors.value(state, "ChosenOptions/StartConfirm")
	);

	const newLayoutProps = useLayoutPropsModifer({
		layoutProps,
		commonData,
		validationPathArray: validationPathArray,

		...(function () {
			if (StartConfirm === false) return { isValid: false };
			return {
				errorMessage: "Please correct the errors above",
			};
		})(),
	});

	const {
		TextBox,
		TextBoxTelephone,
		TextBoxMobile,
		TextBoxCurrency,
		FormGroup,
		DatePickerWithButtons,
		InceptionDate,
		RadioButtonsWebService,
		DatePickerTextBox,
		RadioButtons,
		RadioButtonsYesNo,
		CheckboxButton,
		DropDownTypeWebService,
	} = commonData.storeComponents;

	return (
		<LayoutPageGap
			// headingText="xxxxxxxxxxxx"
			{...newLayoutProps}
		>
			{/* <DevContainer>
				<SetTest />
			</DevContainer> */}
			<Panel header="What is GAP Insurance?" data-cy="panel:what-is-gap">
				<PanelSection data-cy="section:what-is-gap">
					<div>
						GAP Insurance is designed to offer peace of mind by covering the
						difference between the purchase price or todays value of your
						vehicle and the amount the insurer pays in the unfortunate event of
						a total loss claim.
					</div>
					<div>
						We offer different types of GAP Insurance depending on when and how
						you purchased your vehicle.
					</div>
				</PanelSection>

				<PanelSection heading="Return to Invoice" data-cy="section:rti">
					If you have purchased a vehicle from a dealership within the last 180
					days, this policy pays the difference, up to the policy limit, between
					the motor insurers total loss pay out and either the invoice price or
					the amount outstanding on finance, whichever is the highest.
				</PanelSection>

				<PanelSection heading="Return to Value" data-cy="section:rtv">
					If you have purchased your vehicle privately or over 180 days ago,
					this insurance product will pay the difference between your insurance
					pay out and the current value of your vehicle at the time your GAP
					Insurance policy is purchased.
				</PanelSection>

				<div className="alert alert-success mt-4 p-3" data-cy="alert:example">
					<div className="small fw-regular mb-2">
						For example; if you purchased a new car for &pound;25,000 and it was
						unfortunately stolen, your insurer may only pay out &pound;21,000 due to
						depreciation depending on when the claim happens.
					</div>
					<div className="small fw-regular">
						If you have 'Return to Invoice' GAP Insurance then you will also
						receive a 'top up' of &pound;4,000 so you're no longer out of pocket and
						you can replace your vehicle with no further financial burden.			
					</div>
				</div>
			</Panel>

			<Panel header="Ready to get a quote?">
				<PanelSection 
					heading="Please check the below carefully before continuing"
					relatesToFormGroup={true}
				>
					<div className="fw-medium">
						Your vehicle;
					</div>
					<ul>
						<li>
							is not used for hire or reward, including as a taxi, courier or
							for driving tuition
						</li>
						<li>has never been declared an insurance write off</li>
					</ul>
				</PanelSection>

				<FormGroup
					databaseProps={{ path: "ChosenOptions/StartConfirm" }}
					label={`Do you agree with the above statements?`}
					data-cy="form-group:confirm"
					hasError={StartConfirm === false}
					errorMessage={
						StartConfirm === false &&
						<div>
							<div>
								Sorry, we are unable to offer you cover if you do not agree to
								the above statements.
							</div>
							<div>
								If you would like to speak to someone please call <TelephoneNumberSales/>.
							</div>
						</div>
					}
				>
					<RadioButtonsYesNo
						databaseProps={{ path: "ChosenOptions/StartConfirm" }}
						itemData={constants.lists.yesNo}
						data-cy="radio:confirm"
					/>
				</FormGroup>
			</Panel>

			<Panel>
				<FormGroup
					databaseProps={{
						path: "Risk/Vehicle/VehicleType",
						label: "What type of vehicle would you like to cover?",
					}}
					data-cy="form-group:vehicle-type"
				>
					<RadioButtons
						itemData={[
							{
								label: (
									<IconButtonLabel
										iconClass="icon-policytype-car"
										labelText="Car"
									/>
								),
								value: "Car",
								hasIcon: true,
							},
							{
								label: (
									<IconButtonLabel
										iconClass="icon-policytype-van"
										labelText="Van"
									/>
								),
								value: "GoodsVehicle",
								hasIcon: true,
							},
						]}
						databaseProps={{ path: "Risk/Vehicle/VehicleType" }}
						className="two-buttons"
						data-cy="radio:vehicle-type"
					/>
				</FormGroup>

				<FormGroup
					databaseProps={{ path: "Risk/PolicyInceptionDate" }}
					label="When would you like your policy to start?"
					helpText="You can choose a date from tomorrow up to a maximum of 30 days from today."
					data-cy="form-group:inception-date"
				>
					<InceptionDate
						databaseProps={{ path: "Risk/PolicyInceptionDate" }}
						data-cy="datepicker:inception-date"
					/>
				</FormGroup>
			</Panel>
		</LayoutPageGap>
	);
};

export default ViewsGapRiskEditLanding;
