import React from "react";

import _logo from "siteAssets/images/FB-logo-footer.png";
import _logoWithText from "siteAssets/images/logo-with-text.svg";

import { SiteFooter } from "siteComponentsLibrary/Layout";
import { ExternalLink } from "siteComponentsLibrary/Buttons";
import { ScreenFinder } from "siteComponentsLibrary/Dev";
import imgVisa from "siteAssets/images/misc/Visa-Logo.webp";
import imgMastercard from "siteAssets/images/misc/mastercard-logo.webp";
import TrustBox from "./TrustBox";

const PaymentMethods = (props) => {
	const { className } = props;

	const _className = [className, "row", "pt-3", "pt-lg-0"]
		.filter((x) => x)
		.join(" ");

	return (
		// <div className={_className}>
		// 	<div className="col-12">
		<>
			<img
				src={imgVisa}
				alt="Visa accepted"
				className="img-fluid mw-20 mw-md-10 mw-lg-20 me-3"
			/>
			<img
				src={imgMastercard}
				alt="Mastercard accepted"
				className="img-fluid mw-20 mw-md-10 mw-lg-20"
			/>
		</>
		// 	</div>
		// </div>
	);
};

const SiteTemplateFooter = (props) => {
	// const { logoHeader = _logo } = props;

	const { className, logoHeader = _logo, logoFooter = _logo } = props;
	console.log(require("config").default.lookups.links);

	return (
		<SiteFooter
			brandImage={
				<img className="brand-img" src={logoFooter} alt="Freedom Brokers" />
			}
			legalFooterText={
				<>
					<div className="copywrite">
						<small>
							&copy; {process.env.BUILD_TIME.split(",")[0].split("/")[2]}{" "}
							Freedom Brokers Ltd.
						</small>
					</div>
					<div className="legal-text">
						<small>
							Freedom Brokers Ltd is an appointed representative of Action 365
							Ltd which is authorised and regulated by the Financial Conduct
							Authority (FRN 306011). Freedom Brokers Ltd is registered in
							England and Wales, company number 10399389 and its registered
							address is BizSpace Cheadle, Cheadle Place, Stockport Road,
							Cheadle, Cheshire SK8 2JX.
						</small>
					</div>
				</>
			}
			menuData={[
				{
					id: "toba",
					href: require("config").default.lookups.links.documents.tobaGap,
					label: "Terms of Business",
				},
				{
					id: "privacy",
					href: require("config").default.lookups.links.privacyPolicy,
					label: "Privacy Policy",
				},
				{
					id: "cookie",
					href: require("config").default.lookups.links.cookiePolicy,
					label: "Cookie Policy",
				},
			]}
			leftClassName="col-xxl-3"
			rightClassName="col-xxl-9"
		/>
	);
	return (
		<>
			<div className="container-fluid bg-white">
				<TrustBox template="stars" />

				<div className="row justify-content-center">
					<div className="col-12 d-flex justify-content-end mt-auto pb-1 pay-methods">
						<PaymentMethods />
					</div>
				</div>
			</div>

			<div className="container-fluid">
				<div className="row bg-green p-3 d-flex justify-content-center">
					<div className="col-auto">
						<ExternalLink
							href={require("config").default.lookups.links.documents.tobaGap}
							label="Terms of Business"
						/>
					</div>
					<div className="col-auto">
						<ExternalLink
							href={require("config").default.lookups.links.privacyPolicy}
							label="Privacy Policy"
						/>
					</div>
				</div>

				<div className="row bg-darkgreen p-3 justify-content-center">
					<div className="col-12 col-lg-8 text-center layout-max-width">
						<p className="text-white">
							<small>&copy; Freedom Brokers 2021.</small>
						</p>
						<p className="text-white">
							<small>
								Freedom Brokers Ltd is an appointed representative of Action 365
								Ltd which is authorised and regulated by the Financial Conduct
								Authority (FRN 306011). Freedom Brokers Ltd is registered in
								England and Wales, company number 10399389 and its registered
								address is BizSpace Cheadle, Cheadle Place, Stockport Road,
								Cheadle, Cheshire SK8 2JX.
							</small>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default SiteTemplateFooter;
