import React from "react";

import { Panel, Card, PageHeaderWithIcon } from "siteComponentsLibrary/Layout";

import _SectionLayout from "siteLayouts/Section/Generic";
import _PageLayout from "siteLayouts/Page";

const ErrorBaseView = (props) => {
	const {
		children,
		heading,
		icon,
		SectionLayout = _SectionLayout,
		PageLayout = _PageLayout,
	} = props;

	return (
		<SectionLayout>
			<PageLayout
				snapToTop={true}
				headingText={<PageHeaderWithIcon icon={icon} heading={heading} />}
				className="error-page"
				data-cy="page-layout-error-template"
			>
				<Panel data-cy="error-panel">
					{children}
				</Panel>
			</PageLayout>
		</SectionLayout>
	);
};

export default ErrorBaseView;
