const siteComponents = {
	...require("./Buttons.js"),
	...require("./Display.js"),
	...require("./Layout.js"),
	...require("./Dates.js"),
	...require("./Inputs.js"),
	...require("./Feedback.js"),
	...require("./Modal.js"),
};

export * from "./Buttons.js";
export * from "./Display.js";
export * from "./Layout.js";
export * from "./Dates.js";
export * from "./Inputs.js";
export * from "./Feedback.js";
export * from "./Modal.js";
export * from "./Animated.js";

export default siteComponents;
