import React, { useState, useEffect, useContext, createContext } from "react";
import { useOutletContext } from "react-router-dom";
import _ from "lodash";
import LayoutPageGap from "siteLayouts/Page/Gap";
import { Button, ButtonToggle } from "siteComponentsLibrary/Buttons";
import { DevContainer } from "siteComponents/Dev";
import { Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { IptContent } from "siteComponentsLibrary/Display";
import { ExternalLink } from "siteComponentsLibrary/Buttons";
import { TelephoneNumberSales } from "siteComponents/Misc";

import Header from "./Header";
import PolicyDetails from "./PolicyDetails";
import HowToPay, { useHowToPayControls } from "./HowToPay";
import YourAgreement, { useYourAgreementControls } from "./YourAgreement";
import ErrorDisplay from "./ErrorDisplay";
import { useSelector } from "react-redux";

// export const YourAgreementContext = createContext();

const ViewsGapYourQuote = (props) => {
	const commonData = useOutletContext();

	const currentQuote = commonData.quote.data.currentQuote;
	const quoteResults = commonData.quote.data.quoteResults;
	// const availablePaymentMethods = commonData.quote.data.paymentMethods;
	const chosenOptions = commonData.quote.data.chosenOptions;

	console.groupCollapsed("ViersGapYourQuote.jsx");

	[("commonData", "currentQuote", "quoteResults", "chosenOptions")].forEach(
		(x) => console.log(x, ":", eval(x))
	);

	console.groupEnd("");

	const vehicleType = useSelector((state) => {
		return commonData.risk.selectors.value(state, "Risk/Vehicle/VehicleType");
	});

	const vehicleDescription = (function () {
		switch (vehicleType) {
			case "GoodsVehicle":
				return "van";

			case "Car":
				return "car";
			default:
				return "vehicle";
		}
	})();

	return (
		<div className="quote-tier">
			{currentQuote && (
				<>
					<Panel header="Your GAP policy" data-cy="gap-intro">
						<p>
							<strong>
								Please review the details below and ensure you agree before you
								continue.
							</strong>
						</p>

						{currentQuote.GapType === "VAL" && (
							<PanelSection
								heading="Return to Value GAP Protection"
								data-cy="gaptype-val"
							>
								<div>
									Based on the details provided about your vehicle, the cover
									most suitable for you is Return to Value GAP Protection. The
									policy will pay the difference, up to the policy limit,
									between the motor insurers total loss settlement and the
									current value of your {vehicleDescription}, shown below.
								</div>

								<div>
									<strong>
										The vehicle must be comprehensively insured in your name.
									</strong>
								</div>
							</PanelSection>
						)}

						{currentQuote.GapType === "RTI" && (
							<PanelSection
								heading="Return to Invoice GAP Protection"
								data-cy="gaptype-rti"
							>
								<div>
									Based on the details provided about your vehicle, the cover
									most suitable for you is Return to Invoice GAP Protection. The
									policy will pay the difference up to the policy limit between
									the motor insurers total loss settlement and the invoiced
									value of the vehicle.
								</div>

								<div>
									<strong>
										The vehicle must be comprehensively insured in your name.
									</strong>
								</div>
							</PanelSection>
						)}

						<div className="alert alert-success mt-4 text-center">
							<small>
								For more details on the policy cover, including the benefits,
								terms, conditions and exclusions, it's important that you read
								and understand the{" "}
								<ExternalLink
									href={currentQuote.PolicyWordingUrl}
									label={<strong>Policy Wording</strong>}
									data-cy="link:quote-policy-wording"
									className="alert-link"
								/>{" "}
								and the{" "}
								<ExternalLink
									href={currentQuote.IpidUrl}
									label={<strong>Product Information</strong>}
									data-cy="link:quote-policy-ipid"
									className="alert-link"
								/>
								.
							</small>
						</div>
					</Panel>
					<Panel header="Policy summary" data-cy="policy-summary">
						<PolicyDetails />
					</Panel>

					<Panel header="How to pay" data-cy="how-to-pay">
						<HowToPay />
					</Panel>

					<Panel header="Your agreement" data-cy="your-agreement">
						<YourAgreement
						//setAllowContinue={() => props.setAllowContinue()}
						/>
					</Panel>

					{/* {currentQuote?.InstalmentsZeroDeposit &&
						chosenOptions.paymentMethod === "M" &&
						currentQuote.Instalments &&
						currentQuote.Instalments[0].DepositAmount !== 0 && (
							<Panel>
								<div className="alert alert-success mt-4 text-center">
									This instalment plan requires a deposit. If you wish to take
									out insurance without paying a deposit, please call us on{" "}
									<TelephoneNumberSales /> and quote reference{" "}
									<strong>{currentQuote.WebReference}</strong>.
								</div>
							</Panel>
						)} */}
				</>
			)}

			<DevContainer>
				<div>
					<h5>Actions</h5>

					<Button
						onClick={async () => {
							await commonData.quote.functions.getQuote({
								autoSetFirstTier: true,
								autoUpdateQuoteParams: true,
								isPayloadChangedCheck: true,
							});

							//UPDATE PAYMENT METHOD if not already chosen
							if (!chosenOptions.paymentMethod) {
								await commonData.quote.functions.choosePaymentMethod(
									commonData.risk.values.paymentMethod
								);
							}

							// await commonData.quote.functions.chooseQuoteTier(
							// 	quoteResults[0].TierInfo.TierId
							// );

							await commonData.functions.session.save();
						}}
					>
						GET QUOTE
					</Button>
					<Button
						onClick={async () => {
							await commonData.functions.process.resetQuote();
						}}
					>
						Reset Quote
					</Button>
					<Button
						onClick={async () => {
							await commonData.functions.session.save();
						}}
					>
						SAVE SESSION
					</Button>
				</div>

				<div>
					<h5>Choose tier</h5>
					{quoteResults.map((x) => (
						<Button
							key={x.TierInfo.TierId}
							onClick={async () => {
								await commonData.quote.functions.chooseQuoteTier(
									x.TierInfo.TierId
								);
							}}
						>
							{x.TierInfo.TierId}
							{x.isChosen && "(X)"}
						</Button>
					))}
				</div>
			</DevContainer>
		</div>
	);
};

const ViewsGapYourQuoteWrapper = (props) => {
	const { layoutProps = {} } = props;

	const commonData = useOutletContext();
	const currentStatus = commonData.quote.data.currentStatus;
	const isStoreValid = commonData.risk.status.isValid;
	const isPageDisabled = !isStoreValid;
	const currentQuote = commonData.quote.data.currentQuote;
	const [isStickyEnabled, setIsStickyEnabled] = useState(false);

	const [YourAgreementProvider, yourAgreementData] = useYourAgreementControls();
	const [HowToPayProvider, howToPayData] = useHowToPayControls();

	if (isPageDisabled) return null;

	if (!currentStatus.isInit) return <LayoutPageGap {...layoutProps} />;

	if (!currentStatus.isSuccess || !currentQuote)
		return (
			<ErrorDisplay
				pagerData={{
					..._.pick(layoutProps.pagerData, [
						"isPagerEnabled",
						"labelPrevious",
						"fnOnPrev",
					]),
				}}
			/>
		);

	// if (!currentQuote) return null;

	const dataHeader = <Header isSticky={isStickyEnabled} />;

	/*const [AllowContinue, setAllowContinue] = useState(null);

	const errorData = (function () {
		if (AllowContinue === false) {
			alert("Stopping continue");
			return {
				errorMessage: "Please correct the errors above",
				disableNextButton: true,
			};
		}

		return { disableNextButton: false };
	})();

	const newLayoutProps = {
		...layoutProps,
		errorMessage: errorData.errorMessage || layoutProps.errorMessage,
		pagerData: {
			...layoutProps.pagerData,
			fnOnNext: () => {
				if (errorData.disableNextButton) 
					return;
			},
		}
	};

	console.log(AllowContinue);*/

	// console.log("dddd", { layoutProps }, layoutProps.pagerData.fnOnNext);

	// Has the user checked the "Your Agreement"???

	const newLayoutProps = (function () {
		//Exit if everything is okay
		if ([yourAgreementData.isAgree, howToPayData.isValid].every(Boolean))
			return layoutProps;

		const base = _.cloneDeep(layoutProps);

		// Modify the onNext function to show errors
		if (base?.pagerData) {
			if (!howToPayData.isValid) base.pagerData.labelNext = "Continue";

			base.pagerData.fnOnNext = () => {
				yourAgreementData.setShowErrors(true);
				howToPayData.setShowErrors(true);
			};
		}
		// Do we show the error message at the foot of the page...?
		if ([yourAgreementData.showErrors, howToPayData.showErrors].some(Boolean))
			base.errorMessage = "Please correct the errors above.";

		return base;
	})();

	return (
		<HowToPayProvider value={howToPayData}>
			<YourAgreementProvider value={yourAgreementData}>
				<LayoutPageGap
					headingText="Your premium"
					descriptionContent={dataHeader}
					isSticky={true}
					onHeaderStick={() => {
						setIsStickyEnabled(true);
					}}
					onHeaderUnstick={() => {
						setIsStickyEnabled(false);
					}}
					//{...newLayoutProps}
					{...newLayoutProps}
				>
					<ViewsGapYourQuote
					//setAllowContinue={() => setAllowContinue}
					/>
				</LayoutPageGap>
			</YourAgreementProvider>
		</HowToPayProvider>
	);
};

export default ViewsGapYourQuoteWrapper;
