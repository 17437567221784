import _ from "lodash";
import * as regExConstants from "../../constants/regEx";
import dayjs from "dayjs";

const fnGenerateRuleObj = (fn, args = {}) => {
	if (!fn)
		throw `Error in quoteAndBuy REDUX store rules.createObject -- missing fn`;

	const { errorKey = fn.name } = args;

	return {
		ruleName: errorKey,
		fn: fn,
		errorKey: errorKey,
	};
};

const commonRules = {
	validate: (depPath, options = {}) => {
		const {
			postSalusLoad: _postSalusLoad = false,
			postRegistration: _postRegistration = false,
		} = options;

		return (item, controls, { isPostSalusLoad, isPostRegistration }) => {
			if (!_postSalusLoad && isPostSalusLoad) return;
			if (!_postRegistration && isPostRegistration) return;

			if (item.isUpdated()) controls.find(depPath).functions.validate();
		};
	},
	convert: {
		integer: (item) => {
			if (item.isEmpty()) return;
			if (_.isNumber(item.value())) return;
			if (Number.isInteger(item.value())) return;

			const newValue = item.value().replace(/\D/g, "");
			item.functions.setValue(newValue);
		},
	},
	is: {
		age: {
			min: (errorMessage = undefined, errorKey = "isAgeMin", minAge) => {
				if (!minAge) throw `Error in isAgeMin -- missing an minAge`;

				const isAgeMin = (item) => {
					item.functions.errors.clear();
					if (item.isEmpty()) return;

					const age = dayjs().diff(item.value(), "year");

					if (age < minAge) {
						item.functions.errors.set(errorMessage);
					}
				};

				return fnGenerateRuleObj(isAgeMin, { errorKey });
			},
		},
		required: (errorMessage = undefined, errorKey = "isRequired") => {
			//NOTE: We need to name the function so that it can be used as the errorKey in the Processor()
			const isRequired = (item) => {
				item.functions.errors.clear();
				if (!item.isEmpty()) return;
				item.functions.errors.set(errorMessage);
			};

			return fnGenerateRuleObj(isRequired, { errorKey });
		},
		email: (errorMessage = undefined, errorKey = "isEmail") => {
			const isEmail = (item) => {
				item.functions.errors.clear();
				if (item.isEmpty()) return;
				if (regExConstants.misc.email.test(item.value())) return;
				item.functions.errors.set(errorMessage);
			};
			return fnGenerateRuleObj(isEmail, { errorKey });
		},
		mobile: (errorMessage = undefined, errorKey = "isMobile") => {
			const isMobile = (item) => {
				item.functions.errors.clear();
				if (item.isEmpty()) return;
				if (regExConstants.phoneNumber.mobile.test(item.value())) return;
				item.functions.errors.set(errorMessage);
			};
			return fnGenerateRuleObj(isMobile, { errorKey });
		},

		integer: (errorMessage = undefined, errorKey = "isInteger") => {
			const isInteger = (item) => {
				item.functions.errors.clear();

				if (item.isEmpty()) return;
				if (_.isNumber(item.value()) && Number.isInteger(item.value())) return;
				if (_.isString(item.value()) && Number.isInteger(Number(item.value())))
					return;

				item.functions.errors.set(errorKey, errorMessage);
			};
			return fnGenerateRuleObj(isInteger, { errorKey });
		},
	},
};

class itemSet {
	constructor(controls, ...items) {
		if (!controls)
			throw `Error in itemSet -- missing "controls" in constructor`;

		this.items = Object.fromEntries(
			items.map((x) => {
				if (_.isString(x)) {
					const path = x;
					console.log("   ", "itemSet", path);
					const data = controls.find(path);
					// console.log("Clearing errors", data.errorKey(), path);

					if (data.isFound()) {
						data.functions.errors.clear();
					}

					return [path || path, data];
				}

				const data = controls.find(x.path);
				data.functions.errors.clear();
				return [x.key || x.path, data];
			})
		);
	}

	runWithUpdatedCheck(updatedKeys = [], fn) {
		if (
			!updatedKeys
				.filter((k) => k in this.items)
				.some((k) => this.items[k].isUpdated())
		)
			return;

		fn(this.items);
	}

	run(fn) {
		fn(this.items);
	}
}
export default {
	common: commonRules,
	createRule: fnGenerateRuleObj,
	itemSet: itemSet,
};
