import React, { useState, useEffect } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import LayoutPageGap from "siteLayouts/Page/Gap";
import ErrorBaseView from "../../../Errors/Base";
import { Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { PriceDisplay } from "siteComponentsLibrary/Display";
import {
	TelephoneNumberClaims,
	TelephoneNumberService,
} from "siteComponents/Misc";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

const fnFormatDate = (d) => {
	if (!d) throw `Error in fnFormatDate -- missing date`;
	return dayjs(d).format("Do MMMM YYYY");
};

const ViewsGapRiskWrapupInterstitial = (props) => {
	const { layoutProps = {} } = props;

	const [searchParams, setSearchParams] = useSearchParams();
	const amountPaid = searchParams.get("a");

	const hasAmountPaid = amountPaid !== undefined;

	return (
		<LayoutPageGap headingText="Thank you for your payment" {...layoutProps}>
			<Panel>
				<PanelSection>
					{hasAmountPaid && (
						<div>
							We're pleased to confirm that your payment of{" "}
							<PriceDisplay value={amountPaid / 100} /> has been processed
							successfully.
						</div>
					)}

					{!hasAmountPaid && (
						<div>
							We're pleased to confirm that your payment has been processed
							successfully.
						</div>
					)}

					<div className="mt-5">
						<div className="alert alert-success text-center p-3">
							Please don't refresh the page or close your browser whilst we
							complete this process.
						</div>
					</div>
				</PanelSection>
			</Panel>
		</LayoutPageGap>
	);
};

export default ViewsGapRiskWrapupInterstitial;
