import React, { useState, useEffect } from "react";
export * from "libraryComponents/Dates";

// *********************************************************
// **** Custom && Overrides (replaces the export above) ****
// *********************************************************
import * as LibComponents from "libraryComponents/Dates";
import { Button } from "./Buttons";
export const DatePickerWithButtons = (props) => {
	return (
		<div className="site-date-picker">
			<LibComponents.DatePickerWithButtons {...props} />
		</div>
	);
};

export const InceptionDate = (props) => {
	return (
		<DatePickerWithButtons
			{...props}
			filterDaysBefore={-1}
			filterDaysAfter={30}
			showTomorrow={true}
		/>
	);
};

// export const InceptionDateCorrection = (props) => {
// 	const {
// 		value,
// 		onChange,
// 		onConfirm,
// 		onCancel,
// 		labelCancel = "Cancel",
// 		labelConfirm = "Update",
// 		fnValidate = () => true,
// 		...otherProps
// 	} = props;

// 	const [newValue, setNewValue] = useState(undefined);

// 	const disableConfirm = !fnValidate(newValue);

// 	return (
// 		<div className="date-correction row">
// 			<div className="col-12 mb-5">
// 				<InceptionDate
// 					{...otherProps}
// 					value={newValue}
// 					onChange={(v) => {
// 						setNewValue(v);
// 					}}
// 				/>
// 			</div>
// 			{[
// 				onCancel && (
// 					<Button
// 						onClick={() => {
// 							onCancel();
// 						}}
// 						className="btn-outline w-100"
// 					>
// 						{labelCancel}
// 					</Button>
// 				),
// 				onConfirm && (
// 					<Button
// 						className="w-100"
// 						disabled={disableConfirm}
// 						onClick={() => {
// 							if (!newValue) return;
// 							onChange(newValue);
// 							onConfirm();
// 						}}
// 					>
// 						{labelConfirm}
// 					</Button>
// 				),
// 			]
// 				.filter(Boolean)
// 				.map((x, i, arr) => {
// 					return (
// 						<div key={i} className={`col-${12 / arr.length}`}>
// 							{x}
// 						</div>
// 					);
// 				})}
// 		</div>
// 	);
// };

export const DatePickerTextBox = (props) => {
	const { className, label, ...otherProps } = props;

	return (
		<LibComponents.DatePickerTextBox
			{...otherProps}
			className={[className].filter(Boolean).join(" ")}
			ariaLabel={label}
		/>
	);
};
