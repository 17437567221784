import React from "react";

const fnFormatCurrency = (v) =>
	new Intl.NumberFormat("en-GB", {
		style: "currency",
		currency: "GBP",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(v);

export const IptContent = (props) => {
	const { resultType, fee } = props;

	let iptContent = "";
	let iptFee = "";

	switch (resultType) {
		case "AP":
		case "RP": {
			iptContent = <span>All premiums are</span>;
			iptFee = (
				<span>
					There is also a <strong>{fnFormatCurrency(fee)}</strong>{" "}
					non-refundable adjustment fee.
				</span>
			);
			break;
		}

		case "NIL": {
			iptContent = <span>This quote is</span>;
			iptFee = (
				<span>
					There is also a <strong>{fnFormatCurrency(fee)}</strong>{" "}
					non-refundable adjustment fee.
				</span>
			);
			break;
		}

		case "NB": {
			iptContent = <span>All premiums are</span>;
			iptFee = (
				<span>
					There is also a <strong>{fnFormatCurrency(fee)}</strong>{" "}
					<strong>non-refundable</strong> new business fee.
				</span>
			);
			break;
		}

		case "RNL": {
			iptContent = <span>All premiums are</span>;
			iptFee = (
				<span>
					There is also a non-refundable renewal fee of{" "}
					<strong>{fnFormatCurrency(fee)}</strong>.
				</span>
			);
			break;
		}

		default: {
			return null;
		}
	}

	return (
		<small className="ipt-statement" data-cy="ipt-statement">
			{iptContent} inclusive of Insurance Premium Tax (IPT) at the current rate.
			<br />
			{iptFee}
		</small>
	);
};
