import React, { useEffect, useCallback, useMemo, useState } from "react";
import config from "config";
import services from "siteService";
import { Outlet, Link, NavLink } from "react-router-dom";
import { selectors } from "siteStore";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import _ from "lodash";

// import Menu from "../Menu";

// import { StickyContainer, Sticky } from "react-sticky";

import { Sticky } from "siteComponentsLibrary/Layout";
import { generateCypressProps } from "libraryFunctions/cypress";
import {
	useScrollToTop,
	useSnapToTop,
	fnScrollToTop,
	fnSnapToTop,
} from "moveToLibrary/hooks/useScrollTo";

import { Loading } from "siteComponents/Misc";
import { Error } from "siteComponentsLibrary/Feedback";

const ContainerWrapper = ({ children, ["data-cy"]: dataCy }) => (
	<div
		className="container-fluid container-lg layout-max-width"
		data-cy={dataCy}
	>
		{children}
	</div>
);

const LayoutPageBase = (props) => {
	// const isLoggedIn = useSelector(selectors.auth.isLoggedIn);

	const {
		children,
		className,
		siteName,
		headingText,
		isSticky = false,
		stickyOffset = 0,
		descriptionContent,
		autoComplete = false,
		toolbarText,
		routeExit,
		// lctr,
		scrollToTop = false,
		snapToTop = true,
		// isStandardCss = true,
		onHeaderStick = () => {},
		onHeaderUnstick = () => {},
		showHeader = true,

		loadingIsEnabled = false,
		loadingMessage = undefined,
		errorMessage = undefined,
	} = props;

	const _className = [
		!config.isProd ? "lctr-page-layout" : "",
		// !config.isProd && lctr ? lctr : "",
		"layout-page",
		descriptionContent ? "has-subheader" : "",
		className,
		// isStandardCss && "standard-css",
	].toClassName();

	//const showHeader = true; //Aways show the header so that there's a space.

	useEffect(() => {
		fnSnapToTop();
	}, [loadingIsEnabled]);
	// useSnapToTop(snapToTop);
	// useScrollToTop(scrollToTop);

	// **************
	// ** WRAPPERS **
	// **************

	const HeaderWrapper = useMemo(() => {
		if (isSticky) {
			if (headingText || descriptionContent) {
				// return ({ children }) => <div className="sticky-top">{children}</div>;
				// eslint-disable-next-line react/display-name
				return ({ children }) => {
					const [isActive, setIsActive] = useState(false);

					const _className = [
						!isActive && "header-sticky",
						isActive && "header-sticky-active",
					].toClassName();

					return (
						<div className="z-index-max">
							<ContainerWrapper>
								<h1 className="site-name">{siteName}</h1>
							</ContainerWrapper>
							<Sticky
								className={_className}
								stickyClassName="header-sticky-inner"
								topOffset={stickyOffset}
								onChange={(v) => {
									setIsActive(v);

									if (v) onHeaderStick();
									else onHeaderUnstick();
								}}
							>
								<div
									data-cy="page-header"
									className={[
										"header-container",
										"header-container-sticky",

										// "position-relative",
										// "position-absolute",
										// "top-0",
										// "start-0",
									].toClassName()}
								>
									<ContainerWrapper> {children}</ContainerWrapper>
								</div>
							</Sticky>
						</div>
					);
				};
			}
		}

		return ({ children }) => (
			<div
				className={["header-container", className].toClassName()}
				data-cy="page-header"
			>
				<ContainerWrapper>
					<h1 className="site-name">{siteName}</h1>
				</ContainerWrapper>

				<ContainerWrapper>{children}</ContainerWrapper>
			</div>
		);
	}, [
		isSticky,
		stickyOffset,
		headingText || descriptionContent ? true : false,
		//Needed, as sometimes "isSticky" is set on a different render before the headingText is set, meaning that the sticky part never renders
	]);

	// **************
	// ** FINAL RETURN **
	// **************
	return (
		<div className={_className} {...generateCypressProps("page", props)}>
			{toolbarText && <Helmet>{toolbarText}</Helmet>}

			{showHeader && (
				<HeaderWrapper>
					{headingText && (
						<div className="header-text" data-cy="page-header-main">
							{headingText}
						</div>
					)}

					{descriptionContent && (
						<div className="description-text" data-cy="page-header-description">
							{descriptionContent}
						</div>
					)}
				</HeaderWrapper>
			)}

			<div className="body-container">
				{loadingIsEnabled && (
					<ContainerWrapper>
						<div
							className="layout-loading-container row"
							data-cy="page-loading-container"
						>
							<div className="col-12 d-flex justify-content-center mt-5 mb-5">
								<Loading size={16} />
							</div>
							{loadingMessage && (
								<div className="col-12 d-flex justify-content-center mb-5">
									{loadingMessage}
								</div>
							)}
						</div>
					</ContainerWrapper>
				)}

				{!loadingIsEnabled && <ContainerWrapper>{children}</ContainerWrapper>}
			</div>

			{/* <div className="footer-container"></div> */}
		</div>
	);
};

export default LayoutPageBase;
