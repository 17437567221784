import React from "react";
import Base from "../Base";

import { Button } from "siteComponentsLibrary/Buttons";

const ErrorBoundary = (props) => {
	const { newRoute = "/" } = props;
	console.log("ErrorBoundary", props);

	// const navigate = useNavigate();

	const onClick = () => {
		window.location.href = newRoute;
	};

	return (
		<Base heading="We're very sorry!" icon="icon-error">
			<div>
				It appears that something unexpected has happened and we're unable to
				proceed with your request.
			</div>

			<div>You can start a new quote below.</div>

			<div>
				<Button onClick={onClick} className="btn-primary" data-cy="btn:start-new-quote">Start a new quote</Button>
			</div>
		</Base>
	);
};

export default ErrorBoundary;
