import React, {
	useEffect,
	useState,
	useRef,
	useMemo,
	useCallback,
} from "react";
const debugShowLoadingMessage = false;

import _ from "lodash";
import { selectors, actions } from "siteStore";
import dayjs from "dayjs";
import config from "config";

import { useOutletContext, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import { fnValidateInceptionDate } from "./InceptionDate.Correction";
import { useDispatch } from "siteFunctions/hooks";
import { ButtonLink, Button } from "siteComponentsLibrary/Buttons";
import useTimer from "moveToLibrary/hooks/useTimer";

import usePrevious from "moveToLibrary/hooks/usePrevious";
import vehicleMappingList from "./Risk/Edit/YourVehicle/Picker/responseMappings.common";
import validationLists from "./validationLists";

const useGapPageLogic = (args = {}) => {
	const { sectionId, pageId, paymentId, handoverId, routeDictionary } = args;

	const isMounted = useRef(false);

	const [showStoreErrors, setShowStoreError] = useState(false);
	const [basePropsOverrride, setBasePropsOverride] = useState({
		loadingIsEnabled: false,
		loadingMessage: undefined,
		errorMessage: undefined,
	});

	const commonData = useOutletContext();

	const location = useLocation();
	const baseRoute = commonData.dictionary.baseRoute;

	const prevSectionId = usePrevious(sectionId);
	const prevPageId = usePrevious(pageId);
	const locationPathname = location.pathname;
	const prevLocationPathname = usePrevious(locationPathname);
	const chosenOptions = commonData.quote.data.chosenOptions;
	const currentQuote = commonData.quote.data.currentQuote;
	const isRiskValid = commonData.risk.status.isValid;

	const vehicleType = useSelector((state) =>
		commonData.risk.selectors.value(state, "Risk/Vehicle/VehicleType")
	);

	const status_GetQuote = commonData.quote.status.getQuote;
	const status_RegisterPayment = commonData.quote.status.registerPayment;
	const status_purchaseResponse = commonData.quote.status.purchaseResponse;

	const dispatch = useDispatch();

	const fnSetBasePropsOverride = (...args) => setBasePropsOverride(...args);

	const [loadingOnNextData, setLoadingOnNextData] = useState(undefined);
	const [layoutPropsOverride, setLayoutPropsOverride] = useState(undefined);

	const fnIsEntrySection = (v) => v === sectionId && v !== prevSectionId;
	const fnIsEntryPage = (v) => v === pageId && v !== prevPageId;

	const fnGenerateBaseLayoutProps = (pagerData = {}, options = {}) => {
		const { isCheckStoreValid = false, isCheckStoreValidMessage = undefined } =
			options;

		const _pagerData = _.cloneDeep(pagerData);

		// Add a LOADING SHIELD to any onNext functions
		if ("onNext" in pagerData) {
			_pagerData.onNext = async (...args) => {
				setLoadingOnNextData({
					enabled: true,
					//  message: "xxxx OnNext message"
				});
				await pagerData.onNext(...args);
				setLoadingOnNextData(undefined);
			};
		}

		const retData = (function () {
			const base = {
				loadingIsEnabled: false,
				loadingMessage: undefined,
				errorMessage: undefined,
				pagerData: _pagerData,
			};

			if (layoutPropsOverride) {
				return {
					...base,
					...layoutPropsOverride,
				};
			}

			if (loadingOnNextData?.enabled) {
				return {
					...base,
					loadingIsEnabled: true,
					loadingMessage: loadingOnNextData.message,
				};
			}

			if (commonData.status.showSessionSaving) {
				return {
					...base,
					// headingText: undefined,
					// descriptionContent: undefined,
					loadingIsEnabled: true,
					loadingMessage: debugShowLoadingMessage
						? "xxx session save"
						: undefined,
				};
			}

			if (basePropsOverrride.loadingIsEnabled)
				return {
					...base,
					...basePropsOverrride,
				};

			if (status_GetQuote?.isRunning)
				return {
					...base,
					// headingText: undefined,
					// descriptionContent: undefined,
					loadingIsEnabled: true,
					loadingMessage: debugShowLoadingMessage ? "xxxgetQuote" : undefined,
				};
			if (status_RegisterPayment?.isRunning)
				return {
					...base,
					// headingText: undefined,
					// descriptionContent: undefined,
					loadingIsEnabled: true,
					loadingMessage: debugShowLoadingMessage
						? "xxxregisterPayment"
						: undefined,
				};

			// **DISABLED -- the Interstitial page handles this
			// if (status_purchaseResponse?.isRunning) {
			// 	return {
			// 		...base,
			// 		headingText: undefined,
			// 		descriptionContent: undefined,
			// 		loadingIsEnabled: true,
			// 		// loadingMessage: showLoadingMessage ? "xxxpurchaseResponse" :undefined,
			// 	};
			// }

			if (isCheckStoreValid && !isRiskValid)
				return {
					...base,
					errorMessage: showStoreErrors && isCheckStoreValidMessage,
					pagerData: {
						...(base.pagerData || {}),
						fnOnNext: () => {
							setShowStoreError(true);
						},
					},

					// test: { showStoreErrors, isCheckStoreValidMessage },
				};

			if (status_GetQuote.isError && status_GetQuote.errorMessage)
				return { ...base, errorMessage: status_GetQuote.errorMessage };

			if (status_RegisterPayment?.errorMessage)
				return { ...base, errorMessage: status_RegisterPayment.errorMessage };

			return base;
		})();

		return retData;
		// return { ...retData, pagerData };
	};

	const fnRegisterPayment = async (args = {}) => {
		const { directDebit, paymentMethod } = args;
		const fnGenerateUrl = (...urlItems) =>
			[
				[
					window.location.origin,
					(function () {
						if (baseRoute === "/") return "";
						return baseRoute;
					})(),
				].join(""),
				...urlItems.filter(Boolean),
			].join("/");

		// fnSetBasePropsOverride((x) => ({
		// 	...x,
		// 	// headingText: undefined,
		// 	// descriptionContent: undefined,
		// 	loadingIsEnabled: true,
		// 	loadingMessage: debugShowLoadingMessage
		// 		? "xxxxpaymentoverride"
		// 		: undefined,
		// }));

		const payload = {
			redirects: {
				PaymentSuccess: fnGenerateUrl(
					routeDictionary.sections.wrapupInterstitial
				),
				Back: (function () {
					if (paymentMethod === "M")
						return fnGenerateUrl(routeDictionary.sections.risk, "direct-debit");
					return fnGenerateUrl(routeDictionary.sections.risk, "your-quote");
				})(),
				PaymentError: fnGenerateUrl(
					routeDictionary.sections.feedback,
					"processingerror"
					// "paymentError"
				),
				Timeout: fnGenerateUrl(
					routeDictionary.sections.feedback,
					"processingerror"
					// "timeout"
				),
				TechnicalError: fnGenerateUrl(
					routeDictionary.sections.feedback,
					"processingerror"
					// "technicalError"
				),

				Refused: fnGenerateUrl(
					routeDictionary.sections.feedback,
					"notauthorised"
					// "refused"
				),
				Referred: fnGenerateUrl(
					routeDictionary.sections.feedback,
					"notauthorised"
					// "referred"
				),
				Declined: fnGenerateUrl(
					routeDictionary.sections.feedback,
					"notauthorised"
					// "declined"
				),
			},
			directDebit: directDebit,
		};

		// if (directDebit) payload.directDebit = directDebit;

		const response = await commonData.quote.functions.wrapup.registerPayment(
			payload
		);

		if (response.isSuccess && response?.data?.RedirectUrl) {
			commonData.functions.navigate.loadUrl(response.data.RedirectUrl);
			return true;
		}

		// fnSetBasePropsOverride((x) => ({
		// 	...x,
		// 	loadingIsEnabled: false,
		// 	loadingMessage: undefined,
		// }));
		return false;
	};
	const fnGoto = (route) => {
		if (!route) throw `Error in fnGoto`;
		console.log("fnGoto:", route);
		commonData.functions.navigate.to(route);
	};

	// isVisitedEntryPoint CHECK: Used to track the user has started via an approved point;
	if (false) {
		const hasEntered = (function () {
			const key = "isEntry";
			const fnGet = () => sessionStorage.getItem(key) || false;

			// Do this outside of an effect
			const isEntryPoint = (function () {
				if (fnGet() === true) return false;
				if (sectionId === "wrapup") return true;
				if (sectionId === "feedback") return true;
				if (sectionId === "risk") {
					if (pageId === "") return true;
					if (pageId === "your-details") return true;
				}
				return false;
			})();

			if (isEntryPoint) {
				console.log("******************************");
				console.log("** ENTRY DETECTED: ", sectionId, pageId);
				console.log("******************************");

				sessionStorage.setItem(key, true);
			}

			return fnGet();
		})();
	}

	// Paymentmethod
	// {
	// 	const paymentMethodInitial = useSelector((state) =>
	// 		commonData.risk.selectors.value(state, "Risk/Proposer/PaymentMethod")
	// 	);

	// 	useEffect(() => {
	// 		//(e.g. after doing an F5/persist load, then no need to set it)
	// 		if (!isMounted.current) return;

	// 		commonData.quote.functions.choosePaymentMethod(paymentMethodInitial);
	// 	}, [paymentMethodInitial]);
	// }

	// WRAPUP LOGIC
	useEffect(() => {
		if (!paymentId) return;
		if (sectionId !== "wrapupinterstitial") return;
		if (commonData.wrapup.data) return; // If have CACHED data, return that. NOTE: This will also include if isProcessing === true

		const fnGetPurchaseResponse = async () => {
			if (config.isDev) {
				const fnSleep = (delay) =>
					new Promise((resolve) => setTimeout(resolve, delay));

				const sampleWrapupData = {
					PurchaseSuccess: false,
					IsSuccess: true,
					PolicyNumber: "TESTGAP0000040",
					PolicyStartDate: "2024-04-23T23:00:01",
					WebReference: "28486-59410-69319",
					Affinity: "FREEDOM",

					Status: "SUCCESS",
					isMock: true,
				};

				if (paymentId === "mocksuccess") {
					// await fnSleep(5000);
					return {
						isSuccess: true,
						data: {
							...sampleWrapupData,
							PurchaseSuccess: true,
							IsSuccess: true,
						},
					};
				}
				if (paymentId === "mockfail") {
					// await fnSleep(5000);
					return {
						isSuccess: true,
						data: {
							...sampleWrapupData,
							PurchaseSuccess: true,
							IsSuccess: false,
						},
					};
				}
			}

			if (!currentQuote) {
				return { isSuccess: false };
			}

			const response =
				await commonData.quote.functions.wrapup.getPurchaseResponse({
					paymentId: paymentId,
					webReference: currentQuote.WebReference,
				});
			return response;
		};

		const myFn = async () => {
			const fnBase = async () => {
				commonData.wrapup.clear();
				commonData.wrapup.set({ isProcessing: true });

				const response = await fnGetPurchaseResponse();
				const state = commonData.functions.storeHelpers.getState();
				const fnGetValue = (key) => commonData.risk.selectors.value(state, key);

				console.log("fnGetPurchaseResponse()", { response });

				commonData.wrapup.set(
					(function () {
						if (!response?.isSuccess || !response?.data) {
							commonData.functions.error.log({
								errorType: "Wrapup error",
								errorDescription: `Unknown paymentId: "${paymentId}"`,
							});

							return { isError: true };
						}

						const retData = response?.data;
						retData.Email = fnGetValue("Risk/Proposer/Email");
						retData.RegNumber = fnGetValue("Risk/Vehicle/RegNumber");
						retData.VehicleType = (function () {
							switch (fnGetValue("Risk/Vehicle/VehicleType")) {
								case "GoodsVehicle":
									return "van";

								case "Car":
									return "car";
							}
						})();
						return retData;
					})()
				);

				await commonData.functions.process.reset();
				await commonData.functions.session.saveQuietly();
			};

			await Promise.all([
				fnBase(),
				//#3329 -- Set a min delay of 3 seconds
				new Promise((resolve) => setTimeout(resolve, 3000)),
			]);

			fnGoto(routeDictionary.sections.wrapup, { replace: true });
		};

		myFn();
	}, [sectionId === "wrapup"]);

	// ************************************
	// PAGE CHANGE LOGIC
	// ************************************
	useEffect(() => {
		const myFn = async () => {
			const fnConsoleLog = (...args) => {
				// console.groupCollapsed("GAPLOGIC", ...args);
				// console.groupEnd();
				console.log("");
				console.log("");
				console.log("************************************");
				console.log("GAPLOGIC:", ...args);
				console.log("************************************");
				console.log("");
				console.log("");
			};

			console.log("PATHCHANGE:", {
				prevLocationPathname,
				locationPathname,
				prevSectionId,
				prevPageId,
				sectionId,
				pageId,
				commonData,
			});

			setShowStoreError(false);

			// QUOTE AND BUY HANDOVER
			{
				const isHandover = (function () {
					// if (isRiskValid) return false;
					if (fnIsEntrySection("quoteandbuyhandover")) {
						if (!handoverId) throw `Error -- missing handoverId`; //NOTE: should never reach here as the /routes prevents it
						return true;
					}
					return false;
				})();

				if (isHandover) {
					const fnHandleError = (e = `Error in handover -- ${handoverId}`) => {
						console.log("ERROR", e);
						throw e;
					};

					const fnRecallData = async () => {
						try {
							await commonData.functions.process.reset();

							const response =
								await commonData.recall.functions.quoteAndBuyHandover({
									token: handoverId,
								});

							if (response.isSuccess) {
								await commonData.functions.session.saveQuietly();
								return;
							}

							const errorMessage = response?.errorMessage;
							fnHandleError(errorMessage);
						} catch (e) {
							fnHandleError(e);
						}

						fnHandleError();
					};
					await fnRecallData();
				}
			}

			// WRAPUP
			{
				//If we have wraup data, then redirect to the last page
				//commonData.wrapup.data;
				const isRedirect = (function () {
					if (sectionId === "wrapup") return false;
					if (commonData.wrapup.data) return true;
					return false;
				})();

				if (isRedirect) {
					fnGoto(routeDictionary.sections.wrapup, { replace: true });
				}
			}
			// STATUS DATA RESETS
			{
				if (
					(function () {
						if (sectionId === "risk" && pageId === "your-quote") return false;

						if (status_GetQuote.errorMessage) return true;
						return false;
					})()
				) {
					console.log("RESETING STATUS DATA: getQuote()");
					commonData.quote.functions.status.reset.getQuote();
				}

				if (
					(function () {
						if (status_RegisterPayment.errorMessage) {
							if (pageId !== prevPageId) return true;
							if (sectionId !== prevSectionId) return true;
						}
						return false;
					})()
				) {
					console.log("RESETING STATUS DATA: registerPayment()");
					commonData.quote.functions.status.reset.registerPayment();
				}

				if (
					(function () {
						if (sectionId === "wrapup") return false;
						if (status_RegisterPayment.errorMessage) return true;
						return false;
					})()
				) {
					console.log("RESETING STATUS DATA: purchasePolicy()");
					commonData.quote.functions.status.reset.purchasePolicy();
				}
			}

			// QUOTE RESET
			{
				//http://localhost:8083/risk/feedback/paymentError
				const isQuoteReset = (function () {
					if (!commonData.quote.data.hasQuote) return false;

					if (sectionId === "recall") return true;
					if (sectionId === "risk") {
						if (!["your-quote", "direct-debit"].includes(pageId)) return true;
					}
					return false;
				})();

				if (isQuoteReset) {
					fnConsoleLog("QUOTE RESET", { sectionId, pageId });
					await commonData.quote.functions.reset();
				}
			}

			// AUTO REQUOTE
			{
				const isAutoRequote = (function () {
					if (!isRiskValid) return false;
					if (sectionId !== "risk") return false;
					if (pageId === "your-quote" && prevPageId !== "your-quote")
						return true;
					return false;
				})();
				// const currentQuote = commonData.quote.data.currentQuote;

				if (isAutoRequote) {
					setLayoutPropsOverride({
						headingText: undefined,
						descriptionContent: undefined,
						loadingIsEnabled: true,
						loadingMessage: undefined,
					});

					await commonData.quote.functions.getQuote({
						autoSetFirstTier: true,
						autoUpdateQuoteParams: true,
						isPayloadChangedCheck: true,
					});

					await commonData.functions.session.save();
					setLayoutPropsOverride(undefined);
				}
			}

			// Reset PAYMENT METHOD
			{
				const isReset = (function () {
					if (!chosenOptions.paymentMethod) return false;
					// fnIsEntrySection
					// fnIsEntryPage

					if (sectionId == "risk") {
						switch (pageId) {
							case "your-quote":
							case "direct-debit":
								return false;
							default:
								return true;
						}
					}
					return false;
				})();

				if (isReset) {
					await commonData.quote.functions.resetPaymentMethod();
				}
			}

			// Default PAYMENT METHOD
			// {
			// 	if (
			// 		!chosenOptions.paymentMethod &&
			// 		sectionId == "risk" &&
			// 		pageId === "your-quote" &&
			// 		prevPageId !== "your-quote"
			// 	) {
			// 		//UPDATE PAYMENT METHOD if not already chosen
			// 		await commonData.quote.functions.choosePaymentMethod(
			// 			commonData.risk.values.paymentMethod
			// 		);
			// 	}
			// }
		};
		myFn();
	}, [sectionId, pageId]);

	// VEHICLE TYPE CHANGE
	useEffect(() => {
		const myFn = async () => {
			//(e.g. after doing an F5/persist load, then no need to set it)
			if (!isMounted.current) return;
			if (sectionId === "quoteandbuyhandover") return;

			console.log("VEHICLE RESET:", vehicleType);

			vehicleMappingList
				.filter(Boolean)
				.filter((x) => x.path)
				.filter((x) => x.responsePath)
				.map((x) => x.path)
				.concat("Risk/MarketingConsent/PublicLiabilityRenewal")
				.forEach(async (path) => {
					console.log("reseting", path);
					await dispatch(
						commonData.risk.actions.updateErrorShow({
							path: path,
							value: false,
						})
					);
					await dispatch(
						commonData.risk.actions.update({
							path: path,
							value: undefined,
						})
					);
				});
		};

		myFn();
	}, [vehicleType]);

	// isMounted
	useEffect(() => {
		isMounted.current = true;
	}, []);

	const dataBlocker = (function () {
		const retData = useSelector((state) => {
			const base = {
				icon: "icon-time-limit",
				heading: "We're sorry!",
			};

			const baseRestartRisk = {
				...base,
				children: (
					<>
						<p>We don't have enough information to provide you with a quote.</p>
						<p>
							Please{" "}
							<ButtonLink
								className="btn-link-inline"
								onClick={() =>
									fnGoto(routeDictionary.sections.start, { replace: true })
								}
								data-cy="btn:restart-again"
							>
								click here to review your details.
							</ButtonLink>
						</p>
					</>
				),
			};

			const baseRestartQuote = {
				children: (
					<>
						<p>We're missing some details.</p>
						<p>
							Please{" "}
							<ButtonLink
								className="btn-link-inline"
								onClick={() =>
									fnGoto(
										[routeDictionary.sections.start, "your-quote"].join("/"),
										{ replace: true }
									)
								}
								data-cy="btn:restart-again"
							>
								click here to get a price.
							</ButtonLink>
						</p>
					</>
				),
			};

			const baseSessionExpired = {
				...base,
				icon: "icon-time-limit",
				heading: "Your session has expired",
				children: (
					<>
						<div>
							For your security your session with us has expired due to
							inactivity.
						</div>
						<div>You can start a new quote below.</div>

						<div>
							<Button
								data-cy="clear-timeout"
								onClick={() =>
									fnGoto(routeDictionary.sections.start, { replace: true })
								}
							>
								Start a new quote
							</Button>
						</div>
					</>
				),
			};

			const fnGenerateLogging = (errorType, errorDescription) => {
				return {
					onLoad: async () => {
						commonData.functions.error.log({
							errorType: errorType,
							errorDescription: errorDescription,
						});
					},
				};
			};

			// ENTRYPOINT CHECK
			{
				// DISABLED for now. Maybe replaced with a HIGH LEVEL checker (that doesn't create a PERSIST ID)
				// if (!hasEntered) return { ...baseSessionExpired };
			}

			// GOTO START:
			{
				// RISK SKIP PAGE CHECK:
				{
					const dataArray = (function () {
						switch (pageId) {
							case "":
								return [];

							case "your-vehicle":
								return [...validationLists.start];
							case "your-details":
								return [
									...validationLists.start,
									...validationLists.yourVehicle,
								];
						}

						return undefined;
					})();

					if (dataArray && dataArray.length >= 1) {
						const isValid = commonData.risk.selectors.isValid(
							state,
							dataArray.map((x) => x.replaceAll("/*", ""))
						);

						if (!isValid) return { ...baseRestartRisk };
					}
				}

				//MISSING RISK:
				{
					switch (sectionId) {
						case "risk": {
							switch (pageId) {
								case "your-quote":
								case "direct-debit": {
									if (!isRiskValid)
										return {
											...baseRestartRisk,
										};
								}
							}
						}
					}
				}

				// WRAPUP:
				{
					const isRedirect = (function () {
						const wrapupData = commonData.wrapup.data;

						if (sectionId === "wrapupinterstitial") {
							if (!paymentId) return true;
						}

						if (sectionId === "wrapup") {
							if (wrapupData) return false;
							if (wrapupData?.isProcessing) return true;
							return true;
						}

						return false;
					})();

					if (isRedirect) return { ...baseSessionExpired };
				}
			}

			// DIRECT DEBIT CHECK
			{
				if (sectionId === "risk" && pageId === "direct-debit") {
					if (!commonData.quote.data.hasQuote) return { ...baseRestartQuote };
				}
			}

			return undefined;
		});

		if (retData) {
			return { children: retData.children, onLoad: retData.onLoad };
		}

		return undefined;

		//** Example useTimer() -- do not delete */
		if (false) {
			const timeData = useTimer({
				enabled: isSkippedPage || isMissingRiskYourQuote,
				time: 5,
				onEnd: () => console.log("END"),
				onTick: (timeLeft) => console.log(timeLeft),
			});

			console.log("useTimer", timeData);
		}
	})();

	return {
		dataBlocker: dataBlocker,
		// isInceptionDateValid: useSelector((state) => {
		// 	const value = commonData.risk.selectors.value(
		// 		state,
		// 		"Risk/PolicyInceptionDate"
		// 	);
		// 	return fnValidateInceptionDate(value);
		// }),
		fnGenerateBaseLayoutProps: fnGenerateBaseLayoutProps,
		fnSetBasePropsOverride: fnSetBasePropsOverride,
		fnRegisterPayment: fnRegisterPayment,
		fnGoto: fnGoto,
	};
};

export default useGapPageLogic;
