import React from "react";
import { getPriceDetails, PriceDisplay } from "./getPriceDetails";

export const CardAnnual = (props) => {
	const {
		payInFullPremium,
		instalmentDetails,
		allowPayMonthly,
		classNamePound,
		classNamePence,
		classNamePostFix,
		postFixYear,
	} = props;

	if (!payInFullPremium) return null;

	const className = ["price-card", "annual", "card", "h-md-100"]
		.filter((x) => x)
		.join(" ");

	const priceDetails = getPriceDetails({
		instalmentDetails: instalmentDetails,
		isInstalments: false,
		PayInFullPremium: payInFullPremium,
		postFixYear: postFixYear,
	});

	return (
		<div className={className}>
			<div className="card-header text-center bg-transparent border-0 pb-1">
				{priceDetails.mainPriceText({
					classNamePound,
					classNamePence,
					classNamePostFix,
				})}
			</div>

			{allowPayMonthly &&
				priceDetails.premiumSavingValue !== undefined &&
				priceDetails.premiumSavingValue > 0 && (
					<div className="card-body price-info">
						<span className="pb-1 d-block">
							Paying in full saves you{" "}
							<PriceDisplay value={priceDetails.premiumSavingValue} />
						</span>
					</div>
				)}
		</div>
	);
};

export const CardMonthly = (props) => {
	const {
		instalmentDetails,
		classNamePound,
		classNamePence,
		classNamePostFix,
		postFixMonth,
	} = props;

	if (!instalmentDetails) return null;

	const priceDetails = getPriceDetails({
		instalmentDetails: instalmentDetails,
		isInstalments: true,
		postFixMonth: postFixMonth,
	});

	const _className = ["price-card", "monthly", "card", "h-md-100", "h-lg-auto"]
		.filter((x) => x)
		.join(" ");

	return (
		<div className={_className}>
			<div className="card-header text-center bg-transparent border-0 pb-1">
				{priceDetails.mainPriceText({
					classNamePound,
					classNamePence,
					classNamePostFix,
				})}
			</div>

			<div className="card-body price-info">
				{priceDetails.depositPriceText() &&
					priceDetails.depositPriceText().map((t, i) => (
						<span className="pb-1 d-block" key={i}>
							{t}
						</span>
					))}
			</div>
		</div>
	);
};
