import React from "react";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import routeData from "./index";

const routesBrowser = createBrowserRouter(routeData);

const RouteContainer = () => {
	return <RouterProvider router={routesBrowser} />;
};

export default RouteContainer;
