import React from "react";

import { connect } from "react-redux";
import JSONdisplay from "./JsonDisplay";

const mapStateToProps = (state) => {
	return { state };
};

const DevReduxStoreViewer = connect(mapStateToProps)((props) => {
	//todo: dev only
	const { state } = props;
	if (!state) return null;

	return (
		<div className="row">
			<div className="col">
				<h3>Global State</h3>
				<JSONdisplay state={state} expanded={true} />
			</div>
		</div>
	);
});

export default DevReduxStoreViewer;
