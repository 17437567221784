import siteConfig from "config";
import createSession from "moveToLibrary/redux/session";
const debug = siteConfig.isDev;
import services from "siteService";
import JSONhelper from "@library/common/helpers/misc/json";

// ** Commented out for now **
// import { compress, decompress } from "shrink-string";
const compress = async (v) => v;
const decompress = async (v) => v;
const sessionKey = "sessionId";

const storeServices = {
	get: async (payload = {}) => {
		const { sessionId } = payload;
		const response = await services.persist.get({
			Id: sessionId,
		});
		if (response === "ABORT") return "ABORT";

		return {
			data: JSONhelper.parse(await decompress(response?.DataString)),
			isSuccess: response?.Response === "SUCCESS",
		};
	},
	save: async (payload = {}) => {
		const { data, sessionId } = payload;
		if (!data) return undefined;

		const savePayload = {
			Id: sessionId,
			DataString: await compress(data),
		};

		const response = await services.persist.save(savePayload);
		if (response === "ABORT") return "ABORT";

		return {
			isSuccess: response.Response === "SUCCESS",
		};
	},

	init: async (payload = {}) => {
		const sessionData = await (async function () {
			const sessionId = sessionStorage.getItem(sessionKey);

			// Do we create a new SESSION?
			if (!sessionId) {
				const _response = await services.persist.create();

				return {
					isNew: true,
					data: undefined,
					sessionId: _response.SessionId,
					isSuccess: _response.Status === "SUCCESS",
				};
			}

			// ...or use an existing SESSION?
			const _response = await services.persist.get({
				Id: sessionId,
			});

			const isSuccess = _response.Response === "SUCCESS";
			if (!isSuccess)
				return {
					sessionId: sessionId,
					isSuccess: isSuccess,
				};

			return {
				data: JSONhelper.parse(await decompress(_response.DataString)),
				sessionId: sessionId,
				isSuccess: isSuccess,
			};
		})();

		if (sessionData === "ABORT") return "ABORT";

		if (sessionData.isNew) {
			sessionStorage.setItem(sessionKey, sessionData.sessionId);
		}

		return {
			sessionId: sessionData.sessionId,
			isSuccess: sessionData.isSuccess,
			data: sessionData.data,
		};
	},

	initOLD: async (payload = {}) => {
		const { get = true } = payload;

		let sessionId = sessionStorage.getItem(sessionKey);
		let isSuccess = true;

		if (!sessionId) {
			const response = await services.persist.create();
			if (response === "ABORT") return "ABORT";

			const sessionIdNew = response.SessionId;
			const isSuccessNew = response.Response === "SUCCESS";

			sessionStorage.setItem(sessionKey, sessionIdNew);
			sessionId = sessionIdNew;
			isSuccess = isSuccessNew;
		}

		if (get) {
			const response = await services.persist.get({
				Id: sessionId,
			});
			if (response === "ABORT") return "ABORT";

			let isSuccess = response.isSuccess;

			if (!response.DataString) return { sessionId };

			return {
				sessionId,
				isSuccess,
				data: JSONhelper.parse(await decompress(response.DataString)),
			};
		}

		return { sessionId, isSuccess };
	},
	delete: async (payload = {}) => {
		const sessionId = sessionStorage.getItem(sessionKey);
		const response = await services.persist.delete({
			Id: sessionId,
		});
		if (response === "ABORT") return "ABORT";

		sessionStorage.removeItem(sessionKey);
	},
};

const session = createSession({
	storeName: "session",
	excludeKeys: [],
	services: storeServices,
	debug,
	abortChecker: require("./common").abortChecker,
});

export default session;
