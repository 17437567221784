import React from "react";
import { list, set, item } from "@library/common/database/classes";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";
import _ from "lodash";

import constants from "libraryConstants/database";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import generateBaseRuleData from "../../../generateBaseRuleData";
import { Name } from "./Name";

import generateConviction from "./Conviction";

export default (args, itemArgs = {}) => {
	require("@library/common/helpers/misc/argChecker")(itemArgs, [
		"IsResidentSinceBirth",
		"ResidentSince",
		"DateOfBirth",
		"PolicyInceptionDate",
	]);

	// console.log("dddddd", args, itemArgs);

	const ruleItemPersonLogic = new ruleItem(
		{
			...generateBaseRuleData("vehicle", "personLogic"),
		},
		{
			..._.pick(itemArgs, [
				"IsResidentSinceBirth",
				"ResidentSince",
				"DateOfBirth",
				"PolicyInceptionDate",
			]),
			LicenceDate: new dataSetArgItem({
				componentTag: "Date",
				useDefaultComponentSet: true,
			}),
			LicenceType: new dataSetArgItem({
				componentTag: "Type",
				useDefaultComponentSet: true,
			}),
		}
	);

	return new set({
		...args,
		items: {
			Category: new item({
				submitKey: "Category",
				defaultValue: itemArgs.defaultLicenceCategoryValue,
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),
			Convictions: generateConviction({
				submitKey: "Convictions",
			}),

			// // Custom field for updating the date
			// YearsHeld: new item({
			// 	ruleDependencies: [
			// 		{ componentTag: "Date" },
			// 		{ componentTag: "YearsHeld" },
			// 		{ componentTag: "NotYetObtained" },
			// 	],

			// 	label: "Years license held",
			// 	componentTag: "YearsHeld",
			// 	html: {
			// 		itemData: (function () {
			// 			let retObj = [];
			// 			for (let i = 1; i <= 10; i++) {
			// 				retObj.push({ label: `${i}`, value: i });
			// 			}
			// 			return retObj;
			// 		})(),
			// 	},
			// 	rules: [
			// 		new ruleItem({
			// 			...generateBaseRuleData("vehicle", "licenceLogic"),},{
			// 			curKey: "YearsHeld",
			// 			YearsHeld: { componentTag: "YearsHeld" },
			// 			NotYetObtained: { componentTag: "NotYetObtained" },
			// 			Date: { componentTag: "Date" },
			// 		}),
			// 	],
			// }),

			// NotYetObtained: new item({
			// 	ruleDependencies: [
			// 		{ componentTag: "Date" },
			// 		{ componentTag: "YearsHeld" },
			// 		{ componentTag: "NotYetObtained" },
			// 	],
			// 	rules: [
			// 		new ruleItem({
			// 			...generateBaseRuleData("vehicle", "licenceLogic"),},{
			// 			curKey: "NotYetObtained",
			// 			YearsHeld: { componentTag: "YearsHeld" },
			// 			NotYetObtained: { componentTag: "NotYetObtained" },
			// 			Date: { componentTag: "Date" },
			// 		}),
			// 	],

			// 	label: "Years license held",
			// 	componentTag: "NotYetObtained",
			// }),
			Date: new item({
				submitKey: "Date",
				exampleValue: "2020-01-01T00:00:00",
				label: "Date licence obtained",
				helpText: (
					<>
						<span className="d-block">
							This date can be found on the reverse of the driving licence;
							under category B.
						</span>
						<span>
							If you don't have the licence handy, please enter as close as
							posssible.
						</span>
					</>
				),
				fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
				fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),

				rules: [
					ruleItemPersonLogic,
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter the licence date",
						}
					),
					new ruleItem(
						{
							...generateBaseRuleData("general", "dateComparison"),
						},
						{
							errorMessage: "", // "You can't enter a future date",
							yearOffset: 0,
							comparator: "<=",
						}
					),
					// new ruleItem({
					// 	...generateBaseRuleData("vehicle", "licenceLogic"),},{
					// 	curKey: "Date",
					// 	YearsHeld: { componentTag: "YearsHeld" },
					// 	NotYetObtained: { componentTag: "NotYetObtained" },
					// 	Date: { componentTag: "Date" },
					// }),
				],
			}),
			Type: new item({
				label: "Type of driving licence held",
				submitKey: "Type",
				exampleValue: "Full Licence",
				rules: [
					ruleItemPersonLogic,
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),
		},
	});
};
