import React from "react";
import { CardAnnual, CardMonthly } from "./PriceCards";

export const PriceContainer = (props) => {
	const { dataCy, children, isStickyAnimationEnabled } = props;

	return (
		<div className="price-container" data-cy={dataCy}>
			{children && (
				<div className="row">
					<div
						className={[
							"col-12",
							"price-children",
							isStickyAnimationEnabled && "is-selected price-animated",
						].toClassName()}
					>
						{children}
					</div>
				</div>
			)}
		</div>
	);
};

export const PriceCardStaticDual = (props) => {
	const {
		allowPayInFull,
		allowPayMonthly,
		payInFullPremium,
		instalmentDetails,
		separatorContent = undefined,
		postFixMonth,
		postFixYear,
	} = props;

	if (allowPayMonthly && allowPayInFull) {
		return (
			<div className="row">
				<div className="col-12 col-md">
					<CardMonthly
						instalmentDetails={instalmentDetails}
						postFixMonth={postFixMonth}
					/>
				</div>
				{separatorContent && (
					<div className="col-12 col-md-auto">
						{separatorContent}
					</div>
				)}
				<div className="col-12 col-md">
					<CardAnnual
						payInFullPremium={payInFullPremium}
						instalmentDetails={instalmentDetails}
						allowPayMonthly={allowPayMonthly}
						postFixYear={postFixYear}
					/>
				</div>
			</div>
		);
	}

	if (allowPayInFull)
		return (
			<div className="row">
				<div className="col-12">
					<CardAnnual
						payInFullPremium={payInFullPremium}
						instalmentDetails={instalmentDetails}
						allowPayMonthly={allowPayMonthly}
						postFixYear={postFixYear}
					/>
				</div>
			</div>
		);

	if (allowPayMonthly)
		return (
			<div className="row">
				<div className="col-12">
					<CardMonthly
						instalmentDetails={instalmentDetails}
						postFixMonth={postFixMonth}
					/>
				</div>
			</div>
		);
};

export const PriceCardStickyFull = (props) => {
	const {
		allowPayMonthly,
		payInFullPremium,
		instalmentDetails,
		isSticky = false,
		postFixYear,
	} = props;

	return (
		<CardAnnual
			payInFullPremium={payInFullPremium}
			instalmentDetails={instalmentDetails}
			allowPayMonthly={allowPayMonthly}
			postFixYear={postFixYear}
		/>
	);
};

export const PriceCardStickyMonthly = (props) => {
	const { instalmentDetails, isSticky = false, postFixMonth } = props;

	return (
		<CardMonthly
			instalmentDetails={instalmentDetails}
			postFixMonth={postFixMonth}
		/>
	);
};
