import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";
import dateHelpers from "@library/common/helpers/misc/date";

import { useOutletContext } from "react-router-dom";
import ErrorBaseView from "../../../Errors/Base";
import {
	TelephoneNumberService,
	TelephoneNumberSales,
} from "siteComponents/Misc";
import { InceptionDate } from "siteComponentsLibrary/Dates";
import { Button } from "siteComponentsLibrary/Buttons";

import LayoutPageGap from "siteLayouts/Page/Gap";

const ViersGapYourQuoteErrorDisplay = (props) => {
	const { pagerData } = props;

	const commonData = useOutletContext();
	const currentStatus = commonData.quote.data.currentStatus;
	const WebReference = currentStatus?.WebReference;
	const portalUrl = undefined;

	const dispatch = useDispatch();
	const [inceptionData, setInceptionData] = useState({});

	// LOGGING
	useEffect(() => {
		if (currentStatus.isError) {
			commonData.functions.error.log({
				errorType: "quote error",
				errorDescription: "Error",
			});
		}

		if (currentStatus.isTechnicalError) {
			commonData.functions.error.log({
				errorType: "quote error",
				errorDescription: "Technical Error",
			});
		}

		if (currentStatus.isRTPError) {
			commonData.functions.error.log({
				errorType: "quote error",
				errorDescription: "RTP Error",
			});
		}
	}, []);

	const data = (function () {
		const base = {
			icon: "icon-error",
			heading: "We're very sorry",
		};

		if (currentStatus.isInceptionDatePast) {
			const { FormGroup } = commonData.storeComponents;
			const path = "Risk/PolicyInceptionDate";

			const fnSetValue = (v) =>
				setInceptionData((x = {}) => ({ ...x, showError: false, value: v }));
			const fnErrorShow = () =>
				setInceptionData((x = {}) => ({ ...x, showError: true }));
			const fnErrorHide = () =>
				setInceptionData((x = {}) => ({ ...x, showError: false }));
			const isError = inceptionData?.showError;

			return {
				...base,
				displayContent: (
					<>
						<p>
							Your chosen start date has now passed. Please update this below,
							click Continue, and we'll update your quote for you.
						</p>
						<FormGroup
							databaseProps={{ path: path }}
							label="When would you like your policy to start?"
							helpText="You can choose a date from tomorrow up to a maximum of 30 days from today."
							hasError={isError}
							data-cy="form-group:inception-update"
						>
							<InceptionDate
								onChange={fnSetValue}
								value={inceptionData?.value}
							/>
						</FormGroup>
					</>
				),
				errorMessage: isError && "Please choose a date",
				pagerData: {
					isPagerEnabled: true,
					labelNext: "Continue",
					fnOnNext: async () => {
						if (!inceptionData?.value) {
							fnErrorShow();
							return;
						}

						fnErrorHide();

						const updateValue = dateHelpers.toString(inceptionData.value);

						await dispatch(
							commonData.risk.actions.update({
								path: path,
								value: updateValue,
							})
						);

						await commonData.quote.functions.getQuote({
							autoSetFirstTier: true,
							autoUpdateQuoteParams: true,
							isPayloadChangedCheck: false, // Disable this
						});

						await commonData.functions.session.save();
					},
				},
			};
		}

		if (currentStatus.isRTPError) {
			return {
				...base,
				displayContent: (
					<>
						<div>It seems we've had a technical problem.</div>
						<div>Please try again later.</div>
					</>
				),
			};
		}
		if (currentStatus.isError || currentStatus.isTechnicalError) {
			const { newRoute = "/" } = props;
			const onClick = () => {
				window.location.href = newRoute;
			};

			return {
				...base,
				displayContent: (
					<>
						<div>
							It appears that something unexpected has happened and we're unable
							to proceed with your request.
						</div>
						<div>You can start a new quote below.</div>
						<Button
							onClick={onClick}
							className="btn-primary"
							data-cy="btn:start-new-quote"
						>
							Start a new quote
						</Button>
					</>
				),
			};
		}
		if (currentStatus.isRefer) {
			return {
				...base,
				icon: "icon-information",
				heading: "Sorry, we can't offer you a quote online",
				displayContent: (
					<>
						<div>
							Based on the information you've provided, we've not been able to
							offer you a quote on our website today. However, one of our
							advisors may be able to help you.
						</div>
						<div>
							Please call us on{" "}
							<strong>
								<TelephoneNumberService />
							</strong>
							{WebReference && (
								<span>
									{" "}
									and quote your reference number{" "}
									<strong> {WebReference}</strong>
								</span>
							)}
							.
						</div>
					</>
				),
			};
		}
		if (currentStatus.isPurchased) {
			return {
				...base,
				displayContent: (
					<>
						<div>
							It looks like this policy has already been purchased. If you need
							any assistance with this please call us on{" "}
							<strong className="whitespace-no-wrap">
								<TelephoneNumberService />
							</strong>
							.
						</div>
						{portalUrl && (
							<div className="mt-3">
								Alternatively,{" "}
								<a href={portalUrl}>click here to go to your online account</a>.
							</div>
						)}
					</>
				),
			};
		}

		// Default
		return {
			...base,
			icon: "icon-information",
			heading: "No quotes available",
			displayContent: (
				<>
					<div>
						Based on the information you've provided, we've not been able to
						offer you a quote today.
					</div>
				</>
			),
		};
	})();

	return (
		<ErrorBaseView
			heading={data.heading}
			icon={data.icon}
			SectionLayout={({ children }) => <>{children}</>}
			PageLayout={(props) => (
				<LayoutPageGap
					{...props}
					pagerData={data.pagerData}
					errorMessage={data.errorMessage}
				/>
			)}
		>
			{data.displayContent}
		</ErrorBaseView>
	);
};

export default ViersGapYourQuoteErrorDisplay;
