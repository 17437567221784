import React from "react";

import { Markup } from "interweave";
import { generateCypressProps } from "componentsLibraryResources/functions/cypress";

export const Alert = (props) => {
	const { className, children, ...otherProps } = props;

	const _className = [
		"alert",
		"alert-primary",
		"feedback",
		"feedback-info",
		className,
	].filter(Boolean).join(" ");
	return (
		<div className={_className} {...otherProps}  role="alert">
			{children}
		</div>
	);
};

export const Error = (props) => {
	const { className, children, ...otherProps } = props;

	if (!children) return;

	const _className = [
		"alert",
		"alert-danger",
		"feedback",
		className,
	].filter(Boolean).join(" ");
	return (
		<div className={_className} {...otherProps} role="alert">
			{children}
		</div>
	);
};

export const ErrorInline = (props) => {
	const { className, children, ...otherProps } = props;
	if (!children) return;

	const _className = [
		"feedback",
		"feedback-error",
		className,
	].filter(Boolean).join(" ");
	return (
		<div className={_className} {...otherProps}>
			{children}
		</div>
	);
}


export const Feedback = (props) => {
	const {
		debug = false,
		config = [],
		data = {},
		fallbackMsg: globalFallbackMsg,
		fallbackGeneral = null,
		className,
		Wrapper = ({ children }) => <>{children}</>,
	} = props;

	const foundItem = config
		.filter((x) => x.key in data)
		.map((x) => {
			let value = data[x.key];
			return { ...x, value };
		})
		.find((x) => x.value); // Filter anything that isn't truthy (e.g. " ", null, false, undefined)

	if (debug) console.log("Feedback", { config, data, foundItem });

	if (foundItem) {
		const { type, fallbackMsg = globalFallbackMsg, value } = foundItem;

		const MsgData = (function () {
			if (value === true) return <span>{fallbackMsg}</span>;

			return <Markup content={value} />; // Convert to HTML
		})();

		if (type === "error")
			return (
				<Wrapper>
					<Error
						className={className}
						{...generateCypressProps("feedback-error", props)}
					>
						{MsgData}
					</Error>
				</Wrapper>
			);

		return (
			<Wrapper>
				<Alert
					className={className}
					{...generateCypressProps("feedback-success", props)}
				>
					{MsgData}
				</Alert>
			</Wrapper>
		);
	}

	return fallbackGeneral;
};

