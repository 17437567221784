import React from "react";
import Base from "../Base";

import { NavLink } from "react-router-dom";
import { Button } from "siteComponentsLibrary/Buttons";

// There is no
const TimeoutView = (props) => {
	const { routeStart, clearTimeout } = props;

	return (
		<Base heading="Your session has expired" icon="icon-session-expired">
			<div>
				For your security your session with us has expired due to inactivity.
			</div>

			{clearTimeout && (
				<>
					<div className="mt-3">You can start a new quote below.</div>

					<div className="mt-3">
						<Button
							data-cy="btn:clear-timeout"
							onClick={() => {
								clearTimeout();
							}}
							className="btn-primary"
						>
							Start a new quote
						</Button>
					</div>
				</>
			)}
		</Base>
	);
};

export default TimeoutView;
