// import siteRuleSet from "siteRules";

const generateBaseRuleData = (ruleLibrary, ruleType) => {
	if (!ruleLibrary) throw `Error in getRuleKeys -- missing ruleLibrary`;
	if (!ruleType) throw `Error in getRuleKeys -- missing ruleType`;

	// Check the ruleLibrary/ruleType is in siteRuleSet
	// if (!siteRuleSet.getRule({ ruleLibrary, ruleType }))
	// 	throw `Error in getRuleKeys -- unknown database name: ${ruleLibrary}`;

	return {
		ruleId: `${ruleLibrary}/${ruleType}`,
		ruleType,
		ruleLibrary,
		errorKey: `${ruleType}`,
		runOnPostRegistration: true,
		runOnUserUpdate: true,
		forceUpdate: false,
	};
};

export default generateBaseRuleData;
