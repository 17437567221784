import { list, set, item } from "@library/common/database/classes";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";

import constants from "libraryConstants/database";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import generateBaseRuleData from "../../../generateBaseRuleData";
import regExConstants from "@library/common/constants/regEx";

import { Name } from "./Name";

import Conviction from "./Conviction";

const logicRule = new ruleItem(
	{
		...generateBaseRuleData("vehicle", "policyDetailsLogic"),
	},
	{
		BusinessMileage: new dataSetArgItem({
			componentTag: "BusinessMileage",
			useDefaultComponentSet: true,
		}),
		ClassOfUse: new dataSetArgItem({
			componentTag: "ClassOfUse",
			useDefaultComponentSet: true,
		}),
	}
);

export default {
	logicRule,
};
