import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Panel } from "./Layout";

const styleOverlay = {};
const styleMain = {};

const ModalBase = (props) => {
	const { children, isOpen = false, className, overlayClassName } = props;

	if (!isOpen) return null;

	return (
		<div style={styleOverlay} className={overlayClassName}>
			<div style={styleMain} className={className}>
				{children}
			</div>
		</div>
	);
};

export const Modal = (props) => {
	return <ModalBase {...props} />;
};

export const ModalLoading = (props) => {
	const {
		imageSpinner,
		messages = [
			"Please wait a moment...",
			"Sorry, this is taking longer than expected. Please wait...",
		],
		cycleDelay = 15000,
		cycleLoop = false,
		isEnabled = true,
		header,
		...otherProps
	} = props;

	const messageDataArray = _.isArray(messages) ? messages : [messages];
	const messageCount = messageDataArray.length;
	const [curMessageIdx, setCurMessageIdx] = useState(0);
	const curMessage = messageDataArray[curMessageIdx];
	const timeoutRef = useRef();

	useEffect(() => {
		if (!isEnabled && curMessageIdx !== 0) {
			setCurMessageIdx(0);
		}

		// do we set a timeout to set the next message?
		if (
			isEnabled &&
			messageCount >= 2 &&
			(cycleLoop || curMessageIdx < messageCount - 1)
		) {
			timeoutRef.current = setTimeout(() => {
				setCurMessageIdx((curIdx) => {
					if (isEnabled) return (curIdx + 1) % messageCount;
					return curIdx;
				});
			}, cycleDelay);
		}

		return () => {
			clearTimeout(timeoutRef.current);
		};
	}, [isEnabled, curMessageIdx]);

	return (
		<ModalBase {...otherProps}>
			<Panel header={header}>
				{imageSpinner && (
					<div className="text-center mb-2">
						<img src={imageSpinner} alt="Loading Spinner" />
					</div>
				)}

				{curMessage && <div className="px-3 text-center">{curMessage}</div>}
			</Panel>
		</ModalBase>
	);
};
