import React from "react";
import { useOutletContext } from "react-router-dom";
import { useLookupOptionList } from "moveToLibrary/hooks/useLookup";
import { useSelector } from "react-redux";
import services from "siteService";
import { PanelSection } from "siteComponentsLibrary/Layout";
import { DisplayItem } from "siteComponentsLibrary/Display";
import { ExternalLink } from "siteComponentsLibrary/Buttons";
import _ from "lodash";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

const PanelSectionItem = ({ children, label, className, dataCy }) => {
	return (
		<DisplayItem dataCy={dataCy} label={label} outerClass={className}>
			{children}
		</DisplayItem>
	);
};

const toTitleCase = (str) => {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

const fnFormatCurrencyWhole = (v) => {
	if (v === undefined) throw `Error in fnFormatCurrencyWhole -- v is undefined`;

	return new Intl.NumberFormat("en-GB", {
		style: "currency",
		currency: "GBP",
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}).format(v);
};

const ViewsGapYourQuotePolicyDetails = (props) => {
	const commonData = useOutletContext();

	const storeValues = useSelector((state) => {
		const fnGetItem = (path) => commonData.risk.selectors.value(state, path);
		return {
			misc: {
				PolicyInceptionDate: fnGetItem("Risk/PolicyInceptionDate"),
				RenewalDate: fnGetItem("Risk/RenewalDate"),
			},
			proposer: {
				name: {
					Title: fnGetItem("Risk/Proposer/Name/Title"),
					Forenames: fnGetItem("Risk/Proposer/Name/Forenames"),
					Surname: fnGetItem("Risk/Proposer/Name/Surname"),
				},
			},
			address: {
				HouseNameOrNumber: fnGetItem("Risk/Proposer/Address/HouseNameOrNumber"),
				AddressLine1: fnGetItem("Risk/Proposer/Address/AddressLine1"),
				AddressLine2: fnGetItem("Risk/Proposer/Address/AddressLine2"),
				AddressLine3: fnGetItem("Risk/Proposer/Address/AddressLine3"),
				AddressLine4: fnGetItem("Risk/Proposer/Address/AddressLine4"),
				Postcode: fnGetItem("Risk/Proposer/Address/Postcode"),
			},
			vehicle: {
				RegNumber: fnGetItem("Risk/Vehicle/RegNumber"),
				VehicleType: fnGetItem("Risk/Vehicle/VehicleType"),
				Manufacturer: fnGetItem("Risk/Vehicle/Manufacturer"),
				ModelDescription: fnGetItem("Risk/Vehicle/ModelDescription"),
				Fuel: fnGetItem("Risk/Vehicle/Fuel"),
				Transmission: fnGetItem("Risk/Vehicle/Transmission"),
				Cc: fnGetItem("Risk/Vehicle/Cc"),
				Purchased: fnGetItem("Risk/Vehicle/Purchased"),
				Value: fnGetItem("Risk/Vehicle/Value"),
				Paid: fnGetItem("Risk/Vehicle/Paid"),
			},
		};
	}, _.isEqual);

	const vehicleDescription = (function () {
		switch (storeValues.vehicle.VehicleType) {
			case "GoodsVehicle":
				return "van";

			case "Car":
				return "car";
		}
	})();

	const currentQuote = commonData.quote.data.currentQuote;

	console.log("ViewsGapYourQuotePolicyDetails", {
		currentQuote,
		commonData,
		storeValues,
	});

	const formatFunctions = {
		fuelType: useLookupOptionList(services.lookups.vehicle.fueltype),
		transmission: useLookupOptionList(services.lookups.vehicle.transmission),
		cc: (v) => {
			if (!v) return undefined;
			return `${v}cc`;
		},

		date: (d) => {
			if (!d) throw `Error in fnFormatDate -- missing date`;
			return dayjs(d).format("Do MMMM YYYY");
		},
	};

	//

	return (
		<div>
			<PanelSection heading="Your details">
				<DisplayItem dataCy="your-details-name" label="Name">
					{[
						storeValues.proposer.name.Title,
						storeValues.proposer.name.Forenames,
						storeValues.proposer.name.Surname,
					]
						.filter(Boolean)
						.join(" ")}
				</DisplayItem>

				<DisplayItem dataCy="your-details-address" label="Address">
					{[
						[
							storeValues.address.HouseNameOrNumber,
							storeValues.address.AddressLine1,
						].join(" "),
						storeValues.address.AddressLine2,
						storeValues.address.AddressLine3,
						storeValues.address.AddressLine4,
						storeValues.address.Postcode,
					]
						.filter(Boolean)
						.join(", ")}
				</DisplayItem>
			</PanelSection>

			<PanelSection heading={`Your ${vehicleDescription}`}>
				<DisplayItem
					dataCy="your-vehicle-reg"
					label="Registration"
					outerClass="car-reg"
				>
					<span className="vehicle-registration text-center d-inline-block overflow-wrap-normal">
						{storeValues.vehicle.RegNumber}
					</span>
				</DisplayItem>

				<DisplayItem dataCy="your-vehicle-make-model" label="Make & model">
					{[
						storeValues.vehicle.Manufacturer,
						storeValues.vehicle.ModelDescription,
					]
						.filter(Boolean)
						.join(" ")}
				</DisplayItem>

				<DisplayItem
					dataCy="your-vehicle-details"
					label={`${toTitleCase(vehicleDescription)} details`}
				>
					{[
						formatFunctions.fuelType(storeValues.vehicle.Fuel),
						formatFunctions.transmission(storeValues.vehicle.Transmission),
						formatFunctions.cc(storeValues.vehicle.Cc),
					]
						.filter(Boolean)
						.join(", ")}
				</DisplayItem>

				<DisplayItem dataCy="your-vehicle-purchase" label="Purchase date">
					{formatFunctions.date(storeValues.vehicle.Purchased)}
				</DisplayItem>
			</PanelSection>

			<PanelSection heading="Policy details">
				<DisplayItem dataCy="policy-details-quote-ref" label="Quote ref">
					{currentQuote.WebReference}
				</DisplayItem>

				<DisplayItem dataCy="policy-details-period" label="Policy period">
					{[
						formatFunctions.date(storeValues.misc.PolicyInceptionDate),
						"for 3 years",
					].join(" ")}
				</DisplayItem>

				{(function () {
					switch (currentQuote.GapType) {
						case "VAL":
							return (
								<DisplayItem
									dataCy="policy-details-vehicle-value"
									label={`${toTitleCase(vehicleDescription)} value`}
								>
									{fnFormatCurrencyWhole(storeValues.vehicle.Value)}
								</DisplayItem>
							);
						case "RTI":
							return (
								<DisplayItem
									dataCy="policy-details-vehicle-paid"
									label="Price paid"
								>
									{fnFormatCurrencyWhole(storeValues.vehicle.Paid)}
								</DisplayItem>
							);

						default:
							return null;
					}
				})()}

				<DisplayItem dataCy="policy-details-claim-limit" label="Claim limit">
					{fnFormatCurrencyWhole(currentQuote.ClaimLimit)}
				</DisplayItem>

				<DisplayItem
					dataCy="policy-details-underwriter"
					label="Underwritten by"
				>
					{currentQuote.InsurerName}
				</DisplayItem>


			</PanelSection>
		</div>
	);
};

export default ViewsGapYourQuotePolicyDetails;
