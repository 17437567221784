import { list, set, item } from "@library/common/database/classes";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";

import constants from "libraryConstants/database";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import generateBaseRuleData from "../../../generateBaseRuleData";
import regExConstants from "@library/common/constants/regEx";

const monthBanLogic = new ruleItem(
	{
		...generateBaseRuleData("vehicle", "convictionMonthBanLogic"),
	},
	{
		MonthBanQuestion: new dataSetArgItem({
			componentTag: "MonthBanQuestion",
			useDefaultComponentSet: true,
		}),
		MonthBan: new dataSetArgItem({
			componentTag: "MonthBan",
			useDefaultComponentSet: true,
		}),
	}
);

export default (args) => {
	return new list({
		...args,
		template: {
			OffenceCode: new item({
				submitKey: "Code",
				label: "Offence code",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter the offence code",
						}
					),
					new ruleItem(
						{
							...generateBaseRuleData("vehicle", "convictionLogic"),
						},
						{
							OffenceCode: new dataSetArgItem({
								componentTag: "OffenceCode",
								useDefaultComponentSet: true,
							}),
							AlcoholReadingType: new dataSetArgItem({
								componentTag: "AlcoholReadingType",
								useDefaultComponentSet: true,
							}),
							AlcoholReadingAmount: new dataSetArgItem({
								componentTag: "AlcoholReadingAmount",
								useDefaultComponentSet: true,
							}),
						}
					),
				],
			}),
			Date: new item({
				// todo: clone DateOfConviction?
				submitKey: "Date",
				fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
				fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),

				rules: [
					// new ruleItem({
					// 	...generateBaseRuleData("general", "salusConvertStringToDate"),},{
					// 	runOnUserUpdate: false,
					// 	forceUpdate: true,
					// }),
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),
			// Disqualification: new item({
			// 	submitKey: "Disqualification",
			// 	// rules: [
			// 	// 	new ruleItem({
			// 	// 		...generateBaseRuleData("general", "isRequired"),},{
			// 	// 		errorMessage: "", // errorMessage: "Required",
			// 	// 	}),
			// 	// ],
			// }),
			AlcoholReadingType: new item({
				submitKey: "DrinkReading",
				label: "What type of alcohol reading was taken?",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("vehicle", "convictionLogic"),
						},
						{
							OffenceCode: new dataSetArgItem({
								componentTag: "OffenceCode",
								useDefaultComponentSet: true,
							}),
							AlcoholReadingType: new dataSetArgItem({
								componentTag: "AlcoholReadingType",
								useDefaultComponentSet: true,
							}),
							AlcoholReadingAmount: new dataSetArgItem({
								componentTag: "AlcoholReadingAmount",
								useDefaultComponentSet: true,
							}),
						}
					),
				],
			}),

			AlcoholReadingAmount: new item({
				submitKey: "DrinkReadingLevel",
				label: "What was the level of the reading?",
				html: { maxLength: 5, regEx: regExConstants.numbers.whole },
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("vehicle", "convictionLogic"),
						},
						{
							OffenceCode: new dataSetArgItem({
								componentTag: "OffenceCode",
								useDefaultComponentSet: true,
							}),
							AlcoholReadingType: new dataSetArgItem({
								componentTag: "AlcoholReadingType",
								useDefaultComponentSet: true,
							}),
							AlcoholReadingAmount: new dataSetArgItem({
								componentTag: "AlcoholReadingAmount",
								useDefaultComponentSet: true,
							}),
						}
					),
				],
			}),

			Amount: new item({
				submitKey: "Fine",
				label: "What was the cost of the fine?",
				helpText: "Please enter 0 if no fine was given.",
				html: { regEx: regExConstants.numbers.whole, maxLength: 10 },
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter the amount",
						}
					),
				],
			}),
			DateOfConviction: new item({
				submitKey: "OffenceDate",
				label: "Date of conviction",
				helpText:
					"We only need to know about convictions within the last five years",
				fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
				fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),

				rules: [
					// new ruleItem({
					// 	...generateBaseRuleData("general", "salusConvertStringToDate"),},{
					// 	runOnUserUpdate: false,
					// 	forceUpdate: true,
					// }),
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter the date of the conviction",
						}
					),
					new ruleItem(
						{
							...generateBaseRuleData("general", "dateComparison"),
						},
						{
							errorMessage: "", // "You can't enter a future date",
							yearOffset: 0,
							comparator: "<=",
						}
					),
					// new ruleItem({
					// 	...generateBaseRuleData("general", "dateComparison"),},{
					// 	errorMessage: "Conviction must be within the last 5 years",
					// 	yearOffset: -5,
					// 	comparator: ">=",
					// }),
					new ruleItem(
						{
							...generateBaseRuleData("general", "copyValue"),
						},
						{
							target: new dataSetArgItem({
								componentTag: "Date",
								useDefaultComponentSet: true,
							}),
						}
					),
				],
			}),
			Points: new item({
				submitKey: "Points",
				label: "How many points were received?",
				html: { regEx: regExConstants.numbers.whole, maxLength: 4 },
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter the points received",
						}
					),
				],
			}),

			MonthBanQuestion: new item({
				html: { itemData: constants.lists.yesNo },
				label: "Did you receive a ban?",
				rules: [
					monthBanLogic,
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			MonthBan: new item({
				// componentTag:  "MonthBan",
				submitKey: "Disqualification",
				//defaultValue: 0,
				label: "How many months were you banned for?",
				html: { regEx: regExConstants.numbers.whole, maxLength: 10 },

				rules: [
					monthBanLogic,
					//new ruleItem({
					//	...generateBaseRuleData("general", "isRequired"),},{
					//	errorMessage: "Please enter the number of months",
					//}),
				],
			}),
		},
	});
};
