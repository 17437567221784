// import axios from "axios";
// import { createServiceObj } from "@library/common/helpers/service";
import postProcessAxiosFunctions from "@library/configs/site/configItem/postProcessAxiosFunctions";
import _ from "lodash";

import {
	createService,
	createServiceSessionId,
	createServiceSecure,
	generateSet,
	EndPointSet as _EndPointSet,
	endPointItem as _endPointItem,
	generateLink,
} from "./resources";

import generateLookups from "./lookups";

// ******************************************
// Generate end points
// ******************************************
const lookupPostProcessAxiosStandard = [
	postProcessAxiosFunctions.JsonOptions,
	postProcessAxiosFunctions.JSONParse,
	postProcessAxiosFunctions.convertLabels,
];

const generateEndpoints = (args = {}) => {
	// requiredArgs.forEach((x) => {
	//   if (!x in args) {
	//     console.log("args:", args);
	//     throw `Error in base config endPoints -- missing ${x}`;
	//   }
	// });

	// console.log("ddd", args);
	const { endPointSets, isDev } = args;
	const requiredSets = [
		"default",
		// "accounts",
		// "test",
		// "documents",
		// "update",
		// "userDetails",
	];

	if (!endPointSets.isRegistered(requiredSets))
		throw `Error -- missing a required EndPointSet`;

	// ** HELPER FUNCTIONS **

	const generateLooksUps = (hitlist, options) => {
		const dataStatic = hitlist.filter((x) => x.staticData);
		const dataFn = hitlist.filter((x) => x.fn);
		const dataService = hitlist
			.filter((x) => x.listName)
			.map((x) => {
				return {
					...x,
					data: {
						Operation: "single",
						ListName: x.listName,
					},
				};
			});

		return {
			...Object.fromEntries(
				dataStatic.map((x) => [x.key, () => Promise.resolve(x.staticData)])
			),
			...Object.fromEntries(dataFn.map((x) => [x.key, x.fn()])),
			...generateSet(dataService, {
				defaultFunction: createServiceSessionId,
				endPointSet: endPointSets.getItem("lookup"),
				...options,
			}),
		};

		// if (!dataArray) throw `Error in generateLooksUps -- missing dataArray`;
		// if (!endPointSets)
		//   throw `Error in generateLooksUps -- missing endPointSets`;

		// return Object.fromEntries(
		//   dataArray.map((x) => {
		//     if (x.fn) return [x.key, x.fn];
		//     if (x.staticData) return [x.key, x.staticData];

		//     // console.log("x.postProcessAxios",x.postProcessAxios)
		//     const data = createServiceSessionId({
		//       postProcessAxios: (x.postProcessAxios || []).filter((x) => x),
		//       axiosOptions: {
		//         method: "POST",
		//         headers: {
		//           "x-api-key": endPointSets.getItem("default").apiKey,
		//         },
		//         url: `${endPointSets.getItem("default").url}/GetFrontEndLists`,
		//         data: {
		//           Operation: "single",
		//           ListName: x.listName,
		//         },
		//       },
		//     });

		//     return [x.key, data];
		//   })
		// );
	};

	// ** RETURN DATA **
	const returnData = {
		status: createService({
			axiosOptions: {
				method: "POST",
				url: `${endPointSets.getItem("default").url}/StatusCheck`,
				headers: {
					"x-api-key": endPointSets.getItem("default").apiKey,
				},
			},
		}),
		recall: {
			...generateSet(
				[
					{ key: "recallAndQuote", path: "/RecallAndRequote" },
					{ key: "recallAggDeeplink", path: "/RecallAggDeeplink" },
				],
				{
					defaultFunction: createServiceSessionId,
					endPointSet: endPointSets.getItem("default"),
				}
			),
			...generateSet([{ key: "handover", path: "/Recall/Deeplink/Gap" }], {
				defaultFunction: createServiceSessionId,
				endPointSet: endPointSets.getItem("handover"),
			}),
		},

		quote: generateSet([{ key: "multiQuote", path: "/MultiQuote" }], {
			defaultFunction: createServiceSessionId,
			endPointSet: endPointSets.getItem("default"),
		}),
		figl: generateSet([{ key: "validate", path: "/Eligibility/Validate" }], {
			defaultFunction: createServiceSessionId,
			endPointSet: endPointSets.getItem("figlEligibilityData"),
		}),
		getSiteAlerts: createService({
			postProcessAxios: [
				(response) => {
					if (!response) return;
					if (!response.ShowAlert) return;
					if (!response.Alerts) return;
					if (response.Alerts.length === 0) return;

					const { Alerts } = response;
					// console.log("ddd",Alerts)

					return Alerts;
				},
			],
			axiosOptions: {
				method: "POST",
				headers: {
					"x-api-key": endPointSets.getItem("default").apiKey,
				},
				url: `${endPointSets.getItem("default").url}/GetSiteAlerts`,
			},
		}),

		lookups: {
			...generateLookups(endPointSets.getItem("lookup")),

			auth: {
				listSecurityQuestions: createServiceSessionId({
					postProcessAxios: [
						postProcessAxiosFunctions.JsonOptions,
						postProcessAxiosFunctions.JSONParse,
						postProcessAxiosFunctions.convertLabels,
					],

					axiosOptions: {
						method: "POST",
						headers: {
							"x-api-key": endPointSets.getItem("default").apiKey,
						},
						url: `${endPointSets.getItem("default").url}/GetFrontEndLists`,
						data: {
							Operation: "single",
							ListName: "security-questions",
						},
					},
				}),
			},
		},
		search: {
			...generateSet(
				[
					{
						key: "address",
						path: "/GetAddress",
					},
				],
				{
					defaultFunction: createServiceSessionId,
					endPointSet: endPointSets.getItem("default"),
				}
			),

			car: {
				...generateSet(
					[
						{
							key: "dvlaSearch",
							path: "/DvlaSearch",
							postProcessAxios: [
								(response = {}) => {
									const { Vehicles } = response;

									if (_.isArray(Vehicles)) {
										if (Vehicles.length >= 1) return Vehicles[0];
										return undefined;
									}

									return Vehicles;
								},
							],
							data: { LookupType: "PC", CriticalDataCheck: "GAP" },
						},

						{
							key: "vehicleSearch",
							path: "/VehicleSearch",
							postProcessAxios: [
								(response) =>
									postProcessAxiosFunctions.VehicleSearch(response, "PC"),
							],
							data: {
								PolicyType: "PC",
								Operation: "get",
							},
						},
					],

					{
						defaultFunction: createServiceSessionId,
						endPointSet: endPointSets.getItem("default"),
					}
				),
			},
			van: {
				...generateSet(
					[
						{
							key: "dvlaSearch",
							path: "/DvlaSearch",
							postProcessAxios: [
								(response = {}) => {
									const { Vehicles } = response;

									if (_.isArray(Vehicles)) {
										if (Vehicles.length >= 1) return Vehicles[0];
										return undefined;
									}

									return Vehicles;
								},
							],
							data: { LookupType: "GV", CriticalDataCheck: "GAP" },
						},

						{
							key: "vehicleSearch",
							path: "/VehicleSearch",
							postProcessAxios: [
								(response) =>
									postProcessAxiosFunctions.VehicleSearch(response, "GV"),
							],
							data: {
								PolicyType: "GV",
								Operation: "get",
							},
						},
					],

					{
						defaultFunction: createServiceSessionId,
						endPointSet: endPointSets.getItem("default"),
					}
				),
			},
		},

		error: generateSet([{ key: "log", path: "/ErrorNotification" }], {
			defaultFunction: createServiceSessionId,
			endPointSet: endPointSets.getItem("default"),
		}),

		// https://dev.azure.com/FreedomServicesGroup/Odyssey/_wiki/wikis/Odyssey.wiki/151/Persist
		persist: generateSet(
			[
				{
					key: "create",
					path: "/Persist",
					data: {
						Operation: "create",
					},
					requiredPayload: [
						// "Type",
						// , "Location"
					],
				},
				{
					key: "delete",
					path: "/Persist",
					data: {
						Operation: "delete",
					},
					requiredPayload: ["Id"],
				},
				{
					key: "get",
					path: "/Persist",
					data: {
						Operation: "get",
					},
					requiredPayload: ["Id"],
				},
				{
					key: "save",
					path: "/Persist",
					data: {
						Operation: "save",
					},
					requiredPayload: [
						"Id",
						"DataString",
						// , "Location"
					],
				},
			],
			{
				defaultFunction: createService,
				endPointSet: endPointSets.getItem("default"),
			}
		),
		payment: generateSet(
			[
				{
					key: "registerPayment",
					path: "/RegisterPayment",
				},
				{
					key: "getPurchaseResponse",
					path: "/GetPurchaseResponse",
				},
			],
			{
				defaultFunction: createServiceSessionId,
				endPointSet: endPointSets.getItem("payment"),
			}
		),
		bank: {
			...generateSet(
				[
					{
						key: "validateBankDetails",
						path: "/ValidateBankDetails",
					},
				],
				{
					defaultFunction: createServiceSessionId,
					endPointSet: endPointSets.getItem("default"),
				}
			),
		},
	};

	return returnData;
	// contactus: {
	// 	getLookupList: {
	// 		postProcessAxios: [
	// 			postProcessAxiosFunctions.JsonOptions,
	// 			postProcessAxiosFunctions.JSONParse,
	// 			postProcessAxiosFunctions.convertLabels,
	// 		],

	// 		axiosOptions: {
	// 			method: "POST",
	// 			headers: {
	// 				"x-api-key": contactusData.apiKey,
	// 			},
	// 			url: `${contactusData.endPoint}/GetFrontEndLists`,
	// 			data: {
	// 				Operation: "single",
	// 				ListName: "contact-us-types",
	// 			},
	// 		},
	// 	},
	// 	sendRequest: {
	// 		axiosOptions: {
	// 			method: "POST",
	// 			headers: {
	// 				"x-api-key": contactusData.apiKey,
	// 			},
	// 			url: `${contactusData.endPoint}/SendContactRequest`,
	// 			// validateStatus: (status) => {
	// 			// 	if (status === 400) return true; // Invalid request -- pass it to the front end
	// 			// 	return status >= 200 && status < 300;
	// 			// },
	// 		},
	// 	},
	// },

	// getSiteAlerts: {
	// 	axiosOptions: {
	// 		method: "POST",
	// 		headers: {
	// 			"x-api-key": AxiosData.apiKey,
	// 		},
	// 		url: `${AxiosData.endPoint}/GetSiteAlerts`,
	// 	},
	// },

	// payment: {
	// 	GetPolicyBalance: {
	// 		retryCount: 0,
	// 		axiosOptions: {
	// 			method: "POST",
	// 			headers: {
	// 				"x-api-key": AxiosData.apiKey,
	// 			},
	// 			url: `${AxiosData.endPoint}/GetPolicyBalance`,
	// 		},
	// 	},
	// 	RegisterAdhocPayment: {
	// 		retryCount: 0,
	// 		axiosOptions: {
	// 			method: "POST",
	// 			headers: {
	// 				"x-api-key": AxiosData.apiKey,
	// 			},
	// 			url: `${AxiosData.endPoint}/RegisterAdhocPayment`,
	// 		},
	// 	},
	// },
	// vehicle: {
	// 	transmission: {
	// 		// cache: true,

	// 		postProcessAxios: [
	// 			postProcessAxiosFunctions.JsonOptions,
	// 			postProcessAxiosFunctions.JSONParse,
	// 			postProcessAxiosFunctions.convertLabels,
	// 		],
	// 		retryCount: 0,
	// 		axiosOptions: {
	// 			method: "POST",
	// 			data: {
	// 				ListName: "vehicle-transmission",
	// 				Operation: "single",
	// 			},
	// 			headers: {
	// 				"x-api-key": AxiosData.apiKey,
	// 			},

	// 			url: `${AxiosData.endPoint}/GetFrontEndLists`,
	// 		},
	// 	},
	// 	fueltype: {
	// 		// cache: true,

	// 		postProcessAxios: [
	// 			postProcessAxiosFunctions.JsonOptions,
	// 			postProcessAxiosFunctions.JSONParse,
	// 			postProcessAxiosFunctions.convertLabels,
	// 			(response) => {
	// 				if (!response) return response;
	// 				return response.map((x) => {
	// 					let newValue = x.value;
	// 					switch (x.value) {
	// 						case "FuelPetrol":
	// 							newValue = "P";
	// 							break;
	// 						case "FuelDiesel":
	// 							newValue = "D";
	// 							break;
	// 						case "FuelElec":
	// 							newValue = "E";
	// 							break;
	// 					}
	// 					return { ...x, value: newValue };
	// 				});
	// 			},
	// 		],
	// 		retryCount: 0,
	// 		axiosOptions: {
	// 			method: "POST",
	// 			data: {
	// 				ListName: "vehicle-fueltype",
	// 				Operation: "single",
	// 			},
	// 			headers: {
	// 				"x-api-key": AxiosData.apiKey,
	// 			},

	// 			url: `${AxiosData.endPoint}/GetFrontEndLists`,
	// 		},
	// 	},
	// },
	// };
};

export const EndPointSet = _EndPointSet;
export const endPointItem = _endPointItem;
export default generateEndpoints;
