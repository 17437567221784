import createQuoteAndBuy from "moveToLibrary/redux/quoteandbuy";

import migrationDatabase from "./migration/database";
import migrationRules from "./migration/rules";
import services from "siteService";
import _ from "lodash";

const sampleVanQuote = {
	Status: "SUCCESS-M",
	QuoteResults: [
		{
			QuoteResultType: "SUCCESS-M",
			IsTelematics: false,
			GrantedProtectedNcb: false,
			ProtectedNcbImpliedPrice: 0,
			PncbStepbackTable:
				'<table class=\'table pncd-details stepback\'><tbody><tr><th scope="row">Number of Years\' No Claims Discount at this renewal</th><td>9</td></tr><tr><th colspan="2" scope="col">No Claims Discount at Next Renewal Date <u>without</u> NCD Protection</th></tr><tr><td>1 claim in next 12 months</td><td>3 Years</td></tr><tr><td>2 claims in next 12 months</td><td>1 Year</td></tr><tr><td>3 claims in next 12 months</td><td>0 Years</td></tr><tr><td>4 claims in next 12 months</td><td>0 Years</td></tr><tr><th colspan="2" scope="col">No Claims Discount at Next Renewal Date with NCD Protection</th></tr><tr><td>1 claim in any 1 year period</td><td>9 Years</td></tr><tr><td>2 claims in any 1 year period</td><td>3 Years</td></tr><tr><td>3 claims in any 1 year period</td><td>1 Year</td></tr><tr><td>4 claims in any 1 year period</td><td>0 Years</td></tr></tbody></table>',
			PncbAverageDiscountTable:
				"<table class='table pncd-details avg-discount'><thead><tr><th scope=\"col\">Number of Years' No Claims Discount</th><th scope=\"col\">Average NCD Discount in 2023</th></tr></thead><tbody><tr><td>One Year's NCD</td><td>33%</td></tr><tr><td>Two Years' NCD</td><td>43%</td></tr><tr><td>Three Years' NCD</td><td>52%</td></tr><tr><td>Four Years' NCD</td><td>62%</td></tr><tr><td>Five Years' NCD</td><td>64%</td></tr><tr><td>Six Years' NCD</td><td>66%</td></tr><tr><td>Seven Years' NCD</td><td>68%</td></tr><tr><td>Eight Years' NCD</td><td>68%</td></tr><tr><td>Nine Years' NCD</td><td>68%</td></tr></tbody></table>",
			DrivingOtherCars: false,
			NcbVerified: false,
			PolicyId: "180346229",
			ProposerId: "322135",
			BusinessEventId: "180346600",
			PolicyEventReference: "002",
			SchemeCode: "PD",
			SchemeName: "CV NIA",
			InsurerCode: "PC",
			InsurerName: "Action 365 Ltd T/as Pukka Services",
			InsurerRef: "DIRECT",
			Status: "Offered",
			RtpStatus: "Flexed",
			PolicyWordingUrl:
				"https://policywording.uat.cdlcloud.co.uk/NIG_v3_Pukka_CV-202304111559.pdf",
			IpidUrl:
				"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/ef02f62d-5b58-4499-851d-cc2d368673d4",
			IsReferred: false,
			CompulsoryExcess: 250,
			RequestedVoluntaryExcess: 0,
			GrantedVoluntaryExcess: 0,
			Ipt: 60.9,
			IptRate: 12,
			NewBusinessFee: 30,
			Fee: 30,
			AvailablePayMethods: [
				{
					Type: "PayInFull",
				},
				{
					InstalmentType: "Funded",
					Type: "Instalmnts",
				},
			],
			PayInFullPremium: 598.4,
			HasInstalments: true,
			Instalments: [
				{
					Amount: 6384,
					AprRate: 26.9,
					Deposit: 3594,
					DepositFee: 0,
					FacilityFee: 0,
					FlatInterestAmount: 0,
					FundedInd: true,
					FundedRecourse: "Yes",
					FundingCompanyDetailsId: "1093933",
					Id: "180346683",
					InstalmentType: "Funded",
					InstalmentTotalSellingPrice: 67434,
					InterestPercentage: 13.5,
					InterestTotal: 7594,
					IsContinuable: false,
					NumberOfPayments: "10",
					PaymentFrequency: "OneMonth",
					SchemeCode: "NMXNB",
					SchemeName: "10 payments with 6% deposit",
					VariedAmount: 0,
					VariedRepaymentStatus: "None",
					HasVariedPayment: false,
					NumberOfPaymentsToDisplay: 10,
					DepositAmount: 35.94,
					InstalmentAmount: 63.84,
					VariedPaymentAmount: 0,
					ArrangementFee: 0,
					TotalAmountToPay: 674.34,
					TotalAmountOfCredit: 562.46,
					TotalCostOfCredit: 75.94,
					DepositRate: 0.0600601604278075,
					InterestRateFactor: 0.135,
					InstalmentFactor: 0.106683171791444,
					ThirdPartyFee: 0,
					DurationDifference: 2,
				},
			],
			CompulsoryOpex: [],
			DisplayOpex: [
				{
					GroupCode: "BD",
					Title: "UK Breakdown Cover",
					MainInfo: [
						{
							Id: "BD-mi-0",
							Value: "text",
							Text: "With our UK Breakdown Cover you can be assured we'll organise the best people for the job if you're stuck on the roadside, or at home with our UK Full Breakdown cover.",
							SortOrder: 1,
						},
					],
					AdditionalInfo: [
						{
							Id: "BD-ai-0",
							Value: "text",
							Text: "For more details on the policy cover, including the benefits, terms, conditions and exclusions, it's important that you read and understand the policy wording and the product information.",
							SortOrder: 1,
						},
					],
					CoverLevels: [
						{
							Code: "BK1",
							Name: "UK Local Roadside",
							SellingPoints: [
								{
									Id: "bdra",
									Value: "true",
									Text: "Roadside Assistance",
									AdditionalText:
										"If the vehicle breaks down more than one mile from the home address, we will arrange service at the scene of the breakdown for up to 1 hour and pay call-out fees and labour costs in an attempt to repair it.",
									SortOrder: 1,
								},
								{
									Id: "bdlr",
									Value: "true",
									Text: "Local Recovery",
									AdditionalText:
										"If the vehicle breaks down more than one mile from the home address and it cannot be repaired at the scene of the breakdown, we will arrange and pay the cost of taking it, the driver and the passenger(s) from the scene of the breakdown to the nearest repairer, up to a maximum of a 10 mile round trip.",
									SortOrder: 2,
								},
								{
									Id: "bdfr",
									Value: "false",
									Text: "Full Recovery",
									AdditionalText:
										"If the vehicle breaks down more than one mile from the home address and it cannot be repaired at the scene of the breakdown or the nearest repairer the same day, we will arrange and pay the cost of taking it, the driver and the passenger(s) from the scene of the breakdown to your home, your original destination or the nearest repairer.",
									SortOrder: 3,
								},
								{
									Id: "bdat",
									Value: "false",
									Text: "Onward Travel",
									AdditionalText:
										"If the vehicle breaks down and it cannot be repaired the same day, and it cannot be recovered to any one place you choose, we will pay the cost of alternative travel arrangements for the driver and the passenger(s).",
									SortOrder: 4,
								},
								{
									Id: "bdaa",
									Value: "false",
									Text: "Alternative Accommodation",
									AdditionalText:
										"If the vehicle breaks down more than 25 miles from the home address, and it cannot be repaired the same day, and it cannot be recovered to any one place you choose, we will pay the cost of overnight accommodation for the driver and the passenger(s).",
									SortOrder: 5,
								},
								{
									Id: "bdha",
									Value: "false",
									Text: "Doorstep Assistance",
									AdditionalText:
										"If the vehicle breaks down at the home address or within one mile of the home address, we will arrange service at the scene of the breakdown for up to 1 hour and pay call-out fees and labour costs in an attempt to repair it. If it cannot be repaired at the scene of the breakdown, we will arrange and pay the cost of taking it, the driver and the passenger(s) from the scene of the breakdown to the nearest repairer, up to a maximum of a 10 mile round trip.",
									SortOrder: 6,
								},
							],
							AnnualPrice: 54.95,
							MonthlyPrice: 5.86,
							AnnualisedMonthlyPrice: 70.32,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-Breakdown-UKLocal.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/facd1edf-b1fa-4e93-8fc0-c3b46bfdd6bf",
							IsSelected: false,
						},
						{
							Code: "BK2",
							Name: "UK National Roadside",
							SellingPoints: [
								{
									Id: "bdra",
									Value: "true",
									Text: "Roadside Assistance",
									AdditionalText:
										"If the vehicle breaks down more than one mile from the home address, we will arrange service at the scene of the breakdown for up to 1 hour and pay call-out fees and labour costs in an attempt to repair it.",
									SortOrder: 1,
								},
								{
									Id: "bdlr",
									Value: "true",
									Text: "Local Recovery",
									AdditionalText:
										"If the vehicle breaks down more than one mile from the home address and it cannot be repaired at the scene of the breakdown, we will arrange and pay the cost of taking it, the driver and the passenger(s) from the scene of the breakdown to the nearest repairer, up to a maximum of a 10 mile round trip.",
									SortOrder: 2,
								},
								{
									Id: "bdfr",
									Value: "true",
									Text: "Full Recovery",
									AdditionalText:
										"If the vehicle breaks down more than one mile from the home address and it cannot be repaired at the scene of the breakdown or the nearest repairer the same day, we will arrange and pay the cost of taking it, the driver and the passenger(s) from the scene of the breakdown to your home, your original destination or the nearest repairer.",
									SortOrder: 3,
								},
								{
									Id: "bdat",
									Value: "true",
									Text: "Onward Travel",
									AdditionalText:
										"If the vehicle breaks down and it cannot be repaired the same day, and it cannot be recovered to any one place you choose, we will pay the cost of alternative travel arrangements for the driver and the passenger(s).",
									SortOrder: 4,
								},
								{
									Id: "bdaa",
									Value: "true",
									Text: "Alternative Accommodation",
									AdditionalText:
										"If the vehicle breaks down more than 25 miles from the home address, and it cannot be repaired the same day, and it cannot be recovered to any one place you choose, we will pay the cost of overnight accommodation for the driver and the passenger(s).",
									SortOrder: 5,
								},
								{
									Id: "bdha",
									Value: "false",
									Text: "Doorstep Assistance",
									AdditionalText:
										"If the vehicle breaks down at the home address or within one mile of the home address, we will arrange service at the scene of the breakdown for up to 1 hour and pay call-out fees and labour costs in an attempt to repair it. If it cannot be repaired at the scene of the breakdown, we will arrange and pay the cost of taking it, the driver and the passenger(s) from the scene of the breakdown to the nearest repairer, up to a maximum of a 10 mile round trip.",
									SortOrder: 6,
								},
							],
							AnnualPrice: 64.95,
							MonthlyPrice: 6.93,
							AnnualisedMonthlyPrice: 83.16,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-Breakdown-UKNational.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/3e0c7384-0b12-465c-aeac-e18a57bf5eb3",
							IsSelected: false,
						},
						{
							Code: "BK3",
							Name: "UK Full Breakdown",
							SellingPoints: [
								{
									Id: "bdra",
									Value: "true",
									Text: "Roadside Assistance",
									AdditionalText:
										"If the vehicle breaks down more than one mile from the home address, we will arrange service at the scene of the breakdown for up to 1 hour and pay call-out fees and labour costs in an attempt to repair it.",
									SortOrder: 1,
								},
								{
									Id: "bdlr",
									Value: "true",
									Text: "Local Recovery",
									AdditionalText:
										"If the vehicle breaks down more than one mile from the home address and it cannot be repaired at the scene of the breakdown, we will arrange and pay the cost of taking it, the driver and the passenger(s) from the scene of the breakdown to the nearest repairer, up to a maximum of a 10 mile round trip.",
									SortOrder: 2,
								},
								{
									Id: "bdfr",
									Value: "true",
									Text: "Full Recovery",
									AdditionalText:
										"If the vehicle breaks down more than one mile from the home address and it cannot be repaired at the scene of the breakdown or the nearest repairer the same day, we will arrange and pay the cost of taking it, the driver and the passenger(s) from the scene of the breakdown to your home, your original destination or the nearest repairer.",
									SortOrder: 3,
								},
								{
									Id: "bdat",
									Value: "true",
									Text: "Onward Travel",
									AdditionalText:
										"If the vehicle breaks down and it cannot be repaired the same day, and it cannot be recovered to any one place you choose, we will pay the cost of alternative travel arrangements for the driver and the passenger(s).",
									SortOrder: 4,
								},
								{
									Id: "bdaa",
									Value: "true",
									Text: "Alternative Accommodation",
									AdditionalText:
										"If the vehicle breaks down more than 25 miles from the home address, and it cannot be repaired the same day, and it cannot be recovered to any one place you choose, we will pay the cost of overnight accommodation for the driver and the passenger(s).",
									SortOrder: 5,
								},
								{
									Id: "bdha",
									Value: "true",
									Text: "Doorstep Assistance",
									AdditionalText:
										"If the vehicle breaks down at the home address or within one mile of the home address, we will arrange service at the scene of the breakdown for up to 1 hour and pay call-out fees and labour costs in an attempt to repair it. If it cannot be repaired at the scene of the breakdown, we will arrange and pay the cost of taking it, the driver and the passenger(s) from the scene of the breakdown to the nearest repairer, up to a maximum of a 10 mile round trip.",
									SortOrder: 6,
								},
							],
							AnnualPrice: 74.95,
							MonthlyPrice: 8,
							AnnualisedMonthlyPrice: 96,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-Breakdown-UKNational.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/107e99c8-a9da-4265-be46-0a07d6b98cd9",
							IsSelected: false,
						},
					],
					SortOrder: 1,
					IsKeyOpex: true,
					KeyOpexText:
						"We'll repair your vehicle if you're stuck on the roadside",
					IsPromo: false,
				},
				{
					GroupCode: "LEGAL",
					Title: "Legal Expenses",
					MainInfo: [
						{
							Id: "LEGAL-mi-0",
							Value: "text",
							Text: "This product provides cover for you or a named driver against the costs of legal fees and expenses up to £100,000 and also provides additional cover for:",
							SortOrder: 1,
						},
					],
					SellingPoints: [
						{
							Id: "LEGAL-sp-0",
							Value: "li",
							Text: "Motor Contract Disputes up to £10,000",
							SortOrder: 1,
						},
						{
							Id: "LEGAL-sp-1",
							Value: "li",
							Text: "Insurance Database Disputes up to £10,000",
							SortOrder: 2,
						},
						{
							Id: "LEGAL-sp-2",
							Value: "li",
							Text: "Vehicle Cloning up to £25,000",
							SortOrder: 3,
						},
						{
							Id: "LEGAL-sp-3",
							Value: "li",
							Text: "Motor Prosecution Defence up to £1,000",
							SortOrder: 4,
						},
						{
							Id: "LEGAL-sp-4",
							Value: "li",
							Text: "Personal Injury as a result of a bicycle incident",
							SortOrder: 5,
						},
					],
					AdditionalInfo: [
						{
							Id: "LEGAL-ai-0",
							Value: "policy-wording",
							Text: "",
							AdditionalText:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-LegalProtection.pdf",
							SortOrder: 1,
						},
					],
					CoverLevels: [
						{
							Code: "LE",
							Name: "Legal Expenses",
							AnnualPrice: 29.95,
							MonthlyPrice: 3.2,
							AnnualisedMonthlyPrice: 38.4,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-LegalProtection.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/fa782fa9-61fb-457f-95a6-9e1be96f1746",
							IsSelected: false,
						},
					],
					SortOrder: 2,
					IsKeyOpex: true,
					KeyOpexText:
						"Covers you or a named driver against the costs of legal fees and expenses up to £100,000",
					IsPromo: false,
				},
				{
					GroupCode: "XS",
					Title: "Excess Protection",
					Description:
						"The excess on your policy is the amount you will have to contribute should you need to make a claim. With Excess Protection, we've got you covered against this cost. You'll be covered for fault and non-fault claims as a result of accidental damage, fire, theft or vandalism.",
					AdditionalInfo: [
						{
							Id: "XS-ai-0",
							Value: "policy-wording",
							Text: "For full details on what is covered with this service, including the terms, conditions and exclusions, please see the",
							AdditionalText:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-ExcessProtection-FIGL.pdf",
							SortOrder: 1,
						},
					],
					CoverLevels: [
						{
							Code: "EX1",
							Name: "£250 cover",
							AnnualPrice: 34.95,
							MonthlyPrice: 3.73,
							AnnualisedMonthlyPrice: 44.76,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-ExcessProtection-FIGL.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/d845d1de-b781-434c-9546-97e7f2df62f1",
							IsSelected: false,
						},
						{
							Code: "EX2",
							Name: "£500 cover",
							AnnualPrice: 44.95,
							MonthlyPrice: 4.8,
							AnnualisedMonthlyPrice: 57.6,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-ExcessProtection-FIGL.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/82708efd-1e66-4fac-9f7a-a016e59d0c24",
							IsSelected: false,
						},
						{
							Code: "EX3",
							Name: "£750 cover",
							AnnualPrice: 54.95,
							MonthlyPrice: 5.86,
							AnnualisedMonthlyPrice: 70.32,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-ExcessProtection-FIGL.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/c8283ccc-1e66-43f2-81ef-7083f58c9120",
							IsSelected: false,
						},
					],
					SortOrder: 3,
					IsKeyOpex: false,
					KeyOpexText: "Protect against the costs of making a claim",
					IsPromo: false,
				},
				{
					GroupCode: "TOOLS",
					Title: "Tools In Transit",
					MainInfo: [
						{
							Id: "TOOLS-mi-0",
							Value: "text",
							Text: "If your equipment is vital to your business, our Tools in Transit cover will provide the cover you need to get you working again.",
							SortOrder: 1,
						},
						{
							Id: "TOOLS-mi-1",
							Value: "text",
							Text: "Your tools will be replaced if they are stolen, destroyed or damaged whilst in your van.",
							SortOrder: 2,
						},
						{
							Id: "TOOLS-mi-2",
							Value: "text",
							Text: "Also, they will be replaced if they are stolen, destroyed or damaged during loading or unloading from your motor vehicle.",
							SortOrder: 3,
						},
						{
							Id: "TOOLS-mi-3",
							Value: "text",
							Text: "You can select the amount of cover that meets your needs up to a limit of £5,000.",
							SortOrder: 4,
						},
					],
					AdditionalInfo: [
						{
							Id: "TOOLS-ai-0",
							Value: "policy-wording",
							Text: "For full details on what is covered with this service, including the terms, conditions and exclusions, please see the",
							AdditionalText:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-ToolsInTransit-FIGL.pdf",
							SortOrder: 1,
						},
					],
					CoverLevels: [
						{
							Code: "TL1",
							Name: "Up to £1,000 cover",
							AnnualPrice: 54.95,
							MonthlyPrice: 5.86,
							AnnualisedMonthlyPrice: 70.32,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-ToolsInTransit-FIGL.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/98afe327-d2a9-4f2e-a81a-809e8187e8c8",
							IsSelected: false,
						},
						{
							Code: "TL3",
							Name: "Up to £3,000 cover",
							AnnualPrice: 84.95,
							MonthlyPrice: 9.06,
							AnnualisedMonthlyPrice: 108.72,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-ToolsInTransit-FIGL.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/43926687-0061-4519-82e6-1880a8fb177a",
							IsSelected: false,
						},
						{
							Code: "TL5",
							Name: "Up to £5,000 cover",
							AnnualPrice: 109.95,
							MonthlyPrice: 11.73,
							AnnualisedMonthlyPrice: 140.76,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-ToolsInTransit-FIGL.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/0914b522-1032-4c8c-bd46-7b73da26f48c",
							IsSelected: false,
						},
					],
					SortOrder: 4,
					IsKeyOpex: true,
					KeyOpexText:
						"Protection if your work tools are lost, stolen and need replacement",
					IsPromo: false,
				},
				{
					GroupCode: "RPL",
					Title: "Replacement Vehicle",
					MainInfo: [
						{
							Id: "RPL-mi-0",
							Value: "text",
							Text: "Provides cover for a hire vehicle to be provided whilst your vehicle is rendered un-driveable or is being repaired following a road traffic accident, fire, malicious damage or theft or attempted theft.",
							SortOrder: 1,
						},
						{
							Id: "RPL-mi-1",
							Value: "text",
							Text: "We can provide different levels of hire vehicle cover for up to 14, 21 or 28 days.",
							SortOrder: 2,
						},
					],
					AdditionalInfo: [
						{
							Id: "RPL-ai-0",
							Value: "policy-wording",
							Text: "For full details on what is covered with this service, including the terms, conditions and exclusions, please see the",
							AdditionalText:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-ReplacementVehicle.pdf",
							SortOrder: 1,
						},
					],
					CoverLevels: [
						{
							Code: "GH14",
							Name: "14 days cover",
							AnnualPrice: 39.95,
							MonthlyPrice: 4.26,
							AnnualisedMonthlyPrice: 51.12,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-ReplacementVehicle.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/9f57ee27-02e3-43ed-b70a-c278a2c8ae62",
							IsSelected: false,
						},
						{
							Code: "GH21",
							Name: "21 days cover",
							AnnualPrice: 44.95,
							MonthlyPrice: 4.8,
							AnnualisedMonthlyPrice: 57.6,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-ReplacementVehicle.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/c6143faa-6f1e-4e3a-a8d6-771e2b3ff223",
							IsSelected: false,
						},
						{
							Code: "GH28",
							Name: "28 days cover",
							AnnualPrice: 49.95,
							MonthlyPrice: 5.33,
							AnnualisedMonthlyPrice: 63.96,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-ReplacementVehicle.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/7cc17aa3-ea0e-4f30-b142-eae5d750b7d9",
							IsSelected: false,
						},
					],
					SortOrder: 5,
					IsKeyOpex: false,
					KeyOpexText:
						"In the event your vehicle is rendered undriveable or is being repaired",
					IsPromo: false,
				},
				{
					GroupCode: "KEY",
					Title: "Key Cover",
					Description:
						"This policy covers you for locksmith charges if your insured keys are lost, stolen, damaged or locked in your home or vehicle.",
					SellingPoints: [
						{
							Id: "KEY-sp-0",
							Value: "li",
							Text: "New locks if there is a security risk due to the loss or theft of your insured key.",
							SortOrder: 1,
						},
						{
							Id: "KEY-sp-1",
							Value: "li",
							Text: "Replacement keys if your insured keys are stolen, permanently lost or broken.",
							SortOrder: 2,
						},
						{
							Id: "KEY-sp-2",
							Value: "li",
							Text: "Hire costs if you are unable to use your vehicle while away from your home due to lost, stolen or broken keys.",
							SortOrder: 3,
						},
					],
					AdditionalInfo: [
						{
							Id: "KEY-ai-0",
							Value: "policy-wording",
							Text: "For full details on what is covered with this service, including the terms, conditions and exclusions, please see the",
							AdditionalText:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-KeyCover-FIGL.pdf",
							SortOrder: 1,
						},
					],
					CoverLevels: [
						{
							Code: "KE",
							Name: "Key Cover",
							AnnualPrice: 19.95,
							MonthlyPrice: 2.13,
							AnnualisedMonthlyPrice: 25.56,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-KeyCover-FIGL.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/c204cd2b-8cb4-424b-b80e-71194e76bb80",
							IsSelected: false,
						},
					],
					SortOrder: 6,
					IsKeyOpex: false,
					KeyOpexText: "",
					IsPromo: false,
				},
				{
					GroupCode: "LD",
					Title: "Licence Defence",
					Description:
						"This policy provides cover for up to £25,000 costs for legal action in the event of a motoring offence arising from your use of the vehicle.",
					AdditionalInfo: [
						{
							Id: "LD-ai-0",
							Value: "policy-wording",
							Text: "For full details on what is covered with this service, including the terms, conditions and exclusions, please see the",
							AdditionalText:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-LicenceDefence.pdf",
							SortOrder: 1,
						},
					],
					CoverLevels: [
						{
							Code: "LD",
							Name: "Licence Defence",
							AnnualPrice: 19.95,
							MonthlyPrice: 2.13,
							AnnualisedMonthlyPrice: 25.56,
							WordingUrl:
								"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/PW-LicenceDefence.pdf",
							IpidUrl:
								"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/0bcf314c-501e-451a-90e7-72b746fb36cd",
							IsSelected: false,
						},
					],
					SortOrder: 7,
					IsKeyOpex: false,
					KeyOpexText: "",
					IsPromo: false,
				},
			],
			Ipids: [
				{
					DocumentID: "IPID0",
					Guid: "ef02f62d-5b58-4499-851d-cc2d368673d4",
					IpidRequired: true,
					Url: "https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/ef02f62d-5b58-4499-851d-cc2d368673d4",
				},
			],
			TierInfo: {
				TierId: "STAN",
				TierName: "Freedom Standard",
				IsSelected: true,
				BenefitsList: [
					{
						Key: "OPEXINC1",
						Description: "Legal Expenses",
						SortOrder: 1,
						ShowOnQuote: true,
						ShowOnBuy: false,
						Highlight: true,
						IsPositiveBenefit: false,
					},
					{
						Key: "OPEXINC2",
						Description: "Breakdown",
						SortOrder: 1,
						ShowOnQuote: true,
						ShowOnBuy: false,
						Highlight: true,
						IsPositiveBenefit: false,
					},
					{
						Key: "COMP",
						Description: "Comprehensive",
						SortOrder: 1,
						ShowOnQuote: false,
						ShowOnBuy: true,
						Highlight: false,
						IsPositiveBenefit: true,
					},
					{
						Key: "ACC",
						Description: "Online account",
						SortOrder: 2,
						ShowOnQuote: false,
						ShowOnBuy: true,
						Highlight: false,
						IsPositiveBenefit: true,
					},
				],
				MainBenefitText: "Choose a great value upgrade...",
				PriceHighlight: 0,
			},
			PrecisWordings: [
				{
					Id: "2334500",
					MetaData: "",
					Status: "NORM",
					Text: "<p>Action 365 Ltd T/as Pukka Services</p>",
					Type: "COMP",
				},
				{
					Id: "1943501",
					MetaData: "",
					Status: "NORM",
					Text: "<p>Commercial Vehicle</p>",
					Type: "SCHE",
				},
				{
					Id: "2032000",
					MetaData: "",
					Status: "ERRO",
					Text: '<span class="error">There was an error retrieving the Policy Summary.  Please call for details.</span>',
					Type: "POLS",
				},
				{
					Id: "4084597",
					MetaData: "",
					Status: "NORM",
					Text: "https://policywording.uat.cdlcloud.co.uk/NIG_v3_Pukka_CV-202304111559.pdf",
					Type: "POLW",
				},
				{
					Id: "1943504",
					MetaData: "",
					Status: "NORM",
					Text: "<p>Comprehensive.</p>",
					Type: "COVE",
				},
				{
					Id: "1945527",
					MetaData: "----",
					Status: "NORM",
					Text: '<p class="error">There was an error retrieving the excess details.  Additional excesses may apply, please call for details.</p>',
					Type: "EXCE",
				},
				{
					Id: "1947504",
					MetaData: "AFM1",
					Status: "NORM",
					Text: "<p>Accidental Damage, Fire, Theft and Malicious Damage Excess (Drv 1) &pound;250</p>",
					Type: "EXCE",
				},
				{
					Id: "1943506",
					MetaData: "VXL",
					Status: "NORM",
					Text: "<p>Voluntary Excess &pound;0</p>",
					Type: "EXCE",
				},
				{
					Id: "1943507",
					MetaData: "001",
					Status: "NORM",
					Text: "<div>\n\t\t\t<h4>001 Accidental Damage, Theft and Fire Excess</h4>\n\t\t\t<p>We shall not be liable for the first amount as shown on the schedule for any claims or series of claims arising out of one event in respect of which indemnity is provided by the Accidental Damage Section and/or Fire and Theft Section of your policy.</p>\n\t\t\t</div>",
					Type: "ENDO",
				},
				{
					Id: "1943508",
					MetaData: "005",
					Status: "NORM",
					Text: "<div>\n\t\t\t<h4>005 Additional Excess</h4>\n\t\t\t<p>Unless otherwise stated, Excesses shown on the Schedule are in addition to the standard Policy Excesses.</p>\n\t\t\t</div>",
					Type: "ENDO",
				},
				{
					Id: "1945528",
					MetaData: "----",
					Status: "NORM",
					Text: '<p class="error">There was an error retrieving the terms and conditions.  Additional terms may apply, please call for details.</p>',
					Type: "TERM",
				},
				{
					Id: "2015501",
					MetaData: "WSPR",
					Status: "NORM",
					Text: "<p>Windscreen Repair Excess reduced to &pound;10 if windscreen is repaired by approved repairer.</p>",
					Type: "TERM",
				},
				{
					Id: "3998098",
					MetaData: "WSXP",
					Status: "NORM",
					Text: "<p>Windscreen Replacement Excess applies to all Windscreen claims, but if the Windscreen is replaced by a Non-Approved Repairer the maximum payment is &pound;75 after the deduction of the Excess</p>",
					Type: "TERM",
				},
				{
					Id: "3998099",
					MetaData: "BSC",
					Status: "ERRO",
					Text: "TERM MISSING FROM DATABASE: BSC",
					Type: "TERM",
				},
				{
					Id: "2033500",
					MetaData: "BTEN",
					Status: "NONE",
					Text: "No wording to be shown for term BTEN",
					Type: "TERM",
				},
				{
					Id: "2033000",
					MetaData: "PACD",
					Status: "NONE",
					Text: "No wording to be shown for term PACD",
					Type: "TERM",
				},
				{
					Id: "4038598",
					MetaData: "RTID",
					Status: "NONE",
					Text: "No wording to be shown for term RTID",
					Type: "TERM",
				},
				{
					Id: "2094032",
					MetaData: "UUID",
					Status: "NONE",
					Text: "No wording to be shown for term UUID",
					Type: "TERM",
				},
				{
					Id: "1943512",
					MetaData: "",
					Status: "NORM",
					Text: "<p>This policy does not provide any cover to drive any other vehicles.</p>",
					Type: "DOC",
				},
				{
					Id: "1943513",
					MetaData: "",
					Status: "NORM",
					Text: "<p>Use for the carriage of the Insured's own goods in connection with his/her business or profession. Use for Social, Domestic &amp; Pleasure purposes.</p>\n            <p><strong>EXCLUDING</strong></p>\n            <p>Use for the carriage of goods or passengers for hire or reward, racing, pacemaking, speed testing, competitions, rallies, trials, track days, carriage of livestock or use for any purpose in connection with the Motor Trade.</p>",
					Type: "USE",
				},
				{
					Id: "4105597",
					MetaData:
						"<ncd-average-discount><calendar-year>2023</calendar-year><discount><ncd-years>1</ncd-years><ncd-percent>33</ncd-percent></discount><discount><ncd-years>2</ncd-years><ncd-percent>43</ncd-percent></discount><discount><ncd-years>3</ncd-years><ncd-percent>52</ncd-percent></discount><discount><ncd-years>4</ncd-years><ncd-percent>62</ncd-percent></discount><discount><ncd-years>5</ncd-years><ncd-percent>64</ncd-percent></discount><discount><ncd-years>6</ncd-years><ncd-percent>66</ncd-percent></discount><discount><ncd-years>7</ncd-years><ncd-percent>68</ncd-percent></discount><discount><ncd-years>8</ncd-years><ncd-percent>68</ncd-percent></discount><discount><ncd-years>9</ncd-years><ncd-percent>68</ncd-percent></discount></ncd-average-discount>",
					Status: "NORM",
					Text: "<table><thead><tr><th scope=\"col\">Number of Years' No Claims Discount</th><th scope=\"col\">Average NCD Discount in 2023</th></tr></thead><tbody><tr><td>One Year's NCD</td><td>33%</td></tr><tr><td>Two Years' NCD</td><td>43%</td></tr><tr><td>Three Years' NCD</td><td>52%</td></tr><tr><td>Four Years' NCD</td><td>62%</td></tr><tr><td>Five Years' NCD</td><td>64%</td></tr><tr><td>Six Years' NCD</td><td>66%</td></tr><tr><td>Seven Years' NCD</td><td>68%</td></tr><tr><td>Eight Years' NCD</td><td>68%</td></tr><tr><td>Nine Years' NCD</td><td>68%</td></tr></tbody></table>",
					Type: "DISC",
				},
				{
					Id: "2214501",
					MetaData:
						"<step-back-table><ncd-years>9</ncd-years><step-back><claim-count>1</claim-count><ncd-without>3</ncd-without><period-years>1</period-years><ncd-with>9</ncd-with></step-back><step-back><claim-count>2</claim-count><ncd-without>1</ncd-without><period-years>1</period-years><ncd-with>3</ncd-with></step-back><step-back><claim-count>3</claim-count><ncd-without>0</ncd-without><period-years>1</period-years><ncd-with>1</ncd-with></step-back><step-back><claim-count>4</claim-count><ncd-without>0</ncd-without><period-years>1</period-years><ncd-with>0</ncd-with></step-back></step-back-table>",
					Status: "NORM",
					Text: '<table><tbody><tr><th scope="row">Number of Years\' No Claims Discount at this renewal</th><td>9</td></tr><tr><th colspan="2" scope="col">No Claims Discount at Next Renewal Date <u>without</u> NCD Protection</th></tr><tr><td>1 claim in next 12 months</td><td>3 Years</td></tr><tr><td>2 claims in next 12 months</td><td>1 Year</td></tr><tr><td>3 claims in next 12 months</td><td>0 Years</td></tr><tr><td>4 claims in next 12 months</td><td>0 Years</td></tr><tr><th colspan="2" scope="col">No Claims Discount at Next Renewal Date with NCD Protection</th></tr><tr><td>1 claim in any 1 year period</td><td>9 Years</td></tr><tr><td>2 claims in any 1 year period</td><td>3 Years</td></tr><tr><td>3 claims in any 1 year period</td><td>1 Year</td></tr><tr><td>4 claims in any 1 year period</td><td>0 Years</td></tr></tbody></table>',
					Type: "STEP",
				},
			],
			Endorsements: [
				{
					AutoGenerated: false,
					Code: "001",
					EndorsementText: "Accidental Damage, Theft and Fire Excess",
					Id: "180346649",
					Party: "All",
					PartySpecific: false,
					SpecialEndorsement: false,
					Value: 0,
					ValueRequired: false,
				},
				{
					AutoGenerated: false,
					Code: "005",
					EndorsementText: "Additional Excess",
					Id: "180346650",
					Party: "All",
					PartySpecific: false,
					SpecialEndorsement: false,
					Value: 0,
					ValueRequired: false,
				},
			],
			Excesses: [
				{
					ExcessType: "CompXS",
					Id: "180346646",
					IsOverridden: false,
					PolicySection: "A",
					Reason: "999",
					Value: 25000,
				},
				{
					ExcessType: "CompXS",
					Id: "180346647",
					IsOverridden: false,
					PolicySection: "5",
					Reason: "999",
					Value: 11500,
				},
				{
					ExcessType: "CompXS",
					Id: "180346648",
					IsOverridden: false,
					PolicySection: "51",
					Reason: "999",
					Value: 0,
				},
			],
			TermsAndConditions: [
				{
					Id: "52065000",
					Text: "Voluntary Excess",
					Code: "VXL",
					Value: 0,
					ExcessType: "None",
					GeneratedCurrencyValue: 0,
					GeneratedPercentageValue: 0,
					OverrideCurrencyValue: 0,
					OverridePercentageValue: 0,
					Overridden: false,
				},
				{
					Id: "52065001",
					Text: "Windscreen Replacement Excess applies to all Windscreen claims, but if the Windscreen is replaced by a Non-Approved Repairer, the maximum payment is #75 after the deduction of the Excess.",
					Code: "WSXP",
					Value: 11500,
					ExcessType: "None",
					GeneratedCurrencyValue: 0,
					GeneratedPercentageValue: 0,
					OverrideCurrencyValue: 0,
					OverridePercentageValue: 0,
					Overridden: false,
				},
				{
					Id: "52065002",
					Text: "Windscreen Repair Excess reduced to #0 if windscreen is repaired by approved repairer",
					Code: "WSPR",
					Value: 0,
					ExcessType: "None",
					GeneratedCurrencyValue: 0,
					GeneratedPercentageValue: 0,
					OverrideCurrencyValue: 0,
					OverridePercentageValue: 0,
					Overridden: false,
				},
				{
					Id: "52065003",
					Text: "Accidental Damage, Malicious Damage, Fire and Theft Excess for Proposer",
					Code: "AFM1",
					Value: 25000,
					ExcessType: "None",
					GeneratedCurrencyValue: 0,
					GeneratedPercentageValue: 0,
					OverrideCurrencyValue: 0,
					OverridePercentageValue: 0,
					Overridden: false,
				},
				{
					Id: "52065004",
					Text: "BT0",
					Code: "BTEN",
					Value: 0,
					ExcessType: "None",
					GeneratedCurrencyValue: 0,
					GeneratedPercentageValue: 0,
					OverrideCurrencyValue: 0,
					OverridePercentageValue: 0,
					Overridden: false,
				},
				{
					Id: "52065005",
					Text: "Personal Accident Benefits",
					Code: "PACD",
					Value: 0,
					ExcessType: "None",
					GeneratedCurrencyValue: 0,
					GeneratedPercentageValue: 0,
					OverrideCurrencyValue: 0,
					OverridePercentageValue: 0,
					Overridden: false,
				},
				{
					Id: "52065006",
					Text: "SC002",
					Code: "BSC",
					Value: 200,
					ExcessType: "None",
					GeneratedCurrencyValue: 0,
					GeneratedPercentageValue: 0,
					OverrideCurrencyValue: 0,
					OverridePercentageValue: 0,
					Overridden: false,
				},
				{
					Id: "52065007",
					Text: "QD07/03/2024",
					Code: "RTID",
					Value: 0,
					ExcessType: "None",
					GeneratedCurrencyValue: 0,
					GeneratedPercentageValue: 0,
					OverrideCurrencyValue: 0,
					OverridePercentageValue: 0,
					Overridden: false,
				},
				{
					Id: "52065008",
					Text: "XX-427eaee7-e887-45a8-a98f-8374125f1bf5",
					Code: "UUID",
					Value: 0,
					ExcessType: "None",
					GeneratedCurrencyValue: 0,
					GeneratedPercentageValue: 0,
					OverrideCurrencyValue: 0,
					OverridePercentageValue: 0,
					Overridden: false,
				},
			],
			Enrichments: [
				{
					EnrichmentName: "001I",
					ReferenceOne: "352801095657268",
					ReferenceTwo: "",
					ScidCode: "C00515",
				},
			],
			DisplayExcesses: [
				{
					Id: "AFM1-1",
					Value: "precis-text",
					Text: "Accidental Damage, Fire, Theft and Malicious Damage Excess (Drv 1) £250",
					SortOrder: 0,
				},
				{
					Id: "VXL-1",
					Value: "precis-text",
					Text: "Voluntary Excess £0",
					SortOrder: 0,
				},
				{
					Id: "-----1",
					Value: "precis-error",
					Text: "There was an error retrieving the excess details.  Additional excesses may apply, please call for details.",
					SortOrder: 0,
				},
			],
			DisplayEndorsements: [
				{
					Id: "001-0",
					Value: "precis",
					Text: "<div>\n<h4>001 Accidental Damage, Theft and Fire Excess</h4>\n<p>We shall not be liable for the first amount as shown on the schedule for any claims or series of claims arising out of one event in respect of which indemnity is provided by the Accidental Damage Section and/or Fire and Theft Section of your policy.</p>\n</div>",
					SortOrder: 0,
				},
				{
					Id: "005-0",
					Value: "precis",
					Text: "<div>\n<h4>005 Additional Excess</h4>\n<p>Unless otherwise stated, Excesses shown on the Schedule are in addition to the standard Policy Excesses.</p>\n</div>",
					SortOrder: 0,
				},
			],
			DisplayTerms: [
				{
					Id: "WSPR-0",
					Value: "precis",
					Text: "<p>Windscreen Repair Excess reduced to £10 if windscreen is repaired by approved repairer.</p>",
					SortOrder: 0,
				},
				{
					Id: "WSXP-0",
					Value: "precis",
					Text: "<p>Windscreen Replacement Excess applies to all Windscreen claims, but if the Windscreen is replaced by a Non-Approved Repairer the maximum payment is £75 after the deduction of the Excess</p>",
					SortOrder: 0,
				},
				{
					Id: "-----0",
					Value: "precis",
					Text: '<p class="error">There was an error retrieving the terms and conditions.  Additional terms may apply, please call for details.</p>',
					SortOrder: 0,
				},
			],
			DisplayCover: [
				{
					Id: "-0",
					Value: "precis",
					Text: "<p>Comprehensive.</p>",
					SortOrder: 0,
				},
				{
					Id: "-0",
					Value: "precis",
					Text: "<p>Use for the carriage of the Insured's own goods in connection with his/her business or profession. Use for Social, Domestic & Pleasure purposes.</p>\n<p><strong>EXCLUDING</strong></p>\n<p>Use for the carriage of goods or passengers for hire or reward, racing, pacemaking, speed testing, competitions, rallies, trials, track days, carriage of livestock or use for any purpose in connection with the Motor Trade.</p>",
					SortOrder: 0,
				},
				{
					Id: "-0",
					Value: "precis",
					Text: "<p>This policy does not provide any cover to drive any other vehicles.</p>",
					SortOrder: 0,
				},
			],
			Affinity: "FREEDOM",
			WebReference: "91845-65686-69381",
			TransactionId: "180346621",
		},
	],
};

const gapQuoteAndBuyStore = createQuoteAndBuy({
	storeName: "gapQuoteAndBuy",
	abortChecker: require("../../common").abortChecker,
	defaultRisk: require("./config/defaultRisk.json"),
	// rules: require("./config/rules").default,
	template: require("./config/template").default,
	rules: {
		onPostSalusLoad: require("./config/rules").onPostSalusLoad,
		onPostRegistration: require("./config/rules").onPostRegistration,
	},
	defaultQuoteParams: [
		{
			Id: "STAN",
			Affinity: "FREEDOM",
		},
	],

	console: {
		...console,
		// Nerf outs
		...Object.fromEntries(
			[
				// Comment out as needed
				"time",
				"timeEnd",
				"log",
				"groupCollapsed",
				"groupEnd",
			].map((k) => [k, () => null])
		),
	},
	services: {
		getQuote: async (args = {}) => {
			// return sampleVanQuote;
			const payload = {
				QuoteParams: args.quoteParams,
				Risk: args.risk.Risk, // args.risk contains "Risk"
			};

			console.log("store/data/gap/quoteAndBuy", "getQuote", { args, payload });

			const response = await services.quote.multiQuote(payload);

			// Modify all the Instalments to remove anything with NO DEPOSIT
			if (response) {
				if (_.isArray(response.QuoteResults)) {
					response.QuoteResults = response.QuoteResults.map((q) => {
						if (!q.Instalments) return q;
						if (!_.isArray(q.Instalments)) return q;

						const isZeroDeposit = q.Instalments.some(
							(inst) => inst.Deposit === 0
						);

						const newInstalments = q.Instalments.filter(
							(inst) => "Deposit" in inst && inst.Deposit !== 0
						);
						q.Instalments = newInstalments;
						q.HasInstalment = newInstalments.length >= 1;
						q.InstalmentsZeroDeposit = isZeroDeposit;
						return q;
					});
				}
			}

			//QuoteResults []
			// 	HasInstalment
			// Instalments[].Deposit ===0

			// FIGL logging (NONE ASYNCHRONOUS)
			if (response?.quoteResults && response?.quoteResults.length >= 1) {
				try {
					services.figl.validate({
						WebReference: quoteResults[0].WebReference,
					});
				} catch (e) {
					console.warn("FIGL CALL FAILED", e);
				}
			}

			return response;
		},
	},

	/*
	//DISABLED
	// TEMPLATEVAN
	dev: {
		migrate: {
			database: migrationDatabase,
			rules: migrationRules,

			rulesAdditional: {
				vehicleUse_CUSTOM_riskAdditionalInsuredSet: {
					// For ADDING and REMOVING additional drivers
					fn: (...args) => {
						const ruleFn = migrationRules.getRule({
							ruleLibrary: "vehicle",
							ruleType: "vehicleUseLogic",
						});

						// console.log("ddddsadsadsad", ...args);
						const retdata = ruleFn(...args);
						return retdata;
					},
					paths: ["Risk/AdditionalInsuredSet"],
					mappings: {
						AdditionalInsuredSet: "Risk/AdditionalInsuredSet",
						MainUserComponentId: "Risk/MainUserComponentId",
						ProposerVehicleUse: "Risk/Proposer/VehicleUse",
					},
					functionArgs: {
						ComponentTagVehicleUse: "VehicleUse",
					},
					isLegacy: true,
					errorKey: "vehicle/vehicleUseLogic", //Needs to be the same as the one generated in "migration.js"
				},
			},
		},
	},
*/
});

export default gapQuoteAndBuyStore;
