import React from "react";
import config from "config";
import { Navigate, Outlet, useRouteError } from "react-router-dom";
import SessionBlockerView from "siteViews/Errors/SessionBlocker";

import { Maintenance, Timeout } from "siteComponents/Site";
import { Loading } from "siteComponents/Misc";
import { AffiliateWelcome } from "siteComponentsLibrary/Misc";

import { components, selectors } from "siteStore";

const { SessionChecker, SessionBlocker } = components.session;
import { useDispatch } from "siteFunctions/hooks";

//IMPORTS
import LayoutSite from "siteLayouts/Site";
import LayoutSectionGapRisk from "siteLayouts/Section/Gap/Risk";
import LayoutSectionGapRecall from "siteLayouts/Section/Gap/Recall";
import LayoutSectionGapFeedback from "siteLayouts/Section/Gap/Feedback";
import LayoutSectionGapWrapup from "siteLayouts/Section/Gap/Wrapup";

import ViewGapContainer from "siteViews/Gap/Container";
import ViewGap from "siteViews/Gap";
import { useSelector } from "react-redux";

import affiliatesDatabase from "siteAssets/affiliates";

const fnHasSession = () => {
	//NOTE: May need to be a function, so that sessionStorage is re-evaluated
	if (sessionStorage.getItem("sessionId")) return true;
	return false;
};
//NOTE: This needs to be here, instead of the APP, as <Timeout /> needs to be in the ROUTER

const routeData = config.lookups.routes.gap.sections;

const ElementTopLevel = (props) => {
	require("siteStore").stores.gapQuoteAndBuy.helpers.hooks.useAffiliate(
		"refid",
		"Risk/SourceOfEnquiry",
		{
			verbose: true,
			updateOnBlankOnly: false,
		}
	);

	const riskSourceOfEnquiry = useSelector((state) =>
		selectors.gapQuoteAndBuy.userData.risk.value(state, "Risk/SourceOfEnquiry")
	);

	return (
		<LayoutSite>
			<SessionBlocker
				entryPoints={config.lookups.routes.gap.entryPoints}
				FallbackComponent={SessionBlockerView}
				hasSession={fnHasSession()}
			>
				<Timeout>
					<SessionChecker useDispatch={useDispatch}>
						<Maintenance>
							<React.Suspense
								fallback={
									<div className="d-flex justify-content-center mt-5">
										<Loading />
									</div>
								}
							>
								<AffiliateWelcome
									affilate={riskSourceOfEnquiry}
									database={affiliatesDatabase}
									sessionStorageKey="AffiliateWelcome"
								/>
								<Outlet />
							</React.Suspense>
						</Maintenance>
					</SessionChecker>
				</Timeout>
			</SessionBlocker>
		</LayoutSite>
	);
};

const ErrorElement = () => {
	const error = useRouteError();
	throw error;
};

// const Redirector = ({ to }) => {
// 	//http://localhost:8083/?refid=ddddddddddddddd
// 	const [searchParams, setSearchParams] = useSearchParams();

// 	const newRoute = [to, searchParams.search].filter(Boolean).join("?");
// 	console.log("newRoute", newRoute);
// 	return <div>xxxx</div>;
// 	return <Navigate to={newRoute} />;
// };

const routes = [
	{
		element: <ElementTopLevel />,
		// errorElement: <ErrorBoundaryReactRouter />,
		errorElement: <ErrorElement />,
		children: [
			// GAP
			{
				element: <ViewGapContainer />,
				path: "", // OPTIONAL: Change to "gap"

				children: [
					//GAP/Recall
					// {
					// 	element: (
					// 		<LayoutSectionGapRecall>
					// 			<ViewGap sectionId="recall" />
					// 		</LayoutSectionGapRecall>
					// 	),
					// 	path: `${routeData.recall}/:pageId?`,
					// },

					// GAP/RISK
					{
						element: (
							<LayoutSectionGapRisk>
								<ViewGap sectionId="risk" />
							</LayoutSectionGapRisk>
						),
						path: `${routeData.risk}/:pageId?`,
					},

					// GAP/FEEDBACK
					{
						element: (
							<LayoutSectionGapFeedback>
								<ViewGap sectionId="feedback" />
							</LayoutSectionGapFeedback>
						),
						path: `${routeData.feedback}/:pageId?`,
					},

					// GAP/WRAPUP
					{
						element: (
							<LayoutSectionGapWrapup>
								<ViewGap sectionId="wrapupInterstitial" />
							</LayoutSectionGapWrapup>
						),
						path: `${routeData.wrapupInterstitial}/:paymentId?`,
					},

					{
						element: (
							<LayoutSectionGapWrapup>
								<ViewGap sectionId="wrapup" />
							</LayoutSectionGapWrapup>
						),
						path: `${routeData.wrapup}`,
					},

					// Q&B Integration
					{
						element: (
							<LayoutSectionGapRisk>
								<ViewGap sectionId="quoteAndBuyHandover" />
							</LayoutSectionGapRisk>
						),
						path: `${routeData.quoteAndBuyHandover}/:handoverId`,
					},
					// Redirects
					{ element: <Navigate to={routeData.start} />, path: "" },
					{ element: <Navigate to={routeData.start} />, path: "*" },
				],
			},

			// FALLBACK
			{
				path: "*",
				element: <Navigate to={routeData.start} />,
			},
		],
	},
];

export default routes;
