import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";
import dayjs from "dayjs";
import { current } from "@reduxjs/toolkit";

import _ from "lodash";
import hash from "object-hash";

import { rules as ruleHelpers } from "moveToLibrary/redux/quoteandbuy";
const commonRules = ruleHelpers.common;
import siteRules from "./rules";

const templateDate = {
	salus: {
		fnTo: (value) => reduxToSalusHelpers.processDate(value),
		fnFrom: (value) => salusToReduxHelpers.processDate(value),
	},
};

// const fnDependency =
// 	(depPath) =>
// 	(item, controls, { isPostSalusLoad, isPostRegistration }) => {
// 		if (isPostSalusLoad) return;
// 		if (item.isUpdated()) controls.find(depPath).functions.validate();
// 	};

const fnRequired = commonRules.is.required(undefined);

const retData = {
	// Test: {
	// 	"AdditionalInsured[]": {
	// 		ruleList: [
	// 			siteRules.additionalInsured,
	// 			(item, controls, args) => {
	// 				console.log("AdditionalInsured[]", args.arrayAction, {
	// 					item,
	// 					controls,
	// 					args,
	// 				});
	// 			},
	// 		],
	// 		items: {
	// 			Value: {
	// 				_isTemplateDataNode: true,
	// 				ruleList: [
	// 					fnRequired,
	// 					(item, controls, { isPostSalusLoad, isPostRegistration }) => {
	// 						// console.log("....", { isPostSalusLoad, isPostRegistration})
	// 						if (isPostRegistration) return;

	// 						const find = controls.find;

	// 						const _value2 = find("./Value2");
	// 						_value2.functions.setValue("jjjjj");

	// 						const parentTree = item.functions.getParentTree();

	// 						console.log("parentTree", parentTree);
	// 						parentTree.forEach((x) => {
	// 							const _Value = find(x.generatePath("Value")).value();
	// 							console.log(x, _Value);
	// 						});
	// 					},
	// 				],
	// 			},

	// 			Value2: {
	// 				_isTemplateDataNode: true,
	// 				ruleList: [fnRequired],
	// 			},
	// 			"Claims[]": {
	// 				items: {
	// 					ClaimType: {
	// 						_isTemplateDataNode: true,
	// 						ruleList: [fnRequired],
	// 					},
	// 				},
	// 			},
	// 			RelationshipToProposer: {
	// 				_isTemplateDataNode: true,
	// 				ruleList: [siteRules.additionalInsured, fnRequired],
	// 			},
	// 			MaritalStatus: {
	// 				_isTemplateDataNode: true,
	// 				ruleList: [siteRules.additionalInsured, fnRequired],
	// 			},
	// 			DateOfBirth: {
	// 				_isTemplateDataNode: true,
	// 				ruleList: [siteRules.additionalInsured, fnRequired],
	// 			},
	// 			FullTimeOccupation: {
	// 				Type: {
	// 					_isTemplateDataNode: true,
	// 					ruleList: [fnRequired, siteRules.occupationLogic],
	// 				},
	// 				OccCode: {
	// 					_isTemplateDataNode: true,
	// 					ruleList: [fnRequired, siteRules.occupationLogic],
	// 				},
	// 				EmpCode: {
	// 					_isTemplateDataNode: true,
	// 					ruleList: [fnRequired, siteRules.occupationLogic],
	// 				},
	// 			},
	// 		},
	// 	},
	// },
	ChosenOptions: {
		StartConfirm: {
			_isTemplateDataNode: true,
			ruleList: [fnRequired, siteRules.startLogic],
			isSubmit: false,
		},
		VehicleExtraDetailsConfirmation: {
			_isTemplateDataNode: true,
			ruleList: [fnRequired],
			isSubmit: false,
			defaultValue: false,
		},
		OwnerKeeperQuestion: {
			_isTemplateDataNode: true,
			ruleList: [fnRequired, siteRules.vehicleLogic],
			isSubmit: false,
		},
		EmailConfirm: {
			_isTemplateDataNode: true,
			ruleList: [
				fnRequired,
				commonRules.is.email(),
				siteRules.emailLogic,
				// (item, controls, { isPostSalusLoad, isPostRegistration }) => {
				// 	if (isPostSalusLoad) return;

				// 	item.functions.errors.clear();

				// 	if (item.isEmpty()) return;

				// 	if (controls.find("./Email").value() !== item.value()) {
				// 		item.functions.errors.set("Your emails don't match");
				// 		return;
				// 	}
				// },
				// commonRules.validate("./Email", { postRegistration: true }),
			],
		},
	},
	Risk: {
		MarketingConsent: {
			Email: {
				_isTemplateDataNode: true,
				isSubmit: true,
				defaultValue: false,
			},
			Post: {
				_isTemplateDataNode: true,
				isSubmit: true,
				defaultValue: false,
			},
			Phone: {
				_isTemplateDataNode: true,
				isSubmit: true,
				defaultValue: false,
			},
			Sms: {
				_isTemplateDataNode: true,
				isSubmit: true,
				defaultValue: false,
			},
			HouseholdRenewal: {
				_isTemplateDataNode: true,
				isSubmit: true,
			},
			PetRenewal: {
				_isTemplateDataNode: true,
				isSubmit: true,
			},
			PublicLiabilityRenewal: {
				_isTemplateDataNode: true,
				isSubmit: true,
			},
		},
		GapType: {
			_isTemplateDataNode: true,
			isSubmit: true,
			exampleValue: "VAL",
		},
		// VehicleListedOnGlassGuide: {
		// 	_isTemplateDataNode: true,
		// 	ruleList: [fnRequired],
		// 	defaultValue: "true",
		// 	isSubmit: true,
		// 	exampleValue: true,
		// },

		BoughtFromDealer: {
			_isTemplateDataNode: true,
			ruleList: [siteRules.vehicleLogic],
			isSubmit: true,
		},
		HireOrRewardUse: {
			_isTemplateDataNode: true,
			// ruleList: [fnRequired],
			isSubmit: true,
			exampleValue: false,
			defaultValue: false,
		},
		IsInsuranceWriteOff: {
			_isTemplateDataNode: true,
			// ruleList: [fnRequired],
			isSubmit: true,
			defaultValue: false,
		},
		Vehicle: {
			// "VehicleType": "Car",
			// "BodyStyle": "-3--",
			// "Doors": "3",
			// "Purchased": "2020-01-01T00:00:00",
			// "Cc": "1560",
			// "Fuel": "FuelDiesel",
			// "Manufacturer": "FORD",
			// "ModelDescription": "FIESTA ZETEC S TDCI (90)",
			// "Value": "3000",
			// "Paid": "3000",
			// "RegNumber": "BX10HKJ",
			// "Transmission": "TransMan",
			// "YearOfManufacture": "2010",
			// "RegistrationDate": "2008-06-01T00:00:00",
			// "IsOver3500kg": false

			VehicleType: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: "Car",
			},
			BodyStyle: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: "-3--",
			},
			Doors: {
				_isTemplateDataNode: true,
				// ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: "3",
			},
			Purchased: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired, siteRules.vehicleLogic],
				// ruleList: [siteRules.vehicleLogic, fnRequired],
				isSubmit: true,
				exampleValue: "2020-01-01T00:00:00",
				...templateDate,
			},
			Cc: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: "1560",
			},
			Fuel: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: "FuelDiesel",
			},
			Manufacturer: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: "FORD",
			},
			ModelDescription: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: "FIESTA ZETEC S TDCI (90)",
			},
			Value: {
				_isTemplateDataNode: true,
				ruleList: [
					fnRequired,
					commonRules.convert.integer,
					commonRules.is.integer(),
				],
				isSubmit: true,
				exampleValue: "3000",
			},
			Paid: {
				_isTemplateDataNode: true,
				ruleList: [siteRules.vehicleLogic, commonRules.convert.integer],
				isSubmit: true,
				exampleValue: "3000",
			},
			RegNumber: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: "BX10HKJ",
			},

			Transmission: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: "TransMan",
			},
			YearOfManufacture: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: "2010",
			},

			RegistrationDate: {
				_isTemplateDataNode: true,
				// ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: "2008-06-01T00:00:00",

				...templateDate,
			},
			IsOver3500kg: {
				_isTemplateDataNode: true,
				// ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: false,
			},

			// Below is NOT in the RECALL
			AbiCode: {
				_isTemplateDataNode: true,
				// ruleList: [fnRequired],
				isSubmit: true,
			},
			IsOnGlassGuide: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				defaultValue: true,
				isSubmit: true,
				exampleValue: true,
			},

			CurrentMileage: {
				_isTemplateDataNode: true,
				ruleList: [
					fnRequired,
					commonRules.convert.integer,
					commonRules.is.integer(),
				],
				isSubmit: true,
			},

			Owner: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired, siteRules.vehicleLogic],
				isSubmit: true,
			},
			Keeper: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired, siteRules.vehicleLogic],
				isSubmit: true,
			},
		},
		Id: {
			_isTemplateDataNode: true,
			isSubmit: true,
			exampleValue: "180313997",
		},
		Frequency: {
			_isTemplateDataNode: true,
			isSubmit: true,
			defaultValue: "Triennial",
			exampleValue: "Triennial",
		},
		Affinity: {
			_isTemplateDataNode: true,
			ruleList: [fnRequired],
			isSubmit: true,
			defaultValue: "FREEDOM",
			exampleValue: "FREEDOM",
		},
		PolicyTypeCode: {
			_isTemplateDataNode: true,
			ruleList: [fnRequired],
			isSubmit: true,
			defaultValue: "C_BSGP",
			exampleValue: "C_BSGP",
		},
		// StartDate: {
		// 	_isTemplateDataNode: true,
		// 	isSubmit: true,
		// 	exampleValue: "2024-03-01T00:00:00Z",
		// 	...templateDate,
		// },
		// RenewalDate: {
		// 	_isTemplateDataNode: true,
		// 	isSubmit: true,
		// 	exampleValue: "2027-03-01T00:00:00Z",
		// 	...templateDate,
		// },
		// QuoteDate: {
		// 	_isTemplateDataNode: true,
		// 	isSubmit: true,
		// 	exampleValue: "2024-02-29T11:50:12.373Z",
		// 	...templateDate,
		// },
		// EndDate: {
		// 	_isTemplateDataNode: true,
		// 	isSubmit: true,
		// 	exampleValue: "2027-02-28T23:59:59Z",
		// 	...templateDate,
		// },
		QuoteType: {
			_isTemplateDataNode: true,
			isSubmit: true,
			defaultValue: "NewQuotTyp",
			exampleValue: "NewQuotTyp",
		},
		PolicyInceptionDate: {
			_isTemplateDataNode: true,
			ruleList: [
				fnRequired,
				siteRules.vehicleLogic,
				siteRules.proposerLogic,
				(item, controls, { isPostSalusLoad, isPostRegistration }) => {
					return;
					if (isPostSalusLoad) return;
					if (!item.value()) return;

					const isValid = dayjs(item.value()).isAfter(
						dayjs()
						// .add(1, "day")
					);

					if (isValid) {
						item.functions.errors.clear();
						return;
					}

					item.functions.errors.set("Date must be after today.");

					// ** CLONE **
					// 	controls.find("./StartDate").functions.setValue(curItem.value());
					// },
				},
			],
			isSubmit: true,
			exampleValue: "2024-03-01T00:00:00",

			...templateDate,
		},
		SourceOfBusiness: {
			_isTemplateDataNode: true,
			ruleList: [fnRequired],
			isSubmit: true,
			defaultValue: "Website",
			exampleValue: "Website",
		},
		SourceOfEnquiry: {
			_isTemplateDataNode: true,
			isSubmit: true,
		},

		Proposer: {
			PaymentMethod: {
				_isTemplateDataNode: true,
			},
			Name: {
				Forenames: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					exampleValue: "Gurjit",
				},
				Salutation: {
					_isTemplateDataNode: true,
					// ruleList: [fnRequired, siteRules.proposerLogic],
					isSubmit: true,
					exampleValue: "Mr Lee",
				},
				Surname: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired, siteRules.proposerLogic],
					isSubmit: true,
					exampleValue: "Lee",
				},
				Title: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired, siteRules.proposerLogic],
					isSubmit: true,
					exampleValue: "Mr",
				},
				TitleGender: {
					_isTemplateDataNode: true,
					ruleList: [
						// commonRules.is.required(undefined),
						fnRequired,
						// fnRequired,
						siteRules.proposerLogic,
						(curItem) => {
							// console.log("test function");
						},
					],
					isSubmit: true,
					exampleValue: "Mr",
				},
			},
			DateOfBirth: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired, siteRules.proposerLogic],
				isSubmit: true,
				exampleValue: "1967-07-31T00:00:00",
				...templateDate,
			},
			ResidentSince: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired, siteRules.proposerLogic],
				isSubmit: true,
				exampleValue: "1967-07-31T00:00:00",
			},
			IsResidentSinceBirth: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				defaultValue: true,
				exampleValue: true,
			},
			FullTimeOccupation: {
				Type: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					defaultValue: "Employee",
					exampleValue: "Employee",
				},
				OccCode: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					defaultValue: "D63",
					exampleValue: "50D",
				},
				EmpCode: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					defaultValue: "139",
					exampleValue: "431",
				},
			},
			PartTimeOccupation: {
				Type: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					defaultValue: "None",
					exampleValue: "None",
				},
				OccCode: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					defaultValue: "220",
					exampleValue: "220",
				},
				EmpCode: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					defaultValue: "186",
					exampleValue: "186",
				},
			},
			HasSecondOccupation: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				defaultValue: false,
				exampleValue: false,
			},
			Licence: {
				Category: {
					_isTemplateDataNode: true,
					isSubmit: true,
					exampleValue: "",
				},
				Date: {
					_isTemplateDataNode: true,
					isSubmit: true,
					exampleValue: "2010-01-01T00:00:00",
					...templateDate,
				},
				Type: {
					_isTemplateDataNode: true,
					isSubmit: true,
					exampleValue: "Full Licence",
				},
			},
			Gender: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired, siteRules.proposerLogic],
				isSubmit: true,
				exampleValue: "M",
			},
			InsuranceRefused: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: false,
				defaultValue: false,
			},
			MaritalStatus: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				defaultValue: "S",
				exampleValue: "S",
			},
			DvlaRestriction: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: "N",
			},
			NonRTAConviction: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: false,
				defaultValue: false,
			},
			Smoker: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				defaultValue: false,
				exampleValue: false,
			},
			AccessToOtherVehicles: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: "BLANK",
			},
			Tests: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: "1",
			},
			VehicleUse: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: "MainUser",
			},
			NumberOfOtherVehicles: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: "0",
			},
			DriverType: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				defaultValue: "Prpsr",
				exampleValue: "Prpsr",
			},
			StarRider: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: "No",
			},
			ConsentForMedicalConditions: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: "NotKnown",
			},
			ConsentForConvictions: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: "NotKnown",
			},
			MainTelephone: {
				Id: {
					_isTemplateDataNode: true,
					isSubmit: true,
					exampleValue: "180314002",
				},
				Usage: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					defaultValue: "Home",
					exampleValue: "Home",
				},
				AreaCode: {
					_isTemplateDataNode: true,
					// ruleList: [fnRequired],
					isSubmit: true,
					// defaultValue: "01782",
					exampleValue: "01782",
				},
				PhoneNumber: {
					_isTemplateDataNode: true,
					// ruleList: [fnRequired],
					isSubmit: true,
					// defaultValue: "000000",
					exampleValue: "000000",
				},
				TelephoneNumber: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired, commonRules.is.mobile()],
					isSubmit: true,
					// defaultValue: "01782000000",
					exampleValue: "01782000000",
				},
			},
			Id: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: "321987",
			},
			Address: {
				AbodeType: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					defaultValue: "House",
					exampleValue: "House",
				},
				Usage: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					defaultValue: "Main",
					exampleValue: "Main",
				},
				CountryCode: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					defaultValue: "GB",
					exampleValue: "GB",
				},
				Postcode: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					exampleValue: "CF82 8AA",
				},
				HouseNameOrNumber: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					exampleValue: "3",
				},
				AddressLine1: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					exampleValue: "Pengam Road",
				},
				AddressLine2: {
					_isTemplateDataNode: true,
					ruleList: [fnRequired],
					isSubmit: true,
					exampleValue: "Ystrad Mynach",
				},
				AddressLine3: {
					_isTemplateDataNode: true,
					// ruleList: [fnRequired],
					isSubmit: true,
					exampleValue: "HENGOED",
				},
				AddressLine4: {
					_isTemplateDataNode: true,
					// ruleList: [fnRequired],
					isSubmit: true,
					exampleValue: "Mid Glamorgan",
				},
			},
			ClientCategory: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: "STANDARD",
			},
			ClientStatus: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: "Prospect",
			},
			"TelephoneNumbers[]": {
				items: {
					Id: {
						_isTemplateDataNode: true,
						ruleList: [fnRequired],
						isSubmit: true,
						exampleValue: "180314002",
					},
					Usage: {
						_isTemplateDataNode: true,
						ruleList: [fnRequired],
						isSubmit: true,
						exampleValue: "Home",
					},
					AreaCode: {
						_isTemplateDataNode: true,
						ruleList: [fnRequired],
						isSubmit: true,
						exampleValue: "01782",
					},
					PhoneNumber: {
						_isTemplateDataNode: true,
						ruleList: [fnRequired],
						isSubmit: true,
						exampleValue: "000000",
					},
					TelephoneNumber: {
						_isTemplateDataNode: true,
						ruleList: [fnRequired],
						isSubmit: true,
						exampleValue: "01782000000",
					},
				},
			},
			Email: {
				_isTemplateDataNode: true,
				ruleList: [
					fnRequired,
					commonRules.is.email(),
					siteRules.emailLogic,
					// commonRules.validate("./EmailConfirm", {
					// 	postRegistration: true,
					// }),
				],
				isSubmit: true,
				exampleValue: "mock-recall@action365.co.uk",
			},

			MotoringOrg: {
				_isTemplateDataNode: true,
				isSubmit: true,
				exampleValue: "BLANK",
			},
			RelationshipToProposer: {
				_isTemplateDataNode: true,
				ruleList: [fnRequired],
				isSubmit: true,
				exampleValue: "Proposer",
				defaultValue: "Proposer",
			},
		},
		MedicalConsentQuestionsActive: {
			_isTemplateDataNode: true,
			isSubmit: true,
			exampleValue: false,
		},
		ConvictionsConsentQuestionsActive: {
			_isTemplateDataNode: true,
			isSubmit: true,
			exampleValue: false,
		},
	},
};

const fnModifyRules = (obj) => {
	const fnBase = siteRules.base;

	if (!fnBase) return obj;

	if ("ruleList" in obj) {
		obj.ruleList = [fnBase].concat(obj.ruleList);
	}

	Object.entries(obj)
		.filter(([k, v]) => k !== "ruleList")
		.filter(([k, v]) => _.isObject(v))
		.filter(([k, v]) => !_.isArray(v))
		.forEach(([k, v]) => fnModifyRules(v));
};

fnModifyRules(retData);
// console.log(retData);
// throw `hh`;
export default retData;
