import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { PriceDisplay, PriceDetails } from "siteComponentsLibrary/Display";

const ViewsGapYourQuoteHeader = (props) => {
	const commonData = useOutletContext();

	const currentQuote = commonData.quote.data.currentQuote;

	if (!currentQuote) return null;

	const quoteResults = commonData.quote.data.quoteResults;
	const availablePaymentMethods = commonData.quote.data.paymentMethods;
	const chosenOptions = commonData.quote.data.chosenOptions;
	const _values = commonData.risk.values;

	const selectedPayMethod = chosenOptions.paymentMethod;
	const instalmentDetails =
		currentQuote.HasInstalments && currentQuote.Instalments[0];

	return (
		<PriceDetails
			isSticky={props.isSticky}
			allowPayInFull={true}
			allowPayMonthly={currentQuote.HasInstalments}
			selectedPayMethod={selectedPayMethod}
			payInFullPremium={currentQuote.PayInFullPremium}
			instalmentDetails={instalmentDetails}
			postFixMonth={<> / month</>}
			postFixYear={undefined}
			separatorContent={
				<div className="price-separator d-flex justify-content-center align-items-md-center">
					or
				</div>
			}
		/>
	);
};

export default ViewsGapYourQuoteHeader;
