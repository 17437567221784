import moment from "moment";
// import functions from "../../../base";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";
import _ from "lodash";

export default (args) => {
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;

	const dh = new dataHelper({
		mappings: ["ClassOfUse", "BusinessMileage"],
		mappingsOptional: ["TotalMileage"],
		dataSet,
		functionArgs,
	});

	const { policyType } = functionArgs;

	if (policyType !== "PC") return;

	// console.log("ddd", args);
	const enableBusinessMileage =
		!["SDP", "SDPexC"].includes(dh.getValue("ClassOfUse")) &&
		dh.getValue("ClassOfUse") !== undefined;

	dh.setData("visible", "BusinessMileage", enableBusinessMileage);

	if (enableBusinessMileage) {
		dh.addErrorIfEmpty("BusinessMileage", ""); // "Required")

		if (
			// policyType === "dPC" &&
			dh.getValue("TotalMileage") !== undefined &&
			dh.getValue("BusinessMileage") !== undefined &&
			_.toNumber(dh.getValue("TotalMileage")) <
				_.toNumber(dh.getValue("BusinessMileage"))
		) {
			dh.addError("TotalMileage", ""); //"Should not be less than business mileage");
			dh.addError(
				"BusinessMileage",
				"This cannot be more than your estimated annual mileage." //"Should not be greater than your annual mileage"
			);
		}
	} else {
		if (dh.isUpdated("ClassOfUse")) dh.setValue("BusinessMileage", undefined);
		dh.clearError("BusinessMileage");
		dh.resetErrorsShow("BusinessMileage");
	}

	dh.commit();
};
