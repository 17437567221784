import React from "react";
import Base from "../Base";

// import { ButtonStyled } from "libraryComponents";
import { Button } from "siteComponentsLibrary/Buttons";

const ChunkView = (props) => {
	const onClick = () => {
		location.reload(true);
	};

	return (
		<Base heading="Our site has been updated" icon="icon-updated">
			<div>Since you were last here our site has been updated.</div>
			<div>
				To ensure you get everything up to date you'll need to reload our site.
			</div>
			<div>Please refresh your browser to start a new quote.</div>
		</Base>
	);
};

export default ChunkView;
