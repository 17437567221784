import React from "react";
export * from "libraryComponents/Display";

import * as LibComponents from "libraryComponents/Display";

//**** Custom && Overrides (replaces the export above) ****

export const DisplayItem = ({ ...otherProps }) => (
    <LibComponents.DisplayItemFlex
        {...otherProps}
        />
);