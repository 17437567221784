import React, { useState } from "react";
import { SiteHeader} from "siteComponentsLibrary/Layout";

import ContactDetails from "./ContactDetails";

import _logo from "siteAssets/images/logo.png";
import _logoWithText from "siteAssets/images/logo-with-text.svg";

const SiteTemplateHeader = (props) => {
	
	return (
		<SiteHeader
			brandImage={_logo}
			brandImageAlt="Freedom Brokers"
			brandImageOnClick={(e) => {
				e.preventDefault();
				location.href = "/";
			}}
			contactDetails={<ContactDetails />}
		/>
	);
	
	return (
		<div className="row" data-cy="site-header">
			{/* Top left image */}
			<div className="col-6 d-flex align-items-center justify-content-start nav-left" data-cy="site-header-image">
				<img
					style={{ cursor: "pointer" }}
					className="brand-img img-fluid"
					src={_logo}
					alt="Freedom Brokers"
					onClick={(e) => {
						e.preventDefault();
						location.href = "/";
					}}
				/>
			</div>

			{/* Top right contact details */}
			<div className="col-6 d-flex align-items-center justify-content-end nav-right">
				<ContactDetails />
			</div>
		</div>
	);
};

export default SiteTemplateHeader;
