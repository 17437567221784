import dataHelper from "@library/common/database/rules/helpers/dataHelper";

export default (args) => {
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;
	// NOTE: we need to include PolicyInceptionDate as that is part of a RECALL, meaning that this code will then run
	const dh = new dataHelper({
		logging: false,
		loggingName: "riskLogic",
		mappings: ["VehicleExtraDetailsConfirmation", "PolicyInceptionDate"],
		dataSet,
		functionArgs,
	});

	// alert("PolicyInceptionDate")
	// On abi code change, set VehicleExtraDetailsConfirmation to undefined
	if (dh.isPostRecall()) {
		dh.setValue("VehicleExtraDetailsConfirmation", true);
	}

	dh.addError(
		"VehicleExtraDetailsConfirmation",
		dh.getValue("VehicleExtraDetailsConfirmation") === undefined
			? ""
			: undefined
	);
	dh.commit();
};
