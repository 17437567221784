import React, { useState, useEffect } from "react";

const useDirectDebit = (fnValidateService) => {
  if (!fnValidateService)
    throw `Error in useDirectDebit() -- missing a fnValidateService`;

  const [sortCode, setSortCode] = useState(undefined);
  const [accountNumber, setAccountNumber] = useState(undefined);
  const [accountName, setAccountName] = useState(undefined);

  const [isValidating, setIsValidating] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const errorMessage = (function () {
    if (isValidating) return undefined;

    if (!accountName) return "Please enter an account name.";
    if (!sortCode) return "Please enter a sort code.";
    if (!accountNumber) return "Please enter an account number.";

    return undefined;
  })();

  const fnValidate = async () => {
    setIsValid(false);
    setIsInvalid(false);

    if (errorMessage) return;
    if (isValidating) return;

    try {
      setIsValidating(true);
      const _isValid = await fnValidateService({
        sortCode,
        accountNumber,
      });

      setIsValidating(false);

      setIsValid(_isValid);
      setIsInvalid(!_isValid);
    } catch (e) {
      setIsValidating(false);
      setIsValid(false);
    }
  };

  useEffect(() => {
    // console.log("VALIDATING");
    fnValidate();
  }, [sortCode, accountNumber, accountName]);

  return {
    status: {
      errorMessage: errorMessage,
      isValidating: isValidating,
      isValid: isValid,
      isInvalid: isInvalid,
    },
    values: {
      sortCode,
      accountNumber,
      accountName,
    },
    functions: {
      validate: fnValidate,
      update: {
        accountName: setAccountName,
        sortCode: setSortCode,
        accountNumber: setAccountNumber,
      },
    },
  };
};

export default useDirectDebit;
