import React, { useState, useEffect } from "react";
import { useRouteError } from "react-router-dom";
import ErrorBoundaryViewBase from "siteViews/Errors/ErrorBoundary";
import ChunkView from "siteViews/Errors/Chunk";

import SiteLayoutError from "siteLayouts/Site/Error";

import services from "siteService";
import JSONhelper from "@library/common/helpers/misc/json";
import _ from "lodash";
import { ErrorBoundary } from "react-error-boundary";
// import { serializeError, deserializeError } from "serialize-error";
// import serializerr from "serializerr";

const ErrorBoundaryView = () => {
	return <ErrorBoundaryViewBase />;
};

const fnStringifyError = (err) => {
	// return JSON.stringify(err, (value) => {
	//   console.log("---", value, typeof value);
	//   return value;
	// });
	// return JSON.stringify(serializerr(err));
	// return serializeError(err);
	// console.log(level, err);
	// if (!err) return err;

	// return Object.fromEntries(
	//   Object.entries(err).map(([key, value]) => {
	//     return [key, fnStringifyError(value, level + 1)];
	//   })
	// );

	// // return JSON.stringify(err, Object.getOwnPropertyNames(err));
	// // https://levelup.gitconnected.com/beware-of-using-json-stringify-for-logging-933f18626d51
	const replaceError = (key, value) => {
		if (value instanceof Error) {
			const newValue = Object.getOwnPropertyNames(value).reduce(
				(obj, propName) => {
					obj[propName] = value[propName];
					return obj;
				},
				{ name: value.name }
			);
			return newValue;
		} else {
			return value;
		}
	};
	return JSON.stringify(err, replaceError, 2);
};

const fnLogError = (errObj, errorType) => {
	// console.log("ddadadad", errObj, fnStringifyError(errObj));
	// return;
	try {
		const { store, selectors } = require("siteStore");
		const state = store.getState();
		const sessionId = selectors.session.getSessionId(state);

		// console.log("Error logging", { errObjStringify: fnStringifyError(errObj) });

		const payload = {
			PersistId: sessionId,
			VersionNumber: process.env.BUILD_TIME,
			Details: JSONhelper.stringify({
				buildTime: process.env.BUILD_TIME,
				reduxStore: state,
			}),
			What: errorType,
			Where: window.location.href,
			Why: fnStringifyError(errObj),
		};

		console.warn("Error logging", {
			errorType,
			errObj,
			payload,
			// errObjxxx: fnStringifyError(errObj),
		});

		services.error.log(payload);
	} catch (e) {
		console.log("Error in fnLogError", e);
	}
};

export const ErrorBoundarySite = (props) => {
	const { children } = props;

	const [showError, setShowError] = useState(false);
	const [showChunk, setShowChunk] = useState(false);

	const fnCheckIsChunk = (e) => {
		if (!e) return false;

		if (_.isString(e)) {
			if (e.toLowerCase().includes("chunkloaderror")) return true;
		}

		if (e instanceof Error && _.isString(e.message)) {
			if (e.message.toLowerCase().includes("chunkloaderror")) return true;
		}

		return false;
	};

	const fnCheckIsError = (errObj) => {
		if (!errObj) return false;

		// Third party script?
		if (errObj.message === "Script error.") return false;

		return true;
	};

	//*******************************************
	// START: EVENT HANDLERS
	//*******************************************
	// https://stackoverflow.com/questions/44815172/log-shows-error-object-istrustedtrue-instead-of-actual-error-data
	useEffect(() => {
		// Standard onError
		window.onerror = (event, source, lineno, colno, errObj) => {
			try {
				if (showError) return;

				// Do we need to convert the errObj?
				const [_errObj, _isErrConverted] = (function () {
					if (errObj instanceof Error) return [errObj, false];
					// Probably a string
					return [new Error(event), true];
				})();

				if (fnCheckIsChunk(_errObj)) {
					setShowChunk(true);
					return;
				}

				if (fnCheckIsError(_errObj)) {
					setShowError(true);
					fnLogError(
						errObj,
						["window.onerror", _isErrConverted && "string"]
							.filter((x) => x)
							.join(" ")
					);
					return;
				}
			} catch (e) {
				console.error("window.onerror failed", e);
			}
			//else do nothing
		};

		// Promises etc
		window.onunhandledrejection = (errObj) => {
			try {
				if (showError) return;

				// Do we need to convert the errObj?
				const [_errObj, _isErrConverted] = (function () {
					if (errObj.reason && errObj.reason.message)
						return [new Error(errObj.reason.message), true];

					if (_.isString(errObj.reason))
						return [new Error(errObj.reason), true];

					return [errObj, false];
				})();

				if (fnCheckIsChunk(_errObj)) {
					setShowChunk(true);
					return;
				}

				if (fnCheckIsError(_errObj)) {
					setShowError(true);
					fnLogError(
						errObj,
						["window.onunhandledrejection", _isErrConverted && "message"]
							.filter((x) => x)
							.join(" ")
					);
					return;
				}

				//else do nothing
			} catch (e) {
				console.error("window.onunhandledrejection failed", e);
			}
		};
	}, []);
	//*******************************************
	// END: EVENT HANDLERS
	//*******************************************

	// console.log("Errors:", { showError, showChunk });

	if (showError)
		return (
			<SiteLayoutError>
				<ErrorBoundaryView />
			</SiteLayoutError>
		);

	if (showChunk)
		return (
			<SiteLayoutError>
				<ChunkView />
			</SiteLayoutError>
		);

	return (
		<ErrorBoundary
			FallbackComponent={ErrorBoundaryView}
			onError={(e) => {
				const isChunk = fnCheckIsChunk(e);

				if (isChunk) {
					setShowChunk(true);
				} else {
					fnLogError(e, "ErrorBoundary");
				}
			}}
		>
			{children}
		</ErrorBoundary>
	);
};

export const ErrorBoundaryReactRouter = () => {
	const errors = useRouteError();

	useEffect(() => {
		fnLogError(errors, "ErrorBoundaryReactRouter");
	}, []);
	// console.log("dddd",errors )
	return <ErrorBoundaryView />;
};
