import React, { useState } from "react";
import config from "config";

import { generateCypressProps } from "libraryFunctions/cypress";
import { ButtonLink } from "siteComponentsLibrary/Buttons";

export const DevContainer = (props) => {
	const { children, heading, enabled = true } = props;
	const [isVisible, setIsVisible] = useState(true);

	if (!enabled) return null;
	if (!isVisible) return null;
	if (!(config.isDev || config.isTest)) return null;

	return (
		<div
			className="dev-container my-3 p-2 bg-light rounded-3 border border-danger border-5 position-relative"
			{...generateCypressProps("dev-container", props)}
		>
			{heading && <p className="h5 fw-700">{heading}</p>}

			{children}

			<div className="position-absolute top-0 end-0">
				<ButtonLink
					onClick={(e) => {
						e.preventDefault();
						setIsVisible(false);
					}}
				>
					Hide
				</ButtonLink>
			</div>
		</div>
	);
};
