import React from "react";
import { useOutletContext } from "react-router-dom";

import Base, { formatItem } from "./Base";
import { InputGroupPound } from "siteComponentsLibrary/Inputs";

const CarSummary = (props) => {
	const commonData = useOutletContext();
	const { TextBox, FormGroup, DatePickerTextBox } = commonData.storeComponents;
	const showValuePrecalcMessage = false;

	const summaryHitlist = [
		// {
		// 	id: "purchased",
		// 	memoize: false,
		// 	databaseProps: { path: "Risk/Vehicle/Purchased" },
		// 	showInSummary: true,
		// 	showInEdit: true,
		// 	showInMissing: true,
		// 	fnTemplate: (v) => `It was purchased on ${formatItem("date", v)}.`,
		// 	Component: () => (
		// 		<FormGroup
		// 			databaseProps={{ path: "Risk/Vehicle/Purchased" }}
		// 			label="When did you purchase the car?"
		// 			classNameContent="col-md-8 col-lg-6"
		// 			data-cy="form-group:car-purchase"
		// 		>
		// 			<DatePickerTextBox
		// 				databaseProps={{ path: "Risk/Vehicle/Purchased" }}
		// 				label="Car purchase date"
		// 				data-cy="date:car-purchase"
		// 			/>
		// 		</FormGroup>
		// 	),
		// },

		// {
		// 	id: "value",
		// 	memoize: false,
		// 	databaseProps: { path: "Risk/Vehicle/Value" },
		// 	showInSummary: true,
		// 	showInEdit: true,
		// 	showInMissing: true,
		// 	fnTemplate: (v) => (
		// 		<>
		// 			The value is {formatItem("currency", v)}
		// 			{showValuePrecalcMessage && (
		// 				<span>
		// 					{" "}
		// 					<small>(based on an independent valuation)</small>
		// 				</span>
		// 			)}
		// 			.
		// 		</>
		// 	),
		// 	Component: () => (
		// 		<FormGroup
		// 			databaseProps={{
		// 				path: "Risk/Vehicle/Value",
		// 			}}
		// 			label="What is the value of the car?"
		// 			helpText={
		// 				showValuePrecalcMessage &&
		// 				"We have calculated the market value of your car using an independent provider."
		// 			}
		// 			xsCols="6"
		// 			data-cy="form-group:car-value"
		// 		>
		// 			<InputGroupPound
		// 				data-cy="input-group:car-value"
		// 			>
		// 				<TextBox
		// 					databaseProps={{ path: "Risk/Vehicle/Value" }}
		// 					data-cy="text:car-value"
		// 				/>
		// 			</InputGroupPound>
		// 		</FormGroup>
		// 	),
		// },
	];

	return <Base summaryHitlist={summaryHitlist} vehicleTypeDisplay="car" />;
};

export default CarSummary;
