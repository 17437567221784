import React, { useState } from "react";
import { JSONTree } from 'react-json-tree';
//import ReactJson from "react-json-view";

// https://github.com/reduxjs/redux-devtools/tree/75322b15ee7ba03fddf10ac3399881e302848874/src/react/themes
const theme = {
	scheme: "google",
	author: "seth wright (http://sethawright.com)",
	base00: "#1d1f21",
	base01: "#282a2e",
	base02: "#373b41",
	base03: "#969896",
	base04: "#b4b7b4",
	base05: "#c5c8c6",
	base06: "#e0e0e0",
	base07: "#ffffff",
	base08: "#CC342B",
	base09: "#F96A38",
	base0A: "#FBA922",
	base0B: "#198844",
	base0C: "#3971ED",
	base0D: "#3971ED",
	base0E: "#A36AC7",
	base0F: "#3971ED",
};

const showStateItem = (props) => {
	// todo: dev check

	const {
		state,
		sortObjectKeys = true,
		hideRoot = true,
		expandLevel = 0,
		...otherProps
	} = props;
	//console.log("JSONdisplay", state);
	if (!state) return null;

	// const [isExpanded, setIsExpanded] = useState(expanded);
	// const showData = () => {
	// 	setIsExpanded(true);
	// };

	// if (!isExpanded) return <a onClick={showData}>(State) </a>;

	return (
		<div className="border border-success m-2 p2">
			{/* <ReactJson src={state} sortKeys={true} /> */}
			<JSONTree
				data={state}
				theme={theme}
				sortObjectKeys={sortObjectKeys}
				hideRoot={hideRoot}
				shouldExpandNode={(keyPath, data, level) => {
					if (expandLevel >= level) return true;

					return false;
				}}
				{...otherProps}
			/>
		</div>
	);
};

export default showStateItem;
