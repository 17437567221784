import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import LayoutPageGap from "siteLayouts/Page/Gap";

import { Panel, PanelSection, FormGroup } from "siteComponentsLibrary/Layout";
import { Button, ExternalLink } from "siteComponentsLibrary/Buttons";
import { Help, PriceDisplay, FinanceProvider } from "siteComponentsLibrary/Display";
import { Pulse } from "siteComponentsLibrary/Animated";
import { DevContainer } from "siteComponents/Dev";
import { Error } from "siteComponentsLibrary/Feedback";

import {
	CheckboxWithLabel,
	TextBox,
	TextBoxNumber,
	SortCode,
} from "siteComponentsLibrary/Inputs";

import { CircularProgress } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

import useDirectDebit from "moveToLibrary/hooks/useDirectDebit";

import { useOutletContext, useLocation, Navigate } from "react-router-dom";

const DDHeading = ({children, dataCy}) => (
	<div className="col-12 mb-2">
		<div className="dd-heading" data-cy={`dd-subheading-${dataCy}`}>
			{children}
		</div>
	</div>
);

const DDTextBlock = ({ children, className, dataCy }) => (
	<div
		className={["col-12", "mb-3", className].filter(Boolean).join(" ")}
		data-cy={`dd-textblock-${dataCy}`}
	>
		{children}
	</div>
);

const ComponentFeedback = ({ alertType, children }) => (
	<div
		className={
		[
			"alert", 
			`alert-${alertType}`, 
			"dd-feedback", 
			"d-flex", 
			"justify-content-center", 
			"align-items-center"
		].filter(Boolean).join(" ")}
	>
		{children}
	</div>
)

const ComponentMissing = () => (
	<ComponentFeedback alertType="secondary">
		Please enter your bank details.
	</ComponentFeedback>
);
const ComponentValidating = () => (
	<ComponentFeedback alertType="secondary">
		<span className="d-inline-block me-2">Checking your bank details.</span>
		<Pulse size="6px"/>
	</ComponentFeedback>
);
const ComponentOkay = () => (
	<ComponentFeedback alertType="success">
		Your bank details have been validated.
	</ComponentFeedback>
);
const ComponentError = () => (
	<ComponentFeedback alertType="danger">
		Sorry, those details don't appear to be valid. Please check and try again.
	</ComponentFeedback>
);

const ViewsGapDirectDebit = (props) => {
	const { layoutProps } = props;

	const commonData = useOutletContext();

	if (!commonData.quote.data.hasQuote) return null;

	const currentQuote = commonData.quote.data.currentQuote;
	const chosenQuoteInstalments = currentQuote.Instalments[0];

	console.log("ViewsGapDirectDebit.jsx", {
		commonData,
		currentQuote,
		chosenQuoteInstalments,
	});

	const directDebitData = useDirectDebit(
		commonData.functions.process.bank.validateBankDetails
	);

	const [showErrors, setShowErrors] = useState(false);

	// const [DDStatus, setDDStatus] = useState();
	const [DDConfirmation, setDDConfirmation] = useState(undefined);
	const [DDErrShowConfirmation, setDDErrShowConfirmation] = useState(false);

	const isValidating = directDebitData.status.isValidating;

	const DDStatus = (function () {
		if (isValidating) return <ComponentValidating />;
		if (directDebitData.status.isValid) return <ComponentOkay />;
		if (directDebitData.status.isInvalid) return <ComponentError />;
		return <ComponentMissing />
	})();

	// // ** Payment Launcher **
	// useEffect(() => {
	// 	// if (loadingData.isCompleting) return;
	// 	if (_.isEmpty(commonData.values.registerPayment)) return;

	// 	console.log("registerPayment", commonData.values.registerPayment);
	// 	if (commonData.values.registerPayment.RedirectUrl) {
	// 		commonData.functions.navigate.loadUrl(
	// 			commonData.values.registerPayment.RedirectUrl
	// 		);
	// 	}
	// }, [_.isEmpty(commonData.values.registerPayment)]);

	const errorData = (function () {
		if (!DDConfirmation) {
			return {
				disableNextButton: true,
			};
		}

		if (directDebitData.status.isValidating) {
			return {
				disableNextButton: true,
			};
		}

		if (directDebitData.status.errorMessage) {
			return {
				errorMessage: showErrors && directDebitData.status.errorMessage,
				disableNextButton: true,
			};
		}

		if (directDebitData.status.isInvalid) {
			return {
				errorMessage: showErrors && "Please correct the errors above",
				disableNextButton: true,
			};
		}

		return { disableNextButton: false };
	})();

	const newLayoutProps = {
		...layoutProps,
		errorMessage: errorData.errorMessage || layoutProps.errorMessage,
		pagerData: {
			// errorMessage: showErrors && errorData.errorMessage, //TODO: optionally show
			// isPagerEnabled: navControls.isEnabled,
			...layoutProps.pagerData,
			fnOnNext: () => {
				if (isValidating) return;
				if (!DDConfirmation) {
					setDDErrShowConfirmation(true);
					return;
				}

				setShowErrors(true);
				if (errorData.disableNextButton) return;

				layoutProps.pagerData.fnOnNext({
					directDebit: {
						sortCode: directDebitData.values.sortCode,
						accountName: directDebitData.values.accountName,
						accountNumber: directDebitData.values.accountNumber,
					},
				});
			},
			// fnOnPrev: navControls.gotoPrev,
			// labelNext: navControls.labelNext,
			// labelPrevious: navControls.labelPrevious,
		},
	};

	return (
		<LayoutPageGap headingText="Direct Debit" {...newLayoutProps} >
			<Panel header={"Set up your Direct Debit"} data-cy="panel-direct-debit">
				<PanelSection
					data-cy="section:instalment-details"
				>
					By choosing to finance the cost of your insurance you'll need to
					pay an initial payment of{" "}
					<PriceDisplay value={chosenQuoteInstalments.DepositAmount} />;{" "}
					followed by{" "}
					<strong>
						{chosenQuoteInstalments.NumberOfPaymentsToDisplay}
					</strong>{" "}
					monthly repayments of{" "}
					<PriceDisplay value={chosenQuoteInstalments.InstalmentAmount} />
					, including a <strong>&pound;5</strong> facility fee,
					equal to an APR of <strong>{chosenQuoteInstalments.AprRate}%</strong>. 
					<span className="d-block">
						The total cost of the credit is{" "}
						<PriceDisplay value={Math.abs(chosenQuoteInstalments.TotalCostOfCredit)} />.
					</span>
				</PanelSection>
				<PanelSection data-cy="section:finance-provider">
					<FinanceProvider
						imageSrc={require("siteAssets/images/directdebit/direct-debit-logo.png")}
					/>
				</PanelSection>
				
				<PanelSection
					data-cy="section:bank-details"
					heading="Please complete the details below to set up your Direct Debit"
					>
				</PanelSection>
				
				<FormGroup
					label="Name(s) of account holder(s)"
					data-cy="form-group:account-name"
					classNameContent="col-md-7"
				>
					<TextBox
						value={directDebitData.values.accountName}
						onChange={(v) =>
							directDebitData.functions.update.accountName(v)
						}
						disabled={isValidating}
						id="ddAccountName"
						aria-label="Name(s) of account holder(s)"
					/>
				</FormGroup>
				
				<FormGroup
					label="Sort code"
					data-cy="form-group:sort-code"
					classNameContent="col-md-7"
				>
					<SortCode
						value={directDebitData.values.sortCode}
						onChange={(v) => directDebitData.functions.update.sortCode(v)}
						id="ddSortCode"
						disabled={isValidating}
						data-cy="input:sort-code"
					/>
				</FormGroup>

				<FormGroup
					label="Account number"
					data-cy="form-group:account-number"
					classNameContent="col-md-7"
				>
					<TextBoxNumber
						value={directDebitData.values.accountNumber}
						onChange={(v) =>
							directDebitData.functions.update.accountNumber(v)
						}
						disabled={isValidating}
						id="ddAccountNumber"
						maxLength={8}
						aria-label="Account number"
					/>
				</FormGroup>

				<PanelSection data-cy="section:feedback">
					<div className="row">
						<div className="col col-md-7">
							{DDStatus}
						</div>
					</div>					
				</PanelSection>
				
				<PanelSection
					data-cy="section:important-info"
					heading="Important information about your Direct Debit"
				>
					To use this facility you must be resident in the UK, aged 18 years
					or over and hold a bank or building society current account which
					can support Direct Debit payments. Credit is available subject to
					status. After purchasing this policy you will receive a Welcome Pack
					from your finance provider with all the relevant documentation
					(including two copies of your agreement). The monthly instalments
					will be taken on the day of the inception of this policy for the
					following {chosenQuoteInstalments.NumberOfPayments} months. i.e. for
					a policy incepted on 1st March, the instalments will be collected
					starting on 1st April.
				</PanelSection>
				
				<PanelSection
					data-cy="section:dd-instruction"
					heading="Instruction to your Bank/Building Society to pay by Direct Debit"
				>
					<div data-cy="please-pay">
						Please pay PCL LTD Direct Debits from the account detailed in this
						Instruction, subject to the safeguards assured by the Direct Debit
						Guarantee. I understand that this instruction may remain with PCL
						LTD and, if so, details will be passed electronically to my
						Bank/Building Society.
					</div>
					<div data-cy="default-fee">
						Your premium finance company will charge a default fee of £25.00 for
						any missed or failed payments. All charges are explained in your
						pre-contractual explanations document that will be sent to you by
						Premium Credit Limited once your policy is set up.
					</div>
					<div data-cy="guarantee">
						Please read the{" "}
						<ExternalLink
							label="Direct Debit Guarantee"
							href="https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Docs/DirectDebitGuarantee.pdf"
							data-cy="link:dd-guarantee"
						/>.
					</div>
				</PanelSection>

				<PanelSection
					data-cy="section:agreement"
					heading="Your agreement"
				>
					<CheckboxWithLabel
						id="confirm_agree"
						data-cy="checkbox:dd-confirm-auth"
						className=""
						label={`I can confirm that I'm both the policyholder and authorised to initiate debits from this account.`}
						showError={DDErrShowConfirmation && !DDConfirmation}
						errorMsg="Please confirm if you wish to proceed."
						isChecked={DDConfirmation}
						onSelect={() => setDDConfirmation(true)}
						onUnselect={() => setDDConfirmation(false)}
						onClick={() => setDDErrShowConfirmation(true)}
					/>					
				</PanelSection>

			</Panel>

			<DevContainer>
				<Button onClick={() => directDebitData.functions.validate()}>
					Validate
				</Button>
				<Button
					onClick={() => {
						directDebitData.functions.update.accountName("Mr Test");
						directDebitData.functions.update.accountNumber("87654321");
						directDebitData.functions.update.sortCode("112233");
					}}
				>
					Dummy data
				</Button>
				{/* <ButtonStyled
          onClick={() => commonData.functions.process.registerPayment()}
        >
          Payment Launcher
        </ButtonStyled> */}
			</DevContainer>
		</LayoutPageGap>
	);
};

export default ViewsGapDirectDebit;
