import React from "react";
import Base from "./index";

import TemplateHeader from "../Template/Header";
import TemplateFooter from "../Template/Footer";

const SiteLayoutError = (props) => {
	return <Base {...props} />;
	const { children } = props;
	return (
		<Base>
			<header className="layout-header min-h-100-px mt-auto bg-black">
				<TemplateHeader />
			</header>

			<body>{children}</body>
			<footer className="layout-footer min-h-100-px mt-auto bg-black">
				<TemplateFooter />
			</footer>
		</Base>
	);
};

export default SiteLayoutError;
