const fnOutputErrorInfo = (...args) => {
	console.log("********************************************");
	console.log("ERRORINFO:", ...args);
	console.log("********************************************");
};

export default require("./riskUtilsBase").default({
	_fnGetRiskData: (state) => {
		try {
			return state.userData.risk.data;
		} catch (e) {
			fnOutputErrorInfo("ERROR IN fnGetRiskData", { state });
			throw e;
		}
	},
	description: "riskUtils main data",
	_fnOnMissingItem: (errorMessage, ...logArgs) => {
		return undefined;
	},
});
