const globalDebug = false;

const requiredArgs = ["google", "awsConfig", "endPointSets"];

export default (args) => {
	requiredArgs.forEach((x) => {
		if (!(x in args)) {
			console.log("args:", args);
			throw `Error in base config -- missing ${x}`;
		}
	});

	console.log("config args:", args);

	const isDev = args.isDev || false;
	const useStrictMode = args.useStrictMode || false;
	const isTest = args.isTest || false;
	const isStage = args.isStage || false;
	const isProd = args.isProd || false;
	const liveDisable = (preferedValue) => (!isDev ? false : preferedValue);

	return {
		endPoints: require("./endpoints").default({
			endPointSets: args.endPointSets,
			isDev,
			isTest,
			isStage,
			isProd,
		}),
		// awsConfig: args.awsConfig,
		isDev,
		isTest,
		isStage,
		isProd,
		useStrictMode,
		google: args.google,
		debug: {
			clearConsoleOnRefresh: false,
		},
		lookups: {
			telephoneNumbers: {
				sales: "0161 808 9899",
				service: "0161 808 9899",
				claims: "0345 528 0255",
			},
			email: {
				service: "",
			},
			links: {
				privacyPolicy: "https://www.freedombrokers.co.uk/privacy-policy",
				cookiePolicy: "https://www.freedombrokers.co.uk/cookie-policy",

				documents: {
					tobaGeneric: "https://www.freedombrokers.co.uk/terms-of-business",
					tobaGap:
						"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/TOBA-GAP.pdf",
					tobaNonTele:
						"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/TOBA-Car-and-Van.pdf",
					tobaTele:
						"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/TOBA-Telematics.pdf",
					privacyPolicy: "https://www.freedombrokers.co.uk/privacy-policy",
				},
			},

			errors: {
				boundary: {
					whitelist: ["Script error."],
				},
			},

			routes: {
				gap: {
					entryPoints: ["", "quote", "recall-quote/*"],

					//NOTE: These are absolute paths -- they will need changing *if* the GAP page is every moved to a sub route
					sections: {
						quoteAndBuyHandover: "recall-quote",
						start: "quote",
						risk: "quote",
						recall: "recall",
						feedback: "feedback",
						wrapupInterstitial: "completing",
						wrapup: "thank-you",
						yourQuote: "quote/your-quote",
					},
				},
			},
		},
	};
};
