import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useMatches } from "react-router-dom";
import dayjs from "dayjs";
import JSONhelper from "@library/common/helpers/misc/json";
import _ from "lodash";

const useQuoteAndBuy = (args = {}) => {
	const fnThrowError = (msg) => {
		console.log("DEBUG INFO:", {});
		throw ["Error in useQuoteAndBuy()", msg].filter(Boolean).join(" ");
	};

	// *****************************************************
	// VARIABLES
	// *****************************************************

	const [registerPaymentData, setRegisterPaymentData] = useState({});
	const [purchaseResponseData, setPurchaseResponseData] = useState({});

	const [showSessionSaving, setShowSessionSaving] = useState(false);
	const [wrapupData, setWrapupData] = useState(undefined);

	const services = args.services;
	const quoteAndBuyStore = args.quoteAndBuyStore;
	const sessionStore = args.sessionStore;
	const configRisk = args.risk;
	const configFunctions = args.functions;
	const dataDictionary = args.dictionary || {};
	const dataStoreComponents = args.storeComponents || {};

	const {
		dispatch = useDispatch(),
		navigate = useNavigate(),
		location = useLocation(),
	} = args;

	// console.log("useQuoteAndBuy HOOK", {
	// 	location,
	// 	args,
	// });

	// const __defaultPaymentMethod = useSelector((state) => {
	// 	if (!configRisk?.values?.paymentMethod) return undefined;
	// 	return quoteAndBuyStore.selectors.userData.risk.value(
	// 		state,
	// 		configRisk.values.paymentMethod
	// 	);
	// });

	// const salusPayload =
	// 	quoteAndBuyStore.selectors.userData.quote.payloads.getQuote(
	// 		configFunctions.getState()
	// 	);

	// console.log("dddd", { salusPayload });

	// *****************************************************
	// BASE FUNCTIONS
	// *****************************************************

	const fnNavigate = async (route, options = {}) => {
		if (route === undefined) fnThrowError("fnNavigate -- route is undefined");
		console.log("Navigating to", route, options);
		return await navigate(route, options);
	};

	const fnGetStatus = (state) => {
		const quoteStatus =
			quoteAndBuyStore.selectors.userData.quote.currentStatus(state);

		const getQuoteStatus = quoteAndBuyStore.selectors.status.get(
			state,
			"getQuote"
		);

		const quoteResults = quoteAndBuyStore.selectors.userData.quote.quoteResults(
			configFunctions.getState()
		);

		const firstQuote =
			quoteResults && quoteResults.length >= 1 ? quoteResults[0] : undefined;

		const retData = {
			isInit: getQuoteStatus?.isInit === true,
			isTechnicalError: getQuoteStatus?.isError === true,
			isRTP: quoteStatus === "RTP",
			isRTPError: quoteStatus === "RTP_ERROR",
			isError: quoteStatus === "ERROR" || quoteStatus === "FAILED",
			isRefer: quoteStatus === "REFER",
			isPurchased: quoteStatus === "PURCHASED",
			isTeleAQ: quoteStatus === "TELEAQ",
			isNQ: ["NQ", "NQCON"].includes(quoteStatus),
			isInceptionDatePast: quoteStatus === "INCEPTION_PAST",
			isSuccess: ["SUCCESS", "SUCCESS-M", "SUCCESS-H", "SUCCESS-GAP"].includes(
				quoteStatus
			),
			quoteStatus: quoteStatus,
			WebReference: firstQuote?.WebReference,
		};
		return retData;
	};

	// *****************************************************
	// EFFECTS
	// *****************************************************

	// *****************************************************
	// NAVIGATION
	// *****************************************************

	// const dataPagerNavigation = React.useMemo(() => {
	// 	if (!("pagerData" in args)) {
	// 		return {};
	// 	}

	// 	const pagerData = (function () {
	// 		const { pagerData = {} } = args;
	// 		const {
	// 			routes: pagerRoutes = [],
	// 			onPrevious = async () => {},
	// 			onNext = async () => {},
	// 		} = pagerData;

	// 		if (pagerRoutes.length === 0) return undefined;

	// 		const curIdx = pagerRoutes.findIndex((x) =>
	// 			x.pathnameList.some(
	// 				(y) => y.toLowerCase() === location.pathname.toLowerCase()
	// 			)
	// 		);
	// 		if (curIdx === -1) return undefined;

	// 		const _current = pagerRoutes[curIdx];
	// 		const _previous = curIdx >= 1 ? pagerRoutes[curIdx - 1] : undefined;
	// 		const _next =
	// 			curIdx <= pagerRoutes.length - 2 ? pagerRoutes[curIdx + 1] : undefined;

	// 		return {
	// 			current: _current,
	// 			currentIdx: curIdx,
	// 			previous: _previous,
	// 			next: _next,
	// 			routes: pagerRoutes,
	// 			onPrevious: onPrevious,
	// 			onNext: onNext,
	// 		};
	// 	})();

	// 	return {
	// 		...Object.fromEntries(
	// 			["risk", "quote", "wrapup"].map((pagerId) => {
	// 				return [
	// 					pagerId,
	// 					{
	// 						isEnabled: pagerData?.current ? true : false,
	// 						labelPrevious: pagerData?.current.labelPrevious,
	// 						labelNext: pagerData?.current.labelNext,
	// 						gotoPrev: async () => {
	// 							if (!pagerData.previous) {
	// 								await pagerData.onPrevious();
	// 								return;
	// 							}
	// 							fnNavigate(pagerData.previous.pathnameList[0]);
	// 						},
	// 						gotoNext: async () => {
	// 							if (!pagerData.next) {
	// 								await pagerData.onNext();
	// 								return;
	// 							}
	// 							fnNavigate(pagerData.next.pathnameList[0]);
	// 						},
	// 					},
	// 				];
	// 			})
	// 		),
	// 	};
	// }, [location.pathname]);

	const dataNavigation = {};

	// *****************************************************
	// FUNCTIONS
	// *****************************************************
	const dataFunctions = {
		storeHelpers: {
			migration: {
				outputInfo: async () =>
					await quoteAndBuyStore.helpers.migration.outputInfo(),
				convertOldToNew: async () => {
					await quoteAndBuyStore.helpers.migration.convertOldToNew();
				},

				salusToTemplate: async (salusData) => {
					const response =
						await quoteAndBuyStore.helpers.migration.salusToTemplate(salusData);

					console.log("**************************************************");
					console.log(response);
					console.log("**************************************************");
				},

				templateToSalus: async () => {
					await quoteAndBuyStore.helpers.migration.templateToSalus(dispatch);

					const newRiskState = quoteAndBuyStore.selectors.userData.risk.full(
						configFunctions.getState()
					);

					console.log("**************************************************");
					console.log("defaultRisk.json:", newRiskState);
					console.log("**************************************************");
				},
			},
			getState: () => configFunctions.getState(),
		},
		formatters: {
			// pluralise: (v, str) => {
			//   if (v >= 1) return [v, " ", str, "s"].join("");
			//   return [v, " ", str].join("");
			// },
			yearsToToday: (v) => {
				if (!v) fnThrowError(`Error in formatters/daysToToday`);
				const eventdate = dayjs(v);
				const todaysdate = dayjs();

				const daysToGo = -1 * eventdate.diff(todaysdate, "years");
				return daysToGo;
			},
			date: (v) => {
				// if (!v) fnThrowError(`Error in formatters/date`);
				return dayjs(v).format("Do MMM YYYY");
			},
			currency: (v, numberOfDigits = 2) =>
				new Intl.NumberFormat("en-GB", {
					style: "currency",
					currency: "GBP",
					minimumFractionDigits: numberOfDigits,
					maximumFractionDigits: numberOfDigits,
				}).format(v),
		},
		session: {
			save: async (sessionMessage = undefined) => {
				setShowSessionSaving(true);
				await dispatch(sessionStore.actions.save(sessionMessage));
				setShowSessionSaving(false);
			},
			saveQuietly: async (sessionMessage = undefined) => {
				await dispatch(sessionStore.actions.save(sessionMessage));
			},
			saveLazy: (sessionMessage = undefined) => {
				dispatch(sessionStore.actions.save(sessionMessage));
			},
		},
		navigate: {
			loadUrl: (url) => (window.location.href = url),
			to: (route, options = {}) => {
				console.log("useQuoteAndBuy() navigate:", { route, options });
				fnNavigate(route, options);
			},
		},
		error: {
			log: async (args = {}) => {
				if (!services?.error?.log)
					throw `Error in useQuoteAndBuy() functions.error.log -- missing services.error.log`;

				const { errorType, errorDescription } = args;
				const state = configFunctions.getState();
				const payload = {
					PersistId: sessionStore.selectors.getSessionId(state),
					VersionNumber: process.env.BUILD_TIME,
					Details: JSONhelper.stringify({
						buildTime: process.env.BUILD_TIME,
						reduxStore: state,
					}),
					What: errorType,
					Where: window.location.href,
					Why: errorDescription,
				};

				services.error.log(payload);
			},
		},
		process: {
			reset: async () => {
				console.log("********************************************");
				console.log("Reseting QuoteAndBuy store");
				console.log("********************************************");

				await dispatch(quoteAndBuyStore.actions.reset());
			},

			bank: {
				validateBankDetails: async (args = {}) => {
					if (!configFunctions?.bank?.validateBankDetails) {
						throw `Error in process.validateBankDetails() -- missing function bank.validateBankDetails()"`;
					}

					const { sortCode, accountNumber } = args;
					["sortCode", "accountNumber"].forEach((k) => {
						if (!(k in args))
							throw `Error in process.validateBankDetails() -- missing "${k}"`;
					});

					const response = await configFunctions.bank.validateBankDetails({
						sortCode,
						accountNumber,
					});

					// console.log("validateBankDetails:", response);
					return response?.Valid === true;
				},
			},
		},
	};

	// *****************************************************
	// RECALL
	// *****************************************************
	const fnRecallHandleResponse = async (response, options = {}) => {
		const { onError = async () => {}, onSuccess = async () => {} } = options;

		switch (response.Status) {
			case "INVALID_WEB_REF": {
				await onError();

				return {
					errorMessage:
						"Sorry, we couldn't find your quote. Please check your details and try again.",
				};
			}

			case "RECALL_FAIL":
			case "RISK_MATCH_FAIL": {
				await onError();

				return {
					errorMessage: "Sorry, we couldn't find your details.",
				};
			}

			case "INVALID_POSTCODE": {
				await onError();
				return {
					errorMessage: "Please enter a valid postcode.",
				};
			}
			case "SUCCESS": {
				await dispatch(quoteAndBuyStore.actions.loadQuoteData(response));

				await onSuccess();
				return { isSuccess: true };
			}
			default: {
				await onError();

				return {
					errorMessage: "Sorry, we couldn't find your details.",
				};
			}
		}
	};

	const dataRecall = {
		functions: {
			quoteAndBuyHandover: async (args = {}, options = {}) => {
				if (!_.isFunction(services.recallQuoteAndBuyHandover))
					throw `Error in useQuoteAndBuy -- missing "services.recallQuoteAndBuyHandover"`;

				const { onSuccess = async () => {}, onError = async () => {} } =
					options;

				const payload = { token: args.token };
				const response = await services.recallQuoteAndBuyHandover(payload);

				return fnRecallHandleResponse(response, { onError, onSuccess });
			},
			recall: async (args = {}, options = {}) => {
				//https://dev.azure.com/FreedomServicesGroup/Odyssey/_wiki/wikis/Odyssey.wiki/116/RecallAndRequote

				if (!_.isFunction(services.recallQuote))
					throw `Error in useQuoteAndBuy -- missing "services.recallQuote"`;

				const { onSuccess = async () => {}, onError = async () => {} } =
					options;

				const fnOptionObject = (key, argKey, fnFormat = (v) => v) => {
					if (!(argKey in args)) return {};
					return { [key]: fnFormat(args[argKey]) };
				};

				const payload = {
					...fnOptionObject(
						"DateOfBirth",
						"dateOfBirth",
						(v) => `${new dayjs(v).format("YYYY-MM-DD")}T00:00:00`
					),
					...fnOptionObject("WebReference", "reference"),
					...fnOptionObject("EmailAddress", "emailAddress"),
					...fnOptionObject("Postcode", "postcode"),

					IncludeUpgrades: true,
					Requote: false,

					// WebReference: "MOCK_RECALL_PC", //"8413-995161-8932",
					// EmailAddress: "daniel.holland@freedombrokers.co.uk",
					// DateOfBirth: "1967-04-29T00:00:00",
				};

				const response = await services.recallQuote(payload);
				return fnRecallHandleResponse(response, { onError, onSuccess });
			},
		},
	};

	// *****************************************************
	// HOOKS
	// *****************************************************
	const dataHooks = quoteAndBuyStore.helpers.hooks;

	// *****************************************************
	// RISK
	// *****************************************************

	const dataRisk = {
		// pagerNavigation: dataPagerNavigation.risk,
		values: {
			...Object.fromEntries(
				Object.entries(configRisk?.values || {}).map(([k, path]) => {
					const retData = useSelector(
						(state) =>
							quoteAndBuyStore.selectors.userData.risk.value(state, path),
						_.isEqual
					);
					return [k, retData];
				})
			),
		},
		functions: {
			updateValue: async (path, value) =>
				await dispatch(quoteAndBuyStore.actions.updateValue({ path, value })),
		},
		update: {
			...Object.fromEntries(
				Object.entries(configRisk?.values || {}).map(([k, path]) => {
					const retData = async (value) =>
						await dispatch(
							quoteAndBuyStore.actions.updateValue({ path, value })
						);

					return [k, retData];
				})
			),
		},
		status: {
			isValid: useSelector(quoteAndBuyStore.selectors.errors.getIsStoreValid),
			errors: useSelector(
				(state) => quoteAndBuyStore.selectors.errors.list(state, ""),
				_.isEqual
			),
		},
		selectors: {
			isValid: (state, pathArray = []) =>
				quoteAndBuyStore.selectors.errors.isValid(state, pathArray),
			value: (state, path) =>
				quoteAndBuyStore.selectors.userData.risk.value(state, path),
			metaData: (state, path) =>
				quoteAndBuyStore.selectors.userData.risk.metaData(state, path),
			listTree: (state, path) =>
				quoteAndBuyStore.selectors.userData.risk.listTree(state, path),
			valueTree: (state, path) =>
				quoteAndBuyStore.selectors.userData.risk.valueTree(state, path),
			errorList: (state, path) =>
				quoteAndBuyStore.selectors.errors.list(state, path),
		},
		actions: {
			update: quoteAndBuyStore.actions.updateValue,
			updateErrorShow: quoteAndBuyStore.actions.updateErrorShow,
		},
	};
	// *****************************************************
	// QUOTE
	// *****************************************************

	const dataQuote = {
		// pagerNavigation: dataPagerNavigation.quote,
		functions: {
			status: {
				reset: {
					getQuote: async () =>
						await dispatch(
							quoteAndBuyStore.actions.resetStatusData("getQuote")
						),
					registerPayment: async () => {
						setRegisterPaymentData({});
					},
					purchasePolicy: async () => {
						setPurchaseResponseData({});
					},
				},
			},

			reset: async () => {
				console.log("********************************************");
				console.log("Reseting QuoteAndBuy store QUOTE");
				console.log("********************************************");

				await dispatch(quoteAndBuyStore.actions.resetQuote());
			},

			getQuote: async (args = {}) => {
				const {
					autoSetFirstTier = false,
					autoUpdateQuoteParams = false,
					isPayloadChangedCheck = false,
				} = args;

				const hasRiskChanged =
					quoteAndBuyStore.selectors.userData.quote.payloads.hasRiskChanged(
						configFunctions.getState()
					);
				const currentQuote =
					quoteAndBuyStore.selectors.userData.quote.currentQuote(
						configFunctions.getState()
					);

				if (isPayloadChangedCheck) {
					if (currentQuote && !hasRiskChanged) return;
				}

				await dispatch(quoteAndBuyStore.actions.getQuote(args));

				const curStatus = fnGetStatus(configFunctions.getState());

				if (!curStatus.isSuccess) return;

				const quoteResults =
					quoteAndBuyStore.selectors.userData.quote.quoteResults(
						configFunctions.getState()
					);

				if (autoUpdateQuoteParams) {
					if (quoteResults) {
						quoteResults.forEach(async (x) => {
							await dispatch(
								quoteAndBuyStore.actions.updateParam({
									affinity: x.Affinity,
									proposerId: x.ProposerId,
									policyId: x.PolicyId,
								})
							);
						});
					}
				}

				//Set the TierId to the first available
				if (autoSetFirstTier) {
					if (
						quoteResults &&
						quoteResults.length >= 1 &&
						quoteResults[0]?.TierInfo?.TierId
					) {
						await dispatch(
							quoteAndBuyStore.actions.chooseQuoteTier(
								quoteResults[0].TierInfo.TierId
							)
						);
					}
				}
			},

			chooseQuoteTier: async (tierId) => {
				await dispatch(quoteAndBuyStore.actions.chooseQuoteTier(tierId));
			},
			choosePaymentMethod: async (paymentMethod) => {
				await dispatch(
					quoteAndBuyStore.actions.choosePaymentMethod(paymentMethod)
				);
			},
			resetPaymentMethod: async () => {
				await dispatch(quoteAndBuyStore.actions.resetPaymentMethod());
			},
			wrapup: {
				getPurchaseResponse: async (functionArgs = {}) => {
					if (!configFunctions?.quote?.getPurchaseResponse) {
						throw `Error in process.paymentLauncher() -- missing function quote.getPurchaseResponse()"`;
					}

					["paymentId", "webReference"].forEach((k) => {
						if (!functionArgs[k]) {
							throw `Error in process.paymentLauncher() -- missing arg "${k}"`;
						}
					});

					setPurchaseResponseData((d) => ({
						...d,
						lastResponse: undefined,
						isRunning: true,
					}));

					const response =
						(await configFunctions.quote.getPurchaseResponse({
							paymentId: functionArgs.paymentId,
							webReference: functionArgs.webReference,
						})) || {};

					setPurchaseResponseData((d) => ({
						...d,
						...response,
						isRunning: false,
					}));

					return response;
				},

				registerPayment: async (functionArgs = {}) => {
					if (!configFunctions?.quote?.registerPayment) {
						throw `Error in process.registerPayment() -- missing function quote.registerPayment()"`;
					}

					setRegisterPaymentData((d) => ({
						...d,
						lastResponse: undefined,
						isRunning: true,
					}));

					const response =
						(await configFunctions.quote.registerPayment({
							directDebit: functionArgs.directDebit,
							redirects: functionArgs.redirects,
						})) || {};

					setRegisterPaymentData((d) => ({
						...d,
						...response,
						isRunning: false,
					}));

					return response;
				},
			},
		},
		status: {
			...Object.fromEntries(
				["getQuote"].map((k) => [
					k,
					useSelector((state) =>
						quoteAndBuyStore.selectors.status.get(state, k)
					),
				])
			),
			registerPayment: registerPaymentData,
			purchaseResponse: purchaseResponseData,
		},
		data: {
			currentQuote: useSelector(
				quoteAndBuyStore.selectors.userData.quote.currentQuote,
				_.isEqual
			),
			hasQuote: useSelector((state) => {
				const currentQuote =
					quoteAndBuyStore.selectors.userData.quote.currentQuote(state);
				return !_.isEmpty(currentQuote);
			}, _.isEqual),

			currentStatus: useSelector(fnGetStatus, _.isEqual),

			quoteResults: useSelector(
				quoteAndBuyStore.selectors.userData.quote.quoteResults,
				_.isEqual
			),
			paymentMethods: useSelector(
				quoteAndBuyStore.selectors.userData.quote.paymentMethods,
				_.isEqual
			),
			chosenOptions: Object.fromEntries(
				Object.entries(
					quoteAndBuyStore.selectors.userData.quote.chosenOptions
				).map(([k, d]) => [k, useSelector(d, _.isEqual)])
			),
		},
	};

	// *****************************************************
	// WRAPUP
	// *****************************************************

	const dataWrapup = {
		set: (...args) => {
			if (!configFunctions?.wrapup?.set)
				throw `Error in useGapPageLogic -- missing set()`;
			configFunctions.wrapup.set(...args);
		},
		clear: (...args) => {
			if (!configFunctions?.wrapup?.clear)
				throw `Error in useGapPageLogic -- missing clear()`;
			configFunctions.wrapup.clear(...args);
		},
		data: configFunctions.wrapup.get ? configFunctions.wrapup.get() : undefined,
	};
	// *****************************************************
	// FINAL RETURN
	// *****************************************************
	return {
		risk: dataRisk,
		quote: dataQuote,
		wrapup: dataWrapup,
		dictionary: dataDictionary,
		//dataQuote
		navigation: dataNavigation,
		functions: dataFunctions,
		recall: dataRecall,
		hooks: dataHooks,
		storeComponents: dataStoreComponents,
		status: {
			showSessionSaving: showSessionSaving,
		},
		dispatch: dispatch,
	};
};

export default useQuoteAndBuy;
