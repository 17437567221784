import React, { useState } from "react";
import config from "config";

import { generateCypressProps } from "libraryFunctions/cypress";

import { SiteAlerts } from "siteComponents/Misc";
import { ScreenFinder } from "siteComponentsLibrary/Dev";
import { CookieConsent } from "siteComponents/Misc";

const SuspenseWrapper = ({ children }) => {
	const fallback = <></>; //     <LoadingPanel>Please wait...</LoadingPanel>
	return <React.Suspense fallback={fallback}>{children}</React.Suspense>;
};

import TemplateHeader from "../Template/Header";
import TemplateFooter from "../Template/Footer";

const SiteLayoutBase = (props) => {
	const [showScreenFinder, setShowScreenFinder] = useState(config.isDev);

	const {
		children,
		className,
		classNameHeader,

		headerData = <TemplateHeader />,
		footerData = <TemplateFooter />,
		headingText,
		descriptionContent,
	} = props;

	const containerClass = [
		"container-fluid",
		"container-lg",
		"layout-max-width",
	].toClassName();

	const SiteAlertsWrapper = ({ children }) => (
		<div className={containerClass}>{children}</div>
	);

	return (
		<div
			className={[
				"lctr-layout-template",
				"layout-container",
				"flex-shrink-0",
				"d-flex",
				"flex-column",
				"min-vh-100",
				className,
			].toClassName()}
			{...generateCypressProps("layout-section", props)}
		>
			{/* The cookie consent needs to be here for the stick-to-the-bottom footer to work */}
			<CookieConsent />
			
			{headerData}

			<div className="layout-main" data-cy="layout-main">
				<SiteAlerts
					SiteType="portal"
					className="my-3"
					Wrapper={SiteAlertsWrapper}
				/>
				<SuspenseWrapper>{children}</SuspenseWrapper>
				{showScreenFinder && (
					<div
						className="fixed-bottom bg-white p-2 border border-2 border-primary text-center"
						style={{ width: "50px", cursor: "pointer" }}
						onClick={() => setShowScreenFinder(false)}
						data-cy="screenfinder"
					>
						<strong>
							<ScreenFinder />
						</strong>
					</div>
				)}
			</div>

			{footerData}			
		</div>
	);
};

export default SiteLayoutBase;
