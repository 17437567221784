import React, { useEffect, useState } from "react";
import dateHelpers from "@library/common/helpers/misc/date";

const templateDate = {
	fnProcess: (v) =>
		dateHelpers.toString(v, { inputFormat: "YYYY-MM-DDThh:mm:ss" }),
};

const responseMappings = [
	// DON'T add this, as we don't want to RESET
	// {
	// 	responsePath: "VehicleType",
	// 	path: "Risk/Vehicle/VehicleType",
	// },
	{
		responsePath: "BodyStyle",
		path: "Risk/Vehicle/BodyStyle",
	},
	{
		responsePath: "Doors",
		path: "Risk/Vehicle/Doors",
	},
	{
		responsePath: "Purchased",
		path: "Risk/Vehicle/Purchased",
		...templateDate,
	},
	{
		responsePath: "Cc",
		path: "Risk/Vehicle/Cc",
	},
	{
		responsePath: "Fuel",
		path: "Risk/Vehicle/Fuel",
	},
	{
		responsePath: "Manufacturer",
		path: "Risk/Vehicle/Manufacturer",
	},
	{
		responsePath: "ModelDescription",
		path: "Risk/Vehicle/ModelDescription",
	},
	{
		responsePath: "Value",
		path: "Risk/Vehicle/Value",
	},
	// {
	// 	responsePath: "Paid",
	// 	path: "Risk/Vehicle/Paid",
	// },
	{
		responsePath: "RegNumber",
		path: "Risk/Vehicle/RegNumber",
	},
	{
		responsePath: "Transmission",
		path: "Risk/Vehicle/Transmission",
	},

	{
		responsePath: "YearOfManufacture",
		path: "Risk/Vehicle/YearOfManufacture",
	},
	{
		responsePath: "RegistrationDate",
		path: "Risk/Vehicle/RegistrationDate",
		...templateDate,
	},

	{
		responsePath: "IsOver3500kg",
		path: "Risk/Vehicle/IsOver3500kg",
	},

	// {
	// 	responsePath: "VehicleListedOnGlassGuide",
	// 	path: "Risk/Vehicle/VehicleListedOnGlassGuide",
	// },

	// {
	// 	responsePath: "BodySubStyle",
	// 	path: "Risk/Vehicle/BodySubStyle",
	// },
	// {
	// 	responsePath: "Lhd",
	// 	path: "Risk/Vehicle/Lhd",
	// },

	// {
	// 	responsePath: "Seats",
	// 	path: "Risk/Vehicle/Seats",
	// },
	// {
	// 	responsePath: "AbiCode",
	// 	path: "Risk/Vehicle/AbiCode",
	// },
	// {
	// 	responsePath: "AbiProductionYears",
	// 	path: "Risk/Vehicle/AbiProductionYears",
	// },

	// {
	// 	responsePath: "BodyStyle",
	// 	path: "Risk/Vehicle/BodyStyle",
	// },
	// {
	// 	responsePath: "GrossWeight",
	// 	path: "Risk/Vehicle/GrossWeight",
	// },

	// {
	// 	responsePath: "Immob",
	// 	path: "Risk/Vehicle/Immob",
	// },

	// {
	// 	responsePath: "Alarm",
	// 	path: "Risk/Vehicle/Alarm",
	// },

	// {
	// 	responsePath: "Tracker",
	// 	path: "Risk/Vehicle/Tracker",
	// },
];

export default responseMappings;
