import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

// https://stackoverflow.com/questions/43441856/how-to-scroll-to-an-element
// https://bosctechlabs.com/scroll-to-an-element-in-react/
// https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition

export const fnScrollToTop = () => {
	document.documentElement.scrollTo(0, 0);
};

export const fnSnapToTop = () => {
	document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
};

export const useScrollToTop = (watchValue = true) => {
	useLayoutEffect(() => {
		if (!watchValue) return;
		fnScrollToTop();
	}, [watchValue]);
};

export const useSnapToTop = (watchValue = true) => {
	useLayoutEffect(() => {
		if (!watchValue) return;
		fnSnapToTop();
	}, [watchValue]);
};

// const useScrollTo = (watchValue) => {
// 	const scrollToRef = useRef(null);

// 	const fnScrollTo = () => {
// 		console.warn("SCROLLTO");
// 		scrollToRef.current.scrollIntoView();
// 	};

// 	const Anchor = () => {

// 		return <span ref={scrollToRef}>
//     ANCHOR
//     </span>;
// 	};

// 	return [Anchor, fnScrollTo];
// };

// export default useScrollTo;
