import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useDispatch as _useDispatch } from "siteFunctions/hooks";

const useLayoutPropsModifer = (args = {}) => {
	const {
		layoutProps: baseLayoutProps,
		commonData,
		validationPathArray = [],
		validateStore = false,
		isValid: isValidOverride = undefined,
		errorMessage: baseErrorMessage,
		useDispatch = _useDispatch,
	} = args;

	const dispatch = useDispatch();
	const [showErrors, setShowErrors] = useState(false);

	const isValid = useSelector((state) => {
		if (isValidOverride !== undefined) return isValidOverride;

		if (validationPathArray.length === 0) return true;
		if (validateStore && !commonData.risk.status.isValid) return false;
		return commonData.risk.selectors.isValid(
			state,
			validationPathArray.map((x) => x.replaceAll("/*", ""))
		);
	});

	const validationPathArrayErrors = useSelector((state) => {
		return validationPathArray
			.map((x) => x.replaceAll("/*", ""))
			.flatMap((path) => commonData.risk.selectors.errorList(state, path))
			.filter(Boolean);
	}, _.isEqual);

	useEffect(() => {
		if (isValid) {
			setShowErrors(false);
		}
	}, [isValid]);

	const retData = _.clone(baseLayoutProps);

	if (!isValid) {
		retData.pagerData.fnOnNext = async () => {
			const state = commonData.functions.storeHelpers.getState();

			if (validationPathArray.length >= 1) {
				//Show the errors for all the validationPathArray items (And their children)
				validationPathArray.forEach(async (path) => {
					if (path.endsWith("/*")) {
						const itemChildren = commonData.risk.selectors.listTree(
							state,
							path.replaceAll("/*", "")
						);

						if (itemChildren.length >= 1) {
							await dispatch(
								commonData.risk.actions.updateErrorShow({
									pathList: itemChildren,
									value: true,
								})
							);
						}

						return;
					}

					await dispatch(
						commonData.risk.actions.updateErrorShow({
							path: path,
							value: true,
						})
					);
				});
			}

			setShowErrors(true);
		};
		// retData.pagerData.labelNext = "NOT VALIDxxxxxx"

		if (showErrors) retData.errorMessage = baseErrorMessage;
	}

	// console.log("useLayoutPropsModifer.jsx", {
	// 	retData,
	// 	isValid,
	// 	validationPathArrayErrors,
	// 	showErrors,
	// 	validationPathArray,
	// 	validateStore,
	// });
	// console.log("Page isValid:", isValid);
	return retData;
};

export default useLayoutPropsModifer;
