import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
const fnOutputErrorInfo = (...args) => {
	console.log("********************************************");
	console.log("ERRORINFO:", ...args);
	console.log("********************************************");
};
import chalk from "chalk";

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	createExtraReducersSet,
	extraReducersSet,
	consoleLog,
} from "../common";

import riskUtils from "./utils/risk";
import riskSalusUtils from "./utils/riskSalus";
import templateUtils from "./utils/template";

// const { salus: salusUtils } = riskUtils;

import processorClass from "./processor";

const generate = (args = {}) => {
	const {
		storeName,
		privateActions,
		rules,
		defaultRisk = {},
		defaultSalus = {},
		defaultQuoteParams = [],

		template,
		console: _console = console,
		abortChecker = () => false,
		selectors,
	} = args;

	// const defaultRiskComposed = (function () {
	// 	if (defaultRisk) return defaultRisk;

	// 	const salusDataComposed = templateUtils.generateSalusData(
	// 		template,
	// 		defaultSalus
	// 	);

	// 	const convertedSalusData = riskSalusUtils.import(
	// 		salusDataComposed,
	// 		template
	// 	);

	// 	return convertedSalusData;
	// })();

	const initialStateUserDataRisk = {
		data: defaultRisk,
		dataInitial: defaultRisk,
		snapshots: {},
		groups: {},
	};

	const initialStateUserDataQuote = {
		params: defaultQuoteParams,
		source: undefined,
		data: undefined,
		lastPayload: undefined,
		lastResponse: undefined,
		chosenOptions: {
			tierId: undefined,
			paymentMethod: undefined,
			autoRenewal: undefined,
			opex: [],
		},
	};

	const initialState = {
		userData: {
			risk: initialStateUserDataRisk,
			quote: initialStateUserDataQuote,
		},
		statusData: { getQuote: {} },
		debugData: {
			// template: template,
			salusLoad: undefined,
		},
	};

	const fnGroupRegister = (state, payload) => {
		const { group, path } = payload;
		if (!group) {
			_console.log("ERROR INFO:", { path });
			throw `Error in groupRegister -- missing group for path = "${path}"`;
		}
		state.userData.risk.groups[group] = state.userData.risk.groups[group] || [];

		if (!state.userData.risk.groups[group].includes(path))
			state.userData.risk.groups[group].push(path);
	};
	const fnSalusLoad = (state, payload) => {
		console.time(`SALUSLOAD`);
		console.groupCollapsed("SALUSLOAD");

		state.debugData.salusLoad = undefined;
		const processor = new processorClass({
			_console,
			template,
			rules,
			state,
			debugData: {
				action: "salusLoad",
				payload: payload,
			},
		});
		const listAdded = [];
		const {
			salusData,
			isPostSalusLoad = true,
			isPostRegistration = true,
		} = payload;

		_console.log("salusLoad.loading salusData:", salusData, { rules });

		console.time(`SALUSLOAD.IMPORT`);

		const salusDataComposed = templateUtils.generateSalusData(
			template,
			salusData
		);
		console.log("INFO:", "salusDataComposed (merged with template) ", {
			salusDataComposed,
			template,
			salusData,
		});

		const convertedSalusData = riskSalusUtils.import(
			salusDataComposed,
			template,
			(path) => listAdded.push(path.join("/")) // Log the added items
		);

		console.log("INFO:", "state.userData.risk.data =", { convertedSalusData });

		state.userData.risk.data = convertedSalusData;

		console.timeEnd("SALUSLOAD.IMPORT");

		console.groupCollapsed("salusLoad.listAdded");
		console.log(listAdded);
		console.groupEnd();

		_console.time(`SALUSLOAD.RULES`);

		if (isPostRegistration) processor.runRulesPostRegistration();
		if (isPostSalusLoad) processor.runRulesPostSalusLoad();

		processor.runRulesBatch(listAdded, {
			isPostSalusLoad: isPostSalusLoad,
			isPostRegistration: isPostRegistration,
			debugInfo: "slice.salusLoad",
		});

		_console.timeEnd("SALUSLOAD.RULES");

		// Set dataInitial
		state.userData.risk.dataInitial = state.userData.risk.data;

		console.groupEnd();
		console.timeEnd("SALUSLOAD");

		state.debugData.salusLoad = {
			payload: payload,
		};

		// processor.debugData();
	};

	const fnCreate = (state, action) => {
		const processor = new processorClass({
			_console,
			template,
			rules,
			state,
			debugData: { action: "create", payload: action.payload },
		});
		const { path, pathList = [], group, defaultValue } = action.payload;

		if (!path && pathList.length === 0) {
			throw `Error in slice fnCreate -- missing a path or a pathlist`;
		}

		if (pathList.length >= 1) {
			pathList.forEach(({ defaultValue, path }) => {
				processor.create(path);
				processor.updateValue(path, defaultValue);
				if (group) fnGroupRegister(state, { group, path });
			});

			processor.runRulesPostRegistration();

			pathList.forEach(({ path }) => {
				// _console.log("DDDD runRules:", path, { pathList, group });

				processor.runRules(path, {
					isPostSalusLoad: false,
					isPostRegistration: true,
					debugInfo: "slice.create pathList",
				});
			});

			return;
		}

		processor.create(path);

		if (!riskUtils.searchPath.array.isArrayAction(path)) {
			// This prevents the updateValue creating a duplicate entry
			processor.updateValue(path, defaultValue);
		}

		processor.runRulesPostRegistration();
		processor.runRules(path, {
			debugInfo: "slice.create",
			isPostRegistration: true,
		});
	};

	const statusFunctions = {
		pending: (state, key) => {
			state.statusData[key] = state.statusData[key] || {};
			const item = state.statusData[key];
			item.isInit = false;
			item.isRunning = true;
			item.isError = false;
			item.isSuccess = false;
			item.errorMessage = undefined;
		},
		fulfilled: (state, key) => {
			state.statusData[key] = state.statusData[key] || {};
			const item = state.statusData[key];

			item.isRunning = false;
			item.isSuccess = true;
			item.isInit = true;
		},
		rejected: (state, key, errorMessage) => {
			state.statusData[key] = state.statusData[key] || {};
			const item = state.statusData[key];

			item.isRunning = false;
			item.isError = true;
			item.errorMessage = errorMessage;
			item.isInit = true;
		},
	};

	const _slice = createSlice({
		name: storeName,
		initialState: initialState,
		reducers: {
			//Quote actions
			loadQuoteData(state, action) {
				state.userData.quote.data = state.userData.quote.data || {};

				if ("Risk" in action.payload) {
					fnSalusLoad(state, { salusData: { Risk: action.payload.Risk } });
					state.userData.quote.lastPayload =
						selectors.userData.quote.payloads.getQuote({ [storeName]: state });
				}

				if ("QuoteParams" in action.payload) {
					state.userData.quote.params = action.payload.QuoteParams;
				}

				if ("QuoteSource" in action.payload) {
					state.userData.quote.source = action.payload.QuoteSource;
				}

				if ("QuoteResults" in action.payload) {
					state.userData.quote.data.QuoteResults = action.payload.QuoteResults;
				}

				if ("SelectedOpex" in action.payload) {
					state.userData.quote.chosenOptions.opex = action.payload.SelectedOpex;
				}

				// state.userData.quote.lastResponse = action.payload.response;
				// state.userData.quote.data = action.payload.response;
			},
			chooseQuoteTier(state, action) {
				const newTierId = action.payload;

				if (newTierId) {
					state.userData.quote.chosenOptions.tierId = newTierId;
				}
			},

			chooseAutoRenewal(state, action) {
				const newAutoRenewal = action.payload;

				if (newAutoRenewal) {
					state.userData.quote.chosenOptions.autoRenewal = newAutoRenewal;
				}
			},
			choosePaymentMethod(state, action) {
				const newPaymentMethod = action.payload;

				if (newPaymentMethod) {
					state.userData.quote.chosenOptions.paymentMethod = newPaymentMethod;
				}
			},

			resetPaymentMethod(state) {
				state.userData.quote.chosenOptions.paymentMethod = undefined;
			},

			// RISK actions
			runRules(state, action) {
				const processor = new processorClass({
					_console,
					template,
					rules,
					state,
					debugData: { action: "runRules", payload: action.payload },
				});

				processor.runRules(action.payload.path, {
					debugInfo: "slice.create runRules",
				});
			},
			create(state, action) {
				fnCreate(state, action);
			},

			updateParam(state, action) {
				if (!action.payload) throw `Error in updateParam -- missing payload`;

				if (!action.payload.affinity)
					throw `Error in updateParam -- missing affinity`;

				const foundItem = state.userData.quote.params.find(
					(x) => x.Affinity === action.payload.affinity
				);

				if (!foundItem)
					throw `Error in updateParam -- can't find a param with affinity "${action.payload.affinity}"`;

				if ("proposerId" in action.payload)
					foundItem.ProposerId = action.payload.proposerId;
				if ("policyId" in action.payload)
					foundItem.PolicyId = action.payload.policyId;
			},
			removeArrayItem(state, action) {
				const processor = new processorClass({
					_console,
					template,
					rules,
					state,
					debugData: { action: "removeArrayItem", payload: action.payload },
				});
				const { path = "" } = action.payload;
				const pathArray = path.split("/");
				const pathLastItem = pathArray[pathArray.length - 1];

				if (!riskUtils.searchPath.array.isArrayWithIndex(pathLastItem)) {
					throw `Error in removeArrayItem -- path ("${path}") must be an Array with index`;
				}

				const pathAction = `${path}-`;

				const pathRunRules = pathArray
					.map((x, i) => {
						if (i === pathArray.length - 1)
							return riskUtils.searchPath.array.parse(pathLastItem).path;
						return x;
					})
					.join("/");

				processor.create(pathAction);
				processor.runRules(pathRunRules, {
					debugInfo: "slice.removeArrayItem",
				});
			},

			groupRegister(state, action) {
				return fnGroupRegister(state, action.payload);
			},

			updateValue(state, action) {
				const processor = new processorClass({
					_console,
					template,
					rules,
					state,
					debugData: { action: "updateValue", payload: action.payload },
				});
				const { path, value, createIfMissing = false } = action.payload;

				if (!path) throw `Error in REDUX updateValue() -- missing path`;

				if (createIfMissing) {
					riskUtils.find.itemAndCreate(state, path);
				} else {
					const itemExists = riskUtils.find.item(state, path) ? true : false;
					if (!itemExists)
						throw `Error in REDUX updateValue() -- path is not in redux store. Make sure template and defaultState are correct. (path:"${path}")`;
				}

				processor.updateValue(path, value);
				processor.runRules(path, { debugInfo: "slice.updateValue" });
			},

			updateHidden(state, action) {
				const processor = new processorClass({
					_console,
					template,
					rules,
					state,
					debugData: { action: "updateHidden", payload: action.payload },
				});
				const { path, value } = action.payload;
				processor.updateHidden(path, value);

				// processor.runRules(path);
			},

			updateErrorShow(state, action) {
				const { path, value, pathList = [] } = action.payload;
				const processor = new processorClass({
					_console,
					template,
					rules,
					state,
					debugData: { action: "updateErrorShow", payload: action.payload },
				});

				if (pathList.length >= 1) {
					pathList.forEach((path) => {
						processor.updateErrorShow(path, value);
					});
					return;
				}

				processor.updateErrorShow(path, value);
			},

			updateErrorShowByGroup(state, action) {
				const { group, value } = action.payload;
				const processor = new processorClass({
					_console,
					template,
					rules,
					state,
					debugData: {
						action: "updateErrorShowByGroup",
						payload: action.payload,
					},
				});
				const pathList = state.userData.risk.groups[group];

				if (!pathList) return;

				pathList.forEach((path) => processor.updateErrorShow(path, value));
			},

			errorAdd(state, action) {
				const { path, errorKey, description } = action.payload;

				const processor = new processorClass({
					_console,
					template,
					rules,
					state,
					debugData: {
						action: "errorAdd",
						payload: action.payload,
					},
				});
				processor.updateErrorAdd(path, errorKey, description);
				processor.runRules(path, { debugInfo: "slice.errorAdd" });
			},
			errorRemove(state, action) {
				const { path, errorKey } = action.payload;

				const processor = new processorClass({
					_console,
					template,
					rules,
					state,
					debugData: {
						action: "errorRemove",
						payload: action.payload,
					},
				});
				processor.updateErrorRemove(path, errorKey);
				processor.runRules(path, { debugInfo: "slice.errorRemove" });
			},

			salusLoad(state, action) {
				return fnSalusLoad(state, action.payload);
			},

			testData(state) {
				_console.time(`testData`);
				{
					const processor = new processorClass({
						_console,
						template,
						rules,
						state,
						debugData: {
							action: "testData",
						},
					});
					processor.testState();
				}
				_console.timeEnd("testData");
			},

			reset() {
				return initialState;
			},
			resetStatusData(state, action) {
				const key = action.payload;
				state.statusData[key] = {};
			},
			resetRisk(state) {
				state.userData.risk = initialStateUserDataRisk;
			},
			resetQuote(state) {
				// state.userData.quote = initialStateUserDataQuote;

				["data", "lastPayload", "lastResponse"].forEach((key) => {
					state.userData.quote[key] = initialStateUserDataQuote[key];
				});

				state.statusData.getQuote = {};
			},

			snapshotAdd(state, action) {
				const key = action.payload;
				if (key in state.userData.risk.snapshots) {
					return;
				}
				state.userData.risk.snapshots[key] = state.userData.risk.data;
			},

			snapshotRestore(state, action) {
				const key = action.payload;

				if (!(key in state.userData.risk.snapshots)) {
					return;
				}

				const riskData = state.userData.risk.snapshots[key];
				state.userData.risk.data = riskData;
				delete state.userData.risk.snapshots[key];
			},

			snapshotDelete(state, action) {
				const key = action.payload;

				if (!(key in state.userData.risk.snapshots)) {
					return;
				}

				delete state.userData.risk.snapshots[key];
			},
		},
		extraReducers: (builder) => {
			//**LOADDATA
			extraReducersSet(
				builder,
				privateActions.getQuote,
				"getQuote",
				{
					pending: (state, action) => {
						statusFunctions.pending(state, "getQuote");
						state.userData.quote.data = undefined;
						state.userData.quote.lastPayload = undefined;
						state.userData.quote.lastResponse = undefined;
					},
					fulfilled: (state, action) => {
						statusFunctions.fulfilled(state, "getQuote");

						state.userData.quote.lastPayload = action.payload.request;
						state.userData.quote.lastResponse = action.payload.response;

						state.userData.quote.data = action.payload.response;

						if ("QuoteParams" in action.payload.response) {
							state.userData.quote.params = action.payload.response.QuoteParams;
						}

						// fnSetStatusErrorMessage(state, "loadData", errorMsgFallback);
					},
					rejected: (state, data) => {
						statusFunctions.rejected(
							state,
							"getQuote",
							"Sorry, there's been an error"
						);
					},
				},
				{ storeName, abortChecker }
			);
		},
	});

	return { reducer: _slice.reducer, actions: _slice.actions };
};

export default generate;
