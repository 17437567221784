import React from "react";
import config from "config";

import "moveToLibrary/prototypes";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.min.js";

// import { StickyContainer, Sticky } from "react-sticky";

import "libraryCss/brands/freedom";
import "siteAssets/css";

import SiteStore from "siteStore/Provider";
import Routes from "siteRoutes/Provider";

import { ErrorBoundarySite } from "siteComponents/ErrorBoundary";

const ServicesCache = () => {
	// Call any services that CACHE here
	return null;
};

const BaseApp = () => {
	// return <SiteStore>Hello world</SiteStore>;

	return (
		<SiteStore>
			<ErrorBoundarySite>
				<ServicesCache />
				<Routes />
			</ErrorBoundarySite>
		</SiteStore>
	);
};

const App = (props) => {
	if (config.isDev && config.useStrictMode) {
		console.log("*** USING STRICT MODE ***");
		return (
			<React.StrictMode>
				<BaseApp />
			</React.StrictMode>
		);
	}

	return <BaseApp />;
};

export default App;
