import React from "react";
import { generateCypressProps } from "componentsLibraryResources/functions/cypress";

const ScreenFinder = (props) => {
	const { className } = props;

	const _className = ["lctr-resolution-finder", className].filter(Boolean).join(" ");

	return (
		<span
			className={_className}
			{...generateCypressProps("resolution-finder", props)}
		>
			<span className="d-inline-block d-sm-none">XS</span>
			<span className="d-none d-sm-inline-block d-md-none">SM</span>
			<span className="d-none d-md-inline-block d-lg-none">MD</span>
			<span className="d-none d-lg-inline-block d-xl-none">LG</span>
			<span className="d-none d-xl-inline-block d-xxl-none">XL</span>
			<span className="d-none d-xxl-inline-block">XXL</span>
		</span>
	);
};

export default ScreenFinder;
