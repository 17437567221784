import React, { useEffect, useState } from "react";
import _ from "lodash";
import dateHelpers from "@library/common/helpers/misc/date";
import { DevContainer } from "siteComponents/Dev";
import { useLookupOptionList } from "moveToLibrary/hooks/useLookup";

import { generateVehicle } from "siteComponents/redux/gapQuoteAndBuy";
import { createSelector } from "@reduxjs/toolkit";

import { FormGroup } from "siteComponentsLibrary/Layout";
import { TextBox, RadioButtons } from "siteComponentsLibrary/Inputs";
import { Button } from "siteComponentsLibrary/Buttons";
import { VehicleCard } from "siteComponentsLibrary/Display";
import { TelephoneNumberService } from "siteComponents/Misc";
import { Error } from "siteComponentsLibrary/Feedback";

import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";
import usePrevious from "moveToLibrary/hooks/usePrevious";

import services from "siteService";
import { useOutletContext } from "react-router-dom";

const IDVehicleReg = "regNumber";

const ManualSearchPanel = () => {
	return (
		<div className="row">
			<div className="col-12 alert bg-buttermilk mt-3 mb-0" role="alert">
				You are currently unable to get a quote online without a registration
				number. If you would still like a quote, please call us on{" "}
				<TelephoneNumberService />
			</div>
		</div>
	);
};

const FoundPanel = ({ serviceData, configData }) => {
	const commonData = useOutletContext();
	const dispatch = useDispatch();

	const formatFunctions = {
		fuelType: useLookupOptionList(services.lookups.vehicle.fueltype),
		transmission: useLookupOptionList(services.lookups.vehicle.transmission),
	};

	const {
		regNumber,
		vehicleDescription,
		doors,
		seats,
		fuel,
		transmission,
		engineSize,
		value,
	} = useSelector((state) => {
		const fnGetValue = (k) => commonData.risk.selectors.value(state, k);

		return {
			vehicleDescription: [
				fnGetValue("Risk/Vehicle/Manufacturer"),
				fnGetValue("Risk/Vehicle/ModelDescription"),
			]
				.filter(Boolean)
				.join(" "),
			regNumber: fnGetValue("Risk/Vehicle/RegNumber"),
			doors: fnGetValue("Risk/Vehicle/Doors"),
			seats: fnGetValue("Risk/Vehicle/Seats"),
			fuel: fnGetValue("Risk/Vehicle/Fuel"),
			transmission: fnGetValue("Risk/Vehicle/Transmission"),
			engineSize: fnGetValue("Risk/Vehicle/Cc"),
			value: fnGetValue("Risk/Vehicle/Value"),
		};
	}, _.isEqual);

	const onSearchAgain = async () => {
		if (false) {
			await dispatch(
				commonData.risk.actions.update({
					path: "ChosenOptions/VehicleExtraDetailsConfirmation",
					value: undefined,
				})
			);
			await dispatch(
				commonData.risk.actions.updateErrorShow({
					path: "ChosenOptions/VehicleExtraDetailsConfirmation",
					value: undefined,
				})
			);
		}
		await serviceData.functions.reset();
	};

	if (!serviceData?.status?.isFound) return null;

	return (
		<div className="found" data-cy="vehicle:found">
			<h2 className="form-label" data-cy="heading">
				Your {configData.policyTypeHeading}
			</h2>
			<div className="row">
				<div className="col-12 col-lg-10">
					<VehicleCard
						regNumber={regNumber}
						vehicleDescription={vehicleDescription}
						doors={doors}
						seats={seats}
						fuel={formatFunctions.fuelType(fuel)}
						transmission={formatFunctions.transmission(transmission)}
						engineSize={engineSize}
						value={value}
						valuationService={true}
						vehicleType={configData.policyTypeHeading}
					/>
				</div>
			</div>

			<div className="row">
				<div className="col-12 px-0 pt-1">
					<button
						onClick={onSearchAgain}
						className="btn btn-link"
						data-cy="not-your-vehicle"
					>
						Not your {configData.policyTypeHeading}?
					</button>
				</div>
			</div>
		</div>
	);
};

const SearchPanel = ({ serviceData, configData }) => {
	const commonData = useOutletContext();
	const dispatch = useDispatch();

	const { FormGroup: StoreFormGroup } = commonData.storeComponents;

	const [searchVehicleReg, setSearchVehicleReg] = useState("");
	const [showRegWarning, setShowRegWarning] = useState(false);

	const errorMessage = serviceData?.status?.isError ? true : undefined;

	const isSearching = serviceData?.status?.isRunning;
	const isFailedSearch = serviceData?.status?.isMissing;

	const vehicleNotFound = (
		<Error className="text-center">
			Sorry, we've not been able to find your {configData.policyTypeHeading}.
			Unfortunately we're unable to offer you a quote online.
			<br />
			If we can be of any further assistance please call us on{" "}
			<TelephoneNumberService className="alert-link" />.
		</Error>
	);

	const fnOnSearch = async () => {
		if (isSearching) return;
		if (!searchVehicleReg) return;
		if (searchVehicleReg.length < configData.RegNumberMinLength) return;
		if (searchVehicleReg.length > configData.RegNumberMaxLength) return;

		if (false) {
			await dispatch(
				commonData.risk.actions.update({
					path: "ChosenOptions/VehicleExtraDetailsConfirmation",
					value: undefined,
				})
			);
			await dispatch(
				commonData.risk.actions.updateErrorShow({
					path: "ChosenOptions/VehicleExtraDetailsConfirmation",
					value: undefined,
				})
			);
		}

		serviceData.functions.run(searchVehicleReg);
	};

	const fnAllowSearch = () => {
		if (!searchVehicleReg) return false;
		if (searchVehicleReg.length < configData.RegNumberMinLength) return false;
		return true;
	};

	if (serviceData.status.isFound) return null;

	return (
		<span className="search" data-cy="vehicle:search">
			<h2 className="form-label">
				Let's find your {configData.policyTypeHeading}
			</h2>

			<StoreFormGroup
				label="Please enter your registration number"
				data-cy="form-group:reg-search"
				databaseProps={{
					pathList: ["Risk/Vehicle/RegNumber"],
				}}
			>
				<div className="d-flex flex-column flex-wrap justify-content-start align-items-start flex-sm-row flex-sm-nowrap gap-3">
					<div className="col-12 col-sm-5 col-md-3">
						<TextBox
							data-cy="text:reg-search"
							id={IDVehicleReg}
							value={searchVehicleReg}
							onType={(value) => {
								const newValue = value
									? value.toUpperCase().replace(/\s/g, "")
									: undefined;
								setSearchVehicleReg(newValue);
							}}
							onKeyDown={(e) => {
								if (e.which === 32) e.preventDefault(); // Prevent SPACES
							}}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									fnOnSearch();
								}
							}}
							placeholder="XX01ABC"
							size={configData.RegNumberMaxLength}
							maxLength={configData.RegNumberMaxLength}
							className="text-center"
							disabled={isSearching}
						/>
					</div>
					<div className="col-12 col-sm-5 col-md-3">
						<Button
							data-cy="btn:reg-search"
							type="button"
							isLoading={isSearching}
							className={[
								"btn-primary",
								"w-100",
								fnAllowSearch() ? "" : "disabled",
							]
								.filter(Boolean)
								.join(" ")}
							onClick={fnOnSearch}
						>
							{(function () {
								// #3295 FIX: Needs to be a function to avoid GOOGLE TRANSLATE ERRORS
								return `Find my ${configData.policyTypeHeading}`;
							})()}
						</Button>
					</div>
				</div>

				{isFailedSearch && (
					<div className="mt-3" data-cy="feedback:search-failed">
						{vehicleNotFound}
					</div>
				)}

				{errorMessage && (
					<div className="mt-3" data-cy="feedback:search-error">
						{vehicleNotFound}
					</div>
				)}
			</StoreFormGroup>

			{/*<div className="row">
				<div className="col-12">
					<ButtonLink
						onClick={() => {
							setShowRegWarning(true);
						}}
						data-cy="btn:reg-not-known"
					>
						Don't know your {configData.policyTypeHeading}'s registration
						number?
					</ButtonLink>
				</div>
			</div>*/}

			{showRegWarning && <ManualSearchPanel />}

			<DevContainer>
				<h5>Van REGS</h5>

				{[
					{ reg: "RF17HBX", label: "RF17HBX" },
					{ reg: "KS02HOH", label: "KS02HOH -- missing details" },
				].map((x) => (
					<Button
						key={x.reg}
						onClick={() => {
							setSearchVehicleReg(x.reg);
						}}
					>
						{x.label}
					</Button>
				))}
			</DevContainer>
		</span>
	);
};

const PickerBase = (props) => {
	const { configData } = props;

	const commonData = useOutletContext();

	const serviceData = commonData.hooks.useService(
		async (registration) => {
			const response = await configData.searchService({
				Registration: registration,
			});
			// RF17HBX
			// KS02HOH -- VAN Missing details
			if (!response) return undefined;
			return { Response: response };
		},
		{
			responseMappings: configData.responseMappings.map((x) => ({
				...x,
				responsePath: `Response/${x.responsePath}`,
			})),
			fnHasData: (response) => (response?.RegNumber ? true : false),
			hasDataSelector: (state) =>
				commonData.risk.selectors.value(state, "Risk/Vehicle/RegNumber")
					? true
					: false,
		}
	);

	return (
		<div className="vehicle-container">
			{!serviceData.status.isFound && (
				<SearchPanel serviceData={serviceData} configData={configData} />
			)}
			{serviceData.status.isFound && (
				<FoundPanel serviceData={serviceData} configData={configData} />
			)}
		</div>
	);
};

export default PickerBase;
