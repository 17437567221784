// import { required } from "joi";
// import { isEmpty } from "@library/common/database/rules/helpers";
// import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

// Runs rules for all the componentTag in all componentSet in componentTagList
export default (args) => {
	const { dataSet, functionArgs } = args;

	if (!dataSet) throw `Error -- missing dataSet`;
	if (!functionArgs) throw `Error -- missing functionArgs`;

	if (!functionArgs.componentTagList) throw `Error -- missing componentTag`;

	functionArgs.componentTagList.forEach((componentTag) => {
		// get a list of all componentSet belonging to the componentTag
		(dataSet.getComponentSets({ componentTag }) || []).forEach(
			(componentSet) => {
				// dataSet.setIsUpdated({
				// 	useDefaultComponentSet: true,
				// 	useDefaultComponentTag: true,
				// 	key: "value",
				// });

				dataSet.dependencyAdd({
					componentTag,
					componentSet,
				});
			}
		);
	});
};
