import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch as _useDispatch } from "react-redux";
import { useLocation, matchPath } from "react-router-dom";

const DefaultFallbackComponent = () => null;

const generate = (args = {}) => {
	const { storeName, services, sessionActions, sessionSelectors } = args;

	const SessionBlocker = (props) => {
		/*
	  SESSION BLOCKER logic:
		if hasSession === true -- allow all;
		else,	only allow if URL is in entryPoints
	*/

		const { children, entryPoints, FallbackComponent, hasSession } = props;

		// Error checking
		{
			if (!("hasSession" in props))
				throw `Error in SessionBlocker -- missing hasSession`;

			if (!entryPoints) throw `Error in SessionBlocker -- missing entryPoints`;
			if (!FallbackComponent)
				throw `Error in SessionBlocker -- missing FallbackComponent`;
		}

		const { pathname } = useLocation();

		const isEntryPoint = entryPoints.some((x) => matchPath(x, pathname));

		if (!hasSession && !isEntryPoint) return <FallbackComponent />;

		return <>{children}</>;
	};

	const SessionChecker = (props) => {
		const {
			children,
			FallbackComponent = DefaultFallbackComponent,
			useDispatch = _useDispatch,
		} = props;

		const dispatch = useDispatch();
		const isInit = useSelector(
			sessionSelectors.getIsInit,
			require("lodash").isEqual
		);

		useEffect(() => {
			if (isInit) return;
			// console.log("TODO", "SessionChecker");
			dispatch(sessionActions.init({ get: true })); //TODO: Can comment out get:true
		}, []);

		if (!isInit) return <FallbackComponent />;

		return <>{children}</>;
	};

	// Auto saves the session if a watch variable changes (SHALLOW comparison only)
	const SessionAutoSave = (props) => {
		const { children, useDispatch, ...otherProps } = props;

		const hasChanged = useRef(false);
		const dispatch = useDispatch();
		const dataArray = Object.values(otherProps);
		const [initDataArray] = useState(dataArray);
		const [isSaving, setIsSaving] = useState(false);
		const [requestCount, setRequestCount] = useState(0);

		// Append to the queue
		//Removed as it was react.stricyt causing the multiple renders
		// useEffect(() => {
		//   // For some reason, this component may mount multiple times, so we need the "_.isEqual" check until we are sure a value has changed
		//   if (hasChanged.current === false && _.isEqual(initDataArray, dataArray)) {
		//     return;
		//   } else {
		//     hasChanged.current = true;
		//   }

		//   setRequestCount(requestCount + 1);
		// }, dataArray);

		useEffect(() => {
			if (hasChanged.current === false) {
				hasChanged.current = true;
				return;
			}
			setRequestCount(requestCount + 1);
		}, dataArray);

		// Handle the queue
		useEffect(() => {
			if (isSaving) return;
			if (requestCount === 0) return;

			console.log("SessionAutoSave", "saving...", requestCount, otherProps);
			setIsSaving(true);
			dispatch(sessionActions.save())
				.then(() => {
					console.log("SessionAutoSave", "...saved");
					setIsSaving(false);
					setRequestCount(requestCount - 1);
				})
				.catch(() => {
					console.log("SessionAutoSave", "...failed");
					setIsSaving(false);
					setRequestCount(requestCount - 1);
				});
		}, [isSaving, requestCount]);

		return <>{children}</>;
	};

	// Display something if saving
	const SessionSavingChecker = (props) => {
		const {
			LoadingComponent = () => (
				<div className="row">
					<div className="col-12">Please wait...</div>
				</div>
			),
		} = props;
		const isSaving = useSelector(
			sessionSelectors.getIsSaving,
			require("lodash").isEqual
		);
		// console.log("ddd isSaving", isSaving)

		if (isSaving) return <LoadingComponent />;

		return <>{props.children}</>;
	};
	return {
		SessionBlocker,
		SessionChecker,
		SessionAutoSave,
		SessionSavingChecker,
	};
};

export default generate;
