import moment from "moment";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

// import * as constants from "@library/common/constants/salus";
// proposerLogic
export default (args) => {
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;

	require("@library/common/helpers/misc/argChecker")(functionArgs, [
		"AdditionalInsuredSet",
		"AdditionalInsuredSet_RelationshipToProposer_TAG",
	]);

	const dh = new dataHelper({
		logging: false,
		mappings: ["MaritalStatus"],
		dataSet,
		functionArgs,
	});

	const {
		AdditionalInsuredSet,
		AdditionalInsuredSet_RelationshipToProposer_TAG,
	} = functionArgs;

	if (dh.isPostRecall()) return;

	const addDriverTree =
		dh.dataSet.getTree({
			argItem: AdditionalInsuredSet,
			componentTagHitlist: [AdditionalInsuredSet_RelationshipToProposer_TAG],
		}) || [];
	// .map((x) => ({
	// 	componentId:
	// 		x.data[AdditionalInsuredSet_RelationshipToProposer_TAG].componentId,
	// 	RelationshipToProposer:
	// 		x.data[AdditionalInsuredSet_RelationshipToProposer_TAG].value,
	// }));
	//dataSet.dependencyAdd(argItem);
	// console.log("dddd addDriverTree", addDriverTree);

	// call the rule(s) on all additional drivers "RelationshipToProposer"
	addDriverTree.forEach((x) => {
		//dataSetArgItem
		const componentSet = x.componentSet;
		const componentTag = AdditionalInsuredSet_RelationshipToProposer_TAG;
		const componentId = x.data[componentTag].componentId;
		const argItem = new dataSetArgItem({
			componentSet,
			componentTag,
			componentId,
		});
		// console.log("....driver:", argItem);
		dataSet.dependencyAdd(argItem);
	});

	dh.commit();
};
