import moment from "moment";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import * as constants from "@library/common/constants/salus";

export default (args) => {
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;
	//MaritalStatus
	const dh = new dataHelper({
		logging: false,
		loggingName: "additionalDriverLogic",
		mappings: [
			"RelationshipToProposer",
			"MaritalStatus",
			"DateOfBirth",
			"ProposerDateOfBirth",
			"ProposerMaritalStatus",
			"ProposerForenames",
			"ProposerSurname",
			"ProposerTitle",
		],
		dataSet,
		functionArgs,
	});

	if (dh.isPostRecall()) return;

	// console.log("dddd addDriverlogic running", dh.getValue("ProposerSurname"));

	// check if the driver is a child, compare their date of births

	// Task 400 -- remove parent/child DOB validation
	// if (
	// 	dh.isUpdated(
	// 		"RelationshipToProposer",
	// 		"MaritalStatus",
	// 		"DateOfBirth",
	// 		"ProposerDateOfBirth"
	// 	)
	// ) {
	// 	if (
	// 		dh.getValue("RelationshipToProposer") === "OFFSPRNG" &&
	// 		dh.getValueDate("DateOfBirth") <= dh.getValueDate("ProposerDateOfBirth")
	// 	) {
	// 		const errorMessage =
	// 			"Birthdate for a child should be less than parent's birthdate";
	// 		dh.addError("RelationshipToProposer", errorMessage, false);
	// 		dh.addError("DateOfBirth", errorMessage, false);
	// 		dh.addError("ProposerDateOfBirth", errorMessage, false);
	// 	} else if (
	// 		dh.getValue("RelationshipToProposer") === "PARENT" &&
	// 		dh.getValueDate("DateOfBirth") >= dh.getValueDate("ProposerDateOfBirth")
	// 	) {
	// 		const errorMessage =
	// 			"Birthdate for a child should be greater than parent's birthdate";
	// 		dh.addError("RelationshipToProposer", errorMessage, false);
	// 		dh.addError("DateOfBirth", errorMessage, false);
	// 		dh.addError("ProposerDateOfBirth", errorMessage, false);
	// 	} else {
	// 		dh.clearError("ProposerDateOfBirth");
	// 	}
	// }

	// check the RelationshipToProposer is consistant with MaritalStatus
	const curRel = dh.getValue("RelationshipToProposer");
	const curMarStatus = dh.getValue("MaritalStatus");
	const curPropMarStatus = dh.getValue("ProposerMaritalStatus");

	const strProposerName =
		[
			dh.getValue("ProposerTitle"),
			dh.getValue("ProposerForenames"),
			dh.getValue("ProposerSurname"),
		].join(" ") || "the proposer";

	if (curRel === constants.relationship.spouse) {
		// NOTE: curMarStatus could be undefined
		if (curMarStatus !== constants.maritalStatus.married) {
			const errorMessage = "";
			// "`Marital status` must match the `relationship to proposer`";
			dh.addError("RelationshipToProposer", errorMessage, false);
			dh.addError("MaritalStatus", errorMessage, false);
		}

		if (curPropMarStatus !== constants.maritalStatus.married) {
			const errorMessage =
				"Their relationship doesn't match the marital status of " +
				strProposerName +
				". Please review and update.";
			// "`Marital status` of the main driver must be `Married`";
			dh.addError("RelationshipToProposer", errorMessage, false);
			// dh.addError("ProposerMaritalStatus", errorMessage, false);
		}
	}

	if (curRel === constants.relationship.civilPartner) {
		if (curMarStatus !== constants.maritalStatus.civilPartnered) {
			const errorMessage = "";
			// "`Marital status` must match the `relationship to proposer`";
			dh.addError("RelationshipToProposer", errorMessage, false);
			dh.addError("MaritalStatus", errorMessage, false);
		}

		if (curPropMarStatus !== constants.maritalStatus.civilPartnered) {
			const errorMessage =
				"Their relationship doesn't match the marital status of " +
				strProposerName +
				". Please review and update.";
			// "`Marital status` of the main driver must be `civil partner`";
			dh.addError("RelationshipToProposer", errorMessage, false);
			// dh.addError("ProposerMaritalStatus", errorMessage, false);
		}
	}

	// check if there's only one "COMSPOUSE" and "SPOUSEREL"

	const dataTree =
		dataSet.getTreeSiblings({
			argItem: new dataSetArgItem({
				useDefaultComponentTag: true,
				useDefaultComponentSet: true,
			}),
			componentTagHitlist: ["RelationshipToProposer", "MaritalStatus"],
		}) || [];

	dataTree
		.filter(
			(x) =>
				x.curComponentSet === false &&
				x.data.RelationshipToProposer.value ===
					dh.getValue("RelationshipToProposer")
		)
		.forEach((x, i) => {
			const errorMessage =
				"You can only add one spouse or civil partner to your policy.";

			switch (x.data.RelationshipToProposer.value) {
				case "CivPrtnr": {
					dh.addError("RelationshipToProposer", errorMessage, false);
					break;
				}
				case "COMSPOUSE": {
					dh.addError("RelationshipToProposer", errorMessage, false);
					break;
				}
				case "SPOUSEREL": {
					dh.addError("RelationshipToProposer", errorMessage, false);
					break;
				}
			}
		});

	dh.commit();
};
