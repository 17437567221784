import React, { useEffect, useState } from "react";
import dateHelpers from "@library/common/helpers/misc/date";
import { useOutletContext } from "react-router-dom";

const responseMappings = require("./responseMappings.common").default;

import PickerBase from "./Base";

const VanPicker = () => {
	const commonData = useOutletContext();

	const configData = {
		responseMappings: responseMappings,
		RegNumberMinLength: 2,
		RegNumberMaxLength: 10,
		policyTypeHeading: "van",
		searchService: commonData.dictionary.services.lookup.vehicle.van,
	};

	return <PickerBase configData={configData} />;
};

export default VanPicker;
