import generateSlice from "./slice";
import generateActions from "./actions";
import generateSelectors from "./selectors";
import generateHooks from "./hooks";
import generateHelpers from "./helpers";
import { database as migrateDatabase } from "./utils/migration";

const generateStore = (args = {}) => {
	const { storeName, services = {}, selectors, rules, dev, template } = args;

	const fnThrowError = (msg, _storeName = storeName) => {
		throw ["Error in generateStore", _storeName, "-", msg]
			.filter((x) => x)
			.join(" ");
	};

	if (!storeName) fnThrowError("missing storeName");

	if (!dev?.migrate?.database && !template) fnThrowError("missing template");

	["getQuote"].forEach((k) => {
		if (!(k in services)) {
			fnThrowError(`missing service:${k}`);
		}
	});

	const { rules: _rules, template: _template } = (function () {
		//  MIGRATION
		if (dev?.migrate?.database) {
			console.log("MIGRATING RULES", dev.migrate);

			const retdata = migrateDatabase(
				dev.migrate.database, // The old style database
				dev.migrate.rules
			);

			if (dev.migrate.rulesAdditional)
				retdata.rules = { ...retdata.rules, ...dev.migrate.rulesAdditional };

			// (OPTIONAL) Override with config values:
			if (template) retdata.template = template;
			if (rules) retdata.rules = rules;

			return retdata;
		}

		// STANDARD
		if (!template) fnThrowError("missing template");

		return { rules, template };
	})();

	const baseArgs = {
		...args,
		rules: _rules,
		template: _template,
	};

	// console.log(baseArgs);
	// throw `ddd`;

	const _selectors = generateSelectors(baseArgs);
	const _actions = generateActions({ ...baseArgs, selectors: _selectors });

	const _slice = generateSlice({
		...baseArgs,
		selectors: _selectors,
		privateActions: _actions,
	});

	const _hooks = generateHooks({
		...baseArgs,
		storageSelectors: _selectors,
		storageActions: { ..._actions, ..._slice.actions },
	});

	const _helpers = generateHelpers({
		...baseArgs,
		actions: { ..._actions, ..._slice.actions },
		selectors: _selectors,
	});

	const retData = {
		storeName,
		reducer: _slice.reducer,
		actions: { ..._actions, ..._slice.actions },
		selectors: _selectors,
		hooks: _hooks,
		helpers: _helpers,
		template: _template,
	};

	console.log("STORE", storeName, "=", retData);
	return retData;
};

export const rules = require("./rules").default;
export default generateStore;
