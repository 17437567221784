import React from "react";
import { generateCypressProps } from "libraryFunctions/cypress";

import { ReduxStoreViewer } from "siteComponentsLibrary/Dev";
import { DevContainer } from "siteComponents/Dev";

const SiteLayout = (props) => {
	const { children } = props;

	return (
		<div {...generateCypressProps("layout-site", props)}>
			{children}

			<DevContainer>
				process.env.BUILD_TIME = {process.env.BUILD_TIME}
			</DevContainer>
			<DevContainer heading="REDUX Store">
				<ReduxStoreViewer />
			</DevContainer>
		</div>
	);
};

export default SiteLayout;
