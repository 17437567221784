import React, { useState, useCallback, useEffect } from "react";
import config from "config";
import { selectors, actions, store } from "siteStore";
import { useOutletContext } from "react-router-dom";

import { TextBox } from "siteComponentsLibrary/Inputs";
import { DatePickerTextBox } from "siteComponentsLibrary/Dates";

import { Button, ExternalLink } from "siteComponentsLibrary/Buttons";
import { Panel } from "siteComponentsLibrary/Layout";
import { ModalLoading } from "siteComponentsLibrary/Modal";
import { Error } from "siteComponentsLibrary/Feedback";
import { TelephoneNumber, Help } from "siteComponentsLibrary/Display";
import { DevContainer } from "siteComponents/Dev";

import { useLocation } from "react-router-dom";
import composeValidation from "@library/common/components/composers/validation";
import { tidyPostcode } from "@library/common/helpers/misc/postcode";

const ErrorBase = (props) => {
	const { children, className } = props;
	return <span className={className}>{children}</span>;
};

const {
	ErrorContainerDateOfBirth,
	ErrorContainerPostcode,
	ErrorContainerWebReference,
	// generateErrMsg,
	generateIsValid,
} = composeValidation({
	// generateErrMsgName: "generateErrMsg",
	generateIsValidName: "generateIsValid",
	configData: [
		{
			componentName: "ErrorContainerDateOfBirth",
			propIdValue: "value",
			fnValidate: (v) => (v ? true : false),
			errorClass: "has-error",
			propIdIsError: "isError",
			Component: ErrorBase,
			errMsg: "Invalid date of birth. Please check and try again.",
			errPriority: 3,
			errDataKey: "dateOfBirth",
		},
		{
			componentName: "ErrorContainerPostcode",
			propIdValue: "value",
			type: "POSTCODE",
			errorClass: "has-error",
			propIdIsError: "isError",
			Component: ErrorBase,
			errMsg: "Invalid postcode. Please check and try again.",
			errPriority: 2,
			errDataKey: "postcode",
		},
		{
			componentName: "ErrorContainerWebReference",
			propIdValue: "value",
			type: "WEBREFERENCE",
			fnWhitelist: (v) => {
				// console.log("whitelist", v)
				if (!(config.isDev || config.isStage)) return false;

				if (!v) return false;

				if (v.startsWith("MR-")) return true;
			},
			errorClass: "has-error",
			propIdIsError: "isError",
			Component: ErrorBase,
			errMsg: "Invalid web reference. Please check and try again.",
			errPriority: 1,
			errDataKey: "reference",
		},
	],
});

const ViewsCommonRecallForm = (props) => {
	const { onSuccess = async () => {}, onFailure = async () => {} } = props;

	const commonData = useOutletContext();

	console.log("ViewsCommonRecallForm.jsx", { commonData });

	const [showErrors, setShowErrors] = useState(false);
	const [showErrorsReference, setShowErrorsReference] = useState(false);
	const [showErrorsPostcode, setShowErrorsPostcode] = useState(false);
	const [showErrorsDateOfBirth, setShowErrorsDateOfBirth] = useState(false);

	const [errorMessage, setErrorMessage] = useState();

	const [isLoading, setIsLoading] = useState(false);
	const [dateOfBirth, setDateOfBirth] = useState(); // useState("1967-04-29T00:00:00");
	const [reference, setReference] = useState(); //useState("MOCK_RECALL_PC");
	const [postcode, setPostcode] = useState(); // useState("daniel.holland@freedombrokers.co.uk");

	const reactRouterlocation = useLocation();

	const referenceUrl = require("@library/common/helpers/misc/paramExtractor")(
		reactRouterlocation.search,
		"webref"
	);

	const portalUrl = require("react-redux").useSelector((state) =>
		selectors.storage.getValue(state, "status-checker-portalLoginUrl")
	);

	const isFormValid = useCallback(
		generateIsValid({
			postcode,
			dateOfBirth,
			reference,
		}),
		[postcode, dateOfBirth, reference]
	);

	useEffect(() => {
		setReference(referenceUrl);
	}, [referenceUrl]);

	useEffect(() => {
		if (!showErrors) return;
		setErrorMessage("Please correct the errors above.");
	}, [showErrors, isFormValid]);

	const fnRecallData = async (args = {}) => {
		try {
			const { postcode, dateOfBirth, reference, emailAddress } = args;

			setIsLoading(true);
			setErrorMessage(undefined);

			await commonData.functions.process.reset();

			const response = await commonData.recall.functions.recall({
				postcode,
				dateOfBirth,
				reference,
				emailAddress,
			});

			const errorMessage = response?.errorMessage;

			setErrorMessage(errorMessage);

			if (response.isSuccess) {
				await commonData.functions.session.save();
				//REDIRECT to QUOTES PAGE
				await onSuccess();
				return;
			}
		} catch (e) {
			console.error("ERROR", e);
			setIsLoading(false);
		}

		setErrorMessage("Sorry, something has gone wrong.");
		await onFailure({ errorMessage: "Sorry, something has gone wrong." });

		setIsLoading(false);
	};

	return (
		<React.Fragment>
			<ModalLoading isOpen={isLoading}>Recalling your details...</ModalLoading>

			{!isLoading && (
				<React.Fragment>
					<Panel header="Recall your quote">
						<ErrorContainerWebReference
							showErrors={showErrorsReference}
							value={reference}
						>
							<div className="row fb-form-group py-3 px-md-3">
								<div className="col-12 mb-3">
									<div className="alert alert-info">
										If you have already purchased your policy you can{" "}
										<ExternalLink
											href={portalUrl}
											label="visit your online account here"
											nowrap={false}
										/>
										, or if you have any questions, you can call us on{" "}
										<TelephoneNumber
											phonenumber={
												require("config").default.lookups.telephoneNumbers
													.service
											}
											altLabel={
												require("config").default.lookups.telephoneNumbers
													.service
											}
											isLink={true}
										/>
										.
									</div>
								</div>
								<div className="col-12">
									<label
										htmlFor="ManualReference"
										className="form-label fw-bold"
									>
										Quote reference number
									</label>
								</div>
								<div className="col-12">
									<Help>
										<p>
											This will be in one of these formats;{" "}
											<strong>1234-123456-1234</strong> or{" "}
											<strong>12345-12345-12345</strong>.
										</p>
									</Help>
								</div>
								<div className="col-12 col-md-7">
									<TextBox
										id="ManualReference"
										value={reference}
										onChange={(value) => {
											const newValue = value ? value.trim() : value;
											setShowErrorsReference(true);
											setReference(newValue);
										}}
										maxLength="17"
										autoComplete="off"
									/>
								</div>
							</div>
						</ErrorContainerWebReference>

						<ErrorContainerPostcode
							showErrors={showErrorsPostcode}
							value={postcode}
						>
							<div className="row fb-form-group py-3 px-md-3">
								<div className="col-12">
									<label
										htmlFor="ManualPostcode"
										className="form-label fw-bold"
									>
										Postcode
									</label>
								</div>

								<div className="col-12 col-md-7">
									<TextBox
										id="ManualPostcode"
										value={postcode}
										onChange={(value) => {
											const newValue = value
												? tidyPostcode(value.toUpperCase())
												: newValue;
											setShowErrorsPostcode(true);
											setPostcode(newValue);
										}}
										maxLength="10"
										autoComplete="off"
									/>
								</div>
							</div>
						</ErrorContainerPostcode>

						<ErrorContainerDateOfBirth
							showErrors={showErrorsDateOfBirth}
							value={dateOfBirth}
						>
							<div className="fb-form-group py-3 px-md-3">
								<div className="row">
									<div className="col-12">
										<label
											htmlFor="ManualDateOfBirth"
											className="form-label fw-bold"
										>
											Date of birth
										</label>
									</div>
								</div>
								<DatePickerTextBox
									className="mb-3"
									id="ManualDateOfBirth"
									value={dateOfBirth}
									onChange={(value) => {
										setShowErrorsDateOfBirth(true);
										setDateOfBirth(value);
									}}
									label="Date of birth"
								/>
							</div>
						</ErrorContainerDateOfBirth>
					</Panel>

					{!isLoading && errorMessage && (
						<div className="row mb-3">
							<div className="col-12">
								<Error>{errorMessage}</Error>
							</div>
						</div>
					)}

					<div className="row mx-0 mx-md-3 mb-3 my-auto">
						<div className="col-12 text-end">
							<Button
								className="btn-success btn-lg"
								onClick={async () => {
									setShowErrors(true);
									setShowErrorsReference(true);
									setShowErrorsPostcode(true);
									setShowErrorsDateOfBirth(true);

									if (!isFormValid) return;
									await fnRecallData({ postcode, dateOfBirth, reference });
								}}
							>
								Recall your quote
							</Button>
						</div>
					</div>
				</React.Fragment>
			)}
			<DevContainer heading="Debug tools">
				<Button
					className="btn-success btn-lg"
					onClick={async () => {
						setReference("12345-12345-12345");
						setPostcode("ST4 4TW");
						setDateOfBirth(new Date("1 OCTOBER 2000"));
					}}
				>
					FILL IN Mock Data
				</Button>

				<hr />
				{[
					{
						label: "MR-GAP-STAN-|PC",
						WebReference: "MR-GAP-STAN-|PC",
					},
					{
						label: "MR-GAP-STAN-|GV",
						WebReference: "MR-GAP-STAN-|GV",
					},
				].map((x, i) => {
					return (
						<Button
							key={x.label}
							onClick={async () => {
								await fnRecallData({
									dateOfBirth: x.DateOfBirth,
									reference: x.WebReference,
									emailAddress: x.EmailAddress,
									postcode: x.Postcode,
								});
							}}
						>
							{x.label}
						</Button>
					);
				})}
			</DevContainer>
		</React.Fragment>
	);
};

export default ViewsCommonRecallForm;
