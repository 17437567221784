import React, { useEffect, useState, useCallback, useContext } from "react";
import { useOutletContext } from "react-router-dom";
import { Button, ExternalLink } from "siteComponentsLibrary/Buttons";
import { FormGroup as FormGroupSite } from "siteComponentsLibrary/Layout";
import { Error } from "siteComponentsLibrary/Feedback";
import services from "siteService";

// import useFullscreen, {
// 	FullScreenWrapper,
// 	useFullscreenControls,
// } from "moveToLibrary/hooks/useFullscreen";

// const fullScreenId = "setTest";
const SetTestEdit = (props) => {
	const { path, onDelete, onClose } = props;

	const commonData = useOutletContext();

	const { TextBox, FormGroup } = commonData.storeComponents;

	const setSubControls = commonData.hooks.useSet(`${path}/SubList`);

	useEffect(() => {
		const myFn = async () => {
			await setSubControls.add("TESTSUBID", { Value: 33333 });
		};
		myFn();
	}, []);

	return (
		<div>
			Set test edit {path}
			<FormGroup
				databaseProps={{
					path: `${path}/Value`,
				}}
				label="Value"
			>
				<TextBox
					databaseProps={{
						path: `${path}/Value`,
					}}
				/>
			</FormGroup>
			<FormGroup
				databaseProps={{
					path: `${path}/Value2`,
				}}
				label="Value"
			>
				<TextBox
					databaseProps={{
						path: `${path}/Value2`,
					}}
				/>
			</FormGroup>
			<Button className="btn-secondary" onClick={onClose}>
				Close
			</Button>
			<Button className="btn-secondary" onClick={onDelete}>
				Delete
			</Button>
		</div>
	);
};

const TemplateLayout = (function () {
	const ButtonPanel = (props) => {
		const [showError, setShowError] = useState(false);

		const {
			labelCancel,
			onClickCancel,
			labelContinue,
			onClickContinue,
			isValid,
			errorMessage = "Please correct the errors above",
		} = props;

		useEffect(() => {
			if (isValid) setShowError(false);
		}, [isValid]);

		return (
			<div className="row">
				<div className="col-12 col-md-6">
					<Button className="btn-secondary w-100" onClick={onClickCancel}>
						{labelCancel}
					</Button>
				</div>
				<div className="col-12 col-md-6">
					<Button
						className="btn-secondary w-100"
						onClick={() => {
							if (!isValid) {
								setShowError(true);
								return;
							}
							onClickContinue();
						}}
					>
						{labelContinue}
					</Button>
				</div>
				{showError && (
					<div className="col-12 mt-3">
						<Error>{errorMessage}</Error>
					</div>
				)}
			</div>
		);
	};
	return {
		List: (props) => {
			const {
				listData = [],
				fnAddItem,
				fnRowDisplay = (values) => <div> {JSON.stringify(values)}</div>,
				heading,
				missingMessage = "None",
			} = props;

			const hasItems = listData.length !== 0;
			return (
				<FormGroupSite label={heading}>
					{!hasItems && <p>{missingMessage}</p>}
					{hasItems !== 0 && (
						<ul>
							{listData.map((x) => (
								<li key={x.id}>
									{x.id}
									{fnRowDisplay(x.values)}
									<Button className="btn-secondary" onClick={x.controls.edit}>
										Edit
									</Button>
									<Button className="btn-secondary" onClick={x.controls.delete}>
										Delete
									</Button>
								</li>
							))}
						</ul>
					)}

					<Button className="btn-secondary" onClick={fnAddItem}>
						Create
					</Button>
				</FormGroupSite>
			);
		},
		Add: (props) => {
			const { children, data, isValid } = props;

			return (
				<span>
					{children}
					<ButtonPanel
						isValid={isValid}
						labelCancel="Close"
						onClickCancel={data.fnCancel}
						labelContinue="Add"
						onClickContinue={data.fnSave}
					/>
				</span>
			);
		},
		Edit: (props) => {
			const { children, data, isValid } = props;

			return (
				<span>
					{children}
					<ButtonPanel
						isValid={isValid}
						labelCancel="Undo"
						onClickCancel={data.fnCancel}
						labelContinue="Save"
						onClickContinue={data.fnSave}
					/>
				</span>
			);
		},
	};
})();

const DataClaims = (function () {
	const EditBase = (props) => {
		const { data, Wrapper } = props;

		const commonData = useOutletContext();
		const { TextBox, FormGroup } = commonData.storeComponents;

		return (
			<Wrapper {...props}>
				<FormGroup
					databaseProps={{
						path: data.generateItempath("ClaimType"),
						// autoRegister: true,
					}}
					label={data.generateItempath("ClaimType")}
				>
					<TextBox
						databaseProps={{
							path: data.generateItempath("ClaimType"),
						}}
					/>
				</FormGroup>
			</Wrapper>
		);
	};

	return {
		List: (props) => (
			<TemplateLayout.List
				{...props}
				heading="Claims"
				missingMessage="You have no claims"
			/>
		),
		Add: (props) => <EditBase {...props} Wrapper={TemplateLayout.Add} />,
		Edit: (props) => <EditBase {...props} Wrapper={TemplateLayout.Edit} />,
	};
})();

const DataAdditionalInsured = (function () {
	const EditBase = (props) => {
		const { data, Wrapper } = props;

		const commonData = useOutletContext();

		const { TextBox, FormGroup, DropDownTypeWebService, DatePickerTextBox } =
			commonData.storeComponents;

		const claimsData = commonData.hooks.useSet(
			data.generateItempath("Claims"),
			{
				framework: {
					List: DataClaims.List,
					Edit: DataClaims.Edit,
					Add: DataClaims.Add,
				},
			}
		);

		const claimFramework = claimsData.framework;
		const {
			List: ClaimsList,
			Add: ClaimsAdd,
			Edit: ClaimsEdit,
		} = claimFramework.components;

		if (claimFramework.isAdd) return <ClaimsAdd />;
		if (claimFramework.isEdit) return <ClaimsEdit />;

		console.log(
			"services.lookups.person.employstatus",
			services.lookups.person.employstatus
		);
		return (
			<Wrapper {...props}>
				<ClaimsList />
				<FormGroup
					databaseProps={{
						path: data.generateItempath("Value"),
					}}
					label={data.generateItempath("Value")}
				>
					<TextBox
						databaseProps={{
							path: data.generateItempath("Value"),
						}}
					/>
				</FormGroup>
				<FormGroup
					databaseProps={{
						path: data.generateItempath("Value2"),
					}}
					label={data.generateItempath("Value2")}
				>
					<TextBox
						databaseProps={{
							path: data.generateItempath("Value2"),
						}}
					/>
				</FormGroup>

				<FormGroup
					databaseProps={{
						path: data.generateItempath("FullTimeOccupation/Type"),
					}}
					label="Employment status"
				>
					<DropDownTypeWebService
						databaseProps={{
							path: data.generateItempath("FullTimeOccupation/Type"),
						}}
						DataFunction={services.lookups.person.employstatus.main}
					/>
				</FormGroup>
				<FormGroup
					databaseProps={{
						path: data.generateItempath("FullTimeOccupation/OccCode"),
					}}
					label="Job title"
					helpText="If you can't find the exact job, please select the closest description."
				>
					<DropDownTypeWebService
						databaseProps={{
							path: data.generateItempath("FullTimeOccupation/OccCode"),
						}}
						DataFunction={services.lookups.person.jobtitles}
					/>
				</FormGroup>
				<FormGroup
					databaseProps={{
						path: data.generateItempath("FullTimeOccupation/EmpCode"),
					}}
					label="Industry you work in"
					helpText="If you can't find the exact industry, please select the closest description."
				>
					<DropDownTypeWebService
						databaseProps={{
							path: data.generateItempath("FullTimeOccupation/EmpCode"),
						}}
						DataFunction={services.lookups.person.industries}
					/>
				</FormGroup>

				<FormGroup
					databaseProps={{
						path: data.generateItempath("RelationshipToProposer"),
					}}
					label="What's their relationship to you?"
				>
					<DropDownTypeWebService
						databaseProps={{
							path: data.generateItempath("RelationshipToProposer"),
						}}
						DataFunction={services.lookups.person.relationship}
					/>
				</FormGroup>

				<FormGroup
					databaseProps={{
						path: data.generateItempath("DateOfBirth"),
					}}
					label="Date of birth"
					data-cy="form-group:proposer-dob"
					classNameContent="col-md-8 col-lg-6"
				>
					<DatePickerTextBox
						databaseProps={{
							path: data.generateItempath("DateOfBirth"),
						}}
					/>
				</FormGroup>

				{/* {["RelationshipToProposer", "MaritalStatus", "DateOfBirth"].map(
					(key) => (
						<FormGroup
							key={key}
							databaseProps={{
								path: data.generateItempath(key),
							}}
							label={data.generateItempath(key)}
						>
							<TextBox
								databaseProps={{
									path: data.generateItempath(key),
								}}
							/>
						</FormGroup>
					)
				)} */}
			</Wrapper>
		);
	};

	return {
		List: (props) => (
			<TemplateLayout.List
				{...props}
				heading="Additional Drivers"
				missingMessage="You have no drivers"
			/>
		),
		Add: (props) => <EditBase {...props} Wrapper={TemplateLayout.Add} />,
		Edit: (props) => <EditBase {...props} Wrapper={TemplateLayout.Edit} />,
	};
})();

const SetTest = () => {
	return <div>USESET() TEST -- disabled for now</div>
	const commonData = useOutletContext();
	const additionalInsuredData = commonData.hooks.useSet(
		"Test/AdditionalInsured",
		{
			framework: {
				List: DataAdditionalInsured.List,
				Edit: DataAdditionalInsured.Edit,
				Add: DataAdditionalInsured.Add,
			},
		}
	);

	// console.log("ddddcommonData", commonData);
	// const updater = commonData.hooks.useUpdater();

	const frameworkData = additionalInsuredData.framework;

	const fnCreateItem = async () => {
		const newPath = additionalInsuredData.generateId();
		const retData = await additionalInsuredData.add(newPath);

		return retData;
	};

	useEffect(() => {
		const myFn = async () => {
			const fnUpdate = commonData.risk.functions.updateValue;

			await fnCreateItem();

			// function(curItem){}(await fnCreateItem())

			// {
			// 	await commonData.risk.functions.updateValue(
			// 		curItem.generateItempath("RelationshipToProposer"),
			// 		"SPOUSEREL"
			// 	);
			// }

			await fnCreateItem();
			await fnCreateItem();

			(async function (curItem) {
				await fnUpdate(
					curItem.generateItempath("RelationshipToProposer"),
					"SPOUSEREL"
				);
			})(await fnCreateItem());

			frameworkData.init();
		};
		myFn();
	}, []);

	{
		const framework = additionalInsuredData.framework;
		const {
			List: AdditionalInsuredList,
			Add: AdditionalInsuredAdd,
			Edit: AdditionalInsuredEdit,
		} = framework.components;

		if (framework.isAdd) return <AdditionalInsuredAdd />;
		if (framework.isEdit) return <AdditionalInsuredEdit />;

		return <AdditionalInsuredList />;
	}
	// return (
	// 	<FullScreenWrapper id={fullScreenId}>
	// 		{FrameworkComponent && <FrameworkComponent />}
	// 	</FullScreenWrapper>
	// );

	// const treeData = setControls.treeData;
	// // console.log("ddddd", { setControls, curItem, treeData });

	// if (curItem) {
	// 	return (
	// 		<SetTestEdit
	// 			path={curItem.path}
	// 			onClose={() => setCurItem(undefined)}
	// 			onDelete={async () => {
	// 				curItem.delete();
	// 				setCurItem(undefined);
	// 			}}
	// 		/>
	// 	);
	// }

	// return (
	// 	<div>
	// 		<ul>
	// 			{treeData.map((x) => (
	// 				<li key={x._id}>
	// 					{x.id}

	// 					<Button
	// 						className="btn-secondary"
	// 						onClick={async () => {
	// 							setCurItem(x.controls);
	// 						}}
	// 					>
	// 						Edit
	// 					</Button>
	// 					<Button
	// 						className="btn-secondary"
	// 						onClick={async () => {
	// 							await x.controls.delete();
	// 						}}
	// 					>
	// 						Delete
	// 					</Button>
	// 				</li>
	// 			))}
	// 		</ul>
	// 		<Button
	// 			className="btn-secondary"
	// 			onClick={async () => {
	// 				const data = await fnCreateItem();
	// 				setCurItem(data);
	// 			}}
	// 		>
	// 			Create Item
	// 		</Button>
	// 	</div>
	// );
};

export default SetTest;
