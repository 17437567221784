import React, { useEffect, useState, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import config from "config";

const routeDictionary = config.lookups.routes.gap;
import LayoutPageGap from "siteLayouts/Page/Gap";

import { Panel } from "siteComponentsLibrary/Layout";
import { ButtonLink, Button } from "siteComponentsLibrary/Buttons";
import { TelephoneNumberService } from "siteComponents/Misc";

import ErrorBaseView from "../../../Errors/Base";

const ViewsGapRiskFeedback = (props) => {
	const { pagerData, feedbackType, fnExit } = props;

	if (!pagerData) throw `Error in ViewsGapRiskEdit -- missing pagerData`;
	if (!fnExit) throw `Error in ViewsGapRiskEdit -- missing fnExit`;

	const commonData = useOutletContext();
	const currentQuote = commonData.quote.data.currentQuote;

	const sessionKey = "feedback-local-data";
	const sessionData = (function () {
		if (sessionStorage.getItem(sessionKey)) {
			console.log("FEEDBACK:", "found session item", sessionKey);
			return JSON.parse(sessionStorage.getItem(sessionKey));
		}

		if (!currentQuote) return;

		const retData = { WebReference: currentQuote?.WebReference };
		sessionStorage.setItem(sessionKey, JSON.stringify(retData));

		return retData;
	})();

	// **********************************************
	// EFFECTS
	// **********************************************

	useEffect(() => {
		return () => {
			console.log("FEEDBACK", "Removing session key:", sessionKey);
			sessionStorage.removeItem(sessionKey);
		};
	}, []);

	useEffect(() => {
		const myFn = async () => {
			switch (feedbackType) {
				case "declined":
				case "refused":
				case "referred":
				case "notauthorised":
					break;

				default: {
					await commonData.functions.process.reset();
					await commonData.functions.session.save();
				}
			}
		};

		myFn();
	}, [feedbackType]);

	const baseProps = {
		pagerData: {
			...pagerData,
		},
	};

	// **********************************************
	// VARS
	// **********************************************
	const [headerText, icon, displayData] = (function () {
		switch (feedbackType && feedbackType.toLowerCase()) {
			case "declined":
			case "refused":
			case "referred":
			case "notauthorised":
				return [
					"We're sorry",
					"icon-information",
					<>
						<div>Your payment has not been authorised.</div>
						<div className="mt-3">
							You can
							<ButtonLink
								onClick={() =>
									commonData.functions.navigate.to(
										routeDictionary.sections.yourQuote
									)
								}
								className="px-1"
							>
								<strong>try another payment</strong>
							</ButtonLink>
							or, alternatively, call us on{" "}
							<strong>
								<TelephoneNumberService />
							</strong>{" "}
							and one of our agents will be happy to help.
						</div>
						{sessionData?.WebReference && (
							<div className="mt-3">
								Your reference number is:{" "}
								<strong>{sessionData?.WebReference}</strong>
							</div>
						)}
					</>,
				];

			case "paymenterror":
			case "timeout":
			case "techicalerror":
			case "error":
			case "processingerror":
			default:
				return [
					"We're very sorry",
					"icon-error",
					<>
						<div>There's been a problem processing your payment.</div>
						<div className="mt-3">
							Please call us on{" "}
							<strong>
								<TelephoneNumberService/>
							</strong>{" "}
							and one of our agents will be happy to help.
						</div>
						{sessionData?.WebReference && (
							<div className="mt-3">
								Your reference number is:{" "}
								<strong>{sessionData?.WebReference}</strong>
							</div>
						)}
						<div className="mt-5">
							<div className="alert alert-danger text-center p-3">
								<strong>Please do not attempt to make the payment again.</strong>
							</div>
						</div>
					</>,
				];
		}

		// throw `Error in Renewal FeedbackView -- unknown type "${feedbackType}"`;
	})();

	return (
		<ErrorBaseView
			heading={headerText}
			icon={icon}
			SectionLayout={({ children }) => <>{children}</>}
			PageLayout={(props) => (
				<LayoutPageGap
					{...props}
				/>
			)}
		>
			{displayData}
		</ErrorBaseView>
	)
	
};

export default ViewsGapRiskFeedback;
