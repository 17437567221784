import React, { useEffect, useState, useCallback, useMemo } from "react";
import ReactSelect from "react-select";
import useDeepCompareEffect from "use-deep-compare-effect";

import _ from "lodash";

import { generateCypressProps } from "componentsLibraryResources/functions/cypress";
import { tidyPostcode } from "componentsLibraryResources/helpers/misc/postcode";
import useAutofill from "componentsLibraryResources/hooks/useAutofill";
import * as regExConstants from "componentsLibraryResources/constants/regEx";

import composeWebService from "./data/composeWebservice";

import { ButtonToggle } from "./Buttons";
// import LibraryTextBox from "@library/common/components/base/form/TextBox";
// import LibraryDropDownType from "@library/common/components/base/form/DropDown/Type";
// import LibraryRadioButtons from "@library/common/components/base/form/RadioButtons";

import MUICheckbox from "@mui/material/Checkbox";

const BaseTextBox = (props) => {
	const {
		value = "",
		onChange,
		onType,
		onBlur,
		regEx,
		id: _id,
		enableAutoFill = false,
		autoFillKey = undefined, // By default, use the HOOKS's default
		...otherProps
	} = props;
	const [curValue, setCurValue] = useState(value);
	const [updateCount, setUpdateCount] = useState(0);
	const [id] = useState(_id || _.uniqueId("BaseTextBox"));

	const checkRegExFilter = (text) => {
		if (!regEx) return true;
		return regEx.test(text);
	};

	const _onChange = (e) => {
		const newValue = e.target.value;
		if (!checkRegExFilter(newValue)) return;
		setCurValue(newValue);
		if (onType) onType(newValue);
	};

	const _onBlur = !onChange
		? onBlur
		: (e) => {
				const newValue = e.target.value;
				if (!checkRegExFilter(newValue)) return;
				onChange(newValue);
				setUpdateCount(updateCount + 1);
				if (onBlur) onBlur(e);
		  };

	useEffect(() => {
		setCurValue(value);
	}, [value, updateCount]);

	const autoFillProps = (function () {
		if (enableAutoFill) return useAutofill(onChange, autoFillKey);
		return {};
	})();

	return (
		<input
			id={id}
			onBlur={_onBlur}
			onChange={_onChange}
			value={curValue}
			{...otherProps}
			{...autoFillProps}
		/>
	);
};

const BaseDropDownType = (props) => {
	const {
		itemData,
		DataFunction,
		onChange,
		value,
		styles,
		isMultiOutputFullObject = false, // do we output {value:"", label""}
		disabled,
		isDisabled, // put here so it's NOT in otherProps
		className,
		classNamePrefix,
		...otherProps
	} = props;

	const getValueObject = (options, searchData, isMulti) => {
		if (!options) return;
		if (!searchData) return;

		if (!isMulti)
			return (
				// level 1 data
				options
					.filter((x) => !("options" in x))
					.find((x) => x.value === searchData) ||
				// nested data
				options
					.filter((x) => "options" in x)
					.flatMap((x) => getValueObject(x.options, searchData, isMulti))
					.filter((x) => x !== undefined)
					.find((x) => x.value === searchData)
			);

		// multi
		return _.concat(
			options
				.filter((x) => !("options" in x))
				.filter((x) => searchData.includes(x.value)),
			options
				.filter((x) => "options" in x)
				.flatMap((x) => getValueObject(x.options, searchData, isMulti))
				.filter((x) => x !== undefined)
				.filter((x) => searchData.includes(x.value))
		);
	};

	if (
		!(onChange && (itemData || DataFunction) && !(DataFunction && itemData))
	) {
		console.log("props:", props);
		const baseMsg = `Error in DropDownType`;

		if (DataFunction && itemData)
			throw `${baseMsg} -- you can't have both "DataFunction" and "itemData"`;

		throw `${baseMsg} -- missing a key prop`;
	}

	const [reactSelectOptions, setReactSelectOptions] = useState();

	// const [reactSelectValue, setReactSelectValue] = useState();
	const isOptionsLoaded = reactSelectOptions !== undefined;

	// *******************************************
	// handlers
	// *******************************************
	// custom filter function
	const filterOptions = useCallback((candidate, input) => {
		if (input === undefined) return true;

		if (
			candidate &&
			candidate.label &&
			candidate.label.toUpperCase().includes(input.toUpperCase())
		) {
			return true;
		}
		return false;
	}, []);

	// onChange handler
	const newOnChange = useCallback((data) => {
		if (data === undefined || data === null) {
			onChange(undefined);
			return;
		}

		if (props.isMulti) {
			if (isMultiOutputFullObject) onChange(data);
			else onChange(data.map((x) => x.value));
			return;
		}

		onChange(data.value);
	}, []);

	// *******************************************
	// hooks
	// *******************************************

	// init
	useDeepCompareEffect(() => {
		if (DataFunction) {
			DataFunction().then((response) => {
				setReactSelectOptions(response);
			});
		} else {
			setReactSelectOptions(itemData);
		}
	}, [itemData, DataFunction]);

	const reactSelectValue = useMemo(() => {
		if (!reactSelectOptions) return undefined; //Options might not be set yet, so exit out

		if (value === undefined) return null;

		if (props.isMulti) {
			const valueArray = getValueObject(
				reactSelectOptions,
				value,
				props.isMulti
			);
			return valueArray;
		} else {
			const valueItem = getValueObject(
				reactSelectOptions,
				value,
				props.isMulti
			);
			return valueItem;
		}
	}, [value, isOptionsLoaded, props.isMulti]);

	// handle value changes

	// console.log(
	// 	"dddd type.jsx reactSelectValue:",
	// 	reactSelectValue,
	// 	", reactSelectOptions:",
	// 	(reactSelectOptions || []).length
	// );

	if (!reactSelectOptions) return null; //NOTE: could add a "please wait" message here

	return (
		<ReactSelect
			options={reactSelectOptions}
			onChange={newOnChange}
			filterOption={filterOptions}
			value={reactSelectValue}
			styles={styles}
			isDisabled={disabled}
			className={className}
			classNamePrefix={classNamePrefix}
			{...otherProps}
		/>
	);
};

// const BaseRadioButtons = (props) => {
// 	const {
// 		itemData,
// 		itemClassName,
// 		itemClassNameSelected,
// 		itemClassNameUnselected,
// 		value,
// 		id = _.uniqueId("RadioButton"),
// 		name,
// 		onChange,
// 		disabled,
// 		...otherProps
// 	} = props;

// 	if (!itemData) return null;
// 	if (!id) {
// 		console.log("ERROR:", props);
// 		throw `Error in radio buttons -- missing id`;
// 	}

// 	return (
// 		<>
// 			{itemData.map(({ label, value: itemValue }, i) => {
// 				const checked = itemValue === value;
// 				const itemId = `${id}_${i}`;

// 				const curClassName = [
// 					// "btn",
// 					// "form-label",
// 					itemClassName,
// 					checked ? itemClassNameSelected : itemClassNameUnselected,
// 				]
// 					.filter((x) => x)
// 					.join(" ");

// 				return (
// 					<ButtonToggle
// 						key={itemId}
// 						id={itemId}
// 						name={id}
// 						checked={checked}
// 						onClick={() => {
// 							if (checked) return;
// 							onChange(itemValue);
// 						}}
// 						disabled={disabled}
// 						className={curClassName}
// 					>
// 						{label}
// 					</ButtonToggle>
// 				);
// 			})}
// 		</>
// 	);
// };

export const TextBox = (props) => {
	const { className, ...otherProps } = props;

	const _className = ["form-control", className].filter(Boolean).join(" ");

	return (
		<BaseTextBox
			autoComplete="off"
			className={_className}
			{...generateCypressProps("textbox", props)}
			{...otherProps}
		/>
	);
};

export const TextBoxPassword = (props) => {
	const {
		metaData = {},
		ButtonToggle: FallBackButtonToggle = ButtonToggle,
		...otherProps
	} = props;

	const [isVisible, setIsVisible] = useState(false);

	const _type = isVisible ? "text" : "password";

	const _classNameToggle = [
		isVisible
			? metaData?.className?.buttonShow || "bi-eye"
			: metaData?.className?.buttonHide || "bi-eye-slash",
	]
		.filter(Boolean)
		.toString(" ");

	return (
		<div className="input-group">
			<TextBox {...otherProps} type={_type} />
			<FallBackButtonToggle
				checked={isVisible}
				onClick={() => setIsVisible(!isVisible)}
				className={_classNameToggle}
			/>
		</div>
	);
};

export const TextBoxPostcode = (props) => {
	const { onChange, ...otherProps } = props;
	const _onChange = (v) => {
		const _v = tidyPostcode(v);
		onChange(_v);
	};
	return <TextBox {...otherProps} onChange={_onChange} />;
};

export const TextBoxTelephone = (props) => {
	const { className, ...otherProps } = props;
	return (
		<TextBox {...otherProps} className={className} regEx={/^(\s*|\d+)$/} />
	);
};

export const TextBoxMobile = (props) => {
	const { className, ...otherProps } = props;
	return (
		<TextBox
			{...otherProps}
			className={className}
			regEx={/^(\s*|\d+)$/}
			maxLength={11}
		/>
	);
};

export const TextBoxCurrency = (props) => {
	const { className, ...otherProps } = props;

	return (
		<TextBox {...otherProps} className={className} regEx={/^(\s*|\d+)$/} />
	);
};

export const TextBoxNumber = (props) => {
	const { className, ...otherProps } = props;

	return (
		<TextBox {...otherProps} className={className} regEx={/^(\s*|\d+)$/} />
	);
};

const dropdownStyle = {
	container: (base) => ({
		...base,
		paddingLeft: 0,
		paddingRight: 0,
	}),
	groupHeading: (base) => ({
		...base,
		color: "green",
		fontWeight: "bold",
	}),
	menu: (base) => ({
		...base,
		zIndex: 20, // make sure it's on top of everything else
	}),
};
export const DropDownType = (props) => {
	const { className = "", ...otherProps } = props;

	const _className = [className, "react-select", "align-middle"]
		.filter(Boolean)
		.join(" ");

	// console.log("DropDown", props);

	const newProps = {
		className: _className,
		classNamePrefix: "dropdown-type",
		styles: dropdownStyle,
		isClearable: true,
		...otherProps,
	};
	// console.log("dsadsad DropDownType", newProps);
	return (
		<BaseDropDownType
			{...newProps}
			{...generateCypressProps("dropdown", props)}
		/>
	);
};

export const DropDownTypeWebService = (props) => {
	const { DataFunction, ...otherProps } = props;
	return <DropDownType {...otherProps} DataFunction={DataFunction} />;
};

export const RadioButtons = (props) => {
	const {
		className,
		itemData = [],
		id: _id,
		value,
		onChange,
		questionText = "",
	} = props;

	const [id] = useState(_id || _.uniqueId("RadioButtons"));

	if (itemData.length === 0) return null;

	return (
		<div
			className={["radio-buttons", className].filter(Boolean).join(" ")}
			role="group"
			aria-label={questionText}
			{...generateCypressProps("radio-buttons", props)}
		>
			{itemData.map((x, i) => {
				// Name needs to be the same for all radio buttons within a group
				const itemName = x.id || [id, "btnradio"].join("_");
				// Each button should have a unique id
				const itemId = [itemName, i].join("_");
				const isChecked = _.isEqual(x.value, value);
				const hasIcon = x.hasIcon === true;

				const lblClassName = [
					"btn",
					"btn-outline-secondary",
					hasIcon ? "btn-icon" : "",
				]
					.filter(Boolean)
					.join(" ");

				return (
					<React.Fragment key={itemId}>
						<input
							type="radio"
							className="btn-check"
							name={itemName}
							id={itemId}
							autoComplete="off"
							onClick={() => onChange(x.value)}
							checked={isChecked}
							readOnly={true}
							data-cy={`btn:${x.value}`}
						/>
						<label
							className={lblClassName}
							htmlFor={itemId}
							data-cy={`btn-lbl:${x.value}`}
						>
							{x.label}
						</label>
					</React.Fragment>
				);
			})}
		</div>
	);

	// return (
	// 	<div
	// 		className={_className}
	// 		{...generateCypressProps("radio-buttons", props)}
	// 	>
	// 		<BaseRadioButtons
	// 			itemClassName={_itemClassName} // the label
	// 			// itemClassNameSelected={_itemClassNameSelected}
	// 			// itemClassNameUnselected={_itemClassNameUnselected}
	// 			itemData={_itemData}
	// 			{...otherProps}
	// 		/>
	// 	</div>
	// );
};

export const RadioButtonsWebService = composeWebService({
	DataComponent: RadioButtons,
	dataKey: "itemData",
});

export const Checkbox = (props) => {
	const {
		checked = false,
		onSelect = () => {},
		onUnselect = () => {},
		disabled,
		...otherProps
	} = props;

	const sxData = {
		padding: 0,
		margin: 0,
	};

	return (
		<MUICheckbox
			checked={checked}
			onChange={(e) => {
				const _checked = e.target.checked;
				if (_checked) onSelect();
				else onUnselect();
			}}
			disabled={disabled}
			{...otherProps}
			sx={sxData}
			className="mui-checkbox"
		/>
	);
};

export const CheckboxButton = (props) => {
	const {
		value = false,
		checked = false,
		onChange = () => {
			console.log("ERROR INFO:", props);
			throw `Error in CheckboxButton -- missing onChange() prop`;
		},
		...otherProps
	} = props;

	const isChecked = value || checked;

	return (
		<ButtonToggle
			onClick={() => onChange(!isChecked)}
			checked={isChecked}
			{...otherProps}
		/>
	);
};

export const CheckboxWithLabel = (props) => {
	const {
		id,
		className,
		label,
		showError,
		errorMsg,
		isChecked,
		onSelect,
		onUnselect,
		onClick,
	} = props;

	const _className = ["row", className, showError ? "has-error" : ""]
		.filter(Boolean)
		.join(" ");

	return (
		<div {...generateCypressProps("checkbox", props)} className={_className}>
			<div className="col-auto">
				<Checkbox
					data-cy="checkbox"
					checked={isChecked}
					onSelect={onSelect}
					onUnselect={onUnselect}
					onClick={onClick}
					id={id}
				/>
			</div>
			<label className="col ps-0 checkbox-label" data-cy="label" htmlFor={id}>
				{label}
			</label>
			{showError && (
				<div className="col-12">
					<div className="form-text error-text mt-2" data-cy="feedback:error">
						{errorMsg}
					</div>
				</div>
			)}
		</div>
	);
};

export const SortCode = (props) => {
	["value", "onChange"].forEach((x) => {
		if (!(x in props)) {
			console.log("Error Info:", { props });
			throw `Error in Sortcode -- missing a prop: ${x}`;
		}
	});
	const { onChange, value = "", id: _id, disabled } = props;

	const [id] = useState(_id || _.uniqueId("SortCode"));

	const [pt1, setPt1] = useState();
	const [pt2, setPt2] = useState();
	const [pt3, setPt3] = useState();

	useEffect(() => {
		//** if not valid, then return "undefined"
		if (value === undefined || isNaN(value) || value.length !== 6) {
			onChange(undefined);
			return;
		}

		// ...else split "value"
		setPt1(value.slice(0, 2));
		setPt2(value.slice(2, 4));
		setPt3(value.slice(4, 6));
	}, [value]);

	const fnUpdate = (section, value) => {
		//NOTE: won't be actually updated until next render (i.e. after this function has finished)
		if (section === "1") setPt1(value);
		if (section === "2") setPt2(value);
		if (section === "3") setPt3(value);

		const _pt1 = section === "1" ? value : pt1;
		const _pt2 = section === "2" ? value : pt2;
		const _pt3 = section === "3" ? value : pt3;

		// check all 3 parts
		const isValid = ![_pt1, _pt2, _pt3].some(
			(x) => x === undefined || isNaN(x) || x.length !== 2
		);

		if (!isValid) {
			onChange(undefined);
			return;
		}
		onChange(`${_pt1}${_pt2}${_pt3}`);
	};

	return (
		<div className="row">
			<div className="col">
				<TextBox
					value={pt1}
					onChange={(v) => fnUpdate("1", v)}
					regEx={regExConstants.numbers.whole}
					maxLength="2"
					size="2"
					id={`${id}-1`}
					autoComplete="off"
					className="text-center"
					disabled={disabled}
					aria-label="First two digits of sort code"
				/>
			</div>
			<div className="col-auto align-middle p-0 m-0 px-1 my-auto fw-bold">
				-
			</div>
			<div className="col">
				<TextBox
					value={pt2}
					onChange={(v) => fnUpdate("2", v)}
					regEx={regExConstants.numbers.whole}
					maxLength="2"
					size="2"
					id={`${id}-2`}
					autoComplete="off"
					className="text-center"
					disabled={disabled}
					aria-label="Second two digits of sort code"
				/>
			</div>
			<div className="col-auto align-middle p-0 m-0 px-1 my-auto fw-bold">
				-
			</div>
			<div className="col">
				<TextBox
					value={pt3}
					onChange={(v) => fnUpdate("3", v)}
					regEx={regExConstants.numbers.whole}
					maxLength="2"
					size="2"
					id={`${id}-3`}
					autoComplete="off"
					className="text-center"
					disabled={disabled}
					aria-label="Last two digits of sort code"
				/>
			</div>
		</div>
	);
};

export const InputGroup = (props) => {
	const { className, children, text, start = true } = props;

	const _className = ["input-group", className].filter(Boolean).join(" ");

	return (
		<div>
			<div
				className={_className}
				{...generateCypressProps("input-group", props)}
			>
				{start && <span className="input-group-text">{text}</span>}
				{children}
				{!start && <span className="input-group-text">{text}</span>}
			</div>
		</div>
	);
};

export const InputGroupPound = (props) => {
	const { className, children, ...otherProps } = props;

	const _className = ["input-group", className].filter(Boolean).join(" ");

	return (
		<div>
			<div
				className={_className}
				{...generateCypressProps("input-group-pound", props)}
			>
				<span className="input-group-text">&pound;</span>
				{children}
			</div>
		</div>
	);
};
