import React from "react";
import templates from "./templates/gap";
import generateBaseRuleData from "./generateBaseRuleData";

import { list, set, item } from "@library/common/database/classes";

import constants from "libraryConstants/database";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import regExConstants from "@library/common/constants/regEx";
import composeDatabase from "@library/common/database/functions/composeDatabase";
import moment from "moment";

/*
	regex for cloning a tag:
	search: 			// componentTag:  "(.*?)"
	replace: 			// componentTag:  "$1", submitKey: "$1"

*/

// note: composeDatabase does the following --
//
// 1) adds "componentTag" to all the nodes (based on the node's key)
// 2) adds "componentSet" based on the "componentTag" tree, stopping on anything after a "list"

const riskLogic = new ruleItem(
	{
		...generateBaseRuleData("vehicle", "riskLogic"),
	},
	{
		VehicleExtraDetailsConfirmation: new dataSetArgItem({
			componentTag: "VehicleExtraDetailsConfirmation",
			componentSet: "Risk/RiskData",
		}),
		PolicyInceptionDate: new dataSetArgItem({
			componentSet: "Risk",
			componentTag: "PolicyInceptionDate",
		}),
	}
);

const ruleItemNcbLogic = new ruleItem(
	{
		...generateBaseRuleData("vehicle", "ncbLogic"),
	},
	{
		ProtectedBonus: new dataSetArgItem({
			componentTag: "ProtectedNcd",
			useDefaultComponentSet: true,
		}),
		Years: new dataSetArgItem({
			componentTag: "YearsNcd",
			useDefaultComponentSet: true,
		}),
		AllowProtectedNcd: new dataSetArgItem({
			componentTag: "AllowProtectedNcd",
			useDefaultComponentSet: true,
		}),
		DrivingExperienceYears: new dataSetArgItem({
			componentTag: "DrivingExperienceYears",
			useDefaultComponentSet: true,
		}),
		DrivingExperienceType: new dataSetArgItem({
			componentTag: "DrivingExperienceType",
			useDefaultComponentSet: true,
		}),
	}
);

const ruleItemAdditionalDriverLogic = new ruleItem(
	{
		...generateBaseRuleData("vehicle", "additionalDriverLogic"),
	},
	{
		RelationshipToProposer: new dataSetArgItem({
			componentTag: "RelationshipToProposer",
			useDefaultComponentSet: true,
		}),
		MaritalStatus: new dataSetArgItem({
			componentTag: "MaritalStatus",
			useDefaultComponentSet: true,
		}),
		DateOfBirth: new dataSetArgItem({
			componentTag: "DateOfBirth",
			useDefaultComponentSet: true,
		}),
		ProposerDateOfBirth: new dataSetArgItem({
			componentTag: "DateOfBirth",
			componentSet: "Risk/Proposer",
		}),

		ProposerMaritalStatus: new dataSetArgItem({
			componentTag: "MaritalStatus",
			componentSet: "Risk/Proposer",
		}),

		ProposerForenames: new dataSetArgItem({
			componentTag: "Forenames",
			componentSet: "Risk/Proposer/Name",
		}),

		ProposerSurname: new dataSetArgItem({
			componentTag: "Surname",
			componentSet: "Risk/Proposer/Name",
		}),

		ProposerTitle: new dataSetArgItem({
			componentTag: "Title",
			componentSet: "Risk/Proposer/Name",
		}),
	}
);

const startDateRule = new ruleItem(
	{
		...generateBaseRuleData("vehicle", "startDateLogic"),
	},
	{
		PolicyInceptionDate: new dataSetArgItem({
			componentTag: "PolicyInceptionDate",
			useDefaultComponentSet: true,
		}),
		EndDate: new dataSetArgItem({
			componentTag: "EndDate",
			useDefaultComponentSet: true,
		}),
	}
);

const vehicleLogicRule = templates.Vehicle.generateLogicRule(
	{
		Postcode: new dataSetArgItem({
			componentTag: "Postcode",
			componentSet: "Risk/Proposer/Address",
		}),
		PolicyInceptionDate: new dataSetArgItem({
			useDefaultComponentSet: true,
			componentTag: "PolicyInceptionDate",
		}),
	},
	//componentSetPath
	["Vehicle"]
);

const database = composeDatabase({
	Risk: new set({
		// componentSet: "Risk",
		submitKey: "Risk",
		items: {
			//todo:
			// "PreviousInsurance": {
			// 	"Ncb": {
			// 		"Years": 9,
			// 		"ProtectedBonus": true
			// 	},
			// 	"PreviousPolicyBonusProtected": true,
			// 	"ExpiryDate": "2020-12-20T12:00:31.8070827+00:00",
			// 	"YearsHeld": "9",
			// 	"Type": "GV"
			// },

			PreviousInsurance: new set({
				submitKey: "PreviousInsurance",
				items: {
					Ncb: new set({
						submitKey: "Ncb",
						items: {
							Years: new item({
								submitKey: "Years",
								exampleValue: "20000",
							}),
							ProtectedBonus: new item({
								submitKey: "ProtectedBonus",
							}),
							AppliedToAnotherVehicle: new item({
								submitKey: "AppliedToAnotherVehicle",
								defaultValue: false,
							}),
						},
					}),

					PreviousPolicyBonusProtected: new item({
						submitKey: "PreviousPolicyBonusProtected",
						defaultValue: false,
					}),

					ExpiryDate: new item({
						submitKey: "ExpiryDate",
						// fnReduxToSalus: (v) =>
						// 	v
						// 		? v
						// 		: reduxToSalusHelpers.processDate(moment().subtract(1, "days")),
						// fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),
					}),
					YearsHeld: new item({
						submitKey: "YearsHeld",
					}),
					Type: new item({
						submitKey: "Type",
					}),
					InsurerName: new item({
						submitKey: "InsurerName",
					}),
					// PreviousPolicyBonusProtected: new item({
					// 	submitKey: "PreviousPolicyBonusProtected",
					// 	rules: [
					// 		new ruleItem({
					// 			...generateBaseRuleData("general", "isRequired"),},{
					// 			errorMessage: "", // errorMessage: "Required",
					// 		}),
					// 	],
					// }),

					// ExpiryDate: new item({
					// 	submitKey: "ExpiryDate",
					// 	rules: [
					// 		new ruleItem({
					// 			...generateBaseRuleData("general", "isRequired"),},{
					// 			errorMessage: "", // errorMessage: "Required",
					// 		}),
					// 	],
					// }),
					// YearsHeld: new item({
					// 	submitKey: "YearsHeld",
					// 	rules: [
					// 		new ruleItem({
					// 			...generateBaseRuleData("general", "isRequired"),},{
					// 			errorMessage: "", // errorMessage: "Required",
					// 		}),
					// 	],
					// }),
					// Type: new item({
					// 	submitKey: "Type",
					// 	rules: [
					// 		new ruleItem({
					// 			...generateBaseRuleData("general", "isRequired"),},{
					// 			errorMessage: "", // errorMessage: "Required",
					// 		}),
					// 	],
					// }),
				},
			}),

			Cover: new item({
				submitKey: "Cover",
				defaultValue: "C",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			TotalMileage: new item({
				submitKey: "TotalMileage",
				label: "Estimated annual mileage",

				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			BusinessMileage: new item({
				submitKey: "BusinessMileage",
				label: "Business mileage",
				// rules: [
				// 	new ruleItem({
				// 		...generateBaseRuleData("general", "isRequired"),},{
				// 		errorMessage: "", // errorMessage: "Required",
				// 	}),
				// 	require("./templates").default.Policy.logicRule,
				// ],
			}),

			//** defaulted on backend */
			Restrict: new item({
				submitKey: "Restrict",
			}),

			//** defaulted on backend */
			// Restrict: new item({
			// 	submitKey: "Restrict",
			// 	// defaultValue: "INSONLY",
			// 	rules: [
			// 		new ruleItem({
			// 			...generateBaseRuleData("general", "isRequired"),},{
			// 			errorMessage: "", // errorMessage: "Required",
			// 		}),
			// 	],
			// }),

			ClassOfUse: new item({
				// componentTag:  "ClassOfUse",
				submitKey: "ClassOfUse",
				label: "How will you use the van?",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
					templates.Policy.logicRule,
				],
			}),

			Vehicle: templates.Vehicle.generateSet(
				{
					additionalMappings: {
						Postcode: new dataSetArgItem({
							componentTag: "Postcode",
							componentSet: "Risk/Proposer/Address",
						}),
						PolicyInceptionDate: new dataSetArgItem({
							componentSetPath: ["..."],
							componentTag: "PolicyInceptionDate",
						}),
					},
				}

				// // modify items:
				// [
				// 	{
				// 		componentTag: "Purchased",
				// 		key: "rules",
				// 		data: [
				// 			new ruleItem({
				// 				...generateBaseRuleData("general", "dateComparison"),},{
				// 				errorMessage:
				// 					"Your policy start cannot be before the vehicle purchase date.",
				// 				compareDate: new dataSetArgItem({
				// 					componentSetPath: ["..."],
				// 					componentTag: "PolicyInceptionDate",
				// 				}),
				// 				comparator: "<=",
				// 			}),
				// 		],
				// 	},
				// ]
			),

			ExtraDriverDetails: new set({
				submitKey: "ExtraDriverDetails",
				items: {
					DriverType: new item({
						submitKey: "DriverType",
						defaultValue: "GV",
					}),

					YearsNcd: new item({
						submitKey: "YearsNcd",
						label:
							"How many years no claims discount do you have on a van insurance policy in your name?",
						//helpText: ,
						helpTextFurther: (
							<>
								<p>
									Every year you hold a policy in your own name without making a
									claim, you build up a no claims discount which saves you
									money. This proof should be a renewal notice from your
									previous Insurance Company, or a letter on headed paper from
									the Insurance Company.
								</p>
								<p>
									N.B. This no claims discount can only be used on one policy at
									any one time, and must have been used on a policy within the
									last two years.
								</p>
							</>
						),
						html: {
							itemData: [
								{ label: "0 years", value: "0" },
								{ label: "1 year", value: "1" },
								{ label: "2 years", value: "2" },
								{ label: "3 years", value: "3" },
								{ label: "4 years", value: "4" },
								{ label: "5 years", value: "5" },
								{ label: "6 years", value: "6" },
								{ label: "7 years", value: "7" },
								{ label: "8 years", value: "8" },
								{ label: "9 years or over", value: "9" },
							],
						},
						rules: [
							new ruleItem(
								{
									...generateBaseRuleData("general", "isRequired"),
								},
								{
									errorMessage: "", // errorMessage: "Required",
								}
							),
							ruleItemNcbLogic,
						],
					}),
					ProtectedNcd: new item({
						submitKey: "ProtectedNcd",
						label: "Would you like to protect your no claims discount?",
						html: {
							itemData: constants.lists.yesNo,
						},
						rules: [ruleItemNcbLogic],
					}),
					AllowProtectedNcd: new item({
						submitKey: "AllowProtectedNcd",
						defaultValue: true,
						rules: [ruleItemNcbLogic],
					}),
					DrivingExperienceType: new item({
						submitKey: "DrivingExperienceType",
						label:
							"Do you have any named driver experience on another van insurance policy?",
						rules: [ruleItemNcbLogic],
					}),

					DrivingExperienceYears: new item({
						submitKey: "DrivingExperienceYears",
						label: "How many years claim free driving experience do you have?",
						html: {
							itemData: [
								{ label: "0 years", value: "0" },
								{ label: "1 year", value: "1" },
								{ label: "2 years", value: "2" },
								{ label: "3 years", value: "3" },
								{ label: "4 years", value: "4" },
								{ label: "5 years", value: "5" },
								{ label: "6 years", value: "6" },
								{ label: "7 years", value: "7" },
								{ label: "8 years", value: "8" },
								{ label: "9 years or over", value: "9" },
							],
						},
						rules: [ruleItemNcbLogic],
					}),
					OwnAndInsureAnotherVehicle: new item({
						submitKey: "OwnAndInsureAnotherVehicle",
						label:
							"Do you own and insure another vehicle, excluding motorcycles?",
						html: {
							itemData: constants.lists.yesNo,
						},
						rules: [
							new ruleItem(
								{
									...generateBaseRuleData("vehicle", "ncbOtherVehicleLogic"),
								},
								{
									OwnAndInsureAnotherVehicle: new dataSetArgItem({
										componentTag: "OwnAndInsureAnotherVehicle",
										useDefaultComponentSet: true,
									}),
									OtherVehicleYearsNcd: new dataSetArgItem({
										componentTag: "OtherVehicleYearsNcd",
										useDefaultComponentSet: true,
									}),
								}
							),
						],
					}),

					OtherVehicleYearsNcd: new item({
						submitKey: "OtherVehicleYearsNcd",
						label:
							"How many years no claims discount do you have on your other vehicle?",
						html: {
							itemData: [
								{ label: "0 years", value: "0" },
								{ label: "1 year", value: "1" },
								{ label: "2 years", value: "2" },
								{ label: "3 years", value: "3" },
								{ label: "4 years", value: "4" },
								{ label: "5 years", value: "5" },
								{ label: "6 years", value: "6" },
								{ label: "7 years", value: "7" },
								{ label: "8 years", value: "8" },
								{ label: "9 years or over", value: "9" },
							],
						},
						rules: [
							new ruleItem(
								{
									...generateBaseRuleData("vehicle", "ncbOtherVehicleLogic"),
								},
								{
									OwnAndInsureAnotherVehicle: new dataSetArgItem({
										componentTag: "OwnAndInsureAnotherVehicle",
										useDefaultComponentSet: true,
									}),
									OtherVehicleYearsNcd: new dataSetArgItem({
										componentTag: "OtherVehicleYearsNcd",
										useDefaultComponentSet: true,
									}),
								}
							),
						],
					}),

					HasPublicLiability: new item({
						submitKey: "HasPublicLiability",
						label: "Do you have Public Liability insurance cover in place?",
						html: {
							itemData: constants.lists.yesNo,
						},
						rules: [
							new ruleItem(
								{
									...generateBaseRuleData("general", "isRequired"),
								},
								{
									errorMessage: "", // errorMessage: "Required",
								}
							),
						],
					}),
					CompanyName: new item({
						submitKey: "CompanyName",
					}),

					MonthsCompanyEstablished: new item({
						submitKey: "MonthsCompanyEstablished",
						label: "How many months has your business been established?",

						html: { regEx: regExConstants.numbers.whole, maxLength: 10 },

						rules: [],
					}),

					IsMemberOfTradeOrganisation: new item({
						submitKey: "IsMemberOfTradeOrganisation",
					}),
					NameOfDeliveryCompany: new item({
						submitKey: "NameOfDeliveryCompany",
					}),
					NumberOfParcelDropsPerDay: new item({
						submitKey: "NumberOfParcelDropsPerDay",
					}),
				},
			}),

			Frequency: new item({
				submitKey: "Frequency",
				defaultValue: "PlcyAnnual",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			PolicyTypeCode: new item({
				submitKey: "PolicyTypeCode",
				defaultValue: "GV",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			VolXs: new item({
				submitKey: "VolXs",
				exampleValue: "200",
				label: "Voluntary excess",
				helpText: (
					<>
						<span className="d-block">
							A voluntary excess can help to reduce your premium.{" "}
						</span>
						<span>
							This will be in addition to the compulsary excess which would be
							paid in the event of a claim.
						</span>
					</>
				),
				html: {
					itemData: [
						{ label: "£0", value: "0" },
						{ label: "£100", value: "100" },
						{ label: "£200", value: "200" },
						{ label: "£300", value: "300" },
						{ label: "£400", value: "400" },
						{ label: "£500", value: "500" },
					],
				},
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			// StartDate: new item({
			// 	submitKey: "StartDate",
			// 	exampleValue: "2020-10-22T00:00:00+01:00",
			// 	rules: [
			// 		new ruleItem({
			// 			...generateBaseRuleData("general", "isRequired"),},{
			// 			errorMessage: "", // errorMessage: "Required",
			// 		}),
			// 		new ruleItem({
			// 			...generateBaseRuleData("general", "dateComparison"),},{
			// 			errorMessage: "Your policy start date cannot be in the past",
			// 			today: true,
			// 			comparator: ">=",
			// 		}),
			// 		startDateRule,
			// 	],
			// }),
			// RenewalDate: new item({
			// 	// componentTag:  "RenewalDate",
			// 	submitKey: "RenewalDate",
			// 	exampleValue: "2021-10-22T00:00:00+01:00",
			// }),
			// QuoteDate: new item({
			// 	submitKey: "QuoteDate",
			// 	exampleValue: "2020-10-21T15:17:14.5865846+01:00",
			// 	// fnReduxToSalus: () => new Date(),
			// 	fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
			//	fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),

			// 	rules: [
			// 		new ruleItem({
			// 			...generateBaseRuleData("general", "salusConvertStringToDate"),},{
			// 			runOnUserUpdate: false,
			// 			forceUpdate: true,
			// 		}),
			// 		new ruleItem({
			// 			...generateBaseRuleData("general", "isRequired"),},{
			// 			errorMessage: "", // errorMessage: "Required",
			// 		}),
			// 	],
			// }),
			// EndDate: new item({
			// 	submitKey: "EndDate",
			// 	fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
			// 	fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),
			// 	rules: [
			// 		new ruleItem({
			// 			...generateBaseRuleData("general", "isRequired"),},{
			// 			errorMessage: "", // errorMessage: "Required",
			// 		}),
			// 	],
			// }),
			QuoteType: new item({
				submitKey: "QuoteType",
				defaultValue: "NewQuotTyp",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			PolicyInceptionDate: new item({
				submitKey: "PolicyInceptionDate",
				exampleValue: "2020-10-22T00:00:00+01:00",
				label: "When would you like your policy to start?",
				helpText:
					"You can choose a date up to a maximum of 30 days from today.",
				fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
				fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),

				rules: [
					// new ruleItem({
					// 	...generateBaseRuleData("general", "salusConvertStringToDate"),},{
					// 	runOnUserUpdate: false,
					// 	forceUpdate: true,
					// }),
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),

					new ruleItem(
						{
							...generateBaseRuleData("general", "dateComparison"),
						},
						{
							errorMessage: "Your policy start date cannot be in the past",
							today: true,
							comparator: ">=",
						}
					),
					// new ruleItem({
					// 	...generateBaseRuleData("general", "dateComparison"),},{
					// 	errorMessage:
					// 		"Your policy start cannot be before the vehicle purchase date.",
					// 	compareDate: new dataSetArgItem({
					// 		componentSetPath: ["Vehicle"],
					// 		componentTag: "Purchased",
					// 	}),
					// 	comparator: ">=",
					// }),
					new ruleItem(
						{
							...generateBaseRuleData("general", "runRules"),
						},
						{
							componentTagList: ["DateOfBirth"],
						}
					),
					startDateRule,
					vehicleLogicRule,
					riskLogic, // Needed so that the rule runs on a RECALL
				],
			}),

			Proposer: templates.PersonProposer.generateSet(
				{
					submitKey: "Proposer",

					modifyItem: [
						{
							componentTag: "VehicleUse",
							key: "rules",
							data: templates.PersonAddDriver.generateVehicleUseRule({
								ComponentTagVehicleUse: "VehicleUse",
								AdditionalInsuredSet: new dataSetArgItem({
									componentTag: "AdditionalInsuredSet",
									componentSetPath: [".."],
								}),
								MainUserComponentId: new dataSetArgItem({
									componentTag: "MainUserComponentId",
									componentSetPath: [".."],
								}),
								ProposerVehicleUse: new dataSetArgItem({
									componentTag: "VehicleUse",
									useDefaultComponentSet: true,
								}),
								VehicleUseCurrent: new dataSetArgItem({
									componentTag: "VehicleUse",
									useDefaultComponentSet: true,
								}),
							}),
						},
					],
				},
				{
					AdditionalInsuredSet: new dataSetArgItem({
						componentTag: "AdditionalInsuredSet",
						componentSet: [".."],
					}),
					AdditionalInsuredSet_RelationshipToProposer_TAG:
						"RelationshipToProposer",
					defaultLicenceCategoryValue: "CatC1",
				}
			),

			AdditionalInsuredSet: templates.PersonAddDriver.generateList(
				{
					submitKey: "AdditionalInsuredSet",
					rules: [
						templates.PersonAddDriver.generateVehicleUseRule({
							ComponentTagVehicleUse: "VehicleUse",
							AdditionalInsuredSet: new dataSetArgItem({
								componentTag: "AdditionalInsuredSet",
								useDefaultComponentSet: true,
							}),
							MainUserComponentId: new dataSetArgItem({
								componentTag: "MainUserComponentId",
								useDefaultComponentSet: true,
							}),
							ProposerVehicleUse: new dataSetArgItem({
								componentTag: "VehicleUse",
								componentSetPath: ["Proposer"],
							}),
						}),
					],
					modifyItem: [
						{
							componentTag: "VehicleUse",
							key: "rules",
							data: templates.PersonAddDriver.generateVehicleUseRule({
								ComponentTagVehicleUse: "VehicleUse",
								AdditionalInsuredSet: new dataSetArgItem({
									componentTag: "AdditionalInsuredSet",
									componentSetPath: [".."],
								}),
								MainUserComponentId: new dataSetArgItem({
									componentTag: "MainUserComponentId",
									componentSetPath: [".."],
								}),
								ProposerVehicleUse: new dataSetArgItem({
									componentTag: "VehicleUse",
									componentSetPath: ["..", "Proposer"],
								}),
								VehicleUseCurrent: new dataSetArgItem({
									componentTag: "VehicleUse",
									useDefaultComponentSet: true,
								}),
							}),
						},
					],
				},
				{
					ruleItemAdditionalDriverLogic,
					defaultLicenceCategoryValue: "CatC1",
				}
			),

			MainUserComponentId: new item({
				label: "Who is the main user of the vehicle?",
				rules: [
					templates.PersonAddDriver.generateVehicleUseRule({
						ComponentTagVehicleUse: "VehicleUse",
						AdditionalInsuredSet: new dataSetArgItem({
							componentTag: "AdditionalInsuredSet",
							useDefaultComponentSet: true,
						}),
						MainUserComponentId: new dataSetArgItem({
							componentTag: "MainUserComponentId",
							useDefaultComponentSet: true,
						}),
						ProposerVehicleUse: new dataSetArgItem({
							componentTag: "VehicleUse",
							componentSetPath: ["Proposer"],
						}),
					}),
					// new ruleItem({
					// 	...generateBaseRuleData("general", "isRequired"),},{
					// 	errorMessage: "", // errorMessage: "Required",
					// }),
				],
			}),

			Userfields: new list({
				submitKey: "Userfields",
				template: {
					Code: new item({
						submitKey: "Code",
						exampleValue: "COMMEM",
					}),
					Response: new item({
						submitKey: "Response",
						exampleValue: "Yes",
					}),
					ShortResponse: new item({
						submitKey: "ShortResponse",
						exampleValue: "YES",
					}),
					IsList: new item({
						submitKey: "IsList",
					}),
					ScreenPrompt: new item({
						submitKey: "ScreenPrompt",
					}),
				},
			}),
			// Userfields: new list({
			// 	submitKey: "Userfields",
			// 	template: {
			// 		Code: new item({
			// 			submitKey: "Code",
			// 			exampleValue: "COMMEM",
			// 			rules: [
			// 				new ruleItem({
			// 					...generateBaseRuleData("general", "isRequired"),},{
			// 					errorMessage: "", // errorMessage: "Required",
			// 				}),
			// 			],
			// 		}),
			// 		Response: new item({
			// 			submitKey: "Response",
			// 			exampleValue: "Yes",
			// 			rules: [
			// 				new ruleItem({
			// 					...generateBaseRuleData("general", "isRequired"),},{
			// 					errorMessage: "", // errorMessage: "Required",
			// 				}),
			// 			],
			// 		}),
			// 		ShortResponse: new item({
			// 			submitKey: "ShortResponse",
			// 			exampleValue: "YES",
			// 			rules: [
			// 				//new ruleItem({
			// 				//	...generateBaseRuleData("general", "isRequired"),
			// 				//	errorMessage: "", // errorMessage: "Required",
			// 				//}),
			// 			],
			// 		}),
			// 	},
			// }),

			MedicalConsentQuestionsActive: new item({
				submitKey: "MedicalConsentQuestionsActive",
				exampleValue: false,
				// rules: [
				// 	new ruleItem({
				// 		...generateBaseRuleData("general", "isRequired"),},{
				// 		errorMessage: "", // errorMessage: "Required",
				// 	}),
				// ],
			}),
			ConvictionsConsentQuestionsActive: new item({
				submitKey: "ConvictionsConsentQuestionsActive",
				exampleValue: false,
				// rules: [
				// 	new ruleItem({
				// 		...generateBaseRuleData("general", "isRequired"),},{
				// 		errorMessage: "", // errorMessage: "Required",
				// 	}),
				// ],
			}),

			MarketingConsent: new set({
				submitKey: "MarketingConsent",
				items: {
					Email: new item({
						submitKey: "Email",
					}),
					Post: new item({
						submitKey: "Post",
					}),
					Phone: new item({
						submitKey: "Phone",
					}),
					Sms: new item({
						submitKey: "Sms",
					}),
					HouseholdRenewal: new item({
						submitKey: "HouseholdRenewal",
					}),
					PetRenewal: new item({
						submitKey: "PetRenewal",
					}),
					PublicLiabilityRenewal: new item({
						submitKey: "PublicLiabilityRenewal",
					}),
				},
			}),

			SourceOfBusiness: new item({
				submitKey: "SourceOfBusiness",
				defaultValue: "Website",
				exampleValue: null,
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),
			SourceOfEnquiry: new item({
				submitKey: "SourceOfEnquiry",
				// defaultValue: "DRCT",
				exampleValue: null,
				// rules: [
				// 	new ruleItem({
				// 		...generateBaseRuleData("general", "isRequired"),},{
				// 		errorMessage: "", // errorMessage: "Required",
				// 	}),
				// ],
			}),

			Duqs: new list({
				submitKey: "Duqs",
				template: {
					Id: new item({
						// componentTag:  "Id",
						submitKey: "Id",
						exampleValue: null,
					}),
					QuestionNumber: new item({
						// componentTag:  "QuestionNumber",
						submitKey: "QuestionNumber",
						exampleValue: "64",
					}),
					Response: new item({
						// componentTag:  "Response",
						submitKey: "Response",
						exampleValue: "Diesel",
					}),
					ShortResponse: new item({
						// componentTag:  "ShortResponse",
						submitKey: "ShortResponse",
						exampleValue: "D",
					}),
					ScreenPrompt: new item({
						// componentTag:  "ScreenPrompt",
						submitKey: "ScreenPrompt",
						exampleValue: null,
					}),
				},
			}),

			DrivingExperience: new set({
				submitKey: "DrivingExperience",
				items: {
					Type: new item({
						submitKey: "Type",
					}),
					YearsClaimFree: new item({
						submitKey: "YearsClaimFree",
					}),
				},
			}),

			// **************************************
			// ** Custom values
			// **************************************
			RiskData: new set({
				items: {
					VehicleExtraDetailsConfirmation: new item({
						label: "Are these details correct?",
						html: {
							itemData: constants.lists.yesNo,
						},
						rules: [riskLogic],
					}),
				},
			}),

			QuoteData: new set({
				items: {
					MainTelephoneConfirmed: new item({}),
					EmailConfirmed: new item({}),
				},
			}),
			// ** Below may  not be needed

			// Context: new item({
			// 	// componentTag:  "Context",
			// 	submitKey: "Context",
			// 	exampleValue: null,
			// }),

			// TrailerCover: new item({
			// 	// componentTag:  "TrailerCover",
			// 	submitKey: "TrailerCover",
			// 	exampleValue: null,
			// 	label: "Do you require trailer cover?",
			// 	html: {
			// 		itemData: constants.lists.yesNo,
			// 	},
			// 	rules: [
			// 		new ruleItem({
			// 			...generateBaseRuleData("general", "isRequired"),},{
			// 			errorMessage: "", // errorMessage: "Required",
			// 		}),
			// 	],
			// }),
			// Id: new item({
			// 	// componentTag:  "Id",
			// 	submitKey: "Id",
			// 	exampleValue: null,
			// }),

			// Affinity: new item({
			// 	// componentTag:  "Affinity",
			// 	submitKey: "Affinity",
			// 	exampleValue: "XTRA",
			// }),

			// PreferredDeliveryMethod: new item({
			// 	// componentTag:  "PreferredDeliveryMethod",
			// 	submitKey: "PreferredDeliveryMethod",
			// 	exampleValue: null,

			// 	// rules: [
			// 	// 	new ruleItem({
			// 	// 		...generateBaseRuleData("general", "isRequired"),},{
			// 	// 		errorMessage: "", // errorMessage: "Required",
			// 	// 	}),
			// 	// ],
			// }),

			// Enrichments: new item({
			// 	submitKey: "Enrichments",
			// 	exampleValue: null,
			// }),
			// ScratchData: new item({
			// 	submitKey: "ScratchData",
			// 	exampleValue: null,
			// }),

			// TargetPremiumInsurerName: new item({
			// 	submitKey: "TargetPremiumInsurerName",
			// 	exampleValue: null,
			// }),
			// TargetPremiumInsurerType: new item({
			// 	submitKey: "TargetPremiumInsurerType",
			// 	exampleValue: null,
			// }),
			// TargetPremium: new item({
			// 	submitKey: "TargetPremium",
			// 	exampleValue: null,
			// }),
			// PreferredCollectionDay: new item({
			// 	submitKey: "PreferredCollectionDay",
			// 	exampleValue: null,
			// }),
		},
	}),
	// SubagentCodes: new item({
	// 	// componentTag:  "SubagentCodes",
	// 	submitKey: "SubagentCodes",
	// 	exampleValue: null,
	// }),
});

// database.addRule(
// 	"Risk/Proposer/Address",
// 	"Postcode",
// 	templates.Vehicle.logicRule
// );
// console.log("xxxxx database", database);
console.log("Created van database.");
export default database;
