import React from "react";
import useVehicleSummaryData from "moveToLibrary/hooks/useVehicleSummaryData";
import dayjs from "dayjs";
import dateHelpers from "@library/common/helpers/misc/date";

import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import _ from "lodash";
import constants from "libraryConstants/database";

// import { FormGroup } from "siteComponentsLibrary/Layout";
// import { TextBox, RadioButtons } from "siteComponentsLibrary/Inputs";

export const formatItem = (dataType, value) => {
	if (value === undefined) return "";

	switch (dataType) {
		case "string": {
			return value;
		}
		case "boolean": {
			if (value) return "Yes";
			return "No";
		}
		case "booleanFlip": {
			if (value) return "No";
			return "Yes";
		}
		case "currency": {
			return `£${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
		}
		case "number": {
			return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
		case "date": {
			return dayjs(dateHelpers.toDate(value)).format("D MMMM YYYY");
		}

		default:
			return value;
	}
};

const SummaryItemText = (props) => {
	const { databaseProps: itemProps, fnTemplate = (v) => `${v}` } = props;
	const commonData = useOutletContext();

	const metaData = useSelector(
		(state) => commonData.risk.selectors.metaData(state, itemProps.path),
		_.isEqual
	);

	const isVisible = !metaData?.hidden;
	const value = metaData?.value;

	if (isVisible === false) return null;
	const valueString = fnTemplate(value);
	return <span>{valueString}</span>;
};

const VehicleSummary = (props) => {
	const { summaryHitlist = [], vehicleTypeDisplay = "vehicle" } = props;

	const SummaryPanelWrapper = (props) => {
		const commonData = useOutletContext();
		const { FormGroup, RadioButtons, RadioButtonsYesNo } =
			commonData.storeComponents;

		throw `Error -- vehicle summary should no longer be shown`;
		// NOTE: This code kepy here in case we do need to switch back to the old style of asking the confirmation question

		return (
			<div data-cy="vehicle:summary">
				<FormGroup
					databaseProps={{
						path: "ChosenOptions/VehicleExtraDetailsConfirmation",
					}}
					label={`Are the following details about your ${vehicleTypeDisplay} correct?`}
					data-cy="form-group:vehicle-details-correct"
				>
					{props.children}
					<RadioButtonsYesNo
						databaseProps={{
							path: "ChosenOptions/VehicleExtraDetailsConfirmation",
						}}
						data-cy="radio:vehicle-details-correct"
					/>
				</FormGroup>
			</div>
		);
	};

	const commonData = useOutletContext();
	const { FormGroup, RadioButtons } = commonData.storeComponents;

	const regNumber = useSelector((state) =>
		commonData.risk.selectors.value(state, "Risk/Vehicle/RegNumber")
	);

	const isEnabled = regNumber ? true : false;

	const isSummaryMode = useSelector((state) => {
		const VehicleExtraDetailsConfirmation = commonData.risk.selectors.value(
			state,
			"ChosenOptions/VehicleExtraDetailsConfirmation"
		);
		return VehicleExtraDetailsConfirmation !== false;
	});

	const isMissingData = useSelector((state) => {
		return Object.fromEntries(
			summaryHitlist
				.filter((x) => x.databaseProps)
				.map((x) => {
					const val = commonData.risk.selectors.value(
						state,
						x.databaseProps.path
					);
					const isMissing = (function () {
						if (val === undefined) return true;
						if (val === "") return true;
						return false;
					})();

					return [x.id, isMissing];
				})
		);
		//
	}, _.isEqual);

	const summaryData = useVehicleSummaryData({
		hitlist: summaryHitlist.map((x) => ({
			...x,
			isMissing: isMissingData[x.id],
		})),
		isEnabled: isEnabled,
		SummaryItemText: SummaryItemText,
		SummaryPanelWrapper: SummaryPanelWrapper,
		memoizationList: [regNumber], //#3292 -- Only recalc if the regNumber changes. (Note: if regNumber is blank, isEnabled will take priority)
	});

	// console.log("summaryData", {
	// 	summaryData,
	// 	isEnabled,
	// 	isSummaryMode,
	// 	summaryHitlist,
	// 	isMissingData,
	// });

	if (!isEnabled) return null;

	return (
		<div>
			{isSummaryMode && summaryData.summaryPanel}
			{/* {isSummaryMode && (
				<FormGroup
					databaseProps={{
						path: "ChosenOptions/VehicleExtraDetailsConfirmation",
					}}
					label="Are these details correct?"
				>
					<RadioButtons
						databaseProps={{
							path: "ChosenOptions/VehicleExtraDetailsConfirmation",
						}}
						itemData={constants.lists.yesNo}
					/>
				</FormGroup>
			)} */}
			{!isSummaryMode && summaryData.summaryPanelEdit}
			{summaryData.summaryPanelMissing}
		</div>
	);
};

export default VehicleSummary;
