import moment from "moment";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";

export default (args) => {
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;

	const dh = new dataHelper({
		mappings: ["Title", "Gender", "TitleGender", "Salutation", "Surname"],
		dataSet,
		functionArgs,
	});

	// console.log("nameLogic", dh.current.componentTag, dh.dataSet.locklist);

	// ** salutation
	if (dh.isUpdated("Title", "Surname")) {
		if (
			dh.getValue("Title") !== undefined &&
			dh.getValue("Surname") !== undefined
		) {
			dh.setData(
				"value",
				"Salutation",
				`${dh.getValue("Title")} ${dh.getValue("Surname")}`
			);
		} else {
			dh.setData("value", "Salutation", undefined);
		}
	}

	if (!dh.isPostRegistration() && dh.isUpdated("TitleGender")) {
		const JSONString = dh.getValue("TitleGender");

		const JSONData =
			JSONString === undefined ? undefined : JSON.parse(JSONString);

		if (JSONData) {
			dh.setData("value", "Title", JSONData["title"]);
			dh.setData("value", "Gender", JSONData["gender"]);
		} else {
			dh.setData("value", "Title", undefined);
			dh.setData("value", "Gender", undefined);
		}
	}

	if (dh.isPostRegistration()) {
		// {"title":"Dr","gender":"M"}
		const Title = dh.getValue("Title");
		const Gender = dh.getValue("Gender");

		// console.log("dddd nameLogic", dh.errorKey, dh, Title, Gender);

		if (Title !== undefined && Gender !== undefined) {
			// console.log("TitleGender -- setting to something");
			dh.setData(
				"value",
				"TitleGender",
				`{"title":"${Title}","gender":"${Gender}"}`
			);
		} else {
			// console.log("TitltGender -- setting to undefined");

			dh.setData("value", "TitleGender", undefined);
		}
	}

	dh.commit();
};
