import { list, set, item } from "@library/common/database/classes";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";

import constants from "libraryConstants/database";
import regExConstants from "@library/common/constants/regEx";

import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import generateBaseRuleData from "../../../generateBaseRuleData";
import { Name } from "./Name";

import Conviction from "./Conviction";
import PostcodeTemplate from "./Postcode";

export default (args) => {
	return new set({
		...args,
		items: {
			Postcode: new item({
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter your postcode",
						}
					),
					...PostcodeTemplate.generateRules(),
				],
				submitKey: "Postcode",
				label: "Postcode",
			}),
			AbodeType: new item({
				submitKey: "AbodeType",
				exampleValue: "House",
				defaultValue: "House",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),
			Usage: new item({
				submitKey: "Usage",
				exampleValue: "Main",
				defaultValue: "Main",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),
			CountryCode: new item({
				submitKey: "CountryCode",
				defaultValue: "GB",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),
			HouseNameOrNumber: new item({
				submitKey: "HouseNameOrNumber",
				exampleValue: "40",
				label: "House name or number",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),

					new ruleItem(generateBaseRuleData("general", "maxLength"), {
						length: 30,
						errorMessage: "Please enter a maximum of 30 characters",
					}),
				],
			}),
			AddressLine1: new item({
				submitKey: "AddressLine1",
				exampleValue: "Dalehouse Road",
				label: "Address 1",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter the address line.",
						}
					),
					new ruleItem(generateBaseRuleData("general", "maxLength"), {
						length: 32,
						errorMessage: "Please enter a maximum of 32 characters",
					}),
				],
			}),
			AddressLine2: new item({
				submitKey: "AddressLine2",
				exampleValue: "Cheddleton",
				label: "Address 2",
				rules: [
					new ruleItem(generateBaseRuleData("general", "maxLength"), {
						length: 32,
						errorMessage: "Please enter a maximum of 32 characters",
					}),
				],
			}),
			AddressLine3: new item({
				submitKey: "AddressLine3",
				exampleValue: "LEEK",
				label: "Address 3",
				rules: [
					new ruleItem(generateBaseRuleData("general", "maxLength"), {
						length: 32,
						errorMessage: "Please enter a maximum of 32 characters",
					}),
				],
			}),
			AddressLine4: new item({
				// componentTag:  "AddressLine4",
				submitKey: "AddressLine4",
				exampleValue: "Staffordshire",
				label: "Address 4",
				rules: [
					new ruleItem(generateBaseRuleData("general", "maxLength"), {
						length: 32,
						errorMessage: "Please enter a maximum of 32 characters",
					}),
				],
			}),
		},
	});
};
