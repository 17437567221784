import { current } from "@reduxjs/toolkit";
import { merge } from "webpack-merge";

const fnOutputErrorInfo = (...args) => {
	console.log("********************************************");
	console.log("ERRORINFO:", ...args);
	console.log("********************************************");
};

import _ from "lodash";
import hash from "object-hash";

// import { fnIsArray } from "../utils";
import riskUtils from "../utils/risk";
import templateUtils from "../utils/template";

import fnGenerateLegacy from "./generateLegacy";
import {
	fnCreate,
	fnUpdate,
	fnUpdateAddSubKey,
	fnUpdateRemoveSubKey,
} from "./baseFunctions";

// *****************************************************
// Private functions
// *****************************************************
const _fnRunRules = (
	_this,
	{ updatePath = "", mode = undefined },
	options = {}
) => {
	const {
		isPostSalusLoad = false,
		isPostRegistration = false,
		debugInfo,
	} = options;

	console.groupCollapsed("Processor runRules", mode, updatePath);
	console.log({ options });
	console.groupEnd();

	const state = _this.state;
	const fnLog = _this._console.log;

	const persistedData = { history: { runCount: {} } }; // Data that we'll be persisted between recursive calls

	// console.log("RUN RULES", updatePath, mode);
	const _fnRunRulesRecursive = (args = {}) => {
		const { updatePath, level = 1, mode } = args;

		// console.log(
		// 	"ddsadasd",
		// 	riskUtils.searchPath.parse("test[]").array,
		// 	riskUtils.searchPath.parse("test[222]").array,
		// 	riskUtils.searchPath.parse("test[]+").array,
		// 	riskUtils.searchPath.parse("aaaa/bbbb[]/cccc[]/test2").array
		// );

		const pathMeta = riskUtils.searchPath.parse(updatePath); //NOTE: updatePath can be missing

		// console.log("...", level, updatePath, mode);
		const additionalRuleHitlist = [];
		const rules = _this.rules;
		const template = _this.template;

		const helpers = (function () {
			const updatePathClean = (function () {
				if (mode === "path") return pathMeta.cleanPath;
				// return riskUtils.searchPath.array.removeAction(updatePath);
				return undefined;
			})();

			const fnAddAdditionalRuleHitlist = (newPath) => {
				if (!newPath)
					throw `Error in fnAddAdditionalRuleHitlist -- missing "newPath"`;
				if (mode === "path" && newPath === updatePathClean) return; //Don't want to re-run the current path again
				if (additionalRuleHitlist.includes(newPath)) return;
				additionalRuleHitlist.push(newPath);
			};

			const fnProcessRuleItem = (ruleItem, ruleName) => {
				const fnCreateNewMappings = (ruleData) => {
					const updatePathArray = updatePath.split("/");

					//** update mappingBase with genuine values
					//** update mappings with genuine values

					// Update mappingBase
					// Do a replace of all the mappings
					// Check if there's any mappings which don't have a mapping base

					if (!ruleData.mappingBase) return ruleData.mappings;

					const _mappingBase = ruleData.mappingBase
						.split("/")
						.map((segmentValue, i) => {
							const curPathValue = updatePathArray[i];
							if (curPathValue === segmentValue) return segmentValue;
							// If array, return the curPathValue
							if (
								riskUtils.searchPath.array.isArray(segmentValue) &&
								riskUtils.searchPath.array.isArrayWithIndex(curPathValue)
							)
								return curPathValue;

							// This is the error
							fnLog("ERRORINFO:", {
								segmentValue,
								updatePathArray,
								i,
								curPathValue,
								ruleData,
							});
							throw `Error in runRules -- can't find "${curPathValue}" in "${updatePath}"`;
						})
						.join("/")
						.replaceAll("/[", "[");

					const retData = Object.fromEntries(
						Object.entries(ruleData.mappings)
							.map(([k, d]) => {
								if (d.startsWith("/")) return [k, [_mappingBase, d].join("")];

								return [k, d];
							})
							.map(([k, d]) => {
								if (d.includes("[]")) {
									fnLog("ERRORINFO:", {
										updatePathArray,
										ruleData,
										d,
									});
									throw `Error in runRules -- found mapping with [] "${d}"`;
								}
								return [k, d];
							})
					);

					return retData;
				};

				//Legacy
				if (
					_.isObject(ruleItem) &&
					"isLegacy" in ruleItem &&
					ruleItem.isLegacy
				) {
					const newMappings = fnCreateNewMappings(ruleItem);
					return {
						ruleName: ruleName,
						fn: ruleItem.fn,
						mappings: newMappings,
						functionArgs: ruleItem.functionArgs,
						isLegacy: ruleItem.isLegacy,
						errorKey: ruleItem.errorKey,
					};
				}

				// if (_.isString(ruleItem)) {
				// 	if (!rules)
				// 		throw `Error in processor() -- found rule "${ruleName}" but no "rule" file was supplied to the store`;
				// 	const ruleName = ruleItem;
				// 	const fn = rules[ruleName];

				// 	return {
				// 		ruleName: ruleName,
				// 		fn: fn,
				// 		errorKey: ruleName,
				// 	};
				// }

				if (_.isFunction(ruleItem)) {
					return {
						fn: ruleItem,
						errorKey: `inline_function_${hash(ruleItem)}`,
					};
				}

				if (_.isObject(ruleItem)) {
					if (!_.isFunction(ruleItem.fn))
						throw `Error in REDUX Processor() -- fn is not a function for "${updatePath}"`;

					return {
						ruleName: ruleItem.ruleName,
						fn: ruleItem.fn,
						functionArgs: ruleItem.args,
						errorKey: ruleItem.errorKey || `object_function_${hash(ruleItem)}`,
					};
				}

				// this.debugData();
				fnOutputErrorInfo({ ruleItem });
				throw `Error in REDUX Processor() -- unknown item type found in template ruleList for "${updatePath}"`;
			};

			const fnGetRulesByMappings = (updatePath) => {
				const updatePathArray = updatePath.split("/");
				return (
					Object.entries(rules)
						// Filter for where the pathlist matches the path
						.filter(([ruleName, ruleData]) => {
							// console.log("ddddd", updatePath, ruleName, {
							// 	args,
							// 	rules,
							// 	ruleData,
							// });

							return ruleData.paths.some((rulePath) => {
								return rulePath.split("/").every((segmentValue, i) => {
									if (segmentValue === "*") return true;

									if (riskUtils.searchPath.array.isArray(segmentValue)) {
										if (
											!riskUtils.searchPath.array.isArrayWithIndex(
												updatePathArray[i]
											)
										)
											return false;

										if (
											riskUtils.searchPath.array.parse(segmentValue).path ===
											riskUtils.searchPath.array.parse(updatePathArray[i]).path
										)
											return true;

										return false;
									}
									if (segmentValue === updatePathArray[i]) return true; // Case sensitive
									return false;
								});
							});
						})
						.map(([ruleName, ruleData]) =>
							fnProcessRuleItem(ruleData, ruleName)
						)
				);
			};

			const fnExecuteRule = (ruleArgs = {}, extraData = {}) => {
				const { level = 1, arrayAction } = extraData;

				if (ruleArgs.isLegacy) {
					const {
						errorKey,
						fn,
						ruleName,
						mappings = {},
						functionArgs = {},
					} = ruleArgs;

					const { pathListBatch = [] } = options;
					const protectedList = (function () {
						if (!isPostRegistration) return [];

						if (
							!riskUtils.searchPath.array.isArrayWithIndex(updatePath) &&
							!pathListBatch.includes(updatePath)
						) {
							return [...pathListBatch, updatePath];
						}

						return pathListBatch;
					})();

					const legacy = fnGenerateLegacy(state, {
						persistedData: persistedData,
						_console: _this._console,
						errorKey: errorKey || ruleName,
						ruleName,
						mappings,
						updatePath: updatePathClean,
						isPostSalusLoad,
						isPostRegistration,

						fnRunRule: (updatePath) =>
							_fnRunRulesRecursive({
								mode: "path",
								updatePath: updatePath,
								level: level + 1,
							}),
						fnHasChanged: (path) => _this.hasChanged(path),
						protectedList: protectedList,
						level: level,
					});

					if (legacy.runRule) {
						const fnArgs = {
							dataHelper: legacy.dataHelper,
							dataSet: legacy.dataSet,
							functionArgs: { ...mappings, ...functionArgs },
							state: riskUtils.find.data(state),
							executionPath: updatePathClean,
							console: _this._console,
							persistedData: persistedData,
						};
						// console.log("ddddd LEGACY", updatePathClean, { fnArgs });

						fn(fnArgs);
					}
					return;
				}

				// ** NEW RULE TYPE **
				const { errorKey, fn, ruleName, functionArgs = {} } = ruleArgs;

				_this._console.time(
					["PROCESSOR RUN RULE", updatePath, ruleName].join(" ")
				);
				_this.runHistoryRules[ruleName] = true;

				// const fnGetParentTreePath = (path) => {
				// 	if (!path) throw `Error in fnGetParentTreePath -- missing path`;
				// 	const idx = path.lastIndexOf("]");
				// 	if (idx === -1) return path;
				// 	return path.substring(0, idx + 1);
				// };

				const controls = {
					find: (searchPath) => {
						if (!searchPath)
							throw `Error in REDUX processor find() -- missing path"`;

						const foundPath = (function () {
							const searchPathArray = searchPath.split("/");

							// Find relative paths
							switch (searchPathArray[0]) {
								case ".": {
									const updatePathCleanArray = updatePathClean.split("/");
									return [
										...updatePathCleanArray.filter(
											(x, i, arr) => i !== arr.length - 1
										), //Remove the last entry of the main path
										...searchPathArray.filter((x, i) => i !== 0), //Join on the end of the searchPath
									].join("/");
								}
								case "..":
									throw `Error in REDUX processor find() -- ".." not yet implemented. Try "."`;
								case "[]": {
									// Get the nearest tree parent
									const newBase = pathMeta.parentTree;
									if (!newBase)
										throw `Error in REDUX processor find() -- can't find a array in path "${searchPath}"`;

									return [
										newBase,
										...searchPathArray.filter((x, i) => i !== 0), //Join on the end of the searchPath
									]
										.filter(Boolean)
										.join("/");
								}
							}

							return searchPath;
						})();

						const _findPathMeta = riskUtils.searchPath.parse(foundPath);
						const foundItem = riskUtils.find.item(_this.state, foundPath);
						const foundItemInit = riskUtils.find.item(
							_this.stateInit,
							foundPath
						);

						const retBase = {
							path: foundPath,
							pathMetaData: _findPathMeta,
							errorKey: () => errorKey,
							isFound: () => (foundItem ? true : false),
							isUpdated: () => {
								if (isPostSalusLoad) return true;
								if (isPostRegistration) return true;
								return !_.isEqual(foundItem?._value, foundItemInit?._value);
							},
							isDeleted: () => {
								if (!foundItem && foundItemInit) return true;
								return false;
							},
							functions: {
								getParentTree: () => {
									const parentTree = _findPathMeta.parentTree;
									if (!parentTree) return undefined;
									const parentTreeBase = _findPathMeta.array.path;

									const retData = riskUtils.find.arrayList(
										_this.state,
										`${parentTreeBase}[]` //parentTree
									);

									return retData.map((id) => {
										return {
											id: id,
											path: `${parentTreeBase}[${id}]`,
											isCurrent: id === _findPathMeta.array.index,
											generatePath: (subPath) =>
												[`${parentTreeBase}[${id}]`, subPath].join("/"),
										};
									});
								},
							},
						};

						// If it's an array, then return empty controls
						{
							const isArrayItem = [
								_findPathMeta.array.isArray,
								_findPathMeta.array.isArrayWithIndex,
								_findPathMeta.array.isArrayAction,
							].some(Boolean);

							if (isArrayItem) {
								return merge(retBase, {});
							}
						}

						// const foundItem = riskUtils.find.item(_this.state, foundPath);

						// if (!foundItem) {
						// 	throw `Error in REDUX processor find() -- can't find "${foundPath}"`;
						// }

						// const foundItemInit =
						// 	riskUtils.find.item(_this.stateInit, foundPath) || {};

						const fnErrorSet = (errMsg = "") => {
							if (!errorKey) throw `Error in fnErrorSet -- missing errorKey `;
							foundItem._error = foundItem._error || {};
							foundItem._error[errorKey] = errMsg;
						};

						const fnErrorClear = () => {
							if (!errorKey) throw `Error in fnErrorSet -- missing errorKey `;
							foundItem._error = foundItem._error || {};
							delete foundItem._error[errorKey];
						};

						const fnIsEmpty = () => {
							if (foundItem._value === "") return true;
							if (foundItem._value === undefined) return true;
							return false;
						};

						const functions = {
							setValue: (value) => {
								if (_.isEqual(value, foundItem._value)) return; //Exit early if not an update (so we don't keep running the rules for this item)
								foundItem._value = value;
								fnAddAdditionalRuleHitlist(foundPath);
							},
							cloneFrom: (path) => {
								if (path === foundPath) return;

								const sourceItem = riskUtils.find.item(_this.state, path);

								if (_.isEqual(sourceItem._value, foundItem._value)) return;
								//Exit early if not an update (so we don't keep running the rules for this item)

								foundItem._value = sourceItem._value;
								fnAddAdditionalRuleHitlist(foundPath);
							},
							validate: () => {
								fnAddAdditionalRuleHitlist(foundPath);
							},

							hidden: {
								hide: () => {
									foundItem._hidden = true;
									foundItem._errorShow = false;
								},
								show: () => (foundItem._hidden = false),
							},
							errors: {
								setIfMissingAndVisible: (errMsg = "") => {
									if (foundItem._hidden !== true && fnIsEmpty()) {
										fnErrorSet(errMsg);
										return;
									}

									fnErrorClear();
								},
								set: (errMsg = "") => {
									fnErrorSet(errMsg);
								},

								clear: () => {
									fnErrorClear();
								},
								hide: () => {
									foundItem._errorShow = false;
								},
							},
						};

						const retData = {
							// arrayData: foundItem._arrayData,
							value: () => foundItem._value,
							hidden: () => foundItem._hidden,
							error: () => foundItem._error && foundItem._error[errorKey],
							errorShow: () => foundItem._errorShow,
							isEmpty: fnIsEmpty,
							functions: functions,
						};
						return merge(retBase, retData);
					},
				};

				// Execution
				{
					const executionArgs = {
						functionArgs: functionArgs,
						isPostSalusLoad: isPostSalusLoad,
						isPostRegistration: isPostRegistration,
						isAlreadyRan: (function () {
							if (!ruleName) return undefined;
							return persistedData.history.runCount[ruleName] >= 1;
						})(),
						arrayAction: arrayAction, // Optional -- for array actions
					};

					switch (mode) {
						case "path": {
							if (
								pathMeta.array.isArrayAction ||
								pathMeta.array.isArray ||
								pathMeta.array.isArrayWithIndex
							) {
								fn(
									controls.find(pathMeta.array.pathWithIndex),
									controls,
									executionArgs
								);
								break;
							}

							fn(controls.find(updatePathClean), controls, executionArgs);
							break;
						}
						default: {
							fn(controls, executionArgs);
						}
					}
				}

				//Update the runCount (only if we're using "rules")
				if (ruleName) {
					persistedData.history.runCount[ruleName] =
						(persistedData.history.runCount[ruleName] || 0) + 1;
				}

				_this._console.timeEnd(
					["PROCESSOR RUN RULE", updatePath, ruleName].join(" ")
				);
			};

			return {
				executeRule: fnExecuteRule,
				processRuleItem: fnProcessRuleItem,
				getRulesByMappings: fnGetRulesByMappings,
			};
		})();

		switch (mode) {
			case "postRegistration": {
				if (!_this.rules?.onPostRegistration) break;
				const argData = {
					errorKey: "onPostRegistration",
					fn: _this.rules.onPostRegistration,
					functionArgs: undefined,
					ruleName: "onPostRegistration",
				};

				helpers.executeRule(argData);
				break;
			}
			case "postSalusLoad": {
				if (!_this.rules?.onPostSalusLoad) break;
				const argData = {
					errorKey: "onPostSalusLoad",
					fn: _this.rules.onPostSalusLoad,
					functionArgs: undefined,
					ruleName: "onPostSalusLoad",
				};

				helpers.executeRule(argData);
				break;
			}

			case "path": {
				if (updatePath.includes("*"))
					throw `Error in fnRunRules -- can't have "*"`;

				if (!updatePath) {
					fnOutputErrorInfo({ options, debugInfo });
					throw `Error in runRules -- missing updatePath`;
				}

				//Safety check:
				if (_this.runHistoryPathCount[updatePath] > 20) {
					throw `Error in processor -- ran the rules for path "${updatePath}" too many times"`;
				}

				//NOTE: We need to allow _fnRunRule rule to run multiple times for the same "updatePath"
				// as e.g. a rule can update the value, and we need to update the error
				_this.runHistoryPathCount[updatePath]++;

				_this._console.time("FINDING RULES");
				const foundRules = (function () {
					const useNewMethod = true;

					// ARRAY (NOT an ACTION)
					if (pathMeta.array.isArrayWithIndex) {
						if (useNewMethod) return [];

						const cleanPath = updatePath
							.split("/")
							.map((x, i, arr) => {
								if (i === arr.length - 1) {
									return riskUtils.searchPath.array.clean(x);
								}
								return x;
							})
							.join("/");

						const foundRules = helpers.getRulesByMappings(cleanPath);
						return foundRules;
					}

					// ARRAY ACTION
					if (pathMeta.array.isArrayAction) {
						const _foundTemplateRules = templateUtils.getData(
							template,
							pathMeta.array.pathWithIndex.split("/")
						)?.data?.ruleList;

						if (!_foundTemplateRules) return [];

						return _foundTemplateRules.map((curItem) =>
							helpers.processRuleItem(curItem)
						);
					}

					//NOT ARRAY
					if (!pathMeta.array.isArray) {
						//Version 1 below DO NOT DELETE
						if (!useNewMethod) {
							return helpers.getRulesByMappings(updatePath);
						}

						const _foundTemplateRules = templateUtils.getData(
							template,
							updatePath.split("/")
						)?.data?.ruleList;

						if (!_foundTemplateRules) return [];

						return _foundTemplateRules.map((curItem) =>
							helpers.processRuleItem(curItem)
						);
					}

					return [];
				})();

				_this._console.timeEnd("FINDING RULES");

				// Logging
				_this._console.groupCollapsed(
					"PROCESSOR().runRule",
					`"${updatePath}"`,
					`(${foundRules.length})`
				);
				_this._console.log("rules:", rules);
				_this._console.log("template:", template);
				foundRules.forEach((x) => _this._console.log("RULE:", x.ruleName));
				_this._console.groupEnd();

				// console.log("dddd", updatePath, foundRules);
				// EXECUTE the rules
				foundRules.forEach((ruleData) => {
					const pathData = riskUtils.searchPath.array.parse(updatePath);
					helpers.executeRule(ruleData, {
						level: level,
						arrayAction: pathData?.action,
					});
				});

				break;
			}
		}

		// Run ADDITIONAL RULES
		additionalRuleHitlist.forEach((path) => {
			_fnRunRulesRecursive({
				mode: "path",
				updatePath: path,
				level: level + 1,
			});
		});
	};

	switch (mode) {
		case "postSalusLoad": {
			_fnRunRulesRecursive({ mode: mode });
			break;
		}
		case "postRegistration": {
			_fnRunRulesRecursive({ mode: mode });
			break;
		}
		case "path": {
			if (!updatePath && !_.isString(updatePath)) {
				fnLog("ERROR INFO", { updatePath, options });
				throw `Error in runRules -- updatePath is not a string`;
			}
			_this.runHistoryPathCount[updatePath] = 0;
			_fnRunRulesRecursive({ mode: mode, updatePath: updatePath, level: 1 });
			break;
		}
		default: {
			throw `Error in processor -- unknown mode "${mode}"`;
		}
	}
};

// *****************************************************
// The MAIN CLASS
// *****************************************************
class processor {
	constructor(args = {}) {
		["rules", "state"].forEach((key) => {
			if (!(key in args)) {
				throw `Error in processor() -- missing "${key}"`;
			}
		});

		this.template = args.template;
		this.debugData = args.debugData;
		this.stateInit = _.cloneDeep(args.state);
		this.state = args.state;
		this.runHistoryPathCount = {};
		this.runHistoryRules = {};
		this.rules = args.rules;
		this._console = args._console || console;

		this._console.log("CREATE PROCESSOR:", args.debugData, { args });
	}

	debugData() {
		this._console.log("DEBUG", "processor", { ...this });
	}

	hasChanged(path) {
		// NOTE: oldItem could be undefined as we've just created the item

		const oldItem = riskUtils.find.item(this.stateInit, path);
		const newItem = riskUtils.find.item(this.state, path);

		// NONE ARRAY
		const retValue = (function () {
			if (riskUtils.is.dataNodeArray(oldItem)) {
				// Check for added or removed ids
				const listOld = oldItem?._arrayData.map((x) => x.id);
				const listNew = newItem?._arrayData.map((x) => x.id);

				if (listOld.some((idOld) => !listNew.includes(idOld))) return true;
				if (listNew.some((idNew) => !listOld.includes(idNew))) return true;

				return false;
				return !_.isEqual(oldItem?._arrayData, newItem?._arrayData);
			}

			if (riskUtils.is.dataNode(oldItem)) {
				return !_.isEqual(oldItem?._value, newItem?._value);
			}
		})();

		// if (path === "Risk/AdditionalInsuredSet") {
		//   console.log("DDDDDDDDD HASCHANGED ", path, {
		//     retValue,
		//     oldItem,
		//     newItem,
		//     arrayOld: oldItem._arrayData,
		//     arrayNew: newItem._arrayData,
		//   });
		// }
		return retValue;
	}

	runRulesPostSalusLoad(options = {}) {
		if (this.rules?.onPostSalusLoad) {
			return _fnRunRules(this, { mode: "postSalusLoad" }, options);
		}
	}

	runRulesPostRegistration(options = {}) {
		if (this.rules?.onPostRegistration) {
			return _fnRunRules(this, { mode: "postRegistration" }, options);
		}
	}

	runRules(updatePath = "", options = {}) {
		return _fnRunRules(this, { updatePath: updatePath, mode: "path" }, options);
	}

	runRulesBatch(pathList = [], options = {}) {
		if (pathList.length === 0) return;

		pathList.forEach((path) => {
			this.runRules(path, { ...options, pathListBatch: pathList });
		});
	}

	testState() {
		const state = this.state;

		console.groupCollapsed("TESTING");

		const hitlist = [];

		riskUtils.process.all(state, "Risk", (obj, args = {}) => {
			hitlist.push({ path: args.searchPath.join("/"), value: obj._value });
		});

		hitlist.forEach((x) => {
			console.groupCollapsed("Runing rules for", x.path, x.value);
			this.runRules(x.path);
			console.groupEnd();
		});

		console.groupEnd();
	}

	create(path) {
		return fnCreate(this.state, path);
	}

	updateValue(path, value) {
		const state = this.state;
		return fnUpdate(state, path, "_value", value);
	}
	updateHidden(path, value) {
		const state = this.state;
		return fnUpdate(state, path, "_hidden", value);
	}

	updateErrorShow(path, value) {
		const state = this.state;
		return fnUpdate(state, path, "_errorShow", value);
	}

	updateErrorAdd(path, errorKey, description) {
		const state = this.state;
		return fnUpdateAddSubKey(state, path, "_error", errorKey, description);
	}

	updateErrorRemove(path, errorKey) {
		const state = this.state;
		return fnUpdateRemoveSubKey(state, path, "_error", errorKey);
	}
}

export default processor;
