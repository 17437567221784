import dataHelper from "@library/common/database/rules/helpers/dataHelper";

export default (args) => {
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;
	const occupationBaseLogic = require("../occupationLogic/baseLogic").default;

	const dh = new dataHelper({
		mappings: ["HasSecondOccupation", "Type", "OccCode", "EmpCode"],
		dataSet,
		functionArgs,
	});

	if (dh.isPostRegistration()) {
		if (dh.getValue("Type") !== undefined) {
			dh.setData(
				"value",
				"HasSecondOccupation",
				dh.getValue("Type") !== "None"
			);
		}
	}

	if (dh.isUpdated("HasSecondOccupation")) {
		const isVisible = dh.getValue("HasSecondOccupation") === true;

		dh.setVisible("Type", isVisible);
		dh.setVisible("OccCode", false);
		dh.setVisible("EmpCode", false);

		["Type", "OccCode", "EmpCode"].forEach((key) => {
			dh.clearError(key);
		});

		if (!dh.isPostRegistration()) {
			if (isVisible === false) {
				dh.setData("value", "Type", "None");
				dh.setData("value", "OccCode", "220");
				dh.setData("value", "EmpCode", "186");
			} else {
				dh.setData("value", "Type", undefined);
				dh.setData("value", "OccCode", undefined);
				dh.setData("value", "EmpCode", undefined);
			}
		}
	}

	occupationBaseLogic(args, dh);

	if (dh.getValue("HasSecondOccupation") === true) {
		dh.addErrorIfEmpty("Type", ""); // "Required")
		dh.addErrorIfEmpty("OccCode", ""); // "Required")
		dh.addErrorIfEmpty("EmpCode", ""); // "Required")
	}

	dh.commit();
	return;
	// if (!dataSet) throw `Error -- missing dataSet`;
	// if (!functionArgs) throw `Error -- missing functionArgs`;

	// if (
	// 	!functionArgs.SecondaryOccupationQuestion ||
	// 	!functionArgs.Type ||
	// 	!functionArgs.OccCode ||
	// 	!functionArgs.EmpCode
	// 	//updatedComponentTag
	// ) {
	// 	console.log("ERRORS:", args);
	// 	throw `Error -- missing functionArgs in secondaryOccupationLogic`;
	// }

	// //** Get all the values
	// const SecondaryOccupationQuestion = dataSet.getValue({
	// 	key: "value",
	// 	...getProps(functionArgs.SecondaryOccupationQuestion),
	// });
	// // console.log("ddddd",SecondaryOccupationQuestion)

	// dataSet.setValue({
	// 	value: SecondaryOccupationQuestion === true,
	// 	key: "visible",
	// 	...getProps(functionArgs.Type),
	// });
	// dataSet.setValue({
	// 	value: SecondaryOccupationQuestion === true,
	// 	key: "visible",
	// 	...getProps(functionArgs.OccCode),
	// });
	// dataSet.setValue({
	// 	value: SecondaryOccupationQuestion === true,
	// 	key: "visible",
	// 	...getProps(functionArgs.EmpCode),
	// });

	// if (SecondaryOccupationQuestion !== true) {
	// 	dataSet.setValue({
	// 		value: undefined,
	// 		key: "value",
	// 		...getProps(functionArgs.Type),
	// 	});
	// 	dataSet.setValue({
	// 		value: undefined,
	// 		key: "value",
	// 		...getProps(functionArgs.OccCode),
	// 	});
	// 	dataSet.setValue({
	// 		value: undefined,
	// 		key: "value",
	// 		...getProps(functionArgs.EmpCode),
	// 	});
	// }
};
