import React, { useCallback, useState } from "react";
import config from "config";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import store, { selectors } from "siteStore";
import Cookies from "js-cookie";
import { retryFunction } from "moveToLibrary/functions";
import { JSONViewer } from "siteComponentsLibrary/Dev";
import { DevContainer } from "siteComponents/Dev";

import { Button } from "siteComponentsLibrary/Buttons";
import { TextBox } from "siteComponentsLibrary/Inputs";

import { useDispatch } from "siteFunctions/hooks";

import _ from "lodash";
const {
	gapQuoteAndBuy: storeGapQuoteAndBuy,
	session: storeSession,
	storage: storeStorage,
} = require("siteStore").stores;

import useQuoteAndBuy from "moveToLibrary/hooks/useQuoteAndBuy";

import {
	Outlet,
	useLocation,
	useNavigate,
	Navigate,
	useResolvedPath,
} from "react-router-dom";
import services from "siteService";

import * as storeComponents from "siteComponents/redux/gapQuoteAndBuy";

const ViewGapContainer = (props) => {
	const { children } = props;
	const baseRoute = useResolvedPath("").pathname;
	const dispatch = useDispatch();
	const [forceFinaliseFail, setForceFinaliseFail] = useState(false);
	// const pagerData = (function () {
	// 	const fnGeneratePathname = (...args) =>
	// 		[
	// 			"",
	// 			...baseRoute.split("/").filter(Boolean),
	// 			...args.filter(Boolean),
	// 		].join("/");

	// 	const fnGeneratePagerItem = ({
	// 		sectionId,
	// 		pageId,
	// 		labelPrevious = "Back",
	// 		labelNext = "Continue",
	// 	}) => {
	// 		const _pageIdArray = _.isArray(pageId) ? pageId : [pageId];
	// 		return {
	// 			pathnameList: _pageIdArray.map((p) => fnGeneratePathname(sectionId, p)),
	// 			pagerId: sectionId,
	// 			labelPrevious: labelPrevious,
	// 			labelNext: labelNext,
	// 		};
	// 	};

	// 	return {
	// 		onPrevious: () => {
	// 			console.log("PREVIOUS");
	// 		},
	// 		onNext: () => {
	// 			console.log("NEXT");
	// 		},
	// 		routes: [
	// 			// Risk routes
	// 			...[["", "edit"], "your-quote", "direct-debit"].map((pageId) =>
	// 				fnGeneratePagerItem({
	// 					sectionId: "risk",
	// 					pageId,
	// 				})
	// 			),

	// 			// Quote routes
	// 			...[["", "page1"], "page2", "page3", "page4"].map((pageId) =>
	// 				fnGeneratePagerItem({
	// 					sectionId: "quote",
	// 					pageId,
	// 				})
	// 			),
	// 		],
	// 	};
	// })();

	const commonData = useQuoteAndBuy({
		useDispatch: useDispatch,
		quoteAndBuyStore: storeGapQuoteAndBuy,
		storeComponents: storeComponents,
		sessionStore: storeSession,
		// pagerData: pagerData,
		functions: {
			getState: () => store.getState(),
			bank: {
				validateBankDetails: async ({ sortCode, accountNumber }) => {
					return services.bank.validateBankDetails({
						SortCode: sortCode,
						AccountNumber: accountNumber,
					});
				},
			},
			quote: {
				getPurchaseResponse: async (functionArgs = {}) => {
					const templateError = { isSuccess: false, isError: true };
					const templateSuccess = { isSuccess: true, isError: false };

					["paymentId", "webReference"].forEach((k) => {
						if (!functionArgs[k]) {
							throw `Error in process.getPurchaseResponse() -- missing arg "${k}"`;
						}
					});

					const payload = {
						PaymentId: functionArgs.paymentId,
						WebReference: functionArgs.webReference,
					};

					try {
						const retryResponse = await retryFunction(
							async () => await services.payment.getPurchaseResponse(payload),
							2
						);

						if (!retryResponse.isSuccess) return templateError;

						const response = retryResponse.response;
						if (!response) return templateError;

						return {
							...templateSuccess,
							data: response,
						};

						// return {
						// 	isSuccess: response.PurchaseSuccess,
						// 	isError: !response.PurchaseSuccess,
						// 	data: response,
						// };
					} catch (e) {
						console.log("getPurchaseResponse", "CAUGHT ERROR", { e });
						return templateError;
					}
				},
				registerPayment: async (functionArgs = {}) => {
					const templateError = {
						isSuccess: false,
						errorMessage: "Sorry, something has gone wrong.",
						data: undefined,
					};
					console.log("CONTAINER.jsx paymentLaucher()");

					const state = store.getState();

					const fnGetValue = (path) =>
						storeGapQuoteAndBuy.selectors.userData.risk.value(state, path);

					const fnFormatDate = (d) => {
						if (!d) throw `Error in fnFormatDate -- missing date`;
						return `${dayjs(d).format("YYYY-MM-DD")}T00:00:00`;
					};

					const currentQuote =
						storeGapQuoteAndBuy.selectors.userData.quote.currentQuote(state);

					const paymentMethod =
						storeGapQuoteAndBuy.selectors.userData.quote.chosenOptions.paymentMethod(
							state
						);

					const ConsentToAutorenew =
						storeGapQuoteAndBuy.selectors.userData.quote.chosenOptions.autoRenewal(
							state
						) || false;

					const isInstalment = paymentMethod === "M";

					const curInstalments = currentQuote.Instalments[0];
					const paymentAmount = (function () {
						if (isInstalment) return _.toNumber(curInstalments.DepositAmount);
						return _.toNumber(currentQuote.PayInFullPremium);
					})();

					// console.log("dddd", currentQuote);
					const payload = {
						RequestType: "NB",
						SessionId: selectors.session.getSessionId(state),
						// TrackingId: Cookies.get("OMG-FS-cookie"), //TODO: check this
						PayMethod: paymentMethod,
						PolicyType: "C_BSGP",
						PolicyInceptionDate: fnFormatDate(
							fnGetValue("Risk/PolicyInceptionDate")
						),
						WebReference: currentQuote.WebReference,
						AmountToPay: paymentAmount,
						BusinessEventId: _.toInteger(currentQuote.BusinessEventId),
						ProposerId: _.toInteger(currentQuote.ProposerId),
						// SourceOfBusiness: fnGetValue("Risk/SourceOfBusiness"),
						// SourceOfEnquiry: fnGetValue("Risk/SourceOfEnquiry"),
						ForceFinaliseFail: forceFinaliseFail,
						// REDIRECTS
						...(function () {
							if (functionArgs.redirects)
								return { Redirects: functionArgs.redirects };
							return {};
						})(),
						CardholderAddress: {
							HouseNameOrNumber: fnGetValue(
								"Risk/Proposer/Address/HouseNameOrNumber"
							),
							AddressLine1: fnGetValue("Risk/Proposer/Address/AddressLine1"),
							AddressLine2: fnGetValue("Risk/Proposer/Address/AddressLine2"),
							Postcode: fnGetValue("Risk/Proposer/Address/Postcode"),
						},
						ConsentToAutorenew: ConsentToAutorenew,

						// MISC
						...(function () {
							if (currentQuote.Affinity)
								return { Affinity: currentQuote.Affinity };
							return {};
						})(),

						//DIRECT DEBIT
						...(function () {
							if (functionArgs.directDebit)
								return {
									InstalmentSchemeCode: curInstalments.SchemeCode,
									DirectDebitDetails: {
										AccountName: functionArgs.directDebit.accountName,
										SortCode: functionArgs.directDebit.sortCode,
										AccountNumber: functionArgs.directDebit.accountNumber,
										AgreementHolderDob: fnFormatDate(
											fnGetValue("Risk/Proposer/DateOfBirth")
										),
										AgreementHolderName: [
											fnGetValue("Risk/Proposer/Name/Title"),
											fnGetValue("Risk/Proposer/Name/Forenames"),
											fnGetValue("Risk/Proposer/Name/Surname"),
										]
											.filter(Boolean)
											.join(" "),
									},
								};
							return {};
						})(),
					};

					try {
						console.log("CONTAINER.jsx registerPayment()", { payload });

						const retryResponse = await retryFunction(
							async () => await services.payment.registerPayment(payload),
							2
						);

						if (!retryResponse.isSuccess) return templateError;
						const response = retryResponse.response;

						console.log("CONTAINER.jsx registerPayment()", { response });

						if (_.isObject(response) && response.Status === "SUCCESS")
							return {
								isSuccess: true,
								errorMessage: undefined,
								data: { RedirectUrl: response.RedirectUrl },
							};

						return templateError;
					} catch (e) {
						return templateError;
					}
				},
			},

			wrapup: {
				set: async (value) => {
					await dispatch(
						storeStorage.actions.update({
							key: "data-wrapup",
							value: JSON.stringify(value),
							persist: false,
						})
					);
				},
				clear: async () => {
					await dispatch(
						storeStorage.actions.update({
							key: "data-wrapup",
							value: undefined,
							persist: false,
						})
					);
				},
				get: () => {
					const state = store.getState();
					const retData = storeStorage.selectors.getValue(state, "data-wrapup");
					if (!retData) return undefined;

					return JSON.parse(retData);
				},
			},
		},
		risk: {
			dictionary: {},
			values: {
				paymentMethod: "Risk/Proposer/PaymentMethod",
				classOfUse: "Risk/ClassOfUse",
				vehicleRegNumber: "Risk/Vehicle/RegNumber",
				vehicleManufacturer: "Risk/Vehicle/Manufacturer",
				vehicleDescription: "Risk/Vehicle/ModelDescription",
				policyInceptionDate: "Risk/PolicyInceptionDate",
			},
		},
		services: {
			figl: {
				validate: async (webreference) => {
					const payload = { WebReference: webreference };
					const response = await services.figl.validate(payload);
					return response;
				},
			},
			error: { log: services.error.log },
			recallQuote: async (payload) => {
				console.log("recallQuote", "payload", payload);
				const response = await services.recall.recallAndQuote(payload);
				console.log("recallQuote", "response", response);

				return response;
			},

			recallQuoteAndBuyHandover: async (payload = {}) => {
				// https://dev.azure.com/FreedomServicesGroup/Odyssey/_wiki/wikis/Odyssey.wiki/118/RecallAggDeeplink
				// https://eu-west-1.console.aws.amazon.com/dynamodbv2/home?region=eu-west-1#edit-item?itemMode=2&pk=po-test-3-rti&route=ROUTE_ITEM_EXPLORER&sk=&table=salus-test-quotes-SavedGapResults
				console.log("recallQuoteAndBuyHandover", payload);
				// https://dev.azure.com/FreedomServicesGroup/Odyssey/_workitems/edit/3362/
				// Example code: po-test-1-rtv.0d3c4f4c-e74e-4c3d-a7c6-5315c1f9c601
				// http://localhost:8083/recall-quote/po-test-1-rtv.0d3c4f4c-e74e-4c3d-a7c6-5315c1f9c601
				if (true) {
					const response = await services.recall.handover({
						DeeplinkId: payload.token,
						// Requote: false,
						// IncludeUpgrades: true,
					});

					return response;
				}
				// MOCK TEST RECALL
				if (false) {
					// WebReference: "MR-GAP-STAN-|PC",
					// WebReference: "MR-GAP-STAN-|GV",

					const response = await services.recall.recallAndQuote({
						WebReference: "MR-GAP-STAN-|PC",
					});

					// ChosenOptions/OwnerKeeperQuestion {isRequired: '', vehicleLogic: ''}
					// index.js:269 Risk/IsInsuranceWriteOff {isRequired: ''}
					// index.js:269 Risk/Vehicle/CurrentMileage {isRequired: ''}
					// index.js:269 Risk/Vehicle/Owner {isRequired: ''}
					// index.js:269 Risk/Vehicle/Keeper {isRequired: ''}
					// index.js:269 ChosenOptions/EmailConfirm {isRequired: ''}

					const retData = {
						...response,
						QuoteResults: [
							{
								QuoteResultType: "SUCCESS-GAP",
								GapType: "VAL",
								ClaimLimit: 5000,
								PolicyId: "180767300",
								ProposerId: "324647",
								BusinessEventId: "180767309",
								PolicyEventReference: "002",
								PolicyType: "C_BSGP",
								SchemeCode: "999_0005",
								SchemeName: "GAP",
								InsurerCode: "999",
								InsurerName: "Freedom Insurance Guernsey Limited",
								Status: "Offered",
								RtpStatus: "Error",
								PolicyWordingUrl:
									"https://policywording.uat.cdlcloud.co.uk/Freedom_BSGP_PW1022_GAP-2022-10-27-09-29-24.pdf",
								IpidUrl:
									"https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/e083f344-46ba-43e3-bc5d-6d271b601b98",
								IsReferred: false,
								CompulsoryExcess: 0,
								RequestedVoluntaryExcess: 0,
								GrantedVoluntaryExcess: 0,
								Ipt: 24.65,
								IptRate: 12,
								NewBusinessFee: 50,
								Fee: 50,
								AvailablePayMethods: [
									{
										Type: "PayInFull",
									},
									{
										InstalmentType: "Funded",
										Type: "Instalmnts",
									},
								],
								PayInFullPremium: 280.05,
								HasInstalments: true,
								Instalments: [
									{
										Amount: 3304,
										AprRate: 36.8,
										Deposit: 6,
										DepositFee: 0,
										FacilityFee: 0,
										FlatInterestAmount: 0,
										FundedInd: true,
										FundedRecourse: "Yes",
										FundingCompanyDetailsId: "88768243",
										Id: "180767332",
										InstalmentType: "Funded",
										InstalmentTotalSellingPrice: 33046,
										InterestPercentage: 18,
										InterestTotal: 5041,
										IsContinuable: false,
										NumberOfPayments: "10",
										PaymentFrequency: "OneMonth",
										SchemeCode: "QTFNB",
										SchemeName: "GAP/PL NB 20% deposit 10 payments",
										VariedAmount: 0,
										VariedRepaymentStatus: "None",
										HasVariedPayment: false,
										NumberOfPaymentsToDisplay: 10,
										DepositAmount: 0.06,
										InstalmentAmount: 33.04,
										VariedPaymentAmount: 0,
										ArrangementFee: 0,
										TotalAmountToPay: 330.46,
										TotalAmountOfCredit: 279.99,
										TotalCostOfCredit: 50.41,
										DepositRate: 0.000214247455811462,
										InterestRateFactor: 0.18,
										InstalmentFactor: 0.117974718800214,
										ThirdPartyFee: 0,
										DurationDifference: 26,
									},
								],
								Ipids: [
									{
										DocumentID: "402933399",
										Guid: "e083f344-46ba-43e3-bc5d-6d271b601b98",
										IpidRequired: true,
										Url: "https://docengine.uat.cdlcloud.co.uk/DocEngine/v1/pdfById/e083f344-46ba-43e3-bc5d-6d271b601b98",
									},
								],
								TierInfo: {
									TierId: "STAN",
									IsSelected: true,
									PriceHighlight: 0,
								},
								PrecisWordings: [
									{
										Id: "2232000",
										MetaData: "",
										Status: "NORM",
										Text: "<p>Business and Domestic Insurance Services</p>",
										Type: "COMP",
									},
									{
										Id: "2253500",
										MetaData: "",
										Status: "NORM",
										Text: "<p>Total Loss Protect</p>",
										Type: "SCHE",
									},
									{
										Id: "2032000",
										MetaData: "",
										Status: "ERRO",
										Text: '<span class="error">There was an error retrieving the Policy Summary.  Please call for details.</span>',
										Type: "POLS",
									},
									{
										Id: "4066597",
										MetaData: "",
										Status: "NORM",
										Text: "https://policywording.uat.cdlcloud.co.uk/Freedom_BSGP_PW1022_GAP-2022-10-27-09-29-24.pdf",
										Type: "POLW",
									},
									{
										Id: "4118597",
										MetaData: "----",
										Status: "NORM",
										Text: "<p>There are no excesses.</p>",
										Type: "EXCE",
									},
									{
										Id: "4118598",
										MetaData: "----",
										Status: "NORM",
										Text: "<p>There are no endorsements.</p>",
										Type: "ENDO",
									},
									{
										Id: "4119597",
										MetaData: "TCLAIM",
										Status: "NORM",
										Text: "<p>Claim Limit: &pound;5000</p>",
										Type: "TERM",
									},
								],
								TermsAndConditions: [
									{
										Id: "52138557",
										Text: "Claim Limit:\r\n",
										Code: "TCLAIM",
										Value: 500000,
										ExcessType: "None",
										GeneratedCurrencyValue: 0,
										GeneratedPercentageValue: 0,
										OverrideCurrencyValue: 0,
										OverridePercentageValue: 0,
										Overridden: false,
									},
								],
								DisplayExcesses: [
									{
										Id: "-----1",
										Value: "precis-text",
										Text: "There are no excesses.",
										SortOrder: 0,
									},
								],
								DisplayEndorsements: [
									{
										Id: "-----0",
										Value: "precis",
										Text: "<p>There are no endorsements.</p>",
										SortOrder: 0,
									},
								],
								DisplayTerms: [
									{
										Id: "TCLAIM-0",
										Value: "precis",
										Text: "<p>Claim Limit: £5000</p>",
										SortOrder: 0,
									},
								],
								Affinity: "FREEDOM",
								WebReference: "69614-93310-58386",
								TransactionId: "180767329",
							},
						],
					};

					return retData;
				}

				const response = await services.recall.recallAggDeeplink({
					DeeplinkId: payload.token,
					Requote: false,
					IncludeUpgrades: true,
				});

				return response;
			},
		},
		dictionary: {
			baseRoute: baseRoute,
			services: {
				lookup: {
					vehicle: {
						van: services.search.van.dvlaSearch,
						car: services.search.car.dvlaSearch,
						owner: services.lookups.vehicle.owner,
					},
					address: services.search.address,
				},
			},
		},
	});

	const valueTree = useSelector((state) => {
		if (!config.isDev) return {};
		return commonData.risk.selectors.valueTree(state, "");
	}, _.isEqual);

	// console.log("Container.jsx", { baseRoute, commonData });

	//SAFETY CHECKS
	{
		const fnThrowError = (msg, debugInfo = {}) => {
			console.log("ERROR INFO:", debugInfo);
			throw msg;
		};
		const currentQuote = commonData.quote.data.currentQuote;

		if (currentQuote && !currentQuote?.TierInfo?.TierId) {
			fnThrowError("Error -- currentQuote is missing TierInfo.TierId", {
				currentQuote,
			});
		}
	}

	return (
		<>
			<Outlet context={commonData} />

			<DevContainer></DevContainer>

			<DevContainer>
				<Button
					onClick={() => commonData.functions.session.save()}
					className="btn-secondary"
				>
					Persist save
				</Button>

				<Button
					onClick={() =>
						commonData.functions.storeHelpers.migration.outputInfo()
					}
					className="btn-secondary"
				>
					Output info
				</Button>

				<Button
					onClick={() => {
						sessionStorage.removeItem("sessionId");
						location.reload();
					}}
					className="btn-secondary"
				>
					Clear persist
				</Button>

				<Button
					onClick={() => {
						setForceFinaliseFail(!forceFinaliseFail);
					}}
					className="btn-secondary"
				>
					Payment force fail = {forceFinaliseFail ? "TRUE" : "FALSE"}
				</Button>

				<JSONViewer>{valueTree}</JSONViewer>
			</DevContainer>

			{/* <DevContainer>
			commonData.functions.navigate.to(dictionary.sections.start);
			</DevContainer> */}

			<DevContainer>
				<Button
					onClick={() =>
						commonData.functions.storeHelpers.migration.templateToSalus()
					}
				>
					templateToSalus
				</Button>
			</DevContainer>

			<DevContainer enabled={false}>
				<p>This info may be out of date</p>
				<h5>Step 1</h5>
				<p>Paste Recall Response below to convert to a basic template:</p>
				<TextBox
					onChange={(v) => {
						const salusData = JSON.parse(v);
						commonData.functions.storeHelpers.migration.salusToTemplate({
							Risk: salusData.Risk,
						});
					}}
				/>

				<h5>Step 2</h5>
				<p>Copy the contents in the console into the template file</p>

				<h5>Step 3</h5>
				<p>Reload the site</p>
				<h5>Step 4</h5>
				<p>
					<Button
						onClick={() =>
							commonData.functions.storeHelpers.migration.templateToSalus()
						}
					>
						templateToSalus
					</Button>
					This will run the template data against all the rules, thus adding all
					the ERRORS.
				</p>

				<h5>Step 2</h5>
				<p>
					Copy the contents in the console into the default.json file (which
					will now have the ERRORS)
				</p>
			</DevContainer>
		</>
	);
};

export default ViewGapContainer;
