import React from "react";
import Base from "../Base";
import { Button } from "siteComponentsLibrary/Buttons";
import { Error } from "siteComponentsLibrary/Feedback";

const LayoutPageGap = (props) => {
	const { children, pagerData = {}, ...otherProps } = props;

	const {
		isPagerEnabled = false,
		fnOnPrev,
		fnOnNext,
		labelNext = "Next",
		labelPrevious = "Back",
		isLoadingNext = false,
		isLoadingPrevious = false,
		errorMessage,
	} = pagerData;
	// useScrollToTop();

	return (
		<Base {...otherProps} siteName="GAP Insurance">
			{children}

			{/* We want the errors above the navigation buttons */}
			{props.errorMessage && (
				<div className="mt-2" data-cy="page-error-container">
					<Error>{props.errorMessage}</Error>
				</div>
			)}

			{isPagerEnabled && (
				<div className="page-navigation row" data-cy="navigation-buttons">
					{fnOnPrev && (
						<div className="col-12 col-md-3 order-2 order-md-1">
							<Button
								onClick={fnOnPrev}
								className="w-100 btn-outline-dark pager-back"
								data-cy="btn:previous"
								isLoading={isLoadingPrevious}
							>
								{labelPrevious}
							</Button>
						</div>
					)}
					{fnOnNext && (
						<div
							className={[
								"col-12 order-1 order-md-2 mb-2 mb-md-0",
								fnOnPrev && "col-md-9",
							].toClassName()}
						>
							<Button
								onClick={fnOnNext}
								className="w-100 btn-primary pager-next"
								data-cy="btn:next"
								isLoading={isLoadingNext}
							>
								{labelNext}
							</Button>
						</div>
					)}
				</div>
			)}
		</Base>
	);
};

export default LayoutPageGap;
