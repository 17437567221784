import moment from "moment";

const getProps = (item) => {
	return {
		componentTag: item.componentTag,
		componentSet: item.componentSet,
		useDefaultComponentSet: item.componentSet === undefined,
	};
};

const requiredMappings = ["YearsHeld", "NotYetObtained", "Date"];

export default (args) => {
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;
	return; // disabled
	if (!dataSet) throw `Error -- missing dataSet`;
	if (!functionArgs) throw `Error -- missing functionArgs`;

	let values = {};
	let dataSetProps = {};
	let errorMessages = {};
	let newVisible = {};
	let newValue = {};
	let newDisabled = {};

	const { curKey } = functionArgs;

	requiredMappings.forEach((key) => {
		if (!(key in functionArgs)) throw `Error -- Missing mapping ${key}`;
		const curProps = getProps(functionArgs[key]);
		const curValue = dataSet.getValue({
			key: "value",
			...curProps,
		});

		dataSetProps[key] = curProps;
		values[key] = curValue;
	});

	if (curKey === "NotYetObtained" && values.NotYetObtained !== true) {
		newValue.YearsHeld = undefined;
		newValue.Date = undefined;
	}

	newDisabled.YearsHeld = values.NotYetObtained === true;
	//newValue.YearsHeld = values.NotYetObtained === true ? 5 : newValue.YearsHeld;
	//newVisible.YearsHeld = values.NotYetObtained !== true;

	if (curKey === "YearsHeld") {
		newValue.Date = moment().startOf("day").add(values.YearsHeld, "y").toDate();
	}

	//Update values
	requiredMappings.forEach((key) => {
		const curProps = dataSetProps[key];

		dataSet.setValue({
			key: "errors",
			value: {
				errorMessage: errorMessages[key],
				errorKey: functionArgs.errorKey,
			},
			...curProps,
		});

		if (newVisible[key] !== undefined)
			dataSet.setValue({
				key: "visible",
				value: newVisible[key],
				...curProps,
			});
		if (newValue[key] !== undefined)
			dataSet.setValue({
				key: "value",
				value: newValue[key],
				...curProps,
			});
		if (newDisabled[key] !== undefined)
			dataSet.setValue({
				key: "disabled",
				value: newDisabled[key],
				...curProps,
			});
	});
};
