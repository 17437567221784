const validationLists = {
	start: [
		"Risk/Vehicle/VehicleType",
		"Risk/PolicyInceptionDate",
		"ChosenOptions/StartConfirm",
	],
	yourDetails: [
		"Risk/Proposer/Name/TitleGender",
		"Risk/Proposer/Name/Forenames",
		"Risk/Proposer/Name/Surname",
		"Risk/Proposer/Name/Title",
		"Risk/Proposer/Gender",
		"Risk/Proposer/DateOfBirth",
		"Risk/Proposer/Address/HouseNameOrNumber",
		"Risk/Proposer/Address/Address1",
		"Risk/Proposer/Address/Address2",
		"Risk/Proposer/Address/Address3",
		"Risk/Proposer/Address/Address4",
		"Risk/Proposer/Address/Postcode",
		"Risk/Proposer/Email",
		"ChosenOptions/EmailConfirm",
		"Risk/Proposer/MainTelephone/TelephoneNumber",
	],
	yourVehicle: [
		"Risk/Vehicle/*",
		"Risk/HireOrRewardUse",
		"Risk/IsInsuranceWriteOff",
		"Risk/BoughtFromDealer",
		"ChosenOptions/VehicleExtraDetailsConfirmation",
		"ChosenOptions/OwnerKeeperQuestion",
	],
};

export default validationLists;
