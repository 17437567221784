// features/Documents/DocumentsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
	createFnGenerateAction,
	createFnGenerateActionWithThunkAPI,
} from "../common";

const generateStore = (args = {}) => {
	const { storeName, services, selectors = {} } = args;

	const fnGenerateAction = createFnGenerateAction(storeName);
	const fnGenerateActionWithThunkApi =
		createFnGenerateActionWithThunkAPI(storeName);

	const getQuote = fnGenerateActionWithThunkApi(
		"getQuote",
		async (args = {}, thunkApi) => {
			const state = thunkApi.getState();

			const servicePayload = selectors.userData.quote.payloads.getQuote(state);
			const response = await services.getQuote(servicePayload);
			return { args: args, request: servicePayload, response: response };
		}
	);

	return {
		getQuote,
	};
};

export default generateStore;
