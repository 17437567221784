import React from "react";
// import * as siteComponents from "libraryComponents";
import dateHelpers from "@library/common/helpers/misc/date";

import siteComponents from "../../library/All";

const { gapQuoteAndBuy } = require("siteStore").stores;

const { helpers } = gapQuoteAndBuy;

// Which props to inject
const basePropConfig = {
	hidden: true,
	label: true,
	id: true,

	error: false,
	errorShow: false,
	helpText: false,
	helpTextFurther: false,
	debugData: false,
};

const fnComposeComponent = (Component, otherOptions = {}) => {
	console.log("COMPOSING:", Component);
	return helpers.composeComponent(Component, {
		templatePropsPath: "databaseProps",
		...otherOptions,
	});
};

export const FormGroup = fnComposeComponent(siteComponents.FormGroup, {
	debugInfo: "FormGroup",
	propConfig: {
		...basePropConfig,
		error: true,
		errorShow: true,
		helpText: true,
		helpTextFurther: true,
		debugData: true,
	},
});

export const DatePickerTextBox = fnComposeComponent(
	siteComponents.DatePickerTextBox,
	{
		fnModifyValueInput: (v) => dateHelpers.toDate(v),
		fnModifyValueOutput: (v) => dateHelpers.toString(v),
		debugInfo: "DatePickerTextBox",
		propConfig: basePropConfig,
	}
);

export const DatePickerWithButtons = fnComposeComponent(
	siteComponents.DatePickerWithButtons,
	{
		fnModifyValueInput: (v) => dateHelpers.toDate(v),
		fnModifyValueOutput: (v) => dateHelpers.toString(v),
		debugInfo: "DatePickerWithButtons",
		propConfig: basePropConfig,
	}
);

export const InceptionDate = fnComposeComponent(siteComponents.InceptionDate, {
	fnModifyValueInput: (v) => dateHelpers.toDate(v),
	fnModifyValueOutput: (v) => dateHelpers.toString(v),
	debugInfo: "InceptionDate",
	propConfig: basePropConfig,
});

// export const InceptionDateCorrection = fnComposeComponent(
// 	siteComponents.InceptionDateCorrection,
// 	{
// 		fnModifyValueInput: (v) => dateHelpers.toDate(v),
// 		fnModifyValueOutput: (v) => dateHelpers.toString(v),
// 		debugInfo: "InceptionDateCorrection",
// 		propConfig: basePropConfig,
// 	}
// );

export const TextBox = fnComposeComponent(siteComponents.TextBox, {
	debugInfo: "TextBox",
	propConfig: basePropConfig,
});

export const TextBoxTelephone = fnComposeComponent(
	siteComponents.TextBoxTelephone,
	{
		debugInfo: "TextBoxTelephone",
		propConfig: basePropConfig,
	}
);

export const TextBoxMobile = fnComposeComponent(siteComponents.TextBoxMobile, {
	debugInfo: "TextBoxMobile",
	propConfig: basePropConfig,
});

export const TextBoxCurrency = fnComposeComponent(
	siteComponents.TextBoxCurrency,
	{
		debugInfo: "TextBoxCurrency",
		propConfig: basePropConfig,
	}
);

export const TextBoxNumber = fnComposeComponent(siteComponents.TextBoxNumber, {
	debugInfo: "TextBoxNumber",
	propConfig: basePropConfig,
});

// FormRowWithError: fnComposeComponent(siteComponents.FormRowWithError),
export const Error = fnComposeComponent(siteComponents.Error, {
	debugInfo: "Error",
	propConfig: basePropConfig,
});
export const TextBoxPostcode = fnComposeComponent(
	siteComponents.TextBoxPostcode,
	{
		debugInfo: "TextBoxPostcode",
		propConfig: basePropConfig,
	}
);

export const DropDownType = fnComposeComponent(siteComponents.DropDownType, {
	debugInfo: "DropDownType",
	propConfig: basePropConfig,
});
export const DropDownTypeWebService = fnComposeComponent(
	siteComponents.DropDownTypeWebService,
	{
		debugInfo: "DropDownTypeWebService",
		propConfig: basePropConfig,
	}
);
export const RadioButtonsWebService = fnComposeComponent(
	siteComponents.RadioButtonsWebService,
	{
		debugInfo: "RadioButtonsWebService",
		propConfig: basePropConfig,
	}
);
export const RadioButtons = fnComposeComponent(siteComponents.RadioButtons, {
	debugInfo: "RadioButtons",
	propConfig: basePropConfig,
});

export const RadioButtonsYesNo = fnComposeComponent(
	siteComponents.RadioButtonsYesNo,
	{
		debugInfo: "RadioButtonsYesNo",
		propConfig: basePropConfig,
	}
);

export const CheckboxButton = fnComposeComponent(
	siteComponents.CheckboxButton,
	{
		debugInfo: "CheckboxButton",
		propConfig: basePropConfig,
	}
);
