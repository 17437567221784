import React from "react";
// import * as siteComponents from "libraryComponents";

const { gapQuoteAndBuy: helpers } = require("siteStore").helpers;
const { gapQuoteAndBuy } = require("siteStore").stores;

import siteComponents from "libraryComponents/All";

const baseArgs = {
	store: gapQuoteAndBuy,
	helpers: helpers,
};

import * as baseRetData from "./baseComponents";
export * from "./baseComponents";

// const generators = require("./generators").default({
// 	...baseArgs,
// 	baseComponents: baseRetData,
// 	siteComponents: siteComponents,
// });

// export const generateOccupation = generators.generateOccupation;
// export const generateAddress = generators.generateAddress;
// export const generateVehicle = generators.generateVehicle;
