import React, { useState, useEffect } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import LayoutPageGap from "siteLayouts/Page/Gap";
import ErrorBaseView from "../../../Errors/Base";
import { Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { PriceDisplay } from "siteComponentsLibrary/Display";
import {
	TelephoneNumberClaims,
	TelephoneNumberService,
} from "siteComponents/Misc";

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

const fnFormatDate = (d) => {
	if (!d) throw `Error in fnFormatDate -- missing date`;
	return dayjs(d).format("Do MMMM YYYY");
};

const ViewsGapRiskWrapup = (props) => {
	const { layoutProps = {} } = props;

	const commonData = useOutletContext();
	const currentQuote = commonData.quote.data.currentQuote;
	const wrapupData = commonData.wrapup.data;

	if (!wrapupData) return null;

	if (wrapupData.isError)
		return (
			<ErrorBaseView
				heading="We're very sorry"
				icon="icon-error"
				SectionLayout={({ children }) => <>{children}</>}
				PageLayout={(props) => <LayoutPageGap {...props} />}
			>
				<div>There's been a problem processing your payment.</div>
				<div>
					Please call us on{" "}
					<strong>
						<TelephoneNumberService />
					</strong>{" "}
					and one of our agents will be happy to help.
				</div>
				{currentQuote?.WebReference && (
					<div className="mt-3">
						Your reference number is:{" "}
						<strong> {currentQuote?.WebReference}</strong>
					</div>
				)}
				<div className="mt-5">
					<div className="alert alert-danger text-center p-3">
						<strong>Please do not attempt to make the payment again.</strong>
					</div>
				</div>
			</ErrorBaseView>
		);

	return (
		<LayoutPageGap headingText="Thank you" {...layoutProps}>
			<Panel
				header="Your GAP policy has been set up"
				data-cy="panel:thankyou-complete"
			>
				<PanelSection data-cy="section:cover-details">
					{(function () {
						if (!wrapupData.IsSuccess)
							return (
								<div>
									Your reference number is{" "}
									<strong>{wrapupData.WebReference}</strong> and your cover will
									start on{" "}
									<strong>{fnFormatDate(wrapupData.PolicyStartDate)}</strong>.
								</div>
							);

						return (
							<div>
								Your policy number is <strong>{wrapupData.PolicyNumber}</strong>{" "}
								and your cover will start on{" "}
								<strong>{fnFormatDate(wrapupData.PolicyStartDate)}</strong>.
							</div>
						);
					})()}
				</PanelSection>

				<PanelSection data-cy="section:next-steps" heading="What happens next?">
					<div>
						Your policy documents will be sent to{" "}
						<strong>{wrapupData.Email}</strong>
					</div>
					<div>
						Should you need any assistance with a claim please call{" "}
						<TelephoneNumberClaims /> or if you have any questions at all,
						please don't hesitate to get in touch with us on{" "}
						<TelephoneNumberService />.
					</div>
				</PanelSection>

				<PanelSection>
					<div className="alert alert-success mt-4 p-3 text-center">
						<small>
							We'll occasionally send you emails about your policy. Please add{" "}
							<strong>customerservices@freedombrokers.co.uk</strong> to your
							contact list or address book so you never miss any. Alternatively,
							please check your spam or junk folder regularly.
						</small>
					</div>
				</PanelSection>
			</Panel>
		</LayoutPageGap>
	);
};

export default ViewsGapRiskWrapup;
