import React from "react";
import { list, set, item } from "@library/common/database/classes";

import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";
import constants from "libraryConstants/database";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import generateBaseRuleData from "../../../generateBaseRuleData";
import generateName from "./Name";
import Occupation from "./Occupation";
import generateLicence from "./Licence";
import Claim from "./Claim";
import DateOfBirth from "./DateOfBirth";
import generateAddress from "./Address";
import generateTelephone from "./Telephone";

import regExConstants from "@library/common/constants/regEx";

// const generateComponentSetProps = (componentSet, postFix) => {
// 	if (componentSet) return { componentSet: `${componentSet}/${postFix}` };

// 	return {};
// };

const generateRules = (args = {}) => {
	const { msgDateOfBirth } = args;
	const nameRule = new ruleItem(
		{
			...generateBaseRuleData("vehicle", "nameLogic"),
		},
		{
			Title: new dataSetArgItem({
				componentTag: "Title",
				componentSetPath: ["Name"],
			}),
			Surname: new dataSetArgItem({
				componentTag: "Surname",
				componentSetPath: ["Name"],
			}),
			Salutation: new dataSetArgItem({
				componentTag: "Salutation",
				componentSetPath: ["Name"],
			}),
			TitleGender: new dataSetArgItem({
				componentTag: "TitleGender",
				componentSetPath: ["Name"],
			}),
			Gender: new dataSetArgItem({
				componentTag: "Gender",
				useDefaultComponentSet: true,
			}),
		}
	);

	const occupationRuleSecondary = new ruleItem(
		{
			...generateBaseRuleData("vehicle", "secondaryOccupationLogic"),
		},
		{
			HasSecondOccupation: new dataSetArgItem({
				componentTag: "HasSecondOccupation",
				useDefaultComponentSet: true,
			}),

			Type: new dataSetArgItem({
				componentTag: "Type",
				componentSetPath: ["PartTimeOccupation"],
			}),
			OccCode: new dataSetArgItem({
				componentTag: "OccCode",
				componentSetPath: ["PartTimeOccupation"],
			}),
			EmpCode: new dataSetArgItem({
				componentTag: "EmpCode",
				componentSetPath: ["PartTimeOccupation"],
			}),
		}
	);

	const ruleItemPersonLogic = new ruleItem(
		{
			...generateBaseRuleData("vehicle", "personLogic"),
		},
		{
			...(msgDateOfBirth && { msgDateOfBirth }),
			IsResidentSinceBirth: new dataSetArgItem({
				componentTag: "IsResidentSinceBirth",
				useDefaultComponentSet: true,
			}),
			ResidentSince: new dataSetArgItem({
				componentTag: "ResidentSince",
				useDefaultComponentSet: true,
			}),
			DateOfBirth: new dataSetArgItem({
				componentTag: "DateOfBirth",
				useDefaultComponentSet: true,
			}),
			PolicyInceptionDate: new dataSetArgItem({
				componentTag: "PolicyInceptionDate",
				componentSet: "Risk",
			}),
			LicenceDate: new dataSetArgItem({
				componentTag: "Date",
				componentSetPath: ["Licence"],
			}),
			LicenceType: new dataSetArgItem({
				componentTag: "Type",
				componentSetPath: ["Licence"],
			}),
		}
	);

	return { nameRule, occupationRuleSecondary, ruleItemPersonLogic };
};

export default {
	// logicRule: ruleItemPersonLogic,
	generateKeys: (args = {}, itemArgs = {}) => {
		const { msgDateOfBirth, defaultLicenceCategoryValue, defaultEmailAddress } =
			itemArgs;

		const { nameRule, occupationRuleSecondary, ruleItemPersonLogic } =
			generateRules({ msgDateOfBirth });

		// console.log("ruleItemPersonLogic:", ruleItemPersonLogic);
		return {
			Name: generateName({
				submitKey: "Name",
			}),

			DateOfBirth: new item({
				...DateOfBirth,
				submitKey: "DateOfBirth",
				exampleValue: "1987-01-01T00:00:00",
				label: "Date of birth",
				fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
				fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),
				rules: [
					// new ruleItem({
					// 	...generateBaseRuleData("general", "salusConvertStringToDate"),},{
					// 	runOnUserUpdate: false,
					// 	forceUpdate: true,
					// }),
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter your date of birth.",
						}
					),
					new ruleItem(
						{
							...generateBaseRuleData("general", "dateComparison"),
						},
						{
							errorMessage: "", //"You can't enter a future date",
							yearOffset: 0,
							comparator: "<=",
						}
					),
					new ruleItem(
						{
							...generateBaseRuleData("general", "dateComparison"),
						},
						{
							errorMessage: "", //"You must be younger than 120",
							yearOffset: -120,
							comparator: ">=",
						}
					),

					ruleItemPersonLogic,

					// new ruleItem({
					// 	...generateBaseRuleData("general", "copyValue"),},{
					// 	errorMessage: "Please enter your date of birth.",
					// 	source: {},
					// 	target: {
					// 		overwrite: false,
					// 		componentTag: "ResidentSince",
					// 	},
					// }),

					// new ruleItem({
					// 	...generateBaseRuleData("general", "dateComparison"),},{
					// 	errorMessage: "Your residency needs to be after your DOB",
					// 	compareDate: { componentTag: "ResidentSince" },
					// 	comparator: "<",
					// }),
				],
			}),

			MedicalCondition: new set({
				submitKey: "MedicalCondition",
				items: {
					Claims: new item({ submitKey: "Claims" }),
					Code: new item({ submitKey: "Code" }),
					CoronaryAttacks: new item({ submitKey: "CoronaryAttacks" }),
					CoronaryBypass: new item({ submitKey: "CoronaryBypass" }),
					CoronaryDate: new item({ submitKey: "CoronaryDate" }),
					Date: new item({ submitKey: "Date" }),
					DvlaNotified: new item({ submitKey: "DvlaNotified" }),
					DvlaRestriction: new item({ submitKey: "DvlaRestriction" }),
					InsulinInjections: new item({ submitKey: "InsulinInjections" }),
					InsulinUnits: new item({ submitKey: "InsulinUnits" }),
					Status: new item({ submitKey: "Status" }),
					Treatment: new item({ submitKey: "Treatment" }),
					TreatmentDescription: new item({
						submitKey: "TreatmentDescription",
					}),
					VehicleModified: new item({ submitKey: "VehicleModified" }),
				},
			}),

			AccessToOtherVehicles: new item({
				label:
					"Do you own or use any other vehicle other than the one to be insured?",
				defaultValue: "BLANK",
				submitKey: "AccessToOtherVehicles",
				exampleValue: "Owner",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),
			ResidentSince: new item({
				submitKey: "ResidentSince",
				exampleValue: "1987-01-01T00:00:00",
				label: "When did you become a UK resident?",
				helpText:
					"If you don't know the exact date, please enter as close as possible.",
				defaultVisible: false,
				fnReduxToSalus: (value) => reduxToSalusHelpers.processDate(value),
				fnSalusToRedux: (value) => salusToReduxHelpers.processDate(value),

				rules: [
					// new ruleItem({
					// 	...generateBaseRuleData("general", "salusConvertStringToDate"),},{
					// 	runOnUserUpdate: false,
					// 	forceUpdate: true,
					// }),
					ruleItemPersonLogic,
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter when you became a resident.",
						}
					),

					new ruleItem(
						{
							...generateBaseRuleData("general", "dateComparison"),
						},
						{
							errorMessage: "", // "Your residency date needs to be before today",
							today: true,
							comparator: "<=",
						}
					),
					// new ruleItem({
					// 	...generateBaseRuleData("general", "dateComparison"),},{

					// 	errorMessage: "Your residency needs to be after your DOB",
					// 	compareDate: { componentTag: "DateOfBirth" },
					// 	comparator: ">",
					// }),
					new ruleItem(
						{
							...generateBaseRuleData("general", "dateComparison"),
						},
						{
							errorMessage:
								"Your residency date needs to be before when your policy starts",
							compareDate: new dataSetArgItem({
								componentTag: "PolicyInceptionDate",
								componentSet: "Risk",
							}),
							comparator: "<=",
						}
					),
				],
			}),

			IsResidentSinceBirth: new item({
				submitKey: "IsResidentSinceBirth",
				html: { itemData: constants.lists.yesNo },
				label: "Have you been a UK resident since birth?",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
						}
					),
					ruleItemPersonLogic,
				],
			}),

			HasSecondOccupation: new item({
				submitKey: "HasSecondOccupation",
				exampleValue: false,
				label: "Do you have a second occupation?",
				html: {
					itemData: constants.lists.yesNo,
				},
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
					occupationRuleSecondary,
				],
			}),

			FullTimeOccupation: Occupation.FullTimeOccupation({
				//...generateComponentSetProps(args.componentSet, "FullTimeOccupation"),
				submitKey: "FullTimeOccupation",
			}),
			PartTimeOccupation: Occupation.PartTimeOccupation({
				//...generateComponentSetProps(args.componentSet, "PartTimeOccupation"),
				submitKey: "PartTimeOccupation",
				// submitIfUndefined: true,
			}),

			Licence: generateLicence(
				{
					//	...generateComponentSetProps(args.componentSet, "Licence"),
					submitKey: "Licence",
				},
				{
					defaultLicenceCategoryValue,
					IsResidentSinceBirth: new dataSetArgItem({
						componentTag: "IsResidentSinceBirth",
						componentSetPath: [".."],
					}),
					ResidentSince: new dataSetArgItem({
						componentTag: "ResidentSince",
						componentSetPath: [".."],
					}),
					DateOfBirth: new dataSetArgItem({
						componentTag: "DateOfBirth",
						componentSetPath: [".."],
					}),
					PolicyInceptionDate: new dataSetArgItem({
						componentTag: "PolicyInceptionDate",
						componentSet: "Risk",
					}),
				}
			),

			Gender: new item({
				submitKey: "Gender",
				exampleValue: "M",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter your gender",
						}
					),
					nameRule,
				],
			}),

			InsuranceRefused: new item({
				label:
					"Have you ever had insurance declined, cancelled or special terms imposed?",
				submitKey: "InsuranceRefused",
				exampleValue: false,
				html: { itemData: constants.lists.yesNo },
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			MaritalStatus: new item({
				submitKey: "MaritalStatus",
				exampleValue: "S",
				label: "Marital status",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter your marital status",
						}
					),
				],
			}),

			NonRTAConviction: new item({
				label: "Do you have any unspent non-motoring criminal convictions?",
				submitKey: "NonRTAConviction",
				exampleValue: false,
				helpTextFurther: (
					<span>
						An unspent conviction is a conviction that is still on your criminal
						record. Some convictions are removed from your criminal record after
						a certain length of time, whereas other convictions can stay on a
						criminal record for life.
					</span>
				),
				html: { itemData: constants.lists.yesNo },
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			Smoker: new item({
				submitKey: "Smoker",
				defaultValue: false,
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			Tests: new item({
				submitKey: "Tests",
				defaultValue: "0",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			VehicleUse: new item({
				submitKey: "VehicleUse",
				label: "How often will this driver drive the vehicle?",
				exampleValue: "MainUser",
				// rules: [
				// 	new ruleItem({
				// 		...generateBaseRuleData("general", "isRequired"),},{
				// 		errorMessage: "", // errorMessage: "Required",
				// 	}),
				// ],
			}),

			StarRider: new item({
				submitKey: "StarRider",
				defaultValue: "No",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			DvlaRestriction: new item({
				submitKey: "DvlaRestriction",
				label:
					"Do you have any DVLA reported medical conditions or disabilities?",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),

			ConsentForConvictions: new item({
				submitKey: "ConsentForConvictions",
				exampleValue: "NotKnown",
				// rules: [
				// 	new ruleItem({
				// 		...generateBaseRuleData("general", "isRequired"),},{
				// 		errorMessage: "", // errorMessage: "Required",
				// 	}),
				// ],
			}),

			Claims: Claim({
				submitKey: "Claims",
			}),

			// TelephoneNumbers: generateTelephone({
			// 	submitKey: "TelephoneNumbers",
			// }),
			Email: new item({
				submitKey: "Email",
				defaultValue: defaultEmailAddress, //  "dummy@freedombrokers.co.uk",
				label: "Email address",
				html: { maxLength: 50 },
				exampleValue: "sam@action365.co.uk",
				rules: [],
			}),
			RelationshipToProposer: new item({
				submitKey: "RelationshipToProposer",
				exampleValue: "Proposer",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "",
							// errorMessage: "Please enter the relationship.",
						}
					),
				],
			}),

			// HomeOwner: new item({
			// 	// componentTag:  "HomeOwner",
			// 	submitKey: "HomeOwner",
			// 	exampleValue: false,
			// 	html: { itemData: constants.lists.yesNo },
			// 	label: "Are you a homeowner?",
			// }),

			IamCert: new item({
				// componentTag:  "IamCert",
				submitKey: "IamCert",
				//exampleValue: null,
			}),
			// LoyaltyCardNumber: new item({
			// 	// componentTag:  "LoyaltyCardNumber",
			// 	submitKey: "LoyaltyCardNumber",
			// 	exampleValue: null,
			// }),
			// LoyaltyCardLookup: new item({
			// 	// componentTag:  "LoyaltyCardLookup",
			// 	submitKey: "LoyaltyCardLookup",
			// 	exampleValue: null,
			// }),

			// Id: new item({
			// 	// componentTag:  "Id",
			// 	submitKey: "Id",
			// 	exampleValue: null,
			// }),

			VatRegistered: new item({
				submitKey: "VatRegistered",
				exampleValue: null,
			}),

			FsaClass: new item({
				submitKey: "FsaClass",
				exampleValue: null,
			}),
			MotoringOrg: new item({
				submitKey: "MotoringOrg",
			}),

			ConsentForMedicalConditions: new item({
				submitKey: "ConsentForMedicalConditions",
			}),
			// Userfields: new item({
			// 	// componentTag:  "Userfields",
			// 	submitKey: "Userfields",
			// 	exampleValue: null,
			// }),

			...args,
		};
	},
};
