import React, { useState, useEffect } from "react";
import config from "config";
import { useLocation } from "react-router-dom";

import useSessionTimeout from "moveToLibrary/hooks/useSessionTimeout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "siteFunctions/hooks";
import { useSelector } from "react-redux";
import { actions, selectors } from "siteStore";
import TimeoutView from "siteViews/Errors/Timeout";
import SiteLayoutError from "siteLayouts/Site/Error";

import MaintenanceView from "siteViews/Errors/Maintenance";
import services from "siteService";
const routeDictionary = config.lookups.routes.gap;

export const Timeout = (props) => {
	const { children } = props;
	const [showTimeoutComponent, setShowTimeoutComponent] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [isTimeOut, fnClearTimeout] = useSessionTimeout({
		timeout: 1000 * 60 * 30, //30 minutes
		// timeout: 1000 * 10, //5 seconds
		// timeout: 1000 * 60 * 5, //5 minutes
		onTimeout: async () => {
			// todo: maybe https://stackoverflow.com/questions/59061161/how-to-reset-state-of-redux-store-when-using-configurestore-from-reduxjs-toolki
			await dispatch(actions.session.reset());
			await dispatch(actions.gapQuoteAndBuy.reset());
			await dispatch(actions.storage.reset());

			setShowTimeoutComponent(true);
		},
	});

	const onClearTimeout = async () => {
		await navigate(routeDictionary.sections.start);
		await fnClearTimeout();
		setShowTimeoutComponent(false);
	};

	useEffect(() => {
		const myFn = async () => {
			await fnClearTimeout();
			setShowTimeoutComponent(false);
		};
		myFn();
	}, [pathname]);

	if (showTimeoutComponent)
		return <TimeoutView clearTimeout={onClearTimeout} />;

	return <>{children}</>;
};

export const Maintenance = (props) => {
	const { children } = props;
	const [isInit, setIsInit] = useState(false);
	const [isMaintenance, setIsMaintenance] = useState(false);

	useEffect(() => {
		const myFn = async () => {
			try {
				const response = (await services.status()) || {};

				const { Maintenance = false, BrandInfo } = response;

				// console.log("MaintenanceChecker", response, Maintenance);
				setIsMaintenance(Maintenance);
				setIsInit(true);
			} catch (e) {
				setIsInit(true);
			}
		};
		myFn();
	}, []);

	if (!isInit) return null;

	if (isMaintenance) return <MaintenanceView />;

	return <>{children}</>;
};
