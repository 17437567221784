import React from "react";
export * from "libraryComponents/Buttons";

// *********************************************************
// **** Custom && Overrides (replaces the export above) ****
// *********************************************************
import * as LibComponents from "libraryComponents/Buttons";
import { Pulse } from "./Animated";

export const ButtonToggle = ({ className, ...otherProps }) => (
	<LibComponents.ButtonToggle
		className={[className, "site-button-toggle"].toClassName()}
		{...otherProps}
	/>
);

export const Button = ({ className, ...otherProps }) => (
	<LibComponents.Button
		className={[className, "site-button"].toClassName()}
		LoadingComponent={() => <Pulse size="6px" colour="" />}
		{...otherProps}
	/>
);

export const IconButtonLabel = (props) => (
	<LibComponents.IconButtonLabel
		{...props}
	/>
);