import moment from "moment";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import { tidyPostcode } from "@library/common/helpers/misc/postcode";
import regExConstants from "@library/common/constants/regEx";

const fnValidatePostcode = (v) => {
	if (!v) return false;
	if (v === "") return false;

	return regExConstants.postcode.ukPostcode.test(v);
};
export default (args) => {
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;

	// console.log("dddddddd", functionArgs);
	const dh = new dataHelper({
		mappings: [
			"KeptAtHomeQuestion",
			"KeptPostcode",
			"Postcode",
			"Keeper",
			"Owner",
			"OwnerKeeperQuestion",
			// "PurchasedNotYet",
			"Purchased",
			"PolicyInceptionDate",
		],
		dataSet,
		functionArgs,
	});

	// console.log("vehicleLogic", dh);
	//
	//OwnerKeeper logic

	if (dh.isPostRecall()) {
		//// NOTE: will be called 3 times -- once for each item below
		// if (dh.isUpdated("Keeper", "Owner", "OwnerKeeperQuestion")) {
		const _Keeper = dh.getValue("Keeper");
		const _Owner = dh.getValue("Owner");
		let newOwnerKeeperQuestion;

		if (_Keeper === undefined && _Owner === undefined) {
			newOwnerKeeperQuestion = undefined;
		} else if (_Keeper === true && _Owner === "Proposer") {
			newOwnerKeeperQuestion = true;
		} else {
			newOwnerKeeperQuestion = false;
		}

		dh.setData("value", "OwnerKeeperQuestion", newOwnerKeeperQuestion);

		if (
			dh.isUpdated("KeptPostcode", "Postcode", "KeptAtHomeQuestion") &&
			(dh.getValue("KeptPostcode") !== undefined ||
				dh.getValue("Postcode") !== undefined)
		) {
			dh.setData(
				"value",
				"KeptAtHomeQuestion",
				dh.getValue("KeptPostcode") === dh.getValue("Postcode")
			);
		}
		// }
	} else {
		if (dh.isUpdated("OwnerKeeperQuestion")) {
			if (dh.getValue("OwnerKeeperQuestion") === false) {
				dh.setData("value", "Keeper", undefined);
				dh.setData("value", "Owner", undefined);
			} else if (dh.getValue("OwnerKeeperQuestion") === true) {
				dh.setData("value", "Keeper", true);
				dh.setData("value", "Owner", "Proposer");
			}
		}

		if (
			dh.isUpdated("KeptPostcode") &&
			dh.getValue("KeptAtHomeQuestion") === false
		) {
			dh.setValue("KeptPostcode", tidyPostcode(dh.getValue("KeptPostcode")));
		}

		if (
			dh.isUpdated("KeptAtHomeQuestion", "Postcode") &&
			dh.getValue("KeptAtHomeQuestion") === true
		) {
			dh.setData("value", "KeptPostcode", dh.getValue("Postcode"));
		}

		if (
			dh.isUpdated("KeptAtHomeQuestion") &&
			dh.getValue("KeptAtHomeQuestion") === false
		) {
			dh.setData("value", "KeptPostcode", undefined);
		}
	}

	{
		const PolicyInceptionDate = dh.getValueDate("PolicyInceptionDate");
		const Purchased = dh.getValueDate("Purchased");

		if (
			PolicyInceptionDate &&
			Purchased &&
			moment(PolicyInceptionDate).isBefore(Purchased)
		) {
			dh.addError(
				"Purchased",
				"Your policy start cannot be before the vehicle purchase date."
			);
		}
	}
	// *******************************************
	// ** visible
	// *******************************************

	if (dh.isUpdated("KeptAtHomeQuestion")) {
		dh.setVisible("KeptPostcode", dh.getValue("KeptAtHomeQuestion") === false);
	}

	//** Owner keeper
	if (dh.isUpdated("OwnerKeeperQuestion")) {
		dh.setVisible("Owner", dh.getValue("OwnerKeeperQuestion") === false);
		dh.setVisible("Keeper", dh.getValue("OwnerKeeperQuestion") === false);
	}

	// *******************************************
	// ** ERRORS
	// *******************************************
	["Owner", "Keeper", "KeptPostcode"].forEach((k) => {
		if (dh.getData("visible", k)) {
			dh.addErrorIfEmpty(k, "");

			// if (k === "KeptPostcode" && !fnValidatePostcode(dh.getValue(k))) {
			// 	dh.addError(k, "");
			// }
		}
	});

	dh.commit();
	// console.log("dddd", dh)
};
