import React, { useState, useEffect } from "react";
import { Modal } from "siteComponentsLibrary/Modal";
import { Panel } from "siteComponentsLibrary/Layout";
import { Button } from "siteComponentsLibrary/Buttons";

export const ModalBase = ({ children, onClick }) => (
	<Modal isOpen={true}>
		<Panel>
			<div className="row">
				<div className="col-12 mb-5">{children}</div>
				<div className="col-12 d-flex justify-content-center">
					<Button className="btn-primary w-100" onClick={onClick}>
						Continue
					</Button>
				</div>
			</div>
		</Panel>
	</Modal>
);

const affilateWelcomeDatabase = {
	gapqz: {
		Modal: (props) => {
			return (
				<ModalBase {...props}>
					<div className="row">
						<div className="d-none d-lg-block col-md-2" />
						<div className="col-12 col-lg-8 d-flex justify-content-center p-5">
							<img
								src={require("./assets/quoteZone/Quotezone-logo.png")}
								alt="Quotezone Logo"
								className="w-100"
							/>
						</div>
						<div className="d-none d-lg-block col-md-2" />

						<div className="col-12 text-center">
							Thank you for visiting us from Quotezone for your GAP Insurance
							quote
						</div>
					</div>
				</ModalBase>
			);
		},
	},
	law: {
		Modal: (props) => {
			return (
				<ModalBase {...props}>
					<div className="row">
						<div className="d-none d-lg-block col-md-2" />
						<div className="col-12 col-lg-8 d-flex justify-content-center p-5">
							<img
								src={require("./assets/accidentClaimHelpline/Accident-Claim-Line-Logo-2020.png")}
								alt="Accident Claim Helpline Logo"
								className="w-100"
							/>
						</div>
						<div className="d-none d-lg-block col-md-2" />

						<div className="col-12 text-center">
							Thank you for visiting us from Accident Claim Helpline for your
							GAP Insurance quote
						</div>
					</div>
				</ModalBase>
			);
		},
	},
};

export default affilateWelcomeDatabase;
