import { list, set, item } from "@library/common/database/classes";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";

import constants from "libraryConstants/database";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import generateBaseRuleData from "../../../generateBaseRuleData";
import { Name } from "./Name";

import Conviction from "./Conviction";

const occupationRulePrimary = new ruleItem(
	{
		...generateBaseRuleData("vehicle", "occupationLogic"),
	},
	{
		Type: new dataSetArgItem({
			componentTag: "Type",
			useDefaultComponentSet: true,
		}),
		OccCode: new dataSetArgItem({
			componentTag: "OccCode",
			useDefaultComponentSet: true,
		}),
		EmpCode: new dataSetArgItem({
			componentTag: "EmpCode",
			useDefaultComponentSet: true,
		}),
	}
);

const occupationRuleSecondary = new ruleItem(
	{
		...generateBaseRuleData("vehicle", "secondaryOccupationLogic"),
	},
	{
		HasSecondOccupation: new dataSetArgItem({
			componentTag: "HasSecondOccupation",
			componentSetPath: [".."],
		}),

		Type: new dataSetArgItem({
			componentTag: "Type",
			useDefaultComponentSet: true,
		}),
		OccCode: new dataSetArgItem({
			componentTag: "OccCode",
			useDefaultComponentSet: true,
		}),
		EmpCode: new dataSetArgItem({
			componentTag: "EmpCode",
			useDefaultComponentSet: true,
		}),
	}
);

export default {
	FullTimeOccupation: (args) => {
		return new set({
			...args,
			items: {
				Type: new item({
					submitKey: "Type",
					exampleValue: "None",
					label: "Employment status",
					rules: [
						occupationRulePrimary,
						// new ruleItem({
						// 	...generateBaseRuleData("general", "isRequired"),},{
						// 	errorMessage: "", // errorMessage: "Required",
						// }),
					],
				}),
				OccCode: new item({
					submitKey: "OccCode",
					exampleValue: null,
					label: "Job title",
					helpText:
						"If you can't find the exact job, please select the closest description.",
					rules: [
						occupationRulePrimary,
						// new ruleItem({
						// 	...generateBaseRuleData("general", "isRequired"),},{
						// 	errorMessage: "", // errorMessage: "Required",
						// }),
					],
				}),
				EmpCode: new item({
					submitKey: "EmpCode",
					exampleValue: null,

					label: "Industry you work in",
					helpText:
						"If you can't find the exact industry, please select the closest description.",
					rules: [
						occupationRulePrimary,
						// new ruleItem({
						// 	...generateBaseRuleData("general", "isRequired"),},{
						// 	errorMessage: "", // errorMessage: "Required",
						// }),
					],
				}),
			},
		});
	},

	PartTimeOccupation: (args) => {
		return new set({
			...args,
			items: {
				Type: new item({
					submitKey: "Type",
					exampleValue: "None",
					defaultVisible: false,
					label: "Secondary employment status",
					rules: [occupationRuleSecondary],
				}),
				OccCode: new item({
					submitKey: "OccCode",
					exampleValue: null,

					defaultVisible: false,
					label: "Secondary job title",
					helpText:
						"If you can't find the exact job, please select the closest description.",
					rules: [occupationRuleSecondary],

					/*
						new ruleItem({
						...generateBaseRuleData("general", "runRules"),},{
						componentTagList: ["KeptAtHomeQuestion"],
					}),
					*/
				}),
				EmpCode: new item({
					submitKey: "EmpCode",
					exampleValue: null,

					defaultVisible: false,
					label: "Secondary industry you work in",
					helpText:
						"If you can't find the exact industry, please select the closest description.",
					rules: [occupationRuleSecondary],
				}),
			},
		});
	},
};
