import React, { createContext, useContext, useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { ButtonToggle } from "siteComponentsLibrary/Buttons";
import {
	IptContent,
	PriceDisplay,
	InstalmentDetailsTextNoDeposit,
	InstalmentDetailsTextHasDeposit,
} from "siteComponentsLibrary/Display";
import { TelephoneNumberSales } from "siteComponents/Misc";

import { PanelSection } from "siteComponentsLibrary/Layout";

const Context = createContext();

export const useHowToPayControls = () => {
	const [showErrors, setShowErrors] = useState(false);

	const commonData = useOutletContext();
	const chosenOptions = commonData.quote.data.chosenOptions;
	const isValid = chosenOptions.paymentMethod ? true : false;

	const controls = {
		isValid,
		showErrors,
		setShowErrors,
	};

	return [Context.Provider, controls];
};

const ViewsGapYourQuoteHowToPay = (props) => {
	const commonData = useOutletContext();

	const currentQuote = commonData.quote.data.currentQuote;
	const chosenOptions = commonData.quote.data.chosenOptions;

	const instalmentDetails =
		currentQuote.HasInstalments && currentQuote.Instalments[0];

	const controls = useContext(Context);

	const hasError = !controls.isValid && controls.showErrors;

	return (
		<PanelSection>
			<div
				className={["row", "gap-3", "gap-sm-0", hasError && "has-error"]
					.filter(Boolean)
					.join(" ")}
			>
				{instalmentDetails && (
					<div className="col-12 col-sm-6">
						<ButtonToggle
							onClick={async () =>
								await commonData.quote.functions.choosePaymentMethod("M")
							}
							checked={chosenOptions.paymentMethod === "M"}
							className="w-100 btn-outline-secondary"
						>
							Pay by instalments
						</ButtonToggle>
					</div>
				)}
				<div className="col-12 col-sm-6">
					<ButtonToggle
						onClick={async () =>
							await commonData.quote.functions.choosePaymentMethod("F")
						}
						checked={chosenOptions.paymentMethod === "F"}
						className="w-100 btn-outline-secondary"
					>
						Pay in full
					</ButtonToggle>
				</div>

				{hasError && (
					<div
						className="form-text error-text mt-2 col-12"
						data-cy="feedback:error"
					>
						Please choose a payment method
					</div>
				)}
			</div>

			{instalmentDetails && (
				<div className="mt-4 mb-3">
					<small>
						If you choose to pay by instalments you'll pay a deposit of{" "}
						<PriceDisplay value={instalmentDetails.DepositAmount} /> today
						followed by{" "}
						<strong>{instalmentDetails.NumberOfPaymentsToDisplay}</strong>{" "}
						payments of{" "}
						<PriceDisplay value={instalmentDetails.InstalmentAmount} />. Total
						cost of credit{" "}
						<PriceDisplay value={instalmentDetails.TotalCostOfCredit} />,
						including a{" "}
						<PriceDisplay value={5} decimalPlaces={0} allowZero={true} />{" "}
						facility fee. Total amount of credit{" "}
						<PriceDisplay value={instalmentDetails.TotalAmountOfCredit} />.
						Representative APR {instalmentDetails.AprRate}%.
						<br />
						The length of your monthly payment plan is{" "}
						{instalmentDetails.NumberOfPaymentsToDisplay} months and your policy
						will run for 36 months. The policy term is{" "}
						{instalmentDetails.DurationDifference} months longer than the
						instalment plan.
					</small>
				</div>
			)}

			<div className="alert alert-success mt-4 text-center">
				<IptContent resultType="NB" fee={currentQuote.Fee} />
			</div>


			{false && instalmentDetails && (
				<div className="row">
					<div className="col-auto pe-0">*xxx</div>
					<div className="col ps-1">
						xxxx
						<small className="d-block" data-cy="cost-of-credit">
							Total cost of credit{" "}
							<PriceDisplay value={instalmentDetails.TotalCostOfCredit} />,
							including a{" "}
							<PriceDisplay value={5} decimalPlaces={0} allowZero={true} />{" "}
							facility fee. Total amount of credit{" "}
							<PriceDisplay value={instalmentDetails.TotalAmountOfCredit} />.
							Representative APR {instalmentDetails.AprRate}%.
						</small>
						<small className="d-block" data-cy="duration-diff">
							The length of your monthly payment plan is{" "}
							{instalmentDetails.NumberOfPaymentsToDisplay} months and your
							policy will run for 36 months.
							<br />
							The policy term is {instalmentDetails.DurationDifference} months
							longer than the instalment plan.
						</small>
					</div>
				</div>
			)}
		</PanelSection>
	);
};

export default ViewsGapYourQuoteHowToPay;
