import React, { useState, useEffect } from "react";
import { generateCypressProps } from "componentsLibraryResources/functions/cypress";

import dayjs from "dayjs";
import moment from "moment";

import * as regExConstants from "componentsLibraryResources/constants/regEx";
// import enLocale from "date-fns/locale/en-GB"; //Requires version 2.3.x. Anything later doesn't work with @mui/x-date-pickers

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers";

import { ButtonToggle } from "./Buttons";
import { TextBox } from "./Inputs";

export const DatePickerTextBox = (props) => {
	const {
		value,
		onChange,
		id,
		invalidValue = undefined,
		className,
		classNameDay,
		classNameMonth,
		classNameYear,
		classNameDayInput = "",
		classNameMonthInput = "",
		classNameYearInput = "",
		ariaLabel = "",
		labelDay,
		labelMonth,
		labelYear,
		placeholderDay = "DD",
		placeholderMonth = "MM",
		placeholderYear = "YYYY",
		centerInputs = true,
		showDay = true,
		disabled = false,
		enforceYearFourDigits = true,
		enableAutoFill = false,
		// minYear,
		// maxYear,
	} = props;

	const [hasFilledInAllBoxes, setHasFilledInAllBoxes] = useState(false);

	const [chosenDay, setChosenDay] = useState(showDay ? undefined : 1);
	const [chosenMonth, setChosenMonth] = useState();
	const [chosenYear, setChosenYear] = useState();
	// const [isValid, setIsValid] = useState();

	const _className = ["d-flex", "gap-2", className].filter(Boolean).join(" ");

	const _classNameDay = ["col", "day", classNameDay].filter(Boolean).join(" ");
	const _classNameMonth = ["col", "month", classNameMonth]
		.filter(Boolean)
		.join(" ");
	const _classNameYear = ["col", "year", classNameYear]
		.filter(Boolean)
		.join(" ");

	const _classNameInputBase = [centerInputs ? "text-center" : ""]
		.filter(Boolean)
		.join(" ");
	const _classNameInputDay = [_classNameInputBase, classNameDayInput]
		.filter(Boolean)
		.join(" ");
	const _classNameInputMonth = [_classNameInputBase, classNameMonthInput]
		.filter(Boolean)
		.join(" ");
	const _classNameInputYear = [_classNameInputBase, classNameYearInput]
		.filter(Boolean)
		.join(" ");

	const dayOnChange = (day) => {
		setChosenDay(day);
		onChangePostProcess({ day });
	};
	const monthOnChange = (month) => {
		setChosenMonth(month);
		onChangePostProcess({ month });
	};
	const yearOnChange = (year) => {
		setChosenYear(year);
		onChangePostProcess({ year });
	};

	const onChangePostProcess = (args) => {
		const momentArgs = {
			day: "day" in args ? args.day : chosenDay,
			month: "month" in args ? args.month : chosenMonth,
			year: "year" in args ? args.year : chosenYear,
		};

		let newHasFilledInAllBoxes = hasFilledInAllBoxes;

		if (
			!newHasFilledInAllBoxes &&
			momentArgs.day !== undefined &&
			momentArgs.month !== undefined &&
			momentArgs.year !== undefined
		) {
			setHasFilledInAllBoxes(true);
			newHasFilledInAllBoxes = true;
		}
		if (!newHasFilledInAllBoxes) return;

		if (!momentArgs.day || !momentArgs.month || !momentArgs.year) {
			// setIsValid(false);
			onChange(invalidValue);
			return;
		}

		if (enforceYearFourDigits && momentArgs.year.length <= 3) {
			// setIsValid(false);
			onChange(invalidValue);
			return;
		}
		if (enforceYearFourDigits && momentArgs.year.length <= 3) {
			// setIsValid(false);
			onChange(invalidValue);
			return;
		}

		//Adjust month by "-1"
		momentArgs.month -= 1;

		const m = moment(momentArgs);
		if (m.isValid()) {
			// console.log("setting date", m.toDate());
			// setIsValid(true);
			onChange(m.toDate());
		} else {
			// setIsValid(false);
			onChange(invalidValue);
		}
	};

	// If the incoming "value" has changed
	useEffect(() => {
		if (value !== invalidValue) {
			const testDate = moment(value);
			if (testDate.isValid()) {
				setChosenDay(testDate.date());
				setChosenMonth(testDate.month() + 1);
				setChosenYear(testDate.year());
				setHasFilledInAllBoxes(true);
				return;
			} else {
				onChange(invalidValue);
			}
		} else {
			setChosenDay(undefined);
			setChosenMonth(undefined);
			setChosenYear(undefined);
			//	onChange(invalidValue);
		}
	}, [value]);

	return (
		<div {...generateCypressProps("datepicker", props)} className={_className}>
			<div className={_classNameDay}>
				{showDay && labelDay && (
					<label className="form-label" htmlFor={`${id}_DAY`}>
						{labelDay}
					</label>
				)}

				{showDay && (
					<TextBox
						value={chosenDay}
						id={`${id}_DAY`}
						// onType={dayOnType}
						onChange={dayOnChange}
						placeholder={placeholderDay}
						maxLength="2"
						size="2"
						regEx={regExConstants.numbers.whole}
						required
						disabled={disabled}
						type="tel"
						autoComplete="off"
						className={_classNameInputDay}
						aria-label={labelDay ? "" : `${ariaLabel}: day`}
						data-cy="text:day"
						enableAutoFill={enableAutoFill}
					/>
				)}
			</div>
			<div className={_classNameMonth}>
				{labelMonth && (
					<label className="form-label" htmlFor={`${id}_MONTH`}>
						{labelMonth}
					</label>
				)}

				<TextBox
					value={chosenMonth}
					id={`${id}_MONTH`}
					// onType={monthOnType}
					onChange={monthOnChange}
					placeholder={placeholderMonth}
					maxLength="2"
					size="2"
					regEx={regExConstants.numbers.whole}
					disabled={disabled}
					type="tel"
					autoComplete="off"
					className={_classNameInputMonth}
					aria-label={labelMonth ? "" : `${ariaLabel}: month`}
					data-cy="text:month"
					enableAutoFill={enableAutoFill}
				/>
			</div>
			<div className={_classNameYear}>
				{labelYear && (
					<label className="form-label" htmlFor={`${id}_YEAR`}>
						{labelYear}
					</label>
				)}

				<TextBox
					value={chosenYear}
					id={`${id}_YEAR`}
					// onType={yearOnType}
					onChange={yearOnChange}
					placeholder={placeholderYear}
					maxLength="4"
					size="4"
					regEx={regExConstants.numbers.whole}
					disabled={disabled}
					type="tel"
					autoComplete="off"
					className={_classNameInputYear}
					aria-label={labelYear ? "" : `${ariaLabel}: year`}
					data-cy="text:year"
					enableAutoFill={enableAutoFill}
				/>
			</div>
		</div>
	);
};

export const DatePickerWithButtons = (props) => {
	const {
		onChange,
		value,
		className,
		// label = "Choose your start date",
		// Container = DefaultContainer,
		forceShowCalendar = false,
		filterDaysBefore = undefined,
		filterDaysAfter = undefined,
		filterMinDate,
		filterMaxDate,
		labelDateFallback = "Choose a date",
		showToday = false,
		showTomorrow = false,
	} = props;

	const today = new dayjs().startOf("day");

	const minDate = (function () {
		if (filterMinDate) return filterMinDate;
		if (filterDaysBefore !== undefined)
			return today
				.clone()
				.add(-1 * filterDaysBefore, "days")
				.toDate();

		return undefined;
	})();

	const maxDate = (function () {
		if (filterMaxDate) return filterMaxDate;
		if (filterDaysAfter !== undefined)
			return today.clone().add(filterDaysAfter, "days").toDate();

		return undefined;
	})();

	const _onChange = (value) => onChange(value);

	const buttonData = [
		showToday && {
			label: "Today",
			value: today.clone().toDate(),
			type: "date",
			dataCy: "btn:today",
		},
		showTomorrow && {
			label: "Tomorrow",
			value: today.clone().add(1, "days").toDate(),
			type: "date",
			dataCy: "btn:tomorrow",
		},
	]
		.filter(Boolean)
		.filter((x) => dayjs(x.value).isBetween(minDate, maxDate, "day", "[]"))
		.concat({
			label: (
				<span className="d-flex justify-content-center">
					<span className="pe-2">
						<i className="bi bi-calendar-event" />
					</span>
					<span>Pick a date</span>
				</span>
			),
			showCalendar: true,
			type: "showCalendar",
			dataCy: "btn:show-calendar",
		});

	const isButtonsEnabled = (function () {
		return buttonData.some((x) => x.type === "date");
		// return buttonData
		//   .filter((x) => x.value)
		//   .map((x) => x.value)
		//   .some((v) => dayjs(v).isBetween(minDate, maxDate, "day", "[]"));
	})();

	const isButtonsContainCurrentDate = buttonData
		.filter((b) => b.value)
		.some((b) => dayjs(b.value).isSame(dayjs(value)));

	const [isCalendarVisible, setIsCalendarVisible] = useState(
		(function () {
			if (forceShowCalendar) return true;
			if (!isButtonsEnabled) return true;
			if (value && !isButtonsContainCurrentDate) return true;
			return false;
		})()
	);

	const buttonDataFinal = (function () {
		if (!isButtonsEnabled) return undefined;

		return buttonData.map((b, i) => {
			const isSelected = (function () {
				if (b.value !== undefined) {
					if (forceShowCalendar) return false;
					if (isCalendarVisible) return false;
					if (dayjs(b.value).isSame(dayjs(value))) return true;
					return false;
				}

				if (b.showCalendar) {
					if (isCalendarVisible) return true;
					return false;
				}

				return false;
			})();

			const onClick = (function () {
				if ("value" in b)
					return () => {
						_onChange(b.value);
						if (!forceShowCalendar) setIsCalendarVisible(false);
					};

				if (b.showCalendar)
					return () => {
						if (!forceShowCalendar) setIsCalendarVisible(b.showCalendar);
					};

				return undefined;
			})();

			return (
				<ButtonToggle
					key={i}
					checked={isSelected}
					onClick={onClick}
					className="btn-outline-secondary"
					data-cy={b.dataCy}
				>
					{b.label}
				</ButtonToggle>
			);
		});
	})();

	// console.log("DatePickerWithButtons.jsx", {
	//   buttonData,
	//   buttonDataFinal,
	//   isCalendarVisible,
	//   isButtonsEnabled,
	//   minDate,
	//   maxDate,
	// });

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div
				className={["row", className].filter(Boolean).join(" ")}
				{...generateCypressProps("datepicker-with-buttons", props)}
			>
				{buttonDataFinal && (
					<div className="col-12">
						<div
							className={[
								"radio-buttons",
								"stackable",
								buttonDataFinal.length === 2 ? "two-buttons" : "",
							]
								.filter(Boolean)
								.join(" ")}
						>
							{buttonDataFinal}
						</div>
					</div>
				)}

				{isCalendarVisible && (
					<div className="col-12 col-sm-auto mt-3">
						<StaticDatePicker
							className={"date-picker"}
							showToolbar={true}
							toolbarPlaceholder="Please choose"
							toolbarFormat="EEEE d MMM yyyy"
							value={value ? dayjs(value) : ""}
							onChange={(newValue) => {
								_onChange(newValue.toDate());
							}}
							slotProps={{
								textField: { variant: "outlined" },
								actionBar: { hidden: true },
								toolbar: { hidden: true },
							}}
							minDate={minDate && dayjs(minDate)}
							maxDate={maxDate && dayjs(maxDate)}
							//   disableOpenPicker={true}
						/>
					</div>
				)}

				<div
					className={["col-12", "mt-3"].filter(Boolean).join(" ")}
					data-cy="date-display"
				>
					{value ? (
						dayjs(value).format("dddd DD MMM YYYY")
					) : (
						<span>{labelDateFallback}</span>
					)}
				</div>
			</div>
		</LocalizationProvider>
	);
};
