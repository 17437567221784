import _ from "lodash";
import dayjs from "dayjs";
import * as regExConstants from "@library/common/constants/regEx";

import { rules as ruleHelpers } from "moveToLibrary/redux/quoteandbuy";
import * as constants from "@library/common/constants/salus";
import config from "config";

const itemSet = ruleHelpers.itemSet;

const fnGenerateTitleGender = (function () {
	let lookupLists = undefined;

	require("siteService")
		.default.lookups.person.titleWithoutSessionId()
		.then((response) => {
			if (!response) return;
			lookupLists = response
				.map((x) => x.value)
				.map((x) => ({ ...JSON.parse(x), string: x }));
		});

	const retFn = (title) => {
		if (!lookupLists) return undefined;
		if (!title) return;
		return lookupLists.find(
			(x) => x.title.toUpperCase() === title.toUpperCase()
		)?.string;
	};
	return retFn;
})();

// throw `hh`
export const onPostSalusLoad = (controls, args) => {
	const itemData = new itemSet(
		controls,
		"ChosenOptions/StartConfirm",
		"ChosenOptions/OwnerKeeperQuestion",
		"ChosenOptions/EmailConfirm"
	);

	itemData.run((items) => {
		items["ChosenOptions/StartConfirm"].functions.setValue(true);
		items["ChosenOptions/EmailConfirm"].functions.cloneFrom(
			"Risk/Proposer/Email"
		);
		items["ChosenOptions/OwnerKeeperQuestion"].functions.setValue(true);
	});
};
export const onPostRegistration = (controls, args) => {
	// Nothing here
};

const rules = {
	base: (item, controls, args = {}) => {
		// Ammended to run on every rule
		// const { isPostSalusLoad, isPostRegistration } = args;
		// if (isPostSalusLoad) {
		// 	if (args.isAlreadyRan) return;
		// 	controls
		// 		.find("ChosenOptions/VehicleExtraDetailsConfirmation")
		// 		.functions.setValue(true);
		// }
	},

	additionalInsured: ruleHelpers.createRule(
		(curItem, controls, args) => {
			console.log("additionalInsured", args.arrayAction, {
				curItem,
				controls,
				args,
			});

			const itemData = new itemSet(
				controls,
				"[]/RelationshipToProposer",
				"[]/MaritalStatus",
				"[]/DateOfBirth",
				"Risk/Proposer/DateOfBirth",
				"Risk/Proposer/MaritalStatus",
				"Risk/Proposer/Name/Forenames",
				"Risk/Proposer/Name/Surname",
				"Risk/Proposer/Name/Title"
			);

			if (args.arrayAction) {
				console.log("ACTIONS", controls);
				const parentTree = curItem.functions.getParentTree();

				console.log("ACTIONS", parentTree);

				return;
			}

			// Check relationships
			itemData.run((items) => {
				const parentTree = curItem.functions.getParentTree();

				const RelationshipToProposer = items["[]/RelationshipToProposer"];

				switch (
					parentTree
						.filter((x) => !x.isCurrent)
						.find((x) => {
							const OtherRelationshipToProposer = controls.find(
								x.generatePath("RelationshipToProposer")
							);

							if (
								RelationshipToProposer.value() ===
								OtherRelationshipToProposer.value()
							)
								return OtherRelationshipToProposer;
							return false;
						})
				) {
					case constants.relationship.civilPartner:
					case constants.relationship.spouse:
					case constants.relationship.common: {
						RelationshipToProposer.functions.errors.set(
							"You can only add one spouse or civil partner to your policy."
						);
						break;
					}
					default:
						RelationshipToProposer.functions.errors.clear();
				}
			});

			itemData.runWithUpdatedCheck(
				[
					"[]/RelationshipToProposer",
					"[]/MaritalStatus",
					"[]/DateOfBirth",
					"Risk/Proposer/DateOfBirth",
					"Risk/Proposer/MaritalStatus",
					"Risk/Proposer/Name/Forenames",
					"Risk/Proposer/Name/Surname",
					"Risk/Proposer/Name/Title",
				],
				(items) => {
					console.log("additionalInsured", curItem.path, {
						items,
						constants: constants.relationship,
					});

					const strProposerName =
						[
							items["Risk/Proposer/Name/Title"].value(),
							items["Risk/Proposer/Name/Forenames"].value(),
							items["Risk/Proposer/Name/Surname"].value(),
						].join(" ") || "the proposer";

					if (
						items["[]/RelationshipToProposer"].value() ===
						constants.relationship.spouse
					) {
						if (
							items["[]/MaritalStatus"].value() !==
							constants.maritalStatus.married
						) {
							items["[]/RelationshipToProposer"].functions.errors.set();
							items["[]/MaritalStatus"].functions.errors.set();
						}

						if (
							items["Risk/Proposer/MaritalStatus"].value() !==
							constants.maritalStatus.married
						) {
							const errorMessage =
								"Their relationship doesn't match the marital status of " +
								strProposerName +
								". Please review and update.";

							items["[]/RelationshipToProposer"].functions.errors.set(
								errorMessage
							);
						}
					}

					if (
						items["[]/RelationshipToProposer"].value() ===
						constants.relationship.civilPartner
					) {
						if (
							items["[]/MaritalStatus"].value() !==
							constants.maritalStatus.civilPartnered
						) {
							items["[]/RelationshipToProposer"].functions.errors.set();
							items["[]/MaritalStatus"].functions.errors.set();
						}

						if (
							items["Risk/Proposer/MaritalStatus"].value() !==
							constants.maritalStatus.civilPartnered
						) {
							const errorMessage =
								"Their relationship doesn't match the marital status of " +
								strProposerName +
								". Please review and update.";

							items["[]/RelationshipToProposer"].functions.errors.set(
								errorMessage
							);
						}
					}
				}
			);
		}
		// { errorKey: "additionalInsured" }
	),
	occupationLogic: ruleHelpers.createRule((item, controls, args) => {
		const { isPostSalusLoad, isPostRegistration } = args;

		const itemData = new itemSet(
			controls,
			{ key: "Type", path: "./Type" },
			{ key: "OccCode", path: "./OccCode" },
			{ key: "EmpCode", path: "./EmpCode" }
		);

		itemData.runWithUpdatedCheck(["Type"], (items) => {
			if (items["Type"].isUpdated()) {
				switch (items["Type"].value()) {
					case "":
					case undefined: {
						items["OccCode"].functions.hidden.hide();
						items["EmpCode"].functions.hidden.hide();

						if (!isPostRegistration) {
							items["OccCode"].functions.setValue(undefined);
							items["EmpCode"].functions.setValue(undefined);
						}
						break;
					}
					case "None": {
						items["OccCode"].functions.hidden.hide();
						items["EmpCode"].functions.hidden.hide();

						if (!isPostRegistration) {
							items["OccCode"].functions.setValue("220");
							items["EmpCode"].functions.setValue("186");
						}
						break;
					}
					case "Employee": {
						items["OccCode"].functions.hidden.show();
						items["EmpCode"].functions.hidden.show();

						if (!isPostRegistration) {
							items["OccCode"].functions.setValue(undefined);
							items["EmpCode"].functions.setValue(undefined);
						}
						break;
					}
					case "Retired": {
						items["OccCode"].functions.hidden.hide();
						items["EmpCode"].functions.hidden.hide();

						if (!isPostRegistration) {
							items["OccCode"].functions.setValue("R09");
							items["EmpCode"].functions.setValue("947");
						}
						break;
					}

					case "UnEmploy": {
						items["OccCode"].functions.hidden.hide();
						items["EmpCode"].functions.hidden.hide();

						if (!isPostRegistration) {
							items["OccCode"].functions.setValue("U03");
							items["EmpCode"].functions.setValue("186");
						}
						break;
					}

					case "OtherEuro": {
						items["OccCode"].functions.hidden.hide();
						items["EmpCode"].functions.hidden.hide();

						if (!isPostRegistration) {
							items["OccCode"].functions.setValue("43D");
							items["EmpCode"].functions.setValue("949");
						}
						break;
					}
					case "HsePerson": {
						items["OccCode"].functions.hidden.hide();
						items["EmpCode"].functions.hidden.hide();

						if (!isPostRegistration) {
							items["OccCode"].functions.setValue("H09");
							items["EmpCode"].functions.setValue("948");
						}
						break;
					}
					case "FullTmEdu": {
						items["OccCode"].functions.hidden.show();
						items["EmpCode"].functions.hidden.hide();

						if (!isPostRegistration) {
							items["OccCode"].functions.setValue("950");
							items["EmpCode"].functions.setValue(undefined);
						}
						break;
					}

					default: {
						items["OccCode"].functions.hidden.show();
						items["EmpCode"].functions.hidden.show();

						if (!isPostRegistration) {
							items["OccCode"].functions.setValue(undefined);
							items["EmpCode"].functions.setValue(undefined);
						}
					}
				}
			}
		});
	}),

	emailLogic: ruleHelpers.createRule(
		(item, controls, { isPostSalusLoad, isPostRegistration }) => {
			const itemData = new itemSet(
				controls,
				"Risk/Proposer/Email",
				"ChosenOptions/EmailConfirm"
			);

			itemData.runWithUpdatedCheck(
				["Risk/Proposer/Email", "ChosenOptions/EmailConfirm"],
				(items) => {
					if (isPostSalusLoad) return;

					if (items["Risk/Proposer/Email"].isEmpty()) return;
					if (items["ChosenOptions/EmailConfirm"].isEmpty()) return;

					if (
						!_.isEqual(
							items["Risk/Proposer/Email"].value(),
							items["ChosenOptions/EmailConfirm"].value()
						)
					) {
						items["ChosenOptions/EmailConfirm"].functions.errors.set(
							"Your emails don't match"
						);
					}
				}
			);
		}
	),

	startLogic: ruleHelpers.createRule((item, controls, args) => {
		// const itemData = new itemSet(controls, {
		// 	key: "startConfirm",
		// 	path: "ChosenOptions/StartConfirm",
		// });
		// itemData.runWithUpdatedCheck(["startConfirm"], (items) => {
		// 	if (items["startConfirm"].value() === false) {
		// 		items["startConfirm"].functions.errors.set();
		// 	}
		// });
	}),

	proposerLogic: ruleHelpers.createRule(
		(item, controls, args) => {
			// console.log("ddddproposerLogic", args.isAlreadyRan);
			if (args.isAlreadyRan) return;

			const { isPostSalusLoad, isPostRegistration } = args;

			const itemData = new itemSet(
				controls,
				{ key: "dataSurname", path: "Risk/Proposer/Name/Surname" },
				{ key: "dataSalutation", path: "Risk/Proposer/Name/Salutation" },
				{ key: "dataTitle", path: "Risk/Proposer/Name/Title" },
				{ key: "dataGender", path: "Risk/Proposer/Gender" },
				{ key: "dataTitleGender", path: "Risk/Proposer/Name/TitleGender" },
				{ key: "dataPolicyInceptionDate", path: "Risk/PolicyInceptionDate" },
				{ key: "dataDateOfBirth", path: "Risk/Proposer/DateOfBirth" },
				{ key: "dataResidentSince", path: "Risk/Proposer/ResidentSince" }
			);

			// FUTURE CHECK
			itemData.runWithUpdatedCheck(["dataDateOfBirth"], (items) => {
				if (dayjs(items["dataDateOfBirth"].value()).isAfter(dayjs()))
					items["dataDateOfBirth"].functions.errors.set();
			});

			// OLD CHECK
			itemData.runWithUpdatedCheck(["dataDateOfBirth"], (items) => {
				if (
					dayjs(items["dataDateOfBirth"].value()).isBefore(
						dayjs().subtract(120, "year")
					)
				)
					items["dataDateOfBirth"].functions.errors.set();
			});

			// CLONE
			itemData.runWithUpdatedCheck(["dataDateOfBirth"], (items) => {
				items["dataResidentSince"].functions.setValue(
					items["dataDateOfBirth"].value()
				);
			});

			//Min age
			itemData.runWithUpdatedCheck(
				["dataDateOfBirth", "dataPolicyInceptionDate"],
				(items) => {
					const minAge = 17;
					const age = dayjs(items["dataPolicyInceptionDate"].value()).diff(
						items["dataDateOfBirth"].value(),
						"year"
					);

					if (items["dataPolicyInceptionDate"].isEmpty()) return;
					if (items["dataDateOfBirth"].isEmpty()) return;

					if (age < minAge) {
						const errMsg = `You must be ${minAge} on or before the policy start date.`;
						items["dataDateOfBirth"].functions.errors.set(errMsg);
					}
				}
			);

			// NAME LOGIC (IMPORTING)
			itemData.runWithUpdatedCheck(["dataTitle", "dataGender"], (items) => {
				if (!(isPostSalusLoad || isPostRegistration)) return;
				if (items["dataTitle"].isEmpty()) return;
				if (items["dataGender"].isEmpty()) return;

				// #3431
				const newTitleGender = fnGenerateTitleGender(
					items["dataTitle"].value()
				);
				items["dataTitleGender"].functions.setValue(newTitleGender);

				// OLD VERSION
				if (false) {
					const jsonString = JSON.stringify({
						title: items["dataTitle"].value(),
						gender: items["dataGender"].value(),
					});

					items["dataTitleGender"].functions.setValue(jsonString);
				}
			});

			// Name logic (EDITING RISK)
			itemData.runWithUpdatedCheck(["dataTitleGender"], (items) => {
				if (isPostSalusLoad) return;
				// if (isPostRegistration) return;

				items["dataGender"].functions.errors.hide();

				if (items["dataTitleGender"].isEmpty()) {
					items["dataTitle"].functions.setValue(undefined);
					items["dataGender"].functions.setValue(undefined);
					return;
				}
				const jsonData = JSON.parse(items["dataTitleGender"].value());
				items["dataTitle"].functions.setValue(jsonData.title);
				items["dataGender"].functions.setValue(jsonData.gender); //May be blank
			});

			// GENDER VISIBILITY
			itemData.runWithUpdatedCheck(["dataTitleGender"], (items) => {
				const jsonData = JSON.parse(items["dataTitleGender"].value() || "{}");
				if (jsonData.gender) items["dataGender"].functions.hidden.hide();
				else items["dataGender"].functions.hidden.show();
			});

			// Salutation (Needs to be after the name logic rule so that it can use the new values for title and surname)
			itemData.runWithUpdatedCheck(
				["dataSalutation", "dataTitle", "dataSurname"],
				(items) => {
					items["dataSalutation"].functions.setValue(
						[items["dataTitle"].value(), items["dataSurname"].value()].join(" ")
					);
				}
			);
		},
		{
			errorKey: "proposerLogic",
		}
	),

	vehicleLogic: ruleHelpers.createRule(
		(item, controls, args) => {
			if (args.isAlreadyRan) return;

			const { isPostSalusLoad, isPostRegistration } = args;

			const itemData = new itemSet(
				controls,
				{ key: "dataOwner", path: "Risk/Vehicle/Owner" },
				{ key: "dataKeeper", path: "Risk/Vehicle/Keeper" },
				{ key: "dataPolicyInceptionDate", path: "Risk/PolicyInceptionDate" },
				{ key: "dataVehiclePurchased", path: "Risk/Vehicle/Purchased" },
				{ key: "dataBoughtFromDealer", path: "Risk/BoughtFromDealer" },
				{ key: "dataVehiclePaid", path: "Risk/Vehicle/Paid" },
				{
					key: "dataOwnerKeeperQuestion",
					path: "ChosenOptions/OwnerKeeperQuestion",
				}
			);

			itemData.runWithUpdatedCheck(
				[
					"dataVehiclePurchased",
					"dataPolicyInceptionDate",
					"dataVehiclePaid",
					"dataBoughtFromDealer",
				],
				(items) => {
					const vehicleAgeDays = (function () {
						if (items["dataPolicyInceptionDate"].isEmpty()) return undefined;
						if (items["dataVehiclePurchased"].isEmpty()) return undefined;

						return dayjs(items["dataPolicyInceptionDate"].value()).diff(
							items["dataVehiclePurchased"].value(),
							"days"
						);
					})();

					if (vehicleAgeDays !== undefined && vehicleAgeDays <= 180) {
						items["dataBoughtFromDealer"].functions.hidden.show();
						items[
							"dataBoughtFromDealer"
						].functions.errors.setIfMissingAndVisible();

						if (items["dataBoughtFromDealer"].value() === true) {
							items["dataVehiclePaid"].functions.hidden.show();
							items[
								"dataVehiclePaid"
							].functions.errors.setIfMissingAndVisible();
							return;
						}

						items["dataVehiclePaid"].functions.hidden.hide();
						items["dataVehiclePaid"].functions.setValue(undefined);

						return;
					}

					items["dataBoughtFromDealer"].functions.hidden.hide();
					items["dataBoughtFromDealer"].functions.setValue(undefined);

					items["dataVehiclePaid"].functions.hidden.hide();
					items["dataVehiclePaid"].functions.setValue(undefined);
				}
			);
			itemData.runWithUpdatedCheck(
				["dataPolicyInceptionDate", "dataVehiclePurchased"],
				(items) => {
					if (items["dataPolicyInceptionDate"].isEmpty()) return;
					if (items["dataVehiclePurchased"].isEmpty()) return;

					if (
						dayjs(items["dataPolicyInceptionDate"].value()).isBefore(
							dayjs(items["dataVehiclePurchased"].value())
						)
					) {
						items["dataVehiclePurchased"].functions.errors.set(
							"Your policy start cannot be before the vehicle purchase date."
						);

						// ** Commented out, so that the RISK INCOMPLETE SHIELD doesn't show on the VEHICLE PAGE **
						// items["dataPolicyInceptionDate"].functions.errors.set(
						// 	"Your policy start cannot be before the vehicle purchase date"
						// );
					}
				}
			);
			itemData.runWithUpdatedCheck(
				["dataKeeper", "dataOwner", "dataOwnerKeeperQuestion"],
				(items) => {
					if (isPostSalusLoad) {
						const newOwnerKeeperQuestion = (function () {
							if (
								items["dataKeeper"].value() === undefined &&
								items["dataOwner"].value() === undefined
							) {
								return undefined;
							} else if (
								items["dataKeeper"].value() === true &&
								items["dataOwner"].value() === "Proposer"
							) {
								return true;
							} else {
								return false;
							}
						})();

						items["dataOwnerKeeperQuestion"].functions.setValue(
							newOwnerKeeperQuestion
						);

						return;
					}

					if (items["dataOwnerKeeperQuestion"].isUpdated()) {
						switch (items["dataOwnerKeeperQuestion"].value()) {
							case false: {
								items["dataKeeper"].functions.setValue(undefined);
								items["dataOwner"].functions.setValue(undefined);
								break;
							}
							case true: {
								items["dataKeeper"].functions.setValue(true);
								items["dataOwner"].functions.setValue("Proposer");
								break;
							}
						}
					}
				}
			);

			itemData.run((items) => {
				if (
					items["dataOwnerKeeperQuestion"].isUpdated() ||
					isPostSalusLoad ||
					isPostRegistration
				) {
					switch (items["dataOwnerKeeperQuestion"].value()) {
						case false: {
							console.log("HIDE", items["dataOwnerKeeperQuestion"].value());
							items["dataKeeper"].functions.hidden.show();
							items["dataOwner"].functions.hidden.show();
							break;
						}
						default: {
							console.log("SHOW", items["dataOwnerKeeperQuestion"].value());

							items["dataKeeper"].functions.hidden.hide();
							items["dataOwner"].functions.hidden.hide();
							break;
						}
					}
				}
			});

			itemData.run((items) => {
				items[
					"dataOwnerKeeperQuestion"
				].functions.errors.setIfMissingAndVisible();
				items["dataKeeper"].functions.errors.setIfMissingAndVisible();
				items["dataOwner"].functions.errors.setIfMissingAndVisible();
			});
		},
		{
			errorKey: "vehicleLogic",
		}
	),
};

export default rules;
