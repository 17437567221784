import { list, set, item } from "@library/common/database/classes";
import reduxToSalusHelpers from "@library/common/helpers/misc/reduxToSalus";
import salusToReduxHelpers from "@library/common/helpers/misc/salusToRedux";

import constants from "libraryConstants/database";
import ruleItem from "@library/common/database/rules/classes/ruleSet/item";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

import generateBaseRuleData from "../../../generateBaseRuleData";
import Name from "./Name";
import Occupation from "./Occupation";
import Licence from "./Licence";
import regExConstants from "@library/common/constants/regEx";

export default (args = {}) => {
	return new set({
		...args,
		items: {
			// Id: new item({
			// 	componentTag: "Id",
			// 	submitKey: "Id",
			// 	exampleValue: null,
			// }),
			// Extension: new item({
			// 	componentTag: "Extension",
			// 	submitKey: "Extension",
			// 	exampleValue: null,
			// }),
			Usage: new item({
				componentTag: "Usage",
				submitKey: "Usage",
				defaultValue: "Mobile",
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),
				],
			}),
			AreaCode: new item({
				componentTag: "AreaCode",
				submitKey: "AreaCode",
				exampleValue: "01782",
				// rules: [
				// 	new ruleItem({
				// 		...generateBaseRuleData("general", "isRequired"),},{
				// 		errorMessage: "", // errorMessage: "Required",
				// 	}),
				// ],
			}),
			PhoneNumber: new item({
				componentTag: "PhoneNumber",
				submitKey: "PhoneNumber",
				exampleValue: "000000",
				// rules: [
				// 	new ruleItem({
				// 		...generateBaseRuleData("general", "isRequired"),},{
				// 		errorMessage: "", // errorMessage: "Required",
				// 	}),
				// ],
			}),

			TelephoneNumber: new item({
				submitKey: "TelephoneNumber",
				defaultValue: "07777777777",
				label: "Mobile phone number",
				html: { maxLength: 50, regEx: regExConstants.numbers.whole },
				rules: [
					new ruleItem(
						{
							...generateBaseRuleData("general", "isRequired"),
						},
						{
							errorMessage: "", // errorMessage: "Required",
						}
					),

					// TASK 394
					// new ruleItem({
					// 	...generateBaseRuleData("general", "regEx"),},{
					// 	regEx: regExConstants.phoneNumber.mobile,
					// 	errorMessage: "Please enter a valid mobile number.",
					// }),
				],
			}),
		},
	});
};
