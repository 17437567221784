import moment from "moment";
import functions from "../../../general";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

const { dateComparison } = functions;

export default (args) => {
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;
	const dh = new dataHelper({
		mappings: ["MonthBanQuestion", "MonthBan"],
		dataSet,
		functionArgs,
	});

	if (dh.isPostRecall()) {
		dh.setValue("MonthBanQuestion", dh.getValue("MonthBan") !== undefined);
	}

	if (!dh.isPostRecall()) {
		if (dh.isUpdated("MonthBanQuestion")) {
			if (dh.getValue("MonthBanQuestion") === true) {
				dh.setData("visible", "MonthBan", true);
			} else {
				dh.setValue("MonthBan", undefined);
				dh.setData("visible", "MonthBan", false);
			}
		}

		if (
			dh.getValue("MonthBanQuestion") !== false &&
			(dh.getValue("MonthBan") === undefined || dh.getValue("MonthBan") === "")
		) {
			dh.addError("MonthBan", ""); // "Required")
		}
	}

	dh.commit();
};
