import React, { useState } from "react";
import { generateCypressProps } from "componentsLibraryResources/functions/cypress";

import {
	PriceContainer,
	PriceCardStaticDual,
	PriceCardStickyFull,
	PriceCardStickyMonthly,
} from "./PriceDisplay/PriceContainers";

export * from "./PriceDisplay/PriceContainers";
export * from "./PriceDisplay/PriceCards";
export * from "./PriceDisplay/getPriceDetails";
export * from "./PriceDisplay/IptPanels";

export const TelephoneNumber = (props) => {
	const {
		phonenumber,
		altLabel,
		className = "",
		isLink = false,
		isStrong = true,
	} = props;

	const _className = ["whitespace-no-wrap", className]
		.filter(Boolean)
		.join(" ");

	if (!isLink) {
		const noLinkClassName = ["tel-no-link", _className]
			.filter(Boolean)
			.join(" ");

		if (isStrong) {
			return (
				<strong
					className={noLinkClassName}
					{...generateCypressProps("telephone-number", props)}
				>
					{phonenumber}
				</strong>
			);
		}

		return (
			<span
				className={noLinkClassName}
				{...generateCypressProps("telephone-number", props)}
			>
				{phonenumber}
			</span>
		);
	}

	const displayText = altLabel != undefined ? altLabel : phonenumber;
	const display = isStrong ? (
		<strong>{displayText}</strong>
	) : (
		<span>{displayText}</span>
	);

	const linkNumber = phonenumber.replaceAll(" ", "");
	return (
		<a
			className={["tel-link", _className].filter(Boolean).join(" ")}
			href={`tel:${linkNumber}`}
			{...generateCypressProps("telephone-number", props)}
		>
			{display}
		</a>
	);
};

export const Help = (props) => {
	const { value, children, className = "", id } = props;

	if (!value && !children) return null;

	const _className = ["form-text", "help-text", className]
		.filter(Boolean)
		.join(" ");

	return (
		<div id={id} className={_className}>
			{value || children}
		</div>
	);
};

export const HelpFurther = (props) => {
	const { value, children, className, id } = props;
	const [isDisplayed, setIsDisplayed] = useState(false);

	if (!value && !children) return null;

	const _className = ["help-furtherinfo", className].filter(Boolean).join(" ");

	if (!isDisplayed)
		return (
			<div
				className={_className}
				{...generateCypressProps("help-further", props)}
			>
				<button
					className="btn btn-link btn-sm fw-bold ps-0"
					type="button"
					onClick={() => setIsDisplayed(true)}
				>
					More info
				</button>
			</div>
		);

	return (
		<Help id={id} className={_className}>
			{value || children}
		</Help>
	);
};

export const DisplayItemFlex = (props) => {
	const {
		dataCy,
		label,
		children,

		outerClass = "",
		labelClass = "",
		valueClass = "",

		isHorizontal = true,
		isHorizontalMobile = true,

		unwrap = "md",
	} = props;

	const _flexing = [
		isHorizontal && isHorizontalMobile && "flex-row", // Two columns on all breakpoints
		!isHorizontal && !isHorizontalMobile && `flex-column`, // Vertical stacked on all breakpoints
		!isHorizontal && isHorizontalMobile && `flex-row flex-${unwrap}-column`, // Two columns on mobile, stacked at unwrap
		isHorizontal && !isHorizontalMobile && `flex-column flex-${unwrap}-row`, // Stacked on mobile, two columns at unwrap
	]
		.filter(Boolean)
		.join(" ");

	const _horizontalIdClasses = [
		isHorizontalMobile ? `hz-mb` : ``,
		isHorizontal ? `hz-dp` : "",
	]
		.filter(Boolean)
		.join(" ");

	const _classNameOuter = ["display-item", "d-flex", _flexing, outerClass]
		.filter(Boolean)
		.join(" ");

	const _classNameLabel = ["data-label", _horizontalIdClasses, labelClass]
		.filter(Boolean)
		.join(" ");

	const _classNameValue = ["data-value", _horizontalIdClasses, valueClass]
		.filter(Boolean)
		.join(" ");

	return (
		<div className={_classNameOuter} data-cy={dataCy}>
			{label && <div className={_classNameLabel}>{label}</div>}
			<div className={_classNameValue}>{children}</div>
		</div>
	);
};

export const DisplayItem = (props) => {
	const {
		dataCy,
		label,
		children,

		outerClass = "",
		labelClass = "",
		valueClass = "",

		isHorizontalFormat = true,
		isHzntlMobile = true,
		hzntDataCols = 7,
		hzntSmDataCols = 7,
		hzntMdDataCols = 7,
		hzntXlDataCols = 8,
	} = props;

	const _classNameOuter = ["row", "display-item", outerClass]
		.filter(Boolean)
		.join(" ");

	const _classNameLabel = [
		isHzntlMobile
			? `col-${12 - hzntDataCols} col-sm-${12 - hzntSmDataCols} hz-mb`
			: "col-12",
		isHorizontalFormat
			? `col-md-${12 - hzntMdDataCols} col-xl-${12 - hzntXlDataCols} hz-dp`
			: "",
		"data-label",
		labelClass,
	]
		.filter(Boolean)
		.join(" ");

	const _classNameValue = [
		isHzntlMobile
			? `col-${hzntDataCols} col-sm-${hzntSmDataCols} hz-mb`
			: "col-12",
		isHorizontalFormat
			? `col-md-${hzntMdDataCols} col-xl-${hzntXlDataCols} hz-dp`
			: "",
		"data-value",
		valueClass,
	]
		.filter(Boolean)
		.join(" ");

	return (
		<div className={_classNameOuter} data-cy={dataCy}>
			{label && <div className={_classNameLabel}>{label}</div>}
			<div className={_classNameValue}>{children}</div>
		</div>
	);
};

export const VehicleCard = (props) => {
	const fnFormatCurrency = (v) =>
		new Intl.NumberFormat("en-GB", {
			style: "currency",
			currency: "GBP",
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		}).format(v);

	const {
		regNumber,
		vehicleDescription,
		doors,
		seats,
		fuel,
		transmission,
		engineSize,
		value,
		valuationService = false,
		vehicleType,
	} = props;

	const vehicleDetailsDisplay = [
		doors && `${doors} door`,
		seats && `${seats} seats`,
		fuel,
		transmission,
		engineSize && `${engineSize}cc`,
	]
		.filter(Boolean)
		.join(", ");

	return (
		<div className="card" data-cy="vehicle-summary">
			<div className="card-body">
				<div className="px-md-4 d-flex flex-column flex-wrap justify-content-start align-items-start flex-md-row flex-md-nowrap justify-content-md-between align-items-md-center">
					<div className="order-1 order-md-2">
						<span
							className="vehicle-registration text-center d-inline-block overflow-wrap-normal mb-3 mb-md-0"
							data-cy="vehicle-reg"
						>
							{regNumber}
						</span>
					</div>
					<div className="order-2 order-md-1 flex-md-fill">
						<div className="vehicle-description" data-cy="vehicle-description">
							{vehicleDescription}
						</div>
						<div data-cy="vehicle-details">{vehicleDetailsDisplay}</div>
					</div>
				</div>
				{value && (
					<div className="px-md-4">
						<div className="vehicle-found-value" data-cy="vehicle-found-value">
							Current value: <strong>{fnFormatCurrency(value)}</strong>
						</div>
						{valuationService && (
							<div className="help-text small">
								We've used an independent vehicle valuation service to value
								your {vehicleType}.
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export const PriceDetails = (props) => {
	const {
		allowPayInFull,
		allowPayMonthly,
		isSticky = false,
		selectedPayMethod,
		isStickyAnimationEnabled,
		payInFullPremium,
		instalmentDetails,
		postFixMonth,
		postFixYear,
		separatorContent = undefined,
	} = props;

	const isSelectedMonthly = selectedPayMethod === "M";

	if (!isSticky)
		return (
			<PriceContainer
				dataCy="price-dual"
				isStickyAnimationEnabled={isStickyAnimationEnabled}
			>
				<PriceCardStaticDual
					allowPayInFull={allowPayInFull}
					allowPayMonthly={allowPayMonthly}
					selectedPayMethod={selectedPayMethod}
					payInFullPremium={payInFullPremium}
					instalmentDetails={instalmentDetails}
					postFixMonth={postFixMonth}
					postFixYear={postFixYear}
					separatorContent={separatorContent}
				/>
			</PriceContainer>
		);

	if (allowPayMonthly && isSelectedMonthly)
		return (
			<PriceContainer
				dataCy="price-sticky-monthly"
				isStickyAnimationEnabled={isStickyAnimationEnabled}
			>
				<PriceCardStickyMonthly
					instalmentDetails={instalmentDetails}
					postFixMonth={postFixMonth}
				/>
			</PriceContainer>
		);

	if (!isSelectedMonthly)
		return (
			<PriceContainer
				dataCy="price-sticky-annual"
				isStickyAnimationEnabled={isStickyAnimationEnabled}
			>
				<PriceCardStickyFull
					allowPayMonthly={allowPayMonthly}
					payInFullPremium={payInFullPremium}
					instalmentDetails={instalmentDetails}
					postFixYear={postFixYear}
				/>
			</PriceContainer>
		);

	// Should never reach this part
	return undefined;
};

export const FinanceProvider = (props) => {
	const {
		provider = "pcl",
		imageSrc,
		className,
		classNameImg,
		classNameAddress,
	} = props;

	const premiumCredit = (
		<address data-cy="address:premium-credit">
			<span className="provider-name">Premium Credit Limited</span>
			<span className="d-block">Premium Credit House</span>
			<span>60 East Street, Epsom</span>
			<span className="d-block d-md-inline">
				<span className="d-none d-md-inline">, </span>Surrey KT17 1HB
			</span>
		</address>
	);

	const providerAddress = provider === "pcl" ? premiumCredit : null;

	return (
		<div className="finance-provider d-flex">
			<div className="">
				<strong className="fw-bold">Finance is provided by</strong>
				{providerAddress}
			</div>
			<div className="d-flex align-items-center">
				<img
					src={imageSrc}
					alt="Direct Debit Logo"
					className={"img-fluid dd-img"}
				/>
			</div>
		</div>
	);
};
