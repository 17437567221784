import _ from "lodash";
import { current } from "@reduxjs/toolkit";

const fnOutputProxyItem = (obj) => {
	try {
		return current(obj);
	} catch (error) {
		return obj;
	}
};
const fnGenerateRiskUtils = (args = {}) => {
	const {
		_fnGetRiskData = (state) => {
			try {
				return state.userData.risk.data;
			} catch (e) {
				fnOutputErrorInfo("ERROR IN fnGetRiskData", { state });
				throw e;
			}
		},
		description: riskTypeDescription,
		_fnOnMissingItem = (errorMessage, ...logArgs) => {
			fnOutputErrorInfo(...logArgs);
			throw errorMessage;
		},
	} = args;

	const fnOutputErrorInfo = (...args) => {
		console.log("********************************************");
		console.log("ERRORINFO:", riskTypeDescription, ...args);
		console.log("********************************************");
	};

	const debugData = true;
	const fnLog = (...args) => {
		if (!debugData) return;
		console.log(...args);
	};

	const fnCreateDataNode = (args = {}) => {
		const { value = undefined, arrayData = undefined } = args;
		return {
			_arrayData: arrayData,
			_value: value,
			_hidden: false,
			_error: {},
			_errorShow: false,
		};
	};

	const fnCreateArrayItem = (id) => {
		return {
			id: id,
			data: {},
		};
	};

	const _fnIsDataNode = (node) => {
		if (!node) return false;
		if (_.isArray(node)) return false;
		if (!_.isObject(node)) return false;

		if (Object.keys(node).some((x) => x.startsWith("_"))) return true;

		return false;
	};

	const _fnIsDataNodeArray = (node) => {
		if (!_fnIsDataNode(node)) return false;
		if (!node["_arrayData"]) return false;
		return true;
	};

	const _fnIsDataNodeError = (node) => {
		if (!_fnIsDataNode) return false;
		return !_.isEmpty(node._error);
	};

	const fnCleanArray = (pathnode) =>
		pathnode.replaceAll(/\[[\S\s]+\]$/g, "").replaceAll(/\[\]$/g, ""); // Strips out all instance of array brackets

	// Removes the "+" or "-"

	const fnIsArray = (pathNode) => /\[\]$/.test(pathNode);
	const fnIsArrayWithIndex = (pathNode) => /\[[\S\s]+\]$/.test(pathNode);
	const fnIsArrayAction = (pathNode) => {
		if (/\[[\S\s]+\]\+$/.test(pathNode)) return true;
		if (/\[[\S\s]+\]\-$/.test(pathNode)) return true;
		return false;
	};

	const fnArrayRemoveAction = (pathnode) => {
		if (!fnIsArrayAction(pathnode)) return pathnode;

		return pathnode
			.split("]")
			.map((x, i, arr) => {
				if (arr.length - 1 === i) return "";
				return x;
			})
			.join("]");
	};

	const fnParseItem = (path) => {
		if (!path) return undefined;

		const arrayData = (function () {
			const idxEnd = path.lastIndexOf("]");
			if (idxEnd === -1) return undefined;
			const idxStart = path.lastIndexOf("[");

			if (idxEnd < idxStart)
				throw `Error in fnParseItem -- found mismatched brackets in "${path}" `;

			// NOTE: Rememeber, that paths can have indexes NOT at the end (e.g. path1/path2[idx]/path3)
			const status = {
				isArray: fnIsArray(path),
				isArrayWithIndex: fnIsArrayWithIndex(path),
				isArrayAction: fnIsArrayAction(path),
			};

			const retData = {
				...status,
				action: (function () {
					if (!status.isArrayAction) return undefined;
					return path.substring(idxEnd + 1);
				})(),
				index: (function () {
					if (idxEnd === idxStart + 1) return undefined;
					return path.substring(idxStart + 1, idxEnd);
				})(),
				path: path.substring(0, idxStart),
			};

			return {
				...retData,
				pathWithIndex: [retData.path, "[", retData.index, "]"].join(""),
			};
		})();

		return {
			fullPath: path,
			array: arrayData || {},
			parentTree: (function () {
				const idxEnd = path.lastIndexOf("]");
				if (idxEnd === -1) return undefined;
				return path.substring(0, idxEnd + 1);
			})(),
			cleanPath: (function () {
				if (!arrayData) return path;
				if (!arrayData.action) return path;
				return arrayData.path;
			})(),
		};
	};

	const fnParseArrayItem = (path) => {
		if (!fnIsArrayWithIndex(path) && !fnIsArray(path) && !fnIsArrayAction(path))
			return undefined;
		const retData = fnParseItem(path);

		return {
			path: retData.array.path,
			index: retData.array.index,
			action: retData.array.action,
			fullpath: retData.fullPath,
			cleanPath: retData.cleanPath,
		};

		if (!fnIsArrayWithIndex(path) && !fnIsArray(path) && !fnIsArrayAction(path))
			return undefined;

		const data = path.split("[");
		const _path = data[0];

		const _dataEnd = data[1].split("]");
		const _index = _dataEnd[0];
		const _action = _dataEnd.length >= 2 ? _dataEnd[1] : undefined;
		const _fullpath = _index ? `${_path}[${_index}]` : _path;

		return { path: _path, index: _index, action: _action, fullpath: _fullpath };
	};

	const fnArrayRemoveIndex = (pathnode) =>
		pathnode
			.split("/")
			.map((x) => {
				const data = fnParseArrayItem(x);
				return [data.path, "[]"].join("");
				// if (fnIsArrayWithIndex(x)) return `${fnParseArrayItem(x).path}[]`;
				// return x;
			})
			.join("/");

	const fnGetCurIndex = (path) => {
		// NOTE: we CAN'T use fnIsArray as that assumes the path ENDS with an array
		if (!path.includes("[")) return undefined;
		if (!path.includes("]")) return undefined;

		return path
			.split("[")
			.find((c, i, arr) => arr.length - 1 === i) // get the last segment
			.split("]")
			.find((c, i) => i === 0); // get the last segment
	};
	// *********************************************
	// Important functions
	// *********************************************

	const _fnGetRiskGroup = (state, group) => {
		try {
			return state.userData.risk.groups[group];
		} catch (e) {
			fnOutputErrorInfo("ERROR IN fnGetRiskData", { state });
			throw e;
		}
	};

	const _fnFindItem = (state, searchPath = "") => {
		if (!_.isObject(state))
			throw `Error in fnFindItem -- state is not an object`;

		if (!_.isString(searchPath)) {
			fnOutputErrorInfo("ERROR", { searchPath });
			throw `Error in fnFindItem -- searchPath is not a string`;
		}

		// if (searchPath.includes("[]")) {
		// 	fnOutputErrorInfo("ERROR", { searchPath });
		// 	throw `Error in fnFindItem -- found [] in "${searchPath}. This function only accepts indexes (e.g. path/subpath[idx] )"`;
		// }

		const _fnRecursive = (obj, searchPathArray = [], level = 1) => {
			const retFallback = undefined;
			const [curSearchNode, ...otherSearchNodes] = searchPathArray;

			const isLastNode = searchPathArray.length === 1;
			const metaData = fnParseItem(curSearchNode);

			if (searchPathArray.length === 0)
				throw `Error in fnFindItem -- missing searchPath`;

			if (!_.isObject(obj)) return retFallback;

			// LAST ITEM
			if (isLastNode) {
				if (metaData.cleanPath === "*") return obj;
				if (curSearchNode.endsWith("[]")) {
					if (metaData.array.path in obj) return obj[metaData.array.path];
					throw `Error in _fnFindItem -- can't find array item "${curSearchNode}" for "${searchPath}"`;
				}
				if (metaData.cleanPath in obj) return obj[metaData.cleanPath];
				return retFallback;
			}

			// Handle arrays
			if (metaData.array.isArrayWithIndex) {
				if (!isLastNode) {
					const _curArray = obj[metaData.array.path]["_arrayData"];
					const _curArrayItem = _curArray.find(
						(x) => x.id === metaData.array.index
					);

					if (!_curArrayItem) {
						return _fnOnMissingItem(
							`Error in fnFindItem -- can't find array with id = "${metaData.array.index}"`,
							{ searchPath, _curArray, metaData }
						);
					}
					return _fnRecursive(_curArrayItem.data, otherSearchNodes, level + 1);
				}

				return obj[metaData.array.path];
			}

			if (curSearchNode in obj) {
				return _fnRecursive(obj[curSearchNode], otherSearchNodes, level + 1);
			}

			return retFallback;
			//Exit checks
			{
				// if (!_.isObject(obj)) return retFallback;
				// if (searchPathArray.length === 0)
				// 	throw `Error in fnFindItem -- missing searchPath`;
				// // LAST ITEM
				// if (searchPathArray.length === 1) {
				// 	// console.log("_fnFindItem", curSearchNode, metaData);
				// 	// const _curSearchNode = fnCleanArray(curSearchNode); // remove any brackets
				// 	if (metaData.cleanPath === "*") return obj;
				// 	if (metaData.cleanPath in obj) return obj[metaData.cleanPath];
				// 	return retFallback;
				// }
				// // IS "path/subpath[key]""
				// if (metaData.array.isArrayWithIndex) {
				// 	if (!(metaData.array.path in obj)) return retFallback;
				// 	if (!_fnIsDataNode(obj[metaData.cleanPath])) return retFallback;
				// }
				// // NOT "path/subpath[key]"
				// if (!(curSearchNode in obj)) return retFallback;
			}

			// Handle arrays
			if (metaData.array.isArrayWithIndex) {
				const _curArray = obj[metaData.array.path]["_arrayData"];
				const _curArrayItem = _curArray.find(
					(x) => x.id === metaData.array.index
				);

				if (!_curArrayItem) {
					return _fnOnMissingItem(
						`Error in fnFindItem -- can't find array with id = "${metaData.array.index}"`,
						{ searchPath, _curArray, metaData }
					);
				}

				return _fnRecursive(_curArrayItem.data, otherSearchNodes, level + 1);
			}

			// Handle Objects
			if (curSearchNode in obj) {
				return _fnRecursive(obj[curSearchNode], otherSearchNodes, level + 1);
			}

			return retFallback;

			console.log("ERROR:", curSearchNode, {
				obj,
				searchPath,
				searchPathArray,
				metaData,
			});

			throw `Error in _fnRecursive -- reached an unhandled part of the function`;
		};

		// console.log("_fnFindItem", searchPath);
		if (searchPath === "") return _fnGetRiskData(state);
		const retData = _fnRecursive(_fnGetRiskData(state), searchPath.split("/"));

		return retData;
	};

	const _fnFindArrayList = (state, searchPath = "") => {
		const foundItem = _fnFindItem(state, searchPath);

		if (!foundItem["_arrayData"]) {
			fnOutputErrorInfo("ERROR", { searchPath });
			throw `Error in _fnFindArrayList -- "${searchPath} is not an array"`;
		}
		const retData = foundItem["_arrayData"].map((x) => x.id);
		return retData;
	};

	const _fnProcessAll = (state, startPath = "", fnProcessItem = () => {}) => {
		// Finds every node and runs fnProcessItem against the node

		const _FnRecusive = (obj, searchPath = [], level = 1) => {
			if (!obj) return;

			// found a datanode
			if (_fnIsDataNode(obj)) {
				// if it's an array
				if (obj["_arrayData"]) {
					obj["_arrayData"].forEach((x) => {
						_FnRecusive(
							x.data,
							searchPath.map((searchSeg, idx, arr) => {
								if (idx === arr.length - 1) return `${searchSeg}[${x.id}]`;
								return searchSeg;
							}),
							level + 1
						);
					});

					return;
				}

				fnProcessItem(obj, { searchPath });
				return;
			}

			if (_.isObject(obj)) {
				Object.entries(obj).forEach(([key, data]) =>
					_FnRecusive(data, [...searchPath, key], level + 1)
				);
				return;
			}
		};

		if (startPath === "") {
			_FnRecusive(_fnFindItem(state, startPath), [], 1);
			return;
		}
		_FnRecusive(_fnFindItem(state, startPath), startPath.split("/"), 1);
	};

	const fnIsError = (state, pathList = []) => {
		if (pathList.length === 0) {
			fnOutputErrorInfo("fnIsError");
			throw `Error in fnIsError -- empty pathList`;
		}

		const _FnRecusive = (obj, searchPath = [], level = 1) => {
			if (!obj) return false;

			// found a datanode
			if (_fnIsDataNode(obj)) {
				// if it's an array
				if (obj["_arrayData"]) {
					return obj["_arrayData"].some((x) => {
						return _FnRecusive(
							x.data,
							searchPath.map((searchSeg, idx, arr) => {
								if (idx === arr.length - 1) return `${searchSeg}[${x.id}]`;
								return searchSeg;
							}),
							level + 1
						);
					});
				}

				const _isError = _fnIsDataNodeError(obj);
				// if (_isError) console.log("FOUNDERROR:", searchPath.join("/"));

				return _isError;
			}

			if (_.isObject(obj)) {
				return Object.entries(obj).some(([key, data]) =>
					_FnRecusive(data, [...searchPath, key], level + 1)
				);
			}
			return false;
		};

		const isError = pathList.some((path) => {
			return _FnRecusive(_fnFindItem(state, path), path.split("/"));
		});

		return isError;
	};

	const fnBuildTree = (state, startPath = "", options = {}) => {
		const {
			fnProcessItem = () => ({
				data: undefined,
				output: true,
				newSubPath: undefined,
			}),
			fnProcessArrayItem = (id, data) => data,
			keyArrayId = undefined, //Do we inject the ID in each array data?
		} = options;

		const _FnRecusive = (dataObj, searchPath = [], level = 1) => {
			// console.log(
			//   "_FnRecusive",
			//   " ".repeat(level * 5),
			//   level,
			//   searchPath.join("/"),
			//   dataObj
			// );
			if (!dataObj) return { data: undefined, output: false };

			// found a datanode
			if (_fnIsDataNode(dataObj)) {
				// if it's an array

				if (_fnIsDataNodeArray(dataObj)) {
					const _arrayData = dataObj["_arrayData"]
						.map((_arrItem) => {
							const _subData = _FnRecusive(
								_arrItem.data,
								searchPath.map((segment, idx, arr) => {
									if (idx === arr.length - 1)
										return `${segment}[${_arrItem.id}]`;
									return segment;
								}),
								level + 1
							);

							if (keyArrayId) {
								const data = fnProcessArrayItem(_arrItem.id, _subData.data);
								if (_.isObject(data) && keyArrayId in data) {
									throw `Error in fnBuildTree -- Array -- found "${keyArrayId} in data"`;
								}

								return {
									output: _subData.output,
									data: { ...data, [keyArrayId]: _arrItem.id },
								};
							}

							return {
								output: _subData.output,
								data: fnProcessArrayItem(_arrItem.id, _subData.data),
							};
						})
						.filter(({ data, output, newSubPath }) => {
							if (output) return true;
							return false;
						})
						.map(({ data }) => data);

					const _arrayOutput = _arrayData.some((x) => !_.isEmpty(x));
					// console.log("dddddd ARRAY", searchPath.join("/"), {
					//   _arrayData,
					//   _arrayOutput,
					// });

					return { output: _arrayOutput, data: _arrayData };
				}

				// if (searchPath.join("/").endsWith("Name")) {
				//   console.log("DDDD", searchPath.join("/"), {
				//     startPath,
				//     dataObj,
				//     searchPath,
				//     level,
				//   });
				// }
				// Not an array
				return fnProcessItem(dataObj, { searchPath });
			}

			if (_.isObject(dataObj)) {
				const newObj = Object.fromEntries(
					Object.entries(dataObj)
						.map(([key, data]) => [
							key,
							_FnRecusive(data, [...searchPath, key], level + 1),
						])
						.filter(([k, d]) => d.output)
						.map(([key, d]) => {
							return [d.newSubPath || key, d.data];
						})
				);

				return { data: newObj, output: !_.isEmpty(newObj) };
			}

			fnOutputErrorInfo({
				startPath,
				searchPath: searchPath.join("/"),
				dataObj,
				options,
			});
			throw `Error in fnBuildTree -- found seomthing unknown is riskStore "${searchPath.join(
				"/"
			)}" -- maybe the risk item isn't a templateNode?`;
		};

		// console.log("ddddd", { state });

		if (!startPath) {
			const riskData = _fnGetRiskData(state);
			// console.log("ddddd", { riskData });

			const retObj = Object.fromEntries(
				Object.entries(riskData)
					.map(([key, d]) => {
						// console.log("dddd..", key);
						return [key, _FnRecusive(d, [key], 1)];
					})
					.filter(([key, d]) => d.output)
					.map(([key, d]) => [key, d.data])
			);

			// console.log("ddddd2", startPath, { state, riskData, retObj });
			return retObj;
		}

		const _startPath = startPath.split("/");
		const _baseState = _fnFindItem(state, startPath);

		const retObj = _FnRecusive(_baseState, _startPath, 1);
		if (!retObj.output) return undefined;

		// console.log("dddd buildTree", startPath, retObj);

		return retObj.data;
	};

	const _fnCreateItem = (state, createPath = "", options = {}) => {
		const {
			generateBlankItem = () => {
				// console.log("generateBlankItem default")
				return {};
			},
			throwErrorIfExists = true,
		} = options;
		// NOTE: Reducer will return the last entry, which we want to return
		// console.log("calling _fnCreateItem()", createPath, { options });
		//Risk/AdditionalInsuredSet

		// console.log("_fnCreateItem", createPath);

		return createPath.split("/").reduce(
			(acc, cur, idx, arr) => {
				const isLastSearchNode = idx === arr.length - 1;
				// const runningPath = arr.filter((x, i) => i < arr.length - 1).join("/");

				//NOTE: This is a reducer, which returns the found child object on each iteration.
				// So, we only need to parse "cur" and not the full path
				// e.g createPath = "aaaa/bbbb/cccc[idx]+", "cur" could be "cccc[idx]+"
				const metaData = fnParseItem(cur);

				// Array actions -- possible to be in the middle *or* last
				// console.log(idx, "***reducer", cur, metaData, {
				// 	createPath,
				// 	acc,
				// });

				if (metaData.array.isArrayAction) {
					//e.g. MyKey[]+, MyKey[0]+

					fnLog("ACTION", cur, metaData.array.action);

					acc[metaData.array.path] =
						acc[metaData.array.path] || fnCreateDataNode({ arrayData: [] });

					switch (metaData.array.action) {
						case "+": {
							acc[metaData.array.path]["_arrayData"] = [
								...acc[metaData.array.path]["_arrayData"],
								fnCreateArrayItem(metaData.array.index),
							];

							console.log(
								"zzzzz added",
								metaData.array.index,
								acc[metaData.array.path]["_arrayData"]
							);

							return acc[metaData.array.path]["_arrayData"].find(
								(x) => x.id === metaData.array.index
							).data;
						}
						case "-": {
							if (!isLastSearchNode) {
								throw `Error in _fnCreateItem -- Array "-" action is not the last entry in the path`;
							}
							acc[metaData.array.path]["_arrayData"] = acc[metaData.array.path][
								"_arrayData"
							].filter((x) => x.id !== metaData.array.index);

							return undefined;
						}
						default:
							throw `Error in fnCreateObject -- unknown action "${metaData.array.action}"`;
					}
				}

				// NOT last node
				if (!isLastSearchNode) {
					if (metaData.array.isArrayWithIndex) {
						acc[metaData.array.path] =
							acc[metaData.array.path] || fnCreateDataNode({ arrayData: [] });

						const foundArrItem = acc[metaData.array.path]["_arrayData"].find(
							(x) => x.id === metaData.array.index
						);

						if (!foundArrItem) {
							fnOutputErrorInfo({
								isLastSearchNode,
								searchPath: createPath,
								cur,
								idx,
								arr,
								acc,
								metaData,
							});
							throw `Error in _fnCreateItem -- can't find array with id="${metaData.array.index}" in request "${createPath}"`;
						}

						return foundArrItem.data;
					}

					// Must be an object
					acc[metaData.cleanPath] = acc[metaData.cleanPath] || {};
					return acc[metaData.cleanPath];
				}

				// Last NODE code

				if (acc[metaData.cleanPath]) {
					if (throwErrorIfExists) {
						fnOutputErrorInfo("Risk.create", {
							searchPath: createPath,
							cur,
							options,
							acc: current(acc),
							metaData,
						});
						throw `Error in riskUtils -- tried to create something that already exists "${createPath}"`;
					}
					return acc[metaData.cleanPath];
				}

				acc[metaData.cleanPath] = generateBlankItem({
					searchNode: cur,
					idx: idx,
					searchAcc: acc,
					isLastNode: idx === arr.length - 1,
				});
				return acc[metaData.cleanPath];
			},
			//Base start data
			_fnGetRiskData(state)
		);
	};

	// *********************************************
	// OTHER
	// *********************************************

	const _fnFindItemAndCreateBase = (state, searchPath = "", options = {}) => {
		const {
			defaultValue = undefined,
			generateBlankItem = undefined,
			onFind = () => {},
			onCreate = () => {},
		} = options;

		if (!_.isObject(state))
			throw `Error in fnGetRiskItemAndCreate -- state is not an object`;

		if (!searchPath)
			throw `Error in fnGetRiskItemAndCreate -- missing searchPath`;

		if (!_.isString(searchPath))
			throw `Error in fnGetRiskItemAndCreate -- searchPath is NOT a string`;

		const obj = _fnFindItem(state, searchPath);

		if (!obj) {
			// console.log("ddddddd not found", searchPath);

			const createdObj = _fnCreateItem(state, searchPath, {
				generateBlankItem: generateBlankItem,
			});

			createdObj._value = defaultValue;

			onCreate({ searchPath });
			// console.log("dddd _fnFindItemAndCreate created", searchPath);

			return createdObj;
		}

		// console.log("dddd _fnFindItemAndCreate found", searchPath);
		// console.log("ddddddd  found", searchPath);
		onFind({ searchPath });
		return obj;
	};

	const _fnFindItemAndCreate = (state, searchPath = "", options = {}) => {
		return _fnFindItemAndCreateBase(state, searchPath, options);
	};

	const _fnFindItemAndCreateArray = (state, searchPath, options = {}) => {
		const { onCreate = () => {} } = options;
		const retData = _fnFindItemAndCreateBase(state, searchPath, {
			...options,
			generateBlankItem: () => {
				const retData = fnCreateDataNode({ arrayData: [] });
				onCreate({ searchPath });
				return retData;
			},
		});

		return retData;
	};

	const _fnFindItemByNodeName = (state, searchNodeName) => {
		const riskData = _fnGetRiskData(state);
		const retData = [];

		const _fnRecursive = (node, path = [], level = 1) => {
			// fnLog(" ".repeat(level * 10), level, path.join("/"), {
			//   node,
			//   searchNodeName,
			// });

			// if (level > 20) throw `fnFindItemByNodeName`;

			if (_fnIsDataNode(node)) {
				if (level === 1)
					throw `Error in _fnFindItemByNodeName -- first level can't be a dataNode`;

				if (node["_arrayData"]) {
					node["_arrayData"].forEach((n, nodeIdx) =>
						_fnRecursive(
							n,
							path.map((x, pathIdx, thisArray) => {
								if (pathIdx === thisArray.length - 1) return `${x}[${nodeIdx}]`;
								return x;
							}),
							level + 1
						)
					);
					return;
				}

				if (searchNodeName === path[path.length - 1]) retData.push(path);
			}

			if (_.isObject(node)) {
				Object.entries(node).forEach(([key, data]) => {
					_fnRecursive(data, [...path, key], level + 1);
				});
				return;
			}
		};

		_fnRecursive(riskData, [], 1);
		return retData;
	};

	return {
		// salus: salusUtils,
		searchPath: {
			parse: fnParseItem,
			array: {
				isArray: fnIsArray,
				isArrayWithIndex: fnIsArrayWithIndex,
				isArrayAction: fnIsArrayAction,
				parse: fnParseArrayItem,
				clean: fnCleanArray,
				removeIndex: fnArrayRemoveIndex,
				removeAction: fnArrayRemoveAction,
				getCurIndex: fnGetCurIndex,
			},
		},
		find: {
			data: _fnGetRiskData,
			item: _fnFindItem,
			arrayList: _fnFindArrayList,
			itemAndCreate: _fnFindItemAndCreate,
			itemAndCreateArray: _fnFindItemAndCreateArray,
			itemByNodeName: _fnFindItemByNodeName,
			group: _fnGetRiskGroup,
		},
		process: { all: _fnProcessAll, buildTree: fnBuildTree },
		is: {
			error: fnIsError,
			dataNode: _fnIsDataNode,
			dataNodeArray: _fnIsDataNodeArray,
		},
		create: {
			item: _fnCreateItem,
			dataNode: fnCreateDataNode,
			arrayItem: fnCreateArrayItem,
		},
	};
};

export default fnGenerateRiskUtils;
