import riskUtils from "./risk";
import _ from "lodash";
const fnOutputErrorInfo = (...args) => {
	console.log("********************************************");
	console.log("ERRORINFO:", ...args);
	console.log("********************************************");
};
const _fnGetTemplateData = (template, path = []) => {
	const emptyItem = { found: false, data: undefined };

	if (!_.isArray(path)) {
		console.log("ERROR INFO: ", { path });
		throw `Error in _fnGetTemplateData -- path is NOT an array`;
	}
	if (path.length === 0) return emptyItem;

	const _fnRecursive = (templateNode, subPath = [], level = 1) => {
		const [pathFirst, ...pathRest] = subPath;

		if (subPath.length === 0) {
			if (_.isObject(templateNode) && templateNode._isTemplateDataNode === true)
				return { found: true, data: templateNode };

			return { found: false, data: undefined };
		}

		//Is Array
		if (riskUtils.searchPath.array.isArrayWithIndex(pathFirst)) {
			const _newPath = riskUtils.searchPath.array
				.parse(pathFirst)
				.path?.concat("[]");

			if (!_newPath) {
				console.log("ERROR INFO:", { templateNode, subPath, level });
				throw `Error in _fnGetTemplateData "${path.join(
					"/"
				)} -- can't parse path info"`;
			}

			if (!(_newPath in templateNode)) {
				return emptyItem;
			}

			// If it's an ARRAY item we're searching for, return the details
			if (subPath.length === 1) {
				return { found: true, data: templateNode[_newPath] };
			}

			return _fnRecursive(templateNode[_newPath].items, pathRest, level + 1);
		}

		//Is object
		if (pathFirst in templateNode) {
			return _fnRecursive(templateNode[pathFirst], pathRest, level + 1);
		}

		return emptyItem;
	};

	const foundData = _fnRecursive(template, path);

	// console.log("_fnGetTemplateData", path.join("/"), foundData);

	// Returns something of the format  { found: <bool>, data: .... }
	return foundData;
	// return { found: foundData ? true : false, data: foundData };
};

const _fnGenerateSalusData = (template, salusData) => {
	const fnRecursive = (templateNode, salusNode, options = {}) => {
		const {
			level = 1,
			isCreateMode = false, // For when there's no matching salus data (we need this flas as we can't query "salusNode" as the actualy value might be "undefined" or "false")
		} = options;

		const _fnDebug = (...args) => console.log(" ".repeat(level * 5), ...args);

		// Reached a template node
		if (_.isObject(templateNode) && templateNode._isTemplateDataNode === true) {
			// if (isCreateMode) return templateNode.defaultValue; //NOTE: Should never reach this
			return salusNode;
		}

		// NOT a TEMPLATENODE and isCreateMode... so we need to NOT traverse any further.
		// if (isCreateMode) {
		//   return undefined;
		// }

		if (_.isArray(templateNode)) {
			fnOutputErrorInfo({
				template,
				salusData,
				templateNode,
				salusNode,
				options,
			});
			throw `Error in templateUtils -- found array in template -- array items should be named "{example[]:{...}"`;
		}

		if (_.isObject(templateNode)) {
			// Existing entries
			const retObj = Object.fromEntries(
				Object.keys(templateNode)
					.map((k) => {
						const _newIsCreateMode = (function () {
							if (k.endsWith("[]")) return false;
							if (isCreateMode) return true;
							if (!(k in salusNode)) return true;
							return false;
						})();

						// _fnDebug(k, { templateNode, salusNode, _newIsCreateMode });

						if (_newIsCreateMode) {
							const _childNode = templateNode[k];
							// _fnDebug({ _childNode });

							if (
								_.isObject(_childNode) &&
								_childNode._isTemplateDataNode === true
							) {
								return [k, _childNode.defaultValue]; //Could be "undefined"
							}

							const newData = fnRecursive(_childNode, undefined, {
								level: level + 1,
								isCreateMode: true,
							});
							if (newData === undefined) return undefined;
							return [k, newData];
						}

						if (!salusNode) return undefined;

						if (k.endsWith("[]")) {
							const keyClean = k.endsWith("[]") ? k.slice(0, -2) : k; // Strip off the "[]"

							if (!(keyClean in salusNode)) return undefined;
							return [
								keyClean,
								salusNode[keyClean].map((arrItem) =>
									fnRecursive(templateNode[k], arrItem, {
										level: level + 1,
										isCreateMode: false,
									})
								),
							];
						}

						if (!(k in salusNode)) {
							return [
								k,
								fnRecursive(templateNode[k], undefined, {
									level: level + 1,
									isCreateMode: true,
								}),
							];
						}

						return [
							k,
							fnRecursive(templateNode[k], salusNode[k], {
								level: level + 1,
								isCreateMode: false,
							}),
						];
					})
					.filter(Boolean)
			);
			// Don't return empty objects
			if (_.isEmpty(retObj)) return undefined;

			return retObj;
		}

		fnOutputErrorInfo({ templateNode, salusNode, level });
		throw `Error in _fnComposeSalusData -- Unknown templateNode`;
	};

	const retData = fnRecursive(template, salusData);

	console.log("TEMPLATE._fnGenerateSalusData", {
		template,
		salusData,
		retData,
	});
	// throw `hhh`;
	return retData;
};

export default {
	getData: _fnGetTemplateData,
	generateSalusData: _fnGenerateSalusData,
};
