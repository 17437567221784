export default {
	lists: {
		yesNo: [
			{ label: "Yes", value: true },
			{ label: "No", value: false },
		],

		yesNoFlipped: [
			{ label: "Yes", value: false },
			{ label: "No", value: true },
		],
	},
};
