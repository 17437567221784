import React from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";

import LayoutPageGap from "siteLayouts/Page/Gap";
import { RecallBanner } from "siteComponents/Misc";
import { Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { InputGroup } from "siteComponentsLibrary/Inputs";

import {
	ButtonLink,
	Button,
	IconButtonLabel,
	ExternalLink,
} from "siteComponentsLibrary/Buttons";

import services from "siteService";
import useLayoutPropsModifer from "../../../useLayoutPropsModifier";

import AddressPicker from "./AddressPicker";

import config from "config";

const ViewsGapRiskEditYourDetails = (props) => {
	const { layoutProps = {} } = props;

	const commonData = useOutletContext();
	const validationPathArray = require("../../../validationLists").default
		.yourDetails;

	const newLayoutProps = useLayoutPropsModifer({
		layoutProps,
		commonData,
		validationPathArray: validationPathArray,
		errorMessage: "Please correct the errors above",
	});

	const vehicleType = useSelector((state) =>
		commonData.risk.selectors.value(state, "Risk/Vehicle/VehicleType")
	);

	const {
		TextBox,
		TextBoxTelephone,
		TextBoxMobile,
		TextBoxCurrency,
		FormGroup,
		DatePickerWithButtons,
		InceptionDate,
		RadioButtonsWebService,
		DatePickerTextBox,
		RadioButtons,
		CheckboxButton,
		DropDownTypeWebService,
	} = commonData.storeComponents;

	return (
		<LayoutPageGap headingText="About you" {...newLayoutProps}>
			{/* <RecallBanner /> */}
	
			
			<Panel>
				<FormGroup
					databaseProps={{
						path: "Risk/Proposer/Name/TitleGender",
					}}
					label="Title"
					data-cy="form-group:proposer-title"
				>
					<RadioButtonsWebService
						databaseProps={{ path: "Risk/Proposer/Name/TitleGender" }}
						DataFunction={services.lookups.person.title}
						defaultItemKey={false}
						questionText={"Title"}
						className={"stackable"}
						data-cy="radio:proposer-title"
					/>
				</FormGroup>

				<FormGroup
					databaseProps={{
						label: "Your name",
						pathList: [
							"Risk/Proposer/Name/Forenames",
							"Risk/Proposer/Name/Surname",
						],
					}}
					data-cy="form-group:proposer-name"
				>
					<div className="row">
						<div className="col-12 col-md-5 mb-2 mb-md-0">
							<TextBox
								databaseProps={{ path: "Risk/Proposer/Name/Forenames" }}
								placeholder="Forename"
								data-cy="text:proposer-first-name"
								enableAutoFill={true}
							/>
						</div>
						<div className="col-12 col-md-5 mb-2 mb-md-0">
							<TextBox
								databaseProps={{ path: "Risk/Proposer/Name/Surname" }}
								placeholder="Surname"
								data-cy="text:proposer-surname"
								enableAutoFill={true}
							/>
						</div>
					</div>
				</FormGroup>

				<FormGroup
					databaseProps={{
						path: "Risk/Proposer/Gender",
					}}
					label="Gender"
					data-cy="form-group:proposer-gender"
				>
					<RadioButtonsWebService
						databaseProps={{ path: "Risk/Proposer/Gender" }}
						DataFunction={services.lookups.person.gender}
						defaultItemKey={false}
						questionText="Gender"
						className="stackable two-buttons"
						data-cy="radio:proposer-gender"
					/>
				</FormGroup>

				<FormGroup
					databaseProps={{
						path: "Risk/Proposer/DateOfBirth",
						label: "Date of birth",
					}}
					data-cy="form-group:proposer-dob"
					classNameContent="col-md-8 col-lg-6"
				>
					<DatePickerTextBox
						databaseProps={{ path: "Risk/Proposer/DateOfBirth" }}
						label="Date of birth"
						data-cy="date:proposer-dob"
						// enableAutoFill={true}
					/>
				</FormGroup>

				<PanelSection data-cy="section:address-lookup">
					<AddressPicker />
				</PanelSection>
			</Panel>

			<Panel>
				<PanelSection data-cy="section:contact-intro">
					<div>
						<strong>
							It's important that we're able to contact you about your policy.
						</strong>
					</div>
					<div>
						Please enter your contact details accurately to ensure you receive
						your policy documents and any future correspondence in relation to
						your policy.
					</div>
				</PanelSection>

				<FormGroup
					databaseProps={{
						path: "Risk/Proposer/Email",
					}}
					label="Email address"
					data-cy="form-group:email-address"
					classNameContent="col-md-8 col-lg-6"
				>
					<InputGroup text={<span className="bi bi-envelope" />}>
						<TextBox
							enableAutoFill={true}
							databaseProps={{
								path: "Risk/Proposer/Email",
							}}
							data-cy="text:email-address"
						/>
					</InputGroup>
				</FormGroup>

				<FormGroup
					databaseProps={{
						path: "ChosenOptions/EmailConfirm",
					}}
					label="Confirm email address"
					data-cy="form-group:email-confirm"
					classNameContent="col-md-8 col-lg-6"
				>
					<InputGroup text={<span className="bi bi-envelope" />}>
						<TextBox
							enableAutoFill={true}
							databaseProps={{
								path: "ChosenOptions/EmailConfirm",
							}}
							data-cy="text:email-confirm"
						/>
					</InputGroup>
				</FormGroup>

				<FormGroup
					databaseProps={{
						path: "Risk/Proposer/MainTelephone/TelephoneNumber",
					}}
					label="Mobile phone number"
					data-cy="form-group:main-telephone"
					classNameContent="col-md-8 col-lg-6"
				>
					<InputGroup text={<span className="bi bi-telephone" />}>
						<TextBoxMobile
							enableAutoFill={true}
							databaseProps={{
								path: "Risk/Proposer/MainTelephone/TelephoneNumber",
							}}
							data-cy="text:main-telephone"
						/>
					</InputGroup>
				</FormGroup>

				<PanelSection data-cy="section:marketing-intro">
					<div>
						Freedom Brokers would like to contact you from time to time about
						products and services that we feel may be in your interest. We will
						never sell your personal information and will always protect your
						privacy. If you'd like to read more about how we use your data,
						please{" "}
						<ExternalLink
							href={config.lookups.links.privacyPolicy}
							label="review our Privacy Policy"
						/>
						.
					</div>
				</PanelSection>

				<FormGroup
					data-cy="form-group:marketing"
					label="Please select below how you would prefer us to contact you. You can change your mind at any time by getting in touch with us."
					classNameLabel="label-style-2"
					className="marketing-consent"
					classNameContent="col-lg-8"
					databaseProps={{
						path: "",
					}}
				>
					<div className="row row-cols-2 row-cols-sm-4 g-2">
						<div className="col">
							<CheckboxButton
								databaseProps={{ path: "Risk/MarketingConsent/Email" }}
								className="btn-outline-secondary btn-icon w-100"
								data-cy="btn:marketing-email"
							>
								<IconButtonLabel iconClass="bi bi-envelope" labelText="Email" />
							</CheckboxButton>
						</div>
						<div className="col">
							<CheckboxButton
								databaseProps={{ path: "Risk/MarketingConsent/Phone" }}
								className="btn-outline-secondary btn-icon w-100"
								data-cy="btn:marketing-phone"
							>
								<IconButtonLabel
									iconClass="bi bi-telephone"
									labelText="Phone"
								/>
							</CheckboxButton>
						</div>
						<div className="col">
							<CheckboxButton
								databaseProps={{ path: "Risk/MarketingConsent/Post" }}
								className="btn-outline-secondary btn-icon w-100"
								data-cy="btn:marketing-post"
							>
								<IconButtonLabel iconClass="bi bi-mailbox" labelText="Post" />
							</CheckboxButton>
						</div>
						<div className="col">
							<CheckboxButton
								databaseProps={{ path: "Risk/MarketingConsent/Sms" }}
								className="btn-outline-secondary btn-icon w-100"
								data-cy="btn:marketing-sms"
							>
								<IconButtonLabel iconClass="bi bi-chat-dots" labelText="SMS" />
							</CheckboxButton>
						</div>
					</div>
				</FormGroup>

				{vehicleType === "GoodsVehicle" && (
					<FormGroup
						databaseProps={{
							path: "Risk/MarketingConsent/PublicLiabilityRenewal",
							label:
								"If you have public liability insurance, please select the month your policy is due for renewal;",
						}}
						classNameContent="col-md-8 col-lg-6"
						classNameLabel="label-style-2"
						data-cy="form-group:marketing-pl"
					>
						<DropDownTypeWebService
							databaseProps={{
								path: "Risk/MarketingConsent/PublicLiabilityRenewal",
							}}
							DataFunction={services.lookups.months}
							data-cy="select:marketing-pl"
						/>
					</FormGroup>
				)}
			</Panel>
		</LayoutPageGap>
	);
};

export default ViewsGapRiskEditYourDetails;
