import moment from "moment";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import { isNumeric } from "@library/common/database/rules/helpers";
import _ from "lodash";
export default (args) => {
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;

	// console.log("ncbLogic", functionArgs);

	const dh = new dataHelper({
		mappings: [
			"ProtectedBonus",
			"Years",
			"AllowProtectedNcd",
			"DrivingExperienceYears",
			"DrivingExperienceType",
		],
		dataSet,
		functionArgs,
	});

	// if (dh.isCurrent("ProtectedBonus", "Years")) {
	const Years = _.toNumber(dh.getValue("Years"));

	// *************************************************
	// Set values
	// *************************************************
	if (!dh.isPostRecall()) {
		if (dh.isUpdated("Years")) {
			if (Years !== 0) {
				// blank out
				dh.setValue("DrivingExperienceType", undefined);
				dh.setValue("DrivingExperienceYears", undefined);

				dh.resetErrorsShow("DrivingExperienceType");
				dh.resetErrorsShow("DrivingExperienceYears");
			}
		}

		// if (dh.isUpdated("DrivingExperienceType")) {
		// 	dh.resetErrorsShow("DrivingExperienceYears");
		// }
	}

	// *************************************************
	// Hide/show
	// *************************************************
	const showDrivingExperienceType = Years === 0;
	const showDrivingExperienceYears =
		showDrivingExperienceType &&
		dh.getValue("DrivingExperienceType") !== undefined &&
		dh.getValue("DrivingExperienceType") !== "BLANK";

	const showProtectedBonus =
		dh.getValue("AllowProtectedNcd") === true &&
		dh.getValue("Years") !== undefined &&
		Years >= 4;

	if (!showProtectedBonus) {
		dh.setVisible("ProtectedBonus", false);
		dh.setValue("ProtectedBonus", undefined);
		dh.clearError("ProtectedBonus");
	} else {
		dh.setVisible("ProtectedBonus", true);
		dh.addErrorIfEmpty("ProtectedBonus", "");
	}

	dh.setVisible("DrivingExperienceType", showDrivingExperienceType);
	dh.setVisible("DrivingExperienceYears", showDrivingExperienceYears);

	// 	dh.setErrorsShow("DrivingExperienceType", false)
	// dh.setErrorsShow("DrivingExperienceType", false)

	// *************************************************
	// Set values (pt2)
	// *************************************************
	if (!dh.isPostRecall()) {
		if (!showDrivingExperienceType) {
			dh.setValue("DrivingExperienceType", undefined);
			dh.resetErrorsShow("DrivingExperienceType");
		}

		if (!showDrivingExperienceYears) {
			dh.setValue("DrivingExperienceYears", undefined);
			dh.resetErrorsShow("DrivingExperienceYears");
		}
	}
	// *************************************************
	// Validation
	// *************************************************
	if (showDrivingExperienceType)
		dh.addErrorIfEmpty("DrivingExperienceType", "");
	if (showDrivingExperienceYears)
		dh.addErrorIfEmpty("DrivingExperienceYears", "");

	// if (!dh.isPostRecall() && !dh.isPostRegistration()) {
	// 	dh.setErrorsShow("DrivingExperienceType", false); //test
	// }

	dh.commit();
};
