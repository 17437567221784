import moment from "moment";
// import functions from "../../../base";
import dataHelper from "@library/common/database/rules/helpers/dataHelper";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

// const { dateComparison } = functions;

const constMainUser = "MainUser";
const constFrequently = "Frequently";

export default (args) => {
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;

	const { ComponentTagVehicleUse } = functionArgs;
	if (!ComponentTagVehicleUse)
		throw `Error in vehicleUseLogic -- missing argument`;

	const dh = new dataHelper({
		mappings: [
			"AdditionalInsuredSet",
			"MainUserComponentId",
			"ProposerVehicleUse",
		],
		mappingsOptional: [
			"VehicleUseCurrent", // the VehicleUse of the current addDriver/proposer
		],
		dataSet,
		functionArgs,
	});

	const addDriverTree =
		dh.dataSet.getTree({
			argItem: functionArgs.AdditionalInsuredSet,
			componentTagHitlist: [ComponentTagVehicleUse],
		}) || [];

	// *****************************************
	// ** recall
	// *****************************************
	if (dh.isPostRecall()) {
		let foundMainUser = false;

		// check the add drivers
		addDriverTree
			.map((x) => ({
				componentId: x.data[ComponentTagVehicleUse].componentId,
				vehicleUse: x.data[ComponentTagVehicleUse].value,
			}))
			.filter((x) => x.vehicleUse === constMainUser)
			.forEach((x) => {
				dh.setValue("MainUserComponentId", x.componentId);
				foundMainUser = true;
			});

		// if it's not an additional driver, then automatically set the main driver to be the main user
		if (!foundMainUser) {
			const parsedItem = dh.populateItem("ProposerVehicleUse");

			dh.setValue("ProposerVehicleUse", constMainUser);
			dh.setValue("MainUserComponentId", parsedItem.componentId);
		}
	}

	// *****************************************
	// ** none recall (could be a natural update, defaultState, or add driver)
	// *****************************************
	if (!dh.isPostRecall()) {
		const _MainUserComponentId = dh.getValue("MainUserComponentId");
		const componentIdProposerVehicleUse =
			dh.getComponentId("ProposerVehicleUse");

		if (addDriverTree.length === 0) {
			// no additional drivers
			if (
				dh.getValue("ProposerVehicleUse") !== constMainUser ||
				dh.getValue("MainUserComponentId") === undefined
			) {
				dh.setValue("MainUserComponentId", componentIdProposerVehicleUse);
				dh.setValue("ProposerVehicleUse", constMainUser);
			}
		} else {
			// if the user has updated the "MainUserComponentId":

			// ** USER has chosen a MainUserComponentId
			if (dh.isUpdated("MainUserComponentId")) {
				// *********************************
				// STEP 1 -- process the PROPOSER
				// *********************************
				if (!_MainUserComponentId) {
					dh.setValue("ProposerVehicleUse", undefined);
				} else if (_MainUserComponentId === componentIdProposerVehicleUse) {
					// MainUserComponentId contains the "componentId" for the proposerVehicleUse
					// ...set to mainUser
					dh.setValue("ProposerVehicleUse", constMainUser);
				} else {
					// ...else set to frequently
					dh.setValue("ProposerVehicleUse", constFrequently);
				}

				// *********************************
				// STEP 2 -- update the ADDITIONAL DRIVERS (optional)
				// *********************************
				addDriverTree
					.map((x) => ({
						componentId: x.data[ComponentTagVehicleUse].componentId,
						componentSet: x.componentSet,
						componentTag: ComponentTagVehicleUse,
					}))
					.forEach((x) => {
						// update the actual driver's vehicle usage
						let addDriverVehicleUse;

						if (!_MainUserComponentId) {
							addDriverVehicleUse = undefined;
						} else if (x.componentId === _MainUserComponentId) {
							addDriverVehicleUse = constMainUser;
						} else {
							addDriverVehicleUse = constFrequently;
						}

						dh.dataSet.setValue({
							...x,
							key: "value",
							value: addDriverVehicleUse,
						});
					});
			}
		}

		// *************************************
		// ** if we've added or removed a driver:
		// *************************************
		if (dh.isUpdated("AdditionalInsuredSet")) {
			if (addDriverTree.length === 0) {
				//we've removed the last driver,  default the proposer
				dh.setValue("MainUserComponentId", componentIdProposerVehicleUse);
				dh.setValue("ProposerVehicleUse", constMainUser);
			} else {
				// Reset everything to "undefined" so that they have to pick again
				dh.setValue("MainUserComponentId", undefined);
				dh.setValue("ProposerVehicleUse", undefined);
				addDriverTree
					.map((x) => ({
						componentId: x.data[ComponentTagVehicleUse].componentId,
						componentSet: x.componentSet,
						componentTag: ComponentTagVehicleUse,
					}))
					.forEach((x) => {
						// update the actual driver's vehicle usage
						dh.dataSet.setValue({ ...x, key: "value", value: undefined });
					});
			}
		}
	}

	// *****************************************
	// ** TIDY Up
	// *****************************************
	dh.setData("visible", "MainUserComponentId", addDriverTree.length !== 0);
	dh.addErrorIfEmpty("ProposerVehicleUse", ""); // "Required")
	dh.addErrorIfEmpty("MainUserComponentId", ""); // "Required")

	// addDriverTree
	// 	.map((x) => ({
	// 		componentId: x.data[ComponentTagVehicleUse].componentId,
	// 		componentSet: x.componentSet,
	// 		componentTag: ComponentTagVehicleUse,
	// 	}))
	// 	.forEach((x) => {
	// 		const _vehicleUse = this.dataSet.getValue({ ...x, key: "value" });

	// 		this.dataSet.setValue({
	// 			...x,
	// 			key: "errors",
	// 			value: {
	// 				errorKey: dh.errorKey,
	// 				errorMessage: _vehicleUse === undefined ? "Required" : undefined,
	// 			},
	// 		});
	// 		console.log("ssss", x, _vehicleUse);
	// 	});

	// if (dh.isActive("VehicleUseCurrent"))
	// 	dh.addErrorIfEmpty("VehicleUseCurrent", ""); // "Required")

	// // set the error
	// if (
	// 	dh.getValue("MainUserComponentId") === undefined &&
	// 	addDriverTree.length !== 0
	// ) {
	// 	dh.addError("MainUserComponentId", ""); // "Required")
	// } else {
	// 	dh.clearError("MainUserComponentId");
	// }

	dh.commit();
};
