import React, { useState, useEffect, useRef, useCallback } from "react";
import _ from "lodash";
import { Pulse } from "./Animated";
import { generateCypressProps } from "componentsLibraryResources/functions/cypress";

export const ExternalLink = (props) => {
	const {
		href,
		label,
		className = "",
		target = "_blank",
		nowrap = true,
		title = "",
	} = props;

	const nowrapClass = nowrap ? "whitespace-no-wrap" : "";

	return (
		<a
			className={`ext-link ${nowrapClass} ${className}`}
			target={target}
			rel="noopener noreferrer"
			href={href}
			title={title}
			{...generateCypressProps("ext-link", props)}
		>
			{label}
		</a>
	);
};

const ButtonBase = (props) => {
	//https://stackoverflow.com/questions/23123138/how-to-perform-debounce
	const {
		isLoading = false,
		loadingInline = false,
		children,
		className,
		LoadingComponent = () => <Pulse size="6px" colour="" />,
		type,
		onClick = () => {},
		disableClickWhenLoading = true,
		disabled = false,
		innerRef,
	} = props;

	if (!children) return null;
	// if (disabled) return null;

	const handleOnClick = (e) => {
		if (disabled) return;
		if (disableClickWhenLoading && isLoading) return;
		onClick(e);
	};

	return (
		<button
			className={[
				"lctr-button",
				className,
				isLoading && !loadingInline && "active",
				isLoading && "loading",
			]
				.filter(Boolean)
				.join(" ")}
			onClick={handleOnClick}
			type={type}
			disabled={disabled}
			{...generateCypressProps("button", props)}
			ref={innerRef}
		>
			{(function () {
				//NOTE: Be careful of #3295 GOOGLE TRANSLATE ERRORS
				if (isLoading && !loadingInline)
					return (
						<span className="d-flex justify-content-center align-items-center">
							<LoadingComponent />
						</span>
					);

				if (isLoading && loadingInline)
					return (
						<span className="d-flex justify-content-center align-items-center">
							<span>{children}</span>
							<LoadingComponent />
						</span>
					);

				return <span>{children}</span>;
			})()}
		</button>
	);
};

export const Button = (props) => {
	const { className, ...otherProps } = props;

	return (
		<ButtonBase
			type="button"
			className={["btn", className].filter(Boolean).join(" ")}
			{...generateCypressProps("button", props)}
			{...otherProps}
		/>
	);
};

export const ButtonLink = (props) => {
	const { className, ...otherProps } = props;

	return (
		<ButtonBase
			className={["btn", "btn-link", className].filter(Boolean).join(" ")}
			{...generateCypressProps("button-link", props)}
			{...otherProps}
		/>
	);
};

export const ButtonClose = (props) => {
	const { className, content, ...otherProps } = props;

	return (
		<ButtonBase
			className={["btn-close", className].filter(Boolean).join(" ")}
			{...otherProps}
		>
			{content ? <span>{content}</span> : <span>(X)</span>}
		</ButtonBase>
	);
};

export const ButtonSubmit = (props) => {
	const {
		type, // Remove but don't use
		"data-cy": dataCy,
		...otherProps
	} = props;

	return (
		<Button
			type="submit"
			{...otherProps}
			{...generateCypressProps("button-submit", props)}
		/>
	);
};

export const ButtonToggle = (props) => {
	const { className, checked, children, ...otherProps } = props;

	return (
		<button
			type="button"
			autoComplete="off"
			className={["btn", checked && "active", className]
				.filter(Boolean)
				.join(" ")}
			{...otherProps}
		>
			{children}
		</button>
	);
};

export const IconButtonLabel = (props) => {
	const { iconClass, labelText } = props;

	const _iconClass = ["icon", iconClass].filter(Boolean).join(" ");

	return (
		<span className="d-flex flex-column">
			<span className={_iconClass} />
			<span>{labelText}</span>
		</span>
	);
};

{
	/* export const ButtonToggle = (props) => {
	const {
		id = _.uniqueId("ButtonToggle_id"),
		name = _.uniqueId("ButtonToggle-Name"),
		checked = false,
		isActive = false,
		onClick,
		onChange,
		disabled,
		className,
		classNameChecked,
		classNameUnchecked,
		label,
		children,
		value,
	} = props;
	const _isActive = isActive || checked;

	if (!label && !children)
		throw `Error in ButtonToggle --i missing label and/or children`;
	if (!onClick && !onChange)
		throw `Error in ButtonToggle -- missing onClick and/or onChange`;
	if (!("checked" in props) && !("isActive" in props))
		throw `Error in ButtonToggle -- missing checked and/or isActive`;

	const _className = [
		className,
		"btn",
		"n btn-outline-primary",
		//"fb-radio-btn"
	]
		.filter(Boolean)
		.join(" ");

	// console.log("dddd ", props);
	const _onClick = _.throttle(onClick || onChange, 1000, { trailing: false });

	// const _onClick = onClick || onChange;

	return (
		<>
			<input
				className="btn-check"
				id={id}
				type="radio"
				name={name}
				autoComplete="off"
				checked={_isActive}
				disabled={disabled}
				readOnly
				onClick={_onClick}
				value={value}
			/>
			<label className={_className} htmlFor={id}>
				{label || children}
			</label>
		</>
	);
}; */
}
