import React, { useState, useEffect, useCallback, useMemo } from "react";
import _ from "lodash";
import useValueSet from "./useValueSet";

const useVehicleSummaryData = (args = {}) => {
	const {
		hitlist,
		isEnabled = true,
		SummaryPanelWrapper = () => null,
		SummaryItemText,
		fnSortHitlist = (hitlist) => hitlist, //Optional sorter
		memoizationList, // list of items that cause useCallback() to recalculate
	} = args;

	["hitlist", "SummaryItemText"].forEach((k) => {
		if (!(k in args)) {
			throw `Error in useVehicleSummaryData -- missing "${k}"`;
		}
	});

	if (
		hitlist.some((item) => {
			return ![
				"id",
				"showInSummary",
				"showInEdit",
				"showInMissing",
				"isMissing",
			].every((k) => k in item);
		})
	) {
		throw `Error -- invalid vehicle hitlist`;
	}

	const [memoizeCache, setMemoizeCache] = useValueSet();
	const [memoizeWatchValues, setMemoizeWatchValues] = useValueSet();

	// ************************************
	// MAIN FUNCTION
	// ************************************
	const fnRecalculate = () => {
		const baseReturnItem = {
			summaryListProps: hitlist
				.filter((x) => x.databaseProps)
				.map((x) => x.databaseProps),
		};

		if (!isEnabled) return baseReturnItem;

		// ************************************
		// HITLISTS
		// ************************************

		const hitlistSummaryPanelItems = fnSortHitlist(
			hitlist.filter((x) => x.showInSummary && !x.isMissing)
		);

		const hitlistSummaryPanelEditItems = fnSortHitlist(
			hitlist.filter((x) => x.showInEdit && !x.isMissing)
		);

		const hitlistSummaryPanelMissingItems = fnSortHitlist(
			hitlist.filter((x) => x.showInMissing && x.isMissing)
		);

		// ************************************
		// PANELS
		// ************************************

		const summaryPanel =
			hitlistSummaryPanelItems.length >= 1 ? (
				<SummaryPanelWrapper>
					<ul>
						{
							hitlistSummaryPanelItems
								.map((data = {}, i) => {
									const {
										databaseProps,
										fnTemplate,
										fnGenerateSummaryDescription,
										id,
									} = data;

									if (fnGenerateSummaryDescription)
										return (
											<span key={id}>{fnGenerateSummaryDescription()}</span>
										);

									return (
										<li key={id}>
											<SummaryItemText
												databaseProps={databaseProps}
												fnTemplate={fnTemplate}
											/>
										</li>
									);
								})
								.filter((x) => x) //remove blank entries
						}
					</ul>
				</SummaryPanelWrapper>
			) : undefined;

		const fnCacheHandler = (x) => {
			const { id: cacheKey, memoize } = x;

			const useCache = memoize ? true : false;

			if (useCache && cacheKey in memoizeCache) {
				// FILTER LIST
				if (_.isArray(memoize)) {
					//If nothing changed, return the cached value
					if (_.isEqual(memoizeWatchValues[cacheKey], memoize)) {
						return memoizeCache[cacheKey];
					}

					const retItem = <x.Component key={x.id} />;
					setMemoizeWatchValues(cacheKey, memoize);
					setMemoizeCache(cacheKey, retItem);
					return retItem;
				}
				// UNFILTERED
				if (memoize === true) {
					return memoizeCache[cacheKey];
				}
			}

			const retItem = <x.Component key={x.id} />;
			if (useCache) setMemoizeCache(cacheKey, retItem);
			return retItem;
		};

		const summaryPanelEdit = hitlistSummaryPanelEditItems.map(fnCacheHandler);

		const summaryPanelMissing =
			hitlistSummaryPanelMissingItems.map(fnCacheHandler);

		// ************************************
		// Final RETURN
		// ************************************

		return {
			summaryPanel,
			summaryPanelEdit,
			summaryPanelMissing,
			showSummaryPanel: hitlistSummaryPanelItems.length >= 1,
			...baseReturnItem,
		};
	};
	// ************************************
	// RETURN
	// ************************************
	if (memoizationList) return useMemo(fnRecalculate, memoizationList);

	return fnRecalculate();
};

export default useVehicleSummaryData;
