import { useEffect } from "react";
import { useDispatch as _useDispatch, useSelector } from "react-redux";
import useDispatchWithErrors from "moveToLibrary/hooks/useDispatchWithErrors";
import { selectors, actions } from "siteStore";
import { useNavigate } from "react-router-dom";
export const useDispatch = () => {
	// A modified version of useDispatch, but with ERROR HANDLING
	const dispatch = _useDispatch();
	const dispatchWithErrors = useDispatchWithErrors(dispatch);

	return dispatchWithErrors;
};
