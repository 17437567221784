const siteComponents = {
	...require("./Animated.js"),
	...require("./Buttons.js"),
	...require("./Dates.js"),
	...require("./Dev.js"),
	...require("./Display.js"),
	...require("./Feedback.js"),
	...require("./Inputs.js"),
	...require("./Layout.js"),
	...require("./Misc.js"),
	...require("./Modal.js"),
};

// console.log("dddd",siteComponents);
// throw `ggg`

// export * from "./Buttons.js";
// export * from "./Display.js";
// export * from "./Layout.js";
// export * from "./Dates.js";
// export * from "./Inputs.js";
// export * from "./Feedback.js";
// export * from "./Modal.js";
// export * from "./Animated.js";

export default siteComponents;
