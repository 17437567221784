import React from "react";
import Base from "../Base";
import { useNavigate } from "react-router-dom";

import { Button } from "siteComponentsLibrary/Buttons";
import config from "config";
const routeDictionary = config.lookups.routes.gap;

// There is no
const SessionBlockerView = (props) => {
	const navigate = useNavigate();

	return (
		<Base heading="Your session has expired" icon="icon-session-expired">
			<div>
				For your security your session with us has expired due to inactivity.
			</div>

			<div className="mt-3">You can start a new quote below.</div>

			<div className="mt-3">
				<Button
					data-cy="btn:clear-timeout"
					onClick={() => {
						navigate(routeDictionary.sections.start);
					}}
					className="btn-primary"
				>
					Start a new quote
				</Button>
			</div>
		</Base>
	);
};

export default SessionBlockerView;
