import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";

import LayoutPageGap from "siteLayouts/Page/Gap";
import { Panel } from "siteComponentsLibrary/Layout";
import { InputGroupPound } from "siteComponentsLibrary/Inputs";

import useLayoutPropsModifer from "../../../useLayoutPropsModifier";

import VanPicker from "./Picker/Van";
import VanSummary from "./Summary/Van";
import CarPicker from "./Picker/Car";
import CarSummary from "./Summary/Car";
import constants from "libraryConstants/database";

const ViewsGapRiskEditMainDetails = (props) => {
	const { layoutProps = {} } = props;

	const commonData = useOutletContext();
	const validationPathArray = require("../../../validationLists").default
		.yourVehicle;
	// const test =

	const newLayoutProps = useLayoutPropsModifer({
		layoutProps,
		commonData,
		validationPathArray,
		errorMessage: "Please correct the errors above",
	});

	const {
		TextBox,
		TextBoxNumber,
		FormGroup,
		DatePickerWithButtons,
		RadioButtonsWebService,
		DatePickerTextBox,
		RadioButtons,
		RadioButtonsYesNo,
		CheckboxButton,
		DropDownTypeWebService,
	} = commonData.storeComponents;

	const vehicleType = useSelector((state) =>
		commonData.risk.selectors.value(state, "Risk/Vehicle/VehicleType")
	);

	const regNumber = useSelector((state) =>
		commonData.risk.selectors.value(state, "Risk/Vehicle/RegNumber")
	);

	const vehicleTypeDisplay = (function () {
		if (vehicleType === "GoodsVehicle") return "van";
		if (vehicleType === "Car") return "car";
		return vehicleType;
	})();

	const [showErrors, setShowErrors] = useState(false);

	return (
		<LayoutPageGap
			headingText={`About your ${vehicleTypeDisplay}`}
			{...newLayoutProps}
		>
			{vehicleType === "GoodsVehicle" && (
				<Panel>
					<VanPicker />
					<VanSummary />
				</Panel>
			)}

			{vehicleType === "Car" && (
				<Panel>
					<CarPicker />
					<CarSummary />
				</Panel>
			)}

			{vehicleType && (
				<Panel>
					<FormGroup
						databaseProps={{ path: "Risk/Vehicle/Purchased" }}
						label={`When did you purchase your ${vehicleTypeDisplay}?`}
						data-cy={`form-group:${vehicleTypeDisplay}-purchase`}
						classNameContent="col-md-8 col-lg-6"
					>
						<DatePickerTextBox
							databaseProps={{ path: "Risk/Vehicle/Purchased" }}
							itemData={constants.lists.yesNo}
							data-cy={`radio:${vehicleTypeDisplay}-purchase`}
						/>
					</FormGroup>

					<FormGroup
						databaseProps={{ path: "Risk/BoughtFromDealer" }}
						label={`Did you purchase the ${vehicleTypeDisplay} from a dealership?`}
						helpText={`This would be a VAT registered motor trader or ${vehicleTypeDisplay} dealership.`}
						data-cy="form-group:bought-from-dealer"
					>
						<RadioButtonsYesNo
							databaseProps={{ path: "Risk/BoughtFromDealer" }}
							itemData={constants.lists.yesNo}
							data-cy="radio:bought-from-dealer"
						/>
					</FormGroup>

					<FormGroup
						databaseProps={{
							path: "Risk/Vehicle/Paid",
						}}
						label={`How much did you pay for the ${vehicleTypeDisplay}?`}
						data-cy="form-group:vehicle-paid"
						classNameContent="col-md-8 col-lg-6"
					>
						<InputGroupPound data-cy="input-group:vehicle-paid">
							<TextBox
								databaseProps={{ path: "Risk/Vehicle/Paid" }}
								data-cy="text:vehicle-paid"
							/>
						</InputGroupPound>
					</FormGroup>

					<FormGroup
						databaseProps={{ path: "ChosenOptions/OwnerKeeperQuestion" }}
						label={`Are you the owner and registered keeper of the ${vehicleTypeDisplay}?`}
						data-cy="form-group:owner-keeper"
					>
						<RadioButtonsYesNo
							databaseProps={{ path: "ChosenOptions/OwnerKeeperQuestion" }}
							itemData={constants.lists.yesNo}
							data-cy="radio:owner-keeper"
						/>
					</FormGroup>

					<FormGroup
						databaseProps={{ path: "Risk/Vehicle/Owner" }}
						label={`Who is the owner of the ${vehicleTypeDisplay}?`}
						data-cy="form-group:vehicle-owner"
						classNameContent="col-md-8 col-lg-6"
					>
						<DropDownTypeWebService
							databaseProps={{ path: "Risk/Vehicle/Owner" }}
							DataFunction={commonData.dictionary.services.lookup.vehicle.owner}
							data-cy="select:vehicle-owner"
						/>
					</FormGroup>

					<FormGroup
						databaseProps={{ path: "Risk/Vehicle/Keeper" }}
						label={`Are you the registered keeper of the ${vehicleTypeDisplay}?`}
						data-cy="form-group:vehicle-keeper"
					>
						<RadioButtonsYesNo
							databaseProps={{ path: "Risk/Vehicle/Keeper" }}
							itemData={constants.lists.yesNo}
							data-cy="radio:vehicle-keeper"
						/>
					</FormGroup>

					<FormGroup
						databaseProps={{
							path: "Risk/Vehicle/CurrentMileage",
						}}
						label="Your current mileage"
						data-cy="form-group:current-mileage"
						classNameContent="col-lg-10"
					>
						<RadioButtons
							itemData={[
								{
									label: "Up to 100,000",
									value: 100000,
								},
								{
									label: "100,001 to 120,000",
									value: 120000,
								},
								{
									label: "More than 120,000",
									value: 120001,
								},
							]}
							databaseProps={{ path: "Risk/Vehicle/CurrentMileage" }}
							className="stackable"
							data-cy="radio:current-mileage"
						/>
					</FormGroup>

					{/* <FormGroup
						databaseProps={{ path: "Risk/HireOrRewardUse" }}
						label={`Is the ${vehicleTypeDisplay} used for hire or reward?`}
						helpText="This includes being used as a taxi, mini cab, courier, or for driving tuition."
						data-cy="form-group:hire-or-reward"
					>
						<RadioButtonsYesNo
							databaseProps={{ path: "Risk/HireOrRewardUse" }}
							itemData={constants.lists.yesNo}
							data-cy="radio:hire-or-reward"
						/>
					</FormGroup>

					<FormGroup
						databaseProps={{ path: "Risk/IsInsuranceWriteOff" }}
						label={`Has the ${vehicleTypeDisplay} ever been declared an insurance write off?`}
						data-cy="form-group:write-off"
					>
						<RadioButtonsYesNo
							databaseProps={{ path: "Risk/IsInsuranceWriteOff" }}
							itemData={constants.lists.yesNo}
							data-cy="radio:write-off"
						/>
					</FormGroup> */}
				</Panel>
			)}
		</LayoutPageGap>
	);
};

export default ViewsGapRiskEditMainDetails;
