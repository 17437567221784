import React from "react";
import { Provider } from "react-redux";
import { store } from "./index";

const StoreProvider = (props) => {
	const { children } = props;

	return <Provider store={store}>{children}</Provider>;
};

export default StoreProvider;
