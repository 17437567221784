import moment from "moment";

import dataHelper from "@library/common/database/rules/helpers/dataHelper";

export default (args) => {
	const {
		dataSet,
		functionArgs,
		dataHelper = require("@library/common/database/rules/helpers/dataHelper"),
	} = args;
};
