import React from "react";
import { JSONTree } from "react-json-tree";

export const ScreenFinder = require("./data/dev/ScreenFinder").default;

export const ReduxStoreViewer = require("./data/dev/ReduxStoreViewer").default;

export const JSONViewer = (props) => {
	//sortObjectKeys
	const { children } = props;

	return <JSONTree data={children} sortObjectKeys={true} hideRoot={true} />;
};
