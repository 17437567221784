const debugData = false;
// const fnLog = (...args) => {
//   if (!debugData) return;
//   console.log(...args);
// };

const fnOutputErrorInfo = (...args) => {
	console.log("********************************************");
	console.log("ERRORINFO:", ...args);
	console.log("********************************************");
};

import { isEmpty } from "@library/common/database/rules/helpers";
import _ from "lodash";
// import {
//   fnGetRiskItem,
//   fnGetRiskItemAndCreate,
//   fnFindItemByNodeName,
// } from "../utils";

import riskUtils from "../utils/risk";
import {
	fnParseDataSetArgItem,
	fnArgItemToSearchPath,
} from "../utils/migration";
import dateHelpers from "@library/common/helpers/misc/date";

import {
	fnUpdate,
	fnUpdateAddSubKey,
	fnUpdateRemoveSubKey,
} from "./baseFunctions";

class debugHelper {
	constructor(description) {
		this.description = description;
		this.log = [];
	}

	logMessage(...args) {
		this.log.push({ args });
	}

	logDump() {
		console.groupCollapsed("LOG:", "(", this.description, ")");
		this.log.forEach((x) =>
			console.log(
				x.functionName && `functionName: ${x.functionName}`,
				x.info,
				...x.args
			)
		);
		console.groupEnd();
	}

	throwError(e) {
		this.logDump();
		throw e;
	}
	throwErrorMessage(...msgArgs) {
		this.logDump();
		throw ["Error in", this.description, "-", ...msgArgs].join(" ");
	}

	createLogger(functionName, info = {}) {
		return (...args) => {
			this.log.push({ functionName, info, args });
		};
	}
}

class dataHelperBase {
	constructor(args) {
		const requiredArgs = [
			"updatePath",
			"ruleName",
			"isPostSalusLoad",
			"isPostRegistration",
			"errorKey",
			"fnRuleQueueAdd",
			"fnRuleQueueRun",
			"fnHasChanged",
			"fnPersistedData_updateValue_log",
			"fnPersistedData_updateValue_check",
			// "fnIsNew",
			"dataSet",
			"fnIsProtected",
			"debugHelper",
		];

		requiredArgs.forEach((key) => {
			if (!(key in args)) {
				console.log("ERROR INFO:", args);
				throw `Error in dataHelperBase -- missing arg "${key}"`;
			}
		});

		// fnLog("dataHelper created:", args);
		this.state = args.state;

		this.debugHelper = args.debugHelper;
		this.updatePath = args.updatePath; // This is the item being updated
		this._console = args._console;
		this.mappings = args.mappings;
		this.mappingCache = {};
		this.ruleName = args.ruleName;
		// this.ruleHistory = args.ruleHistory || [];
		this.persistedData = args.persistedData;
		this._isPostSalusLoad = args.isPostSalusLoad;
		this._isPostRegistration = args.isPostRegistration;
		this.errorKey = args.errorKey;

		// this.persistedData.ruleHistory = this.persistedData.ruleHistory || [];
		// this.persistedData.ruleHistory.push({
		//   ruleName: this.ruleName,
		//   path: this.updatePath,
		// });
		this.fnPersistedData_updateValue_log = args.fnPersistedData_updateValue_log;
		this.fnPersistedData_updateValue_check =
			args.fnPersistedData_updateValue_check;
		this.fnRuleQueueRun = args.fnRuleQueueRun;
		this.fnIsProtected = args.fnIsProtected;
		this.fnRuleQueueAdd = (path, debugData) => {
			return args.fnRuleQueueAdd(path);
			// if (this.ruleHistory.includes(this.ruleName)) {
			//   console.log("Already ran this dataHelper -- skipping", {
			//     ruleName: this.ruleName,
			//     ruleHistory: this.ruleHistory,
			//   });

			//   return;
			// }
			this._console.log(" this.fnRuleQueueAdd", "debugData", debugData, {
				ruleHistory: this.persistedData.ruleHistory,
			});

			console.log(" this.fnRuleQueueAdd", path, "debugData", debugData);
			return args.fnRuleQueueAdd(path);
		};

		this.fnHasChanged = args.fnHasChanged;
		// this.fnIsNew = args.fnIsNew;
		this.dataSet = args.dataSet;

		// *** persistedData updatedPaths
		this.persistedData.updatedPaths = this.persistedData.updatedPaths || {};
		if (this.fnHasChanged(this.updatePath)) {
			this.persistedData.updatedPaths[this.updatePath] = true;
		}
		// clear all errors on load
		//todo: executionCount = 1
		if (
			this.mappings
			// && !this.ruleHistory.map((x) => x.ruleName).includes(this.ruleName) // Not ran this rule before
		) {
			Object.keys(this.mappings).forEach((mappingId) => {
				// if (!mappingId) {
				// 	console.log("ERROR INFO:", { this: this });
				// 	throw `Error in dataHelper -- missing mapping`;
				// }
				this.clearError(mappingId);
			});
		}
	}

	returnDebugData() {
		return {
			..._.pick(this, [
				"updatePath",
				"mappings",
				"ruleName",
				"_isPostSalusLoad",
				"_isPostRegistration",
				"errorKey",
			]),
		};
	}

	debugLog(...args) {
		const _console = this._console;
		_console.log("    ", this.updatePath, ...args);
		this.debugHelper.logMessage("unknown", ...args);
	}

	getMappingItem(mappingId, debugInfo) {
		const _console = this._console;

		// fnLog(
		//   "dataHelper.getMappingItem",
		//   mappingId,
		//   debugInfo,
		//   "=",
		//   "{searching}"
		// );

		if (!(mappingId in this.mappings)) {
			fnOutputErrorInfo({ mappingId, debugInfo, this: this });

			this.debugHelper.throwErrorMessage(
				`Error in datahelper -- can't find mappingId "${mappingId}"`
			);
		}
		const foundValue = this.mappings[mappingId];
		// fnLog("dataHelper.getMappingItem", mappingId, debugInfo, "=", foundValue);
		if (!foundValue) {
			fnOutputErrorInfo({ mappingId, debugInfo, this: this });
			this.debugHelper.throwErrorMessage(
				`Error in datahelper --  mappingId "${mappingId} is blank"`
			);
		}

		return foundValue;
	}

	cacheGet(mappingId) {
		if (mappingId in this.mappingCache) {
			// console.log("CACHE GET", mappingId);
			return this.mappingCache[mappingId];
		}
		return undefined;
	}

	cacheSet(mappingId, obj) {
		if (mappingId in this.mappingCache) return this.mappingCache[mappingId];

		// console.log("CACHE SET", mappingId);
		this.mappingCache[mappingId] = obj;
	}

	cacheWrapper(mappingId, generateFunc) {
		const cacheObj = this.cacheGet(mappingId);
		if (cacheObj) return cacheObj;

		const newObj = generateFunc();
		this.cacheSet(mappingId, newObj);
		return newObj;
	}

	persistedData_updateValue_log(mappingId) {
		const path = this.getMappingItem(mappingId, "persistedDataUpdate_log");

		this.persistedData.updatedPaths[path] = true;
	}

	persistedData_updateValue_check(mappingId) {
		const path = this.getMappingItem(mappingId, "persistedDataUpdate_check");

		if (this.persistedData.updatedPaths[path]) return true;
		return false;
	}

	throwError(...msg) {
		this.debugHelper.logMessage("Unknown", "ERROR INFO:", { this: this });
		this.debugHelper.throwErrorMessage(
			`${msg.join(",")} (ruleName:"${this.ruleName}")`,
			{ this: this }
		);
	}

	setData(metaKey, mappingId, value) {
		const _console = this._console;

		switch (metaKey) {
			case "value": {
				this.setValue(mappingId, value);
				break;
			}
			case "visible": {
				this.setVisible(mappingId, value);
				break;
			}
			default:
				this.throwErrorMessage(
					`Error in setData -- unknown metaKey "${metaKey}/${mappingId}"`
				);
		}
	}

	getData(metaKey, mappingId) {
		const _console = this._console;

		switch (metaKey) {
			case "value":
				return this.getValue(mappingId);
			case "visible":
				return this.getVisible(mappingId);
			default:
				this.throwErrorMessage(
					`Error in getData -- unknown metaKey "${metaKey}/${mappingId}"`
				);
		}
	}

	cloneValue(srcMappingId, destMappingId) {
		const _console = this._console;

		const srcObj = riskUtils.find.itemAndCreate(
			this.state,
			this.getMappingItem(srcMappingId, "cloneValue"),
			{
				onCreate: (args = {}) => {
					const { searchPath } = args;
					this.fnRuleQueueAdd(searchPath, "dh.cloneValue");
				},
			}
		);
		const destObj = riskUtils.find.itemAndCreate(
			this.state,
			this.getMappingItem(destMappingId, "cloneValue"),
			{
				onCreate: (args = {}) => {
					const { searchPath } = args;
					this.fnRuleQueueAdd(searchPath, "dh.cloneValue");
				},
			}
		);

		// fnLog("dddddd", { state: this.state });
		// throw `hhhh`
		destObj._value = srcObj._value;
	}

	runRules(mappingId) {
		const searchPath = this.getMappingItem(mappingId, "runRules");
		this.fnRuleQueueAdd(searchPath, "runRules");
	}

	runRulesByNodeName(nodeName) {
		const _console = this._console;

		const foundItems = riskUtils.find.itemByNodeName(this.state, nodeName);

		foundItems
			.map((x) => x.join("/"))
			.forEach((path) => {
				this.debugLog("runRulesByNodeName", path);
				this.fnRuleQueueAdd(path, "runRulesByNodeName");
			});
	}

	getValue(mappingId) {
		const _console = this._console;

		// fnLog("dataHelper.getValue()", path);
		_console.time("datahelper.getValue");

		const obj = this.cacheWrapper(mappingId, () =>
			riskUtils.find.itemAndCreate(
				this.state,
				this.getMappingItem(mappingId, "getValue"),
				{
					onCreate: (args = {}) => {
						const { searchPath } = args;
						this.fnRuleQueueAdd(searchPath, "dh.getValue");
					},
				}
			)
		);

		const retData = obj["_value"];

		_console.timeEnd("datahelper.getValue");
		return retData;
	}

	getValueDate(mappingId) {
		const _console = this._console;

		_console.log("dataHelper.getValueDate()", mappingId);

		const value = this.getValue(mappingId);

		if (value === undefined) return undefined;

		return dateHelpers.toDate(value);
	}

	setValue(mappingId, value, options = {}) {
		const _console = this._console;
		const { ignoreProtection = false } = options;

		this.debugLog("dataHelper.setValue()", {
			mappingId,
			value,
		});

		const path = this.getMappingItem(mappingId, "setValue");

		if (!ignoreProtection && this.fnIsProtected(path)) return; // a.k.a. the locklist

		const existingValue = this.getValue(mappingId);
		this.persistedData_updateValue_log(mappingId); //Log the change regardless

		if (!_.isEqual(existingValue, value)) {
			_console.time("datahelper.setValue-" + mappingId);

			const cacheObj = this.cacheGet(mappingId);

			if (cacheObj) {
				cacheObj["_value"] = value;
			} else {
				const updatedObject = fnUpdate(this.state, path, "_value", value, {
					onCreate: (args = {}) => {
						const { searchPath } = args;
						// console.log("CREATED", mappingId);
						this.fnRuleQueueAdd(searchPath, "dh.setValue");
					},
				});
				this.cacheSet(mappingId, updatedObject);
			}

			this.debugLog("dataHelper.setValue()", {
				mappingId,
				value,
			});

			this.fnRuleQueueAdd(path, {
				info: "datahelper.setValue",
				existingValue,
				value,
			});
			_console.timeEnd("datahelper.setValue-" + mappingId);
		}

		// this.setErrorShow(mappingId, true);
	}

	setVisible(mappingId, value = true) {
		const _console = this._console;

		_console.time("datahelper.setVisible");

		this.debugLog("dataHelper.setVisible()", {
			mappingId,
			value,
		});
		const isHidden = !value; // Need to flip it

		fnUpdate(
			this.state,
			this.getMappingItem(mappingId, "setVisible"),
			"_hidden",
			isHidden
		);

		if (isHidden) {
			this.setErrorShow(mappingId, false);
		}

		_console.timeEnd("datahelper.setVisible");
	}

	setErrorShow(mappingId, value) {
		const _console = this._console;

		this.debugLog("dataHelper.setVisible()", {
			mappingId,
			value,
		});

		fnUpdate(
			this.state,
			this.getMappingItem(mappingId, "setVisible"),
			"_errorShow",
			value
		);
	}

	getVisible(mappingId) {
		const _console = this._console;

		this.debugLog("dataHelper.setVisible()", { mappingId });

		const obj = riskUtils.find.item(
			this.state,
			this.getMappingItem(mappingId, "getVisible")
			// "_hidden"
		);

		if (!obj) return true;

		if ("_hidden" in obj) {
			return !obj._hidden;
		}
		return true;
	}

	getComponentId(mappingId) {
		const _console = this._console;

		const searchPath = this.getMappingItem(mappingId, "getComponentId");
		return searchPath;
	}

	isUpdated(...args) {
		const _console = this._console;

		const fnCheckIsUpdate = (mappingId) => {
			//NOTE: We need this, as the USER could change a value back to it's original value
			if (this.persistedData_updateValue_check(mappingId)) return true;

			//TODO: Check this
			// if (this.isPostRegistration()) return true; // NOTE: ??set by "postRegistrationFirstLevel" in node-library-redux
			// if (this.isPostRecall()) return true;

			// if (this.fnHasChanged(searchPath)) return true;

			// Array actions
			const searchPath = this.getMappingItem(mappingId, "isUpdated");

			if (riskUtils.searchPath.array.isArrayWithIndex(this.updatePath)) {
				// Check for ARRAY updates
				const cleanPath = searchPath
					.split("/")
					.map((x, i, arr) => {
						if (i === arr.length - 1)
							return riskUtils.searchPath.array.clean(x);
						return x;
					})
					.join("/");

				if (cleanPath === searchPath) return true;
			}

			return false;
			//OLD BELOW
			if (false) {
				// console.log(
				//   "TODO: We need to flag as true if an attempt has been made to update, that way secondaryOccupation will work"
				// );

				if (this.isPostRegistration()) return true; // NOTE: ??set by "postRegistrationFirstLevel" in node-library-redux
				// if (this.isPostRecall()) return true;

				// if (this.isPostRecall()) {
				//   this.debugLog("dataHelper.isUpdated()", mappingId, true, {
				//     isPostRecall: this.isPostRecall(),
				//   });
				//   return true;
				// }

				// console.log("dddddddddddddpersistedData", mappingId, this.persistedData);

				// if (this.persistedData_updateValue_check(mappingId)) return true;

				const searchPath = this.getMappingItem(mappingId, "isUpdated");

				if (riskUtils.searchPath.array.isArrayWithIndex(this.updatePath)) {
					// Check for ARRAY updates
					const cleanPath = searchPath
						.split("/")
						.map((x, i, arr) => {
							if (i === arr.length - 1)
								return riskUtils.searchPath.array.clean(x);
							return x;
						})
						.join("/");

					if (cleanPath === searchPath) return true;
				}

				const retValue = this.fnHasChanged(searchPath);

				this.debugLog("dataHelper.isUpdated()", mappingId, retValue, {
					isPostRecall: this.isPostRecall(),
					searchPath: searchPath,
					updatePath: this.updatePath,
				});

				return retValue;
			}
		};

		_console.time("datahelper.isUpdated");
		const retData = args.some((mappingId) => fnCheckIsUpdate(mappingId));
		// console.log("ddddddddd,isUpdated", ...args, retData, {
		//   persistedData: this.persistedData,
		//   isPostRegistation: this.isPostRegistration(),
		// });
		_console.timeEnd("datahelper.isUpdated");
		return retData;
	}

	isPostRegistration(...args) {
		return this._isPostRegistration;
		return this._isPostRegistration || this._isPostSalusLoad;
	}

	isPostRecall(...args) {
		// return false
		return this._isPostSalusLoad;
		return this._isPostRegistration || this._isPostSalusLoad;
	}

	addError(mappingId, errorMessage) {
		const _console = this._console;

		_console.time("dh.addError-" + mappingId);

		if (errorMessage === undefined) {
			fnUpdateRemoveSubKey(
				this.state,
				this.getMappingItem(mappingId, "addError"),
				"_error",
				this.errorKey
			);
			return;
		}

		fnUpdateAddSubKey(
			this.state,
			this.getMappingItem(mappingId, "addError"),
			"_error",
			this.errorKey,
			errorMessage
		);
		_console.timeEnd("dh.addError-" + mappingId);
	}
	clearError(mappingId) {
		const _console = this._console;

		const searchPath = this.getMappingItem(mappingId, "clearError");

		fnUpdateRemoveSubKey(this.state, searchPath, "_error", this.errorKey);
	}

	addErrorIfEmpty(mappingId, errorMessage) {
		const _console = this._console;

		const searchPath = this.getMappingItem(mappingId, "addErrorIfEmpty");
		const value = this.getValue(mappingId);

		// console.log("dddddddddddd addErrorIfEmpty", searchPath, { value, isEmpty: isEmpty(value) });
		if (isEmpty(value)) {
			fnUpdateAddSubKey(
				this.state,
				searchPath,
				"_error",
				this.errorKey,
				errorMessage
			);
		} else {
			fnUpdateRemoveSubKey(this.state, searchPath, "_error", this.errorKey);
		}
	}

	resetErrorsShow(mappingId) {
		const _console = this._console;

		this.setErrorShow(mappingId, false);
	}

	populateItem(mappingId) {
		const _console = this._console;

		const path = this.getMappingItem(mappingId, "populateItem");
		riskUtils.find.itemAndCreate(this.state, path, {
			onCreate: (args = {}) => {
				const { searchPath } = args;
				this.fnRuleQueueAdd(searchPath, "dh.populateItem");
			},
		});
		return { componentId: path };
	}

	commit() {
		this.fnRuleQueueRun();
		//Does nothing
		// console.warn("commit", "not implemented");
	}
}

const fnGenerateLegacy = (state, args = {}) => {
	// Base data
	const {
		_console,
		ruleName,
		mappings,
		updatePath,
		isPostSalusLoad = false,
		isPostRegistration = false,
		fnRunRule,
		fnHasChanged,
		errorKey,
		persistedData,
		protectedList = [],
		level,
	} = args;

	const requiredArgs = [
		"_console",
		"ruleName",
		"mappings",
		"updatePath",
		"isPostSalusLoad",
		"isPostRegistration",
		"fnRunRule",
		"fnHasChanged",
		"errorKey",
		"persistedData",
		"protectedList",
		"level",
	];

	requiredArgs.forEach((key) => {
		if (!(key in args)) {
			console.log("ERROR INFO:", args);
			throw `Error in fnGenerateLegacy -- missing arg "${key}"`;
		}
	});

	// console.log("*************", updatePath, ruleName);

	//******************************************
	// SETUP
	//******************************************
	const _debugHelper = new debugHelper(updatePath);

	persistedData.ruleHistory = persistedData.ruleHistory || [];
	persistedData.ruleHistory.push({
		ruleName: ruleName,
		path: updatePath,
	});

	const ruleQueue = []; // This is effectively reset on every rule call. We use a queue so that we can delay rulecalls until the commit() is called.

	const fnRuleQueueAdd = (path, debugData) => {
		// console.log(" this.fnRuleQueueAdd", path, "debugData", debugData);
		if (!ruleQueue.includes(path)) {
			ruleQueue.push(path);
		}
	};

	const fnRuleQueueRun = async () => {
		// Called by commit()
		for (const curPath of ruleQueue) {
			//NOTE: fnRunRule() is NOT ASYNC, so don't edit this to add an "await"
			fnRunRule(curPath);
		}
	};

	persistedData.updatedPaths = persistedData.updatedPaths || {};

	const fnPersistedData_updateValue_log = (path) => {
		persistedData.updatedPaths[path] = true;
	};

	const fnPersistedData_updateValue_check = (path) => {
		if (persistedData.updatedPaths[path]) return true;
		return false;
	};

	if (level === 1) {
		fnPersistedData_updateValue_log(updatePath);
	}

	const fnIsProtected = (path) => {
		if (protectedList.includes(path)) return true;
		return false;
	};

	//******************************************
	// ABORT CODE
	//******************************************
	if (
		false &&
		persistedData.ruleHistory.some(
			(x) => (x.ruleName = ruleName && x.path === updatePath)
		)
	) {
		return { runRule: false };
	}

	//******************************************
	// DATASET
	//******************************************

	const dataSet = (function () {
		const _fnGetTree = (args = {}) => {
			try {
				const _fnLog = _debugHelper.createLogger("_fnGetTree", {
					functionArgs: args,
				});
				const { argItem, componentTagHitlist = [] } = args;

				const searchPath = fnArgItemToSearchPath(
					argItem,
					updatePath.split("/")
				);

				_fnLog("starting", { searchPath });

				if (!searchPath) {
					fnOutputErrorInfo({ argItem, componentTagHitlist, args });
					_debugHelper.throwErrorMessage(
						`Error in getTree -- can't find a searchpath`
					);
				}

				// if (searchPath === "Risk/AdditionalInsuredSet") {
				//   throw `gettree fhhhhhhhhhhhhhhh`;
				// }

				_fnLog("riskUtils.find.itemAndCreateArray", {
					searchPath: searchPath.join("/"),
				});

				const obj = riskUtils.find.itemAndCreateArray(
					state,
					searchPath.join("/"),
					{
						onCreate: (args = {}) => {
							const { searchPath } = args;
							fnRuleQueueAdd(searchPath, "dh.dataSet");
						},
					}
				);

				_fnLog("!riskUtils.is.dataNodeArray(obj)", {
					searchPath: searchPath.join("/"),
					itemAndCreateArray: obj,
				});

				if (!riskUtils.is.dataNodeArray(obj)) {
					// return []
					fnOutputErrorInfo({
						searchPath: searchPath.join("/"),
						argItem,
						componentTagHitlist,
						args,
					});
					_debugHelper.throwErrorMessage(
						`Error in getTree -- "${searchPath.join(
							"/"
						)}" didn't return an array`
					);
				}

				const retData = obj._arrayData.map(({ data, id }) => ({
					componentSet: id, //searchPath,
					// curComponentSet:11111,
					data: Object.fromEntries(
						Object.entries(_.pick(data, componentTagHitlist)).map(([l, d]) => [
							l,
							{
								value: d._value,
								componentId: `${searchPath}[${id}]/${l}`,
							},
						])
					),
				}));

				_fnLog("retData", {
					searchPath: searchPath.join("/"),
					itemAndCreateArray: obj,
					retData,
				});

				// _console.log("getTree", args, retData, {
				// 	["obj._arrayData"]: obj._arrayData,
				// });
				// throw `hhhh`;
				return retData;
			} catch (e) {
				_debugHelper.throwError(e);
			}
		};

		const _fnGetTreeSiblings = (args = {}) => {
			const { argItem, componentTagHitlist = [] } = args;

			const searchPath = fnArgItemToSearchPath(argItem, updatePath.split("/"));

			if (!searchPath) {
				fnOutputErrorInfo({ searchPath, argItem, componentTagHitlist, args });
				_debugHelper.throwErrorMessage(
					`Error in getTreeSiblings -- can't find a searchpath`
				);
			}

			//Find the last item in searchPath which is an array;
			const idx = searchPath.findLastIndex((x) =>
				riskUtils.searchPath.array.isArrayWithIndex(x)
			);

			if (idx === -1) {
				return [];
				_console.log("ERROR INFO:", args);
				throw `Error in getTreeSiblings -- argItem doesn't contain an array`;
			}

			const newSearchPath = searchPath.slice(0, idx + 1); // slice up to the last known array
			const { index: curComponentSet } = riskUtils.searchPath.array.parse(
				newSearchPath[newSearchPath.length - 1]
			);

			const retData = _fnGetTree({
				argItem: newSearchPath.join("/"),
				componentTagHitlist,
			})
				// Add in "curComponentSet"
				.map((x) => {
					return {
						...x,
						curComponentSet: x.componentSet === curComponentSet,
					};
				});

			return retData;
		};

		const fnDatasetGetObject = (dataSetArgItem, metaKey) => {
			_console.log("dataSet.fnDatasetGetObject()", {
				dataSetArgItem,
				metaKey,
			});

			if (!dataSetArgItem) {
				_console.log("ERROR INFO:", { state, ruleName, mappings });
				_debugHelper.throwErrorMessage(
					`Error in fnDatasetGetObject -- missing item`
				);
			}

			// Can sometimes be a string
			if (_.isString(dataSetArgItem)) {
				return riskUtils.find.item(state, dataSetArgItem);
			}

			const retObj = (function () {
				const searchPath = fnParseDataSetArgItem(
					dataSetArgItem,
					updatePath.split("/")
				);

				if (!searchPath) {
					_console.log("ERROR INFO:", { dataSetArgItem, searchPath });
					_debugHelper.throwErrorMessage(
						`Error in fnDatasetGetObject -- can't parse dataSetArgItem`
					);
				}

				return riskUtils.find.item(state, searchPath.join("/"));
			})();

			if (!retObj) {
				fnOutputErrorInfo({ updatePath, dataSetArgItem, metaKey });
				_debugHelper.throwErrorMessage(
					`Error in dataSet.fnDatasetGetObject() -- can't find item`
				);
			}

			switch (metaKey) {
				case "value":
					return retObj["_value"];
			}

			_debugHelper.throwErrorMessage(
				`Error in fnDatasetGetObject -- unknown key "${metaKey}"`
			);
		};

		return {
			getTree: _fnGetTree,
			getTreeSiblings: _fnGetTreeSiblings,
			getComponentSets: (args = {}) => {
				const { componentTag } = args; // DateOfBirth
				_console.log("getComponentSets", { componentTag, updatePath });

				console.log("getComponentSets", { componentTag, updatePath });

				const retData = _fnGetTreeSiblings({
					argItem: updatePath,
					componentTagHitlist: [componentTag],
				});

				// TODO: Need to find all DateOfBirth items, and the array they're part of
				// throw `Not yet impemented -- getComponentSets`;
				return retData.map((x) => x.componentSet);
			},
			dependencyAdd: (argItem) => {
				const searchPath = fnArgItemToSearchPath(argItem);
				fnRuleQueueAdd(searchPath.join("/"), "dh.dependencyAdd");
			},
			getValue: fnDatasetGetObject,

			setValue: (dataSetArgItem) => {
				_console.log("ddddd dataSet.setValue()", {
					dataSetArgItem,
				});

				const searchPath = fnParseDataSetArgItem(
					dataSetArgItem,
					updatePath.split("/")
				).join("/");

				_console.log("dataSet.setValue()", searchPath, {
					dataSetArgItem,
				});

				switch (dataSetArgItem.key) {
					case "errors": {
						//Add
						if (dataSetArgItem.value.errorMessage !== undefined) {
							fnUpdateAddSubKey(
								state,
								searchPath,
								"_error",
								errorKey,
								dataSetArgItem.value.errorMessage
							);
							break;
						}

						//Remove
						fnUpdateRemoveSubKey(state, searchPath, "_error", errorKey);

						break;
					}
					case "value": {
						if (fnIsProtected(searchPath)) return; //a.k.a. the locklist

						fnPersistedData_updateValue_log(searchPath); //Log the attempted change regardless

						const existingValue = fnDatasetGetObject(dataSetArgItem, "value");

						if (!_.isEqual(existingValue, dataSetArgItem.value)) {
							// this.persistedData_updateValue_log(mappingId);
							fnUpdate(state, searchPath, "_value", dataSetArgItem.value, {
								onCreate: ({ searchPath }) => {
									fnRuleQueueAdd(searchPath, "dh.setValue");
								},
							});
							fnRuleQueueAdd(searchPath, "dh.setValue value");

							fnRuleQueueRun();
						}

						break;
					}

					default:
						fnOutputErrorInfo({ dataSetArgItem });
						_debugHelper.throwErrorMessage(
							`Error in generateLegacy dh.dataSet.setValue -- unknown key "${dataSetArgItem.key}"`
						);
				}
			},
			debugData: { args },
		};
	})();

	//******************************************
	// DATAHELPER
	//******************************************
	class dataHelper extends dataHelperBase {
		constructor(args) {
			super({
				updatePath,
				_console,
				mappings,
				state,
				ruleName,
				isPostRegistration,
				isPostSalusLoad,
				fnRuleQueueAdd,
				fnRuleQueueRun,
				fnPersistedData_updateValue_log,
				fnPersistedData_updateValue_check,
				fnIsProtected,
				dataSet,
				fnHasChanged,
				// fnIsNew,
				persistedData,
				errorKey,
				debugHelper: _debugHelper,
			});
		}
	}

	return {
		dataHelper: dataHelper,
		dataSet: dataSet,
		runRule: true,
	};
};

export default fnGenerateLegacy;
