import React from "react";

export * from "libraryComponents/Modal";

// *********************************************************
// **** Custom && Overrides (replaces the export above) ****
// *********************************************************
import * as LibComponents from "libraryComponents/Modal";

export const Modal = ({ className, ...otherProps }) => (
	<LibComponents.Modal
		className={"modal-main p-2 m-0 col-12 col-md-8 bg-transparent"}
		overlayClassName={"modal-overlay"}
		{...otherProps}
	/>
);
