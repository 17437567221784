// import { required } from "joi";
// import { isEmpty } from "@library/common/database/rules/helpers";
import dataSetArgItem from "@library/common/database/rules/classes/dataSet/argItem";

export default (args) => {
	const { dataSet, functionArgs } = args;

	if (!dataSet) throw `Error -- missing dataSet`;
	if (!functionArgs) throw `Error -- missing functionArgs`;
	if (!functionArgs.regEx) {
		console.log("args:", args);
		throw `Error -- missing regEx`;
	}

	const testValue = dataSet.getValue(
		new dataSetArgItem({
			useDefaultComponentSet: true,
			useDefaultComponentTag: true,
		}),
		"value"
	);

	if (!("regEx" in functionArgs)) {
		console.log("args:", args);
		throw `Error in regEx`;
	}

	// console.log("ddddd", args)
	const isValid =
		testValue !== undefined &&
		testValue !== "" &&
		functionArgs.regEx.test(testValue);

	if (functionArgs.invert === true) isValid = !isValid;

	dataSet.setValue(
		new dataSetArgItem({
			key: "errors",
			value: {
				errorMessage: isValid ? undefined : functionArgs.errorMessage,
				errorKey: functionArgs.errorKey,
			},

			useDefaultComponentSet: true,
			useDefaultComponentTag: true,
		})
	);

	return null;
};
