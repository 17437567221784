import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import {
	useOutletContext,
	useParams,
	useResolvedPath,
	Navigate,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch } from "siteFunctions/hooks";

import { actions } from "siteStore";

import LayoutPageGap from "siteLayouts/Page/Gap";
import { DevContainer } from "siteComponents/Dev";
import { Button } from "siteComponentsLibrary/Buttons";
import { Modal } from "siteComponentsLibrary/Modal";
import { Panel } from "siteComponentsLibrary/Layout";

import ViewsGapRiskEditStart from "./Risk/Edit/Start";
import ViewsGapRiskEditYourDetails from "./Risk/Edit/YourDetails";
import ViewsGapRiskEditYourVehicle from "./Risk/Edit/YourVehicle";
import ViewsGapRiskYourQuote from "./Risk/YourQuote";
import ViewsGapRiskDirectDebit from "./Risk/DirectDebit";
import ViewsGapRiskFeedback from "./Risk/Feedback";
import ViewsGapRiskWrapup from "./Risk/Wrapup";
import ViewsGapRiskWrapupInterstitial from "./Risk/Wrapup/Interstitial";

import ViewsCommonRecallForm from "../Common/Recall/Form";

import ViewsGapHandoverQuoteAndBuy from "./Handover/QuoteAndBuy";

import ErrorGeneric from "../Errors/Generic";

import usePrevious from "moveToLibrary/hooks/usePrevious";
import useGapPageLogic from "./useGapPageLogic";
import config from "config";
import SetTest from "./Set.Test.jsx";

// const usePreviousValue = (value) => {
// 	const ref = React.useRef();

// 	useEffect(() => {
// 		console.log("dddd update", value)
// 		ref.current = value; //assign the value of ref to the argument
// 	}, [value]); //this code will run when the value of 'value' changes

// 	return ref.current; //in the end, return the current ref value.
// };

const routeDictionary = config.lookups.routes.gap;

// import InceptionDateCorrection from "./InceptionDate.Correction";

// 	return {
// 		show: (args) => {
// 			setShow(true);
// 			setArgs(args);
// 		},
// 		generateProps: fnGenerateProps,
// 	};
// };

const PageContainer = ({
	children,
	className,
	dataCy,
	// inceptionDateCorrectionData = undefined,
}) => {
	return (
		<div className={className} data-cy={dataCy}>
			{/* {inceptionDateCorrectionData && (
				<Modal isOpen={true}>
					<Panel>
						<InceptionDateCorrection
							onConfirm={inceptionDateCorrectionData.onConfirm}
							onCancel={inceptionDateCorrectionData.onCancel}
						/>
					</Panel>
				</Modal>
			)} */}

			{children}
		</div>
	);
};

const ViewGapMain = (props) => {
	const commonData = useOutletContext();

	const location = useLocation();
	const baseRoute = commonData.dictionary.baseRoute;

	const { sectionId: _sectionId = "" } = props;
	const {
		pageId: _pageId = "",
		paymentId: _paymentId = "",
		handoverId,
	} = useParams();

	const sectionId = _sectionId.toLowerCase();
	const pageId = _pageId.toLowerCase();
	const paymentId = _paymentId.toLowerCase();

	const {
		isInceptionDateValid,
		fnSetBasePropsOverride,
		fnGenerateBaseLayoutProps: _fnGenerateBaseLayoutProps,
		fnRegisterPayment,
		dataBlocker,
		fnGoto: _fnGoto,
	} = useGapPageLogic({
		sectionId,
		pageId,
		paymentId,
		routeDictionary,
		handoverId,
	});

	const chosenOptions = commonData.quote.data.chosenOptions;
	const currentQuote = commonData.quote.data.currentQuote;
	const dispatch = useDispatch();

	// commonData.hooks.useAffiliate("refid", "Risk/SourceOfEnquiry", {
	// 	verbose: true,
	// 	updateOnBlankOnly: false,
	// });

	const [showDummyLoading, setShowDummyLoading] = useState(false);
	const [isLoadingBlank, setIsLoadingBlank] = useState(false);

	const loadingScreen = {
		show: () => setIsLoadingBlank(true),
		hide: () => setIsLoadingBlank(false),
	};
	// const dateCorrection = useInceptionDateCorrection(
	// 	isInceptionDateValid,
	// 	sectionId,
	// 	pageId
	// );

	const fnGoto = (...args) => _fnGoto(args.join("/"));

	const fnGenerateBaseLayoutProps = (...args) => {
		const data = _fnGenerateBaseLayoutProps(...args);

		if (showDummyLoading) {
			return {
				...data,
				loadingIsEnabled: true,
				loadingMessage: "This is a dummy loading message",
			};
		}

		if (isLoadingBlank) {
			return {
				...data,
				headingText: undefined,
				descriptionContent: undefined,
				loadingIsEnabled: true,
				loadingMessage: undefined,
			};
		}

		return data;
	};

	//** INCEPTION DATE CORRECTION
	const dataNavigation = (
		<DevContainer>
			{[
				routeDictionary.sections.recall,
				routeDictionary.sections.risk,
				[routeDictionary.sections.risk, "your-vehicle"].join("/"),
				[routeDictionary.sections.risk, "your-quote"].join("/"),
				[routeDictionary.sections.risk, "direct-debit"].join("/"),
			].map((route) => (
				<Button
					key={route}
					className="btn-secondary me-3"
					data-cy={"debug-btn:" + route}
					onClick={() => fnGoto(route)}
				>
					{route}
				</Button>
			))}
			<hr />
			<Button
				className="btn-secondary"
				onClick={() => setShowDummyLoading(!showDummyLoading)}
			>
				Dummy Loading
			</Button>
			<Button
				className="btn-secondary"
				onClick={async () => {
					await dispatch(actions.session.reset());
					await dispatch(actions.gapQuoteAndBuy.reset());
					await dispatch(actions.storage.reset());

					localStorage.clear();
					sessionStorage.clear();
					window.location.reload();
				}}
			>
				FULL Reset
			</Button>
			<hr />
			<Button
				className="btn-secondary"
				onClick={async () => {
					await commonData.wrapup.clear();
					await dispatch(
						commonData.risk.actions.update({
							path: "Risk/Proposer/Email",
							value: "dummy@dummy.com",
						})
					);
					await commonData.functions.session.save();

					await fnGoto(
						`${routeDictionary.sections.wrapupInterstitial}/mocksuccess?a=12345`
					);
					// window.location.reload();
				}}
			>
				WRAPUP Success
			</Button>
			<Button
				className="btn-secondary"
				onClick={async () => {
					await commonData.wrapup.clear();

					await dispatch(
						commonData.risk.actions.update({
							path: "Risk/Proposer/Email",
							value: "dummy@dummy.com",
						})
					);
					await commonData.functions.session.save();

					await fnGoto(
						`${routeDictionary.sections.wrapupInterstitial}/mockfail?a=12345`
					);
					// window.location.reload();
				}}
			>
				WRAPUP Failure
			</Button>

			<hr />
			<div className="border">
				<SetTest />
			</div>
		</DevContainer>
	);

	console.groupCollapsed("ViewsGaps DATA");
	console.log({
		commonData,
		sectionId,
		pageId,
		baseRoute,
		chosenOptions,
		location,
		currentQuote,
	});

	console.groupEnd();

	console.groupCollapsed(
		"RISK ERRORS",
		"(",
		commonData.risk.status.errors.length,
		")"
	);
	commonData.risk.status.errors.forEach((x) => console.log(x.path, x.error));
	console.groupEnd();

	if (dataBlocker)
		return (
			<ErrorGeneric
				heading={dataBlocker.heading}
				icon={dataBlocker.icon}
				onLoad={dataBlocker.onLoad}
			>
				{dataBlocker.children}
			</ErrorGeneric>
		);

	switch (sectionId) {
		case "recall": {
			const layoutProps = fnGenerateBaseLayoutProps({});
			return (
				<PageContainer className="page-gap-recall" dataCy="page-recall">
					<LayoutPageGap {...layoutProps}>
						<ViewsCommonRecallForm
							onSuccess={() => {
								fnGoto(routeDictionary.sections.risk, "your-quote");
							}}
						/>
						{dataNavigation}
					</LayoutPageGap>
				</PageContainer>
			);
		}
		case "risk": {
			switch (pageId) {
				case "": {
					const layoutProps = fnGenerateBaseLayoutProps({
						isPagerEnabled: true,
						fnOnPrev: undefined,
						fnOnNext: () => {
							commonData.functions.session.saveLazy();
							fnGoto(routeDictionary.sections.risk, "your-vehicle");
						},
						labelNext: "Continue",
						labelPrevious: "Back",
					});

					return (
						<PageContainer
							className="page-gap-risk-start"
							dataCy="page-risk-start"
						>
							<ViewsGapRiskEditStart layoutProps={layoutProps} />
							{dataNavigation}
						</PageContainer>
					);
				}

				case "your-vehicle": {
					const fnContinue = async () => {
						loadingScreen.show();
						await commonData.functions.session.save();
						loadingScreen.hide();

						fnGoto(routeDictionary.sections.risk, "your-details");
					};

					const layoutProps = fnGenerateBaseLayoutProps({
						isPagerEnabled: true,
						fnOnPrev: () => fnGoto(routeDictionary.sections.risk),
						fnOnNext: async () => fnContinue(),
						labelNext: "Continue",
						labelPrevious: "Back",
					});

					return (
						<PageContainer
							className="page-gap-risk-your-vehicle"
							dataCy="page-risk-your-vehicle"
							// {...dateCorrection.generateProps(fnContinue)}
						>
							<ViewsGapRiskEditYourVehicle layoutProps={layoutProps} />
							{dataNavigation}
						</PageContainer>
					);
				}

				case "your-details": {
					const layoutProps = fnGenerateBaseLayoutProps(
						{
							isPagerEnabled: true,
							fnOnPrev: () =>
								fnGoto(routeDictionary.sections.risk, "your-vehicle"),
							fnOnNext: () => {
								commonData.functions.session.saveLazy();
								fnGoto(routeDictionary.sections.risk, "your-quote");
							},
							labelNext: "Continue",
							labelPrevious: "Back",
						},
						{
							isCheckStoreValid: true,
							// isCheckStoreValidMessage: "xxxxx store error message",
						}
					);

					return (
						<PageContainer
							className="page-gap-risk-your-details"
							dataCy="page-risk-your-details"
						>
							<ViewsGapRiskEditYourDetails layoutProps={layoutProps} />
							{dataNavigation}
						</PageContainer>
					);
				}

				case "your-quote": {
					const fnContinue = async () => {
						loadingScreen.show();
						await commonData.functions.session.save();
						const isSuccess = await fnRegisterPayment({ paymentMethod: "F" });
						if (!isSuccess) loadingScreen.hide();
					};

					const layoutProps = fnGenerateBaseLayoutProps({
						isPagerEnabled: true,
						fnOnPrev: () =>
							fnGoto(routeDictionary.sections.risk, "your-details"),
						labelPrevious: "Back",
						fnOnNext: async () => {
							if (chosenOptions.paymentMethod === "M") {
								commonData.functions.session.saveLazy();
								fnGoto(routeDictionary.sections.risk, "direct-debit");
								return;
							}

							fnContinue();
						},

						labelNext: (function () {
							if (chosenOptions.paymentMethod === "M")
								return "Continue to Direct Debit";
							return "Continue to payment";
						})(),
					});
					return (
						<PageContainer
							className="page-gap-your-quote"
							dataCy="page-risk-your-quote"
							// {...dateCorrection.generateProps(fnContinue)}
						>
							<ViewsGapRiskYourQuote layoutProps={layoutProps} />
							{dataNavigation}
						</PageContainer>
					);
				}
				case "direct-debit": {
					const fnContinue = async (args = {}) => {
						loadingScreen.show();
						const { directDebit } = args;
						await commonData.functions.session.save();
						const isSuccess = await fnRegisterPayment({
							directDebit,
							paymentMethod: "M",
						});
						if (!isSuccess) loadingScreen.hide();
					};

					const layoutProps = fnGenerateBaseLayoutProps({
						isPagerEnabled: true,
						fnOnPrev: () => fnGoto(routeDictionary.sections.risk, "your-quote"),
						labelPrevious: "Back",
						fnOnNext: async (args = {}) => {
							fnContinue(args);
						},
						labelNext: "Continue to payment",
					});
					return (
						<PageContainer
							className="page-gap-direct-debit"
							dataCy="page-direct-debit"
							// {...dateCorrection.generateProps(fnContinue)}
						>
							<ViewsGapRiskDirectDebit layoutProps={layoutProps} />
							{dataNavigation}
						</PageContainer>
					);
				}
				default:
					return <Navigate to={pageId} />;
			}
		}

		case "feedback": {
			const baseProps = { pagerData: { isPagerEnabled: false } };
			return (
				<PageContainer className="page-gap-feedback" dataCy="page-feedback">
					<ViewsGapRiskFeedback
						{...baseProps}
						feedbackType={pageId}
						fnExit={() => {
							fnGoto(routeDictionary.sections.risk);
						}}
					/>
					{dataNavigation}
				</PageContainer>
			);
		}

		case "wrapupinterstitial": {
			const layoutProps = fnGenerateBaseLayoutProps({});
			if (!paymentId) return null;
			return (
				<PageContainer className="page-gap-wrapup" dataCy="page-wrapup">
					<ViewsGapRiskWrapupInterstitial layoutProps={layoutProps} />
					{dataNavigation}
				</PageContainer>
			);
		}

		case "wrapup": {
			if (!commonData.wrapup.data) return;

			const layoutProps = fnGenerateBaseLayoutProps({});

			return (
				<PageContainer className="page-gap-wrapup" dataCy="page-wrapup">
					<ViewsGapRiskWrapup layoutProps={layoutProps} />
					{dataNavigation}
				</PageContainer>
			);
		}

		case "quoteandbuyhandover": {
			const layoutProps = fnGenerateBaseLayoutProps(
				{
					isPagerEnabled: true,
					fnOnNext: async () => {
						loadingScreen.show();
						await commonData.functions.session.save();
						loadingScreen.hide();

						fnGoto(routeDictionary.sections.risk, "your-quote");
					},
					labelNext: "Continue",
				}
				// {
				// 	isCheckStoreValid: true,
				// 	isCheckStoreValidMessage: "xxxxx store error message",
				// }
			);

			if (!handoverId) return null;

			return (
				<PageContainer
					className="page-gap-handover"
					dataCy="page-quote-and-buy-handover"
				>
					<ViewsGapHandoverQuoteAndBuy layoutProps={layoutProps} />
				</PageContainer>
			);
		}
	}

	console.log("ERROR INFO:", { dictionary: routeDictionary });
	throw `Unknown route  in ViewGapMain.jsx: ${sectionId || "<missing>"}/${
		pageId || "<missing>"
	}`;
};

export default ViewGapMain;
