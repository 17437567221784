import _ from "lodash";
import hash from "object-hash";

// import {
//   fnGetRiskItem as _fnGetRiskItem,
//   fnGetRiskData as _fnGetRiskData,
// } from "./utils";

import riskUtils from "./utils/risk";
import riskUtilsInitial from "./utils/risk/initial";

import templateUtils from "./utils/template";

// const fnIsError = (obj) => {
//   if (!obj) return false;
//   if (_.isArray(obj)) return obj.some((d) => fnIsError(d));
//   if (_.isObject(obj))
//     return Object.entries(obj).some(([key, data]) => {
//       if (key === "_error") {
//         if (!_.isEmpty(data)) return true;
//         return false;
//       }
//       if (_.isObject(data)) return fnIsError(data);
//     });
//   return false;
// };

const fnCheckObjForError = (obj, level = 1) => {
	//
};

const generate = (args = {}) => {
	const { storeName, template } = args;

	const fnGetSubState = (state) => state[storeName];
	const fnGetRiskItem = (state, path) =>
		riskUtils.find.item(fnGetSubState(state), path);
	const fnGetRiskData = (state, path) =>
		riskUtils.find.data(fnGetSubState(state), path);

	const fnGetRiskGroupData = (state, group) => {
		const subState = fnGetSubState(state);
		return subState.userData.risk.groups[group];
	};

	// const fnGetObjects = (obj, path) => {
	//   if (path.endsWith("/*"))
	//     return _.get(obj, path.substring(0, path.length - 2));
	//   return _.get(obj, path);
	// };

	const fnIsValid = (state, pathArray = [], options = {}) => {
		const isValid = !riskUtils.is.error(fnGetSubState(state), pathArray);
		return isValid;
	};

	const fnGenerateSelector_SalusData =
		({ riskUtils }) =>
		// Pass in the config data we need
		(state, options = {}) => {
			// The final function
			// const baseData = fnGetRiskData(state);
			const { keyArrayId = undefined, stripEmptyValues = true } = options;
			const retData = riskUtils.process.buildTree(fnGetSubState(state), "", {
				keyArrayId: keyArrayId,
				fnProcessItem: (curNode, args = {}) => {
					const { searchPath } = args;

					const curItemTemplateData = templateUtils.getData(
						template,
						searchPath
					);

					if (!curItemTemplateData?.found) {
						return {
							data: curNode?._value,
							output: false,
						};
					}

					// Empty value found:
					if (stripEmptyValues && curNode?._value === undefined) {
						return {
							data: curNode?._value,
							output: false,
						};
					}

					const fnTo = (function () {
						if (!curItemTemplateData?.data?.salus?.fnTo) return (v) => v;
						return curItemTemplateData.data.salus.fnTo;
					})();

					const retData = {
						data: fnTo(curNode?._value),
						output: curItemTemplateData?.data.isSubmit, //x?._submitKey ? true : false,
					};

					return retData;
				},
			});

			// console.log("SELECTORS salusData :", retData);
			return retData;
		};

	const fnRiskItem = (state, path) => {
		if (!path) throw `Error in selector.userData.risk -- missing "path"`;
		const foundObject = fnGetRiskItem(state, path);

		if (!foundObject) {
			return undefined;
		}
		return foundObject;
	};

	const fnUserDataQuotePayloadGetQuote = (state) => {
		const subState = fnGetSubState(state);
		const fnGetRiskData = fnGenerateSelector_SalusData({ riskUtils });

		const retData = {
			quoteParams: subState.userData.quote.params,
			// quoteSource: subState.userData.quote.quoteSource,
			risk: fnGetRiskData(state),
		};
		return retData;
	};

	return {
		status: {
			get: (state, key) => {
				return fnGetSubState(state).statusData[key] || {};
			},
		},
		userData: {
			quote: {
				payloads: {
					getQuote: fnUserDataQuotePayloadGetQuote,
					hasRiskChanged: (state) => {
						const subState = fnGetSubState(state);

						const fnAreObjectsEqual = (obj1, obj2) => {
							return hash(obj1) === hash(obj2);
						};

						const lastPayload = subState.userData.quote.lastPayload || {};
						const salusPayload = fnUserDataQuotePayloadGetQuote(state) || {};
						const retValue = !fnAreObjectsEqual(lastPayload, salusPayload);
						return retValue;
						///
					},
				},
				chosenOptions: {
					paymentMethod: (state) =>
						fnGetSubState(state).userData.quote.chosenOptions.paymentMethod,
					autoRenewal: (state) =>
						fnGetSubState(state).userData.quote.chosenOptions.autoRenewal,
					tierId: (state) =>
						fnGetSubState(state).userData.quote.chosenOptions.tierId,
				},

				paymentMethods: (state) => {
					const chosenPaymentMethod =
						fnGetSubState(state).userData.quote.chosenOptions.paymentMethod;

					const retData = [
						{ value: "F", label: "Full" },
						{ value: "M", label: "Monthly" },
					].map((x) => ({ ...x, isChosen: x.value === chosenPaymentMethod }));

					return retData;
				},

				quoteResults: (state) => {
					const subState = fnGetSubState(state);
					const results = subState?.userData?.quote?.data?.QuoteResults;

					if (!results) return [];

					const retData = results.map((x) => ({
						...x,
						isChosen:
							x?.TierInfo?.TierId ===
							subState.userData.quote.chosenOptions.tierId,
					}));

					return retData || [];
				},
				currentStatus: (state) => {
					const subState = fnGetSubState(state);
					return subState?.userData?.quote?.data?.Status;
				},
				currentQuote: (state) => {
					const subState = fnGetSubState(state);

					const chosenTierId = subState.userData.quote.chosenOptions.tierId;

					if (!chosenTierId) return undefined;

					const quoteResults = subState?.userData?.quote?.data?.QuoteResults;

					if (!quoteResults) return undefined;

					const retData = quoteResults.find(
						(x) => x?.TierInfo?.TierId === chosenTierId
					);

					if (!retData) return undefined;

					return retData;
				},
			},
			risk: {
				salusData: fnGenerateSelector_SalusData({ riskUtils }),
				full: (state) => fnGetRiskData(state),
				item: (state, path) => fnRiskItem(state, path),
				isRegistered: (state, path) => (fnRiskItem(state, path) ? true : false),
				value: (state, path) => {
					const foundObject = fnRiskItem(state, path);
					return foundObject?._value;
				},

				metaData: (state, path) => {
					if (!path)
						throw `Error in selector.userData.metaData -- missing "path"`;

					const foundObject = fnGetRiskItem(state, path);

					if (!foundObject) {
						return undefined;
					}
					return {
						value: foundObject["_value"],
						hidden: foundObject["_hidden"],
						error: foundObject["_error"],
						errorShow: foundObject["_errorShow"],
						submitKey: foundObject["_submitKey"],
					};
				},

				metaDataTree: (
					state,
					path,
					processNode = (metaData) => ({ data: metaData, output: true })
				) => {
					return riskUtils.process.buildTree(fnGetSubState(state), path, {
						fnProcessItem: processNode,
						fnProcessArrayItem: (id, data) => {
							return { ["_id"]: id, ...data };
						},
					});
				},
				valueTree: (
					state,
					path,
					processNode = (metaData) => ({ data: metaData?._value, output: true })
				) => {
					return riskUtils.process.buildTree(fnGetSubState(state), path, {
						fnProcessItem: processNode,
						fnProcessArrayItem: (id, data) => {
							return { ["_id"]: id, ...data };
						},
					});
				},
				listTree: (state, path) => {
					// Returns and ARRAY of all the child items

					const retData = [];
					const subState = fnGetSubState(state);

					riskUtils.process.all(subState, path, (obj, args = {}) => {
						retData.push(args.searchPath.join("/"));
					});

					return retData;
				},
				listTreeIds: (state, path) => {
					const subState = fnGetSubState(state);
					const item = riskUtils.find.item(subState, path);
					return item?._arrayData.map((x) => x.id) || [];
				},
				getGroupPaths: (state, group) => fnGetRiskGroupData(state, group),
			},
			riskInitial: {
				salusData: fnGenerateSelector_SalusData({
					riskUtils: riskUtilsInitial,
				}),
				metaDataTree: (
					state,
					path,
					processNode = (metaData) => ({ data: metaData, output: true })
				) => {
					return riskUtilsInitial.process.buildTree(
						fnGetSubState(state),
						path,
						{
							fnProcessItem: processNode,
							fnProcessArrayItem: (id, data) => {
								return { ["_id"]: id, ...data };
							},
						}
					);
				},
			},
		},
		errors: {
			getIsStoreValid: (state) => fnIsValid(state, [""]),
			isValid: fnIsValid,
			isGroupValid(state, group) {
				const pathList = fnGetRiskGroupData(state, group);

				const isValid = (function () {
					if (!pathList) return false;
					if (pathList.length === 0) return false;
					return fnIsValid(state, pathList);
				})();

				console.log(`ISGROUPVALID(${group})`, "=", isValid);
				return isValid;
			},
			// isValidGroup(state, group) {
			//   return true;
			// },
			list: (state, searchPath) => {
				const retData = [];
				// console.log("list", searchPath)
				const subState = fnGetSubState(state);

				riskUtils.process.all(
					subState,
					// state,
					searchPath,
					(obj, args = {}) => {
						if (riskUtils.is.error(subState, [args.searchPath.join("/")])) {
							retData.push({
								path: args.searchPath.join("/"),
								error: obj._error,
							});
						}
					}
				);
				return retData;
			},
		},
		// debug: {
		// userData: {
		//   risk: (state) => {
		//     return fnGetRiskData(state);
		//   },
		// },
		// logger: (state) => {
		//   const subState = fnGetSubState(state);
		//   return subState.logger;
		// },
		// },
	};
};

export default generate;
