import React, { useEffect } from "react";
import { Pulse as AnimatedPulse } from "libraryComponents/Animated";
import config from "config";
import { generateCypressProps } from "libraryFunctions/cypress";
import CookieConsentLibrary from "@library/common/components/base/misc/CookieConsent";
import { ButtonLink, ExternalLink } from "siteComponentsLibrary/Buttons";
import { CookieBannerFullWidth } from "siteComponentsLibrary/Layout";
import { Alert } from "libraryComponents/Feedback";
import { useNavigate } from "react-router-dom";
import { actions, selectors } from "siteStore";
import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";
import services from "siteService";
import { Markup } from "interweave";
import { TelephoneNumber } from "libraryComponents/Display";

export const CookieConsent = () => {
	return (
		<CookieConsentLibrary
			expireTime={30}
			cookieId="CookieConsent"
			text={
				<span>
					Our website uses cookies to deliver you the best possible experience.
					By continuing to use this website you are agreeing to the use of
					cookies. For further details, please{" "}
					<ExternalLink
						href={require("config").default.lookups.links.cookiePolicy}
						label={"review our cookie policy"}
						data-cy={"link:cookie-policy"}
					/>
					.
				</span>
			}
			buttonText={<span>Okay</span>}
			buttonClassName="btn btn-primary"
			enabled={true}
			Container={CookieBannerFullWidth}
		/>
	);
};

export const SiteAlerts = (props) => {
	const {
		SiteType,
		className,
		Wrapper = ({ children }) => <>{children}</>,
		storageKey = "siteAlerts",
	} = props;

	["SiteType"].argChecker(props);

	const bannerDataRaw = useSelector((state) =>
		selectors.storage.getValue(state, storageKey)
	);
	const bannerData = bannerDataRaw ? JSON.parse(bannerDataRaw) : undefined;

	const dispatch = useDispatch();

	useEffect(() => {
		const myFn = async () => {
			const response = await services.getSiteAlerts({ SiteType });

			const isEmpty = (function () {
				if (!response) return true;
				if (!_.isArray(response)) return true;
				if (response.length === 0) return true;
				return false;
			})();

			await dispatch(
				actions.storage.update({
					key: storageKey,
					value: isEmpty ? undefined : JSON.stringify(response),
					persist: false,
				})
			);
		};
		try {
			myFn();
		} catch (e) {
			//caught
		}
	}, []);

	const _className = ["site-alerts", className].toClassName();

	if (!bannerData) return null;

	return (
		<Wrapper>
			<div
				className={_className}
				{...generateCypressProps("site-alert", props)}
			>
				{bannerData.map((x, i) => {
					const _subClassName = [
						"alert",
						x.MessageType ? `alert-${x.MessageType}` : "alert-primary",
					].toClassName();

					return (
						<div
							className={_subClassName}
							key={i}
							{...generateCypressProps("site-alert-child", props)}
						>
							<Markup content={x.MessageContent} />
						</div>
					);
				})}
			</div>
		</Wrapper>
	);
};

export const TelephoneNumberSales = (props) => {
	const { className } = props;
	return (
		<TelephoneNumber
			phonenumber={config.lookups.telephoneNumbers.sales}
			isLink={true}
			className={className}
		/>
	);
};

export const TelephoneNumberService = (props) => {
	const { className } = props;
	return (
		<TelephoneNumber
			phonenumber={config.lookups.telephoneNumbers.service}
			isLink={true}
			className={className}
		/>
	);
};

export const TelephoneNumberClaims = (props) => {
	const { className } = props;
	return (
		<TelephoneNumber
			phonenumber={config.lookups.telephoneNumbers.claims}
			isLink={true}
			className={className}
		/>
	);
};

export const Loading = (props) => {
	return (
		<AnimatedPulse {...props} {...generateCypressProps("loading", props)} />
	);
};

export const RecallBanner = (props) => {
	const { targetRoute = "/recall" } = props;

	const navigate = useNavigate();

	return (
		<Alert className="text-center">
			If you already have a quote, you can{" "}
			<ButtonLink
				className="p-0 m-0 border-0"
				onClick={() => {
					navigate(targetRoute);
				}}
			>
				recall your details here
			</ButtonLink>
		</Alert>
	);
};
