import React, { useState, useEffect } from "react";
import _ from "lodash";
import LayoutPageGap from "siteLayouts/Page/Gap";
import useLayoutPropsModifer from "../useLayoutPropsModifier";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";

import { Panel, FormGroup, PanelSection } from "siteComponentsLibrary/Layout";
import { RadioButtonsYesNo, RadioButtons } from "siteComponentsLibrary/Inputs";
import { Help } from "siteComponentsLibrary/Display";
import { TelephoneNumberSales } from "siteComponents/Misc";

import { Error } from "siteComponentsLibrary/Feedback";
import { Loading } from "siteComponents/Misc";

const itemDataMileage = [
	{
		label: "up to 100,000",
		value: 100000,
	},
	{
		label: "100,001 to 120,000",
		value: 120000,
	},
	{
		label: "More than 120,000",
		value: 120001,
	},
];

const HandoverQuoteAndBuy = (props) => {
	const { layoutProps = {} } = props;
	const commonData = useOutletContext();
	const dispatch = commonData.dispatch;

	const isRiskValid = commonData.risk.status.isValid;

	const [isAgree, setIsAgree] = useState(undefined);
	const [showErrors, setShowErrors] = useState(false);
	const [newMileage, setNewMileage] = useState(itemDataMileage[0]?.value);

	const [isContinueRequest, setIsContinueRequest] = useState(false);

	// useEffect(() => {
	// 	console.log("HandoverQuoteAndBuy LOADED");
	// 	return () => console.log("HandoverQuoteAndBuy UNLOADED");
	// }, []);

	{
		// Handle the CONTINUE
		const isContinuing = isRiskValid && isContinueRequest;
		const riskMileage = useSelector((state) =>
			commonData.risk.selectors.value(state, "Risk/Vehicle/CurrentMileage")
		);
		useEffect(() => {
			const myFn = async () => {
				if (!isContinuing) return;
				const fnOnNextOrig = layoutProps.pagerData.fnOnNext;

				// If the USER has updated their mileage...
				if (newMileage !== riskMileage) {
					await dispatch(
						commonData.risk.actions.update({
							path: "Risk/Vehicle/CurrentMileage",
							value: newMileage,
						})
					);
				}
				await fnOnNextOrig();
			};
			myFn();
		}, [isContinuing]);
	}

	const newLayoutProps = (function () {
		const baseProps = _.cloneDeep(layoutProps);

		baseProps.headingText = "Thank you for your interest in GAP Insurance";

		const fnShowErrors = async () => setShowErrors(true);
		const fnRequestContinue = async () => setIsContinueRequest(true);
		const fnNull = async () => {};

		if (isContinueRequest) {
			baseProps.pagerData.fnOnNext = fnNull;
			baseProps.loadingIsEnabled = true;
			baseProps.headingText = undefined;
			return baseProps;
		}

		if (isAgree === false) {
			baseProps.pagerData.fnOnNext = fnShowErrors;
			return baseProps;
		}

		if (isAgree === undefined) {
			if (showErrors)
				baseProps.errorMessage = "Please correct the errors above";
			baseProps.pagerData.fnOnNext = fnShowErrors;
			return baseProps;
		}

		if (isAgree !== true)
			throw `Error in HandoverQuoteAndBuy -- unknown value for "isAgree"`;

		// if (!isRiskValid) {
		// 	if (isContinueRequest) {
		// 		baseProps.errorMessage = (
		// 			<div className="row">
		// 				<div className="col-auto">
		// 					<Loading />
		// 				</div>
		// 				<div className="col">
		// 					Please wait while we prepare your quote...
		// 				</div>
		// 			</div>
		// 		);
		// 	}
		// }

		baseProps.pagerData.fnOnNext = () => fnRequestContinue();

		return baseProps;
	})();

	const hasError = (function () {
		if (!newLayoutProps.errorMessage) return false;
		if (isAgree === undefined) return true;
		return false;
	})();

	return (
		<LayoutPageGap {...newLayoutProps}>
			<Panel 
				header="Before you continue"
				data-cy="panel:deeplink-interstitial"
			>
				<PanelSection 
					heading="Please check the below carefully"
					data-cy="section:check-details"
					relatesToFormGroup={true}
				>
					<div>
						<strong>Your vehicle;</strong>
					</div>
					<ul>
						<li>
							is not used for hire or reward, including as a taxi, courier or
							for driving tuition.
						</li>
						<li>has never been declared an insurance write off.</li>
					</ul>
				</PanelSection>

				<FormGroup
					label={`Do you agree with the above statements?`}
					hasError={hasError}
					data-cy="form-group:confirm"
				>
					<RadioButtonsYesNo
						value={isAgree}
						onChange={(v) => {
							setIsAgree(v);
						}}
						data-cy="radio:confirm"
					/>
					{isAgree === false && (
						<div className="text-danger mt-3" data-cy="feedback:no-continue">
							Sorry, we are unable to offer you cover if you do not agree to
							the above statements. If you would like to speak to someone
							please call <TelephoneNumberSales />.
						</div>
					)}
				</FormGroup>

				<FormGroup
					label="Check your current mileage"
					helpText="Your quote is based on your mileage being no more than 100,000. If your mileage is higher than this, please choose an option below and we'll update your quote."
					data-cy="form-group:current-mileage"
				>
					<RadioButtons
						itemData={itemDataMileage}
						className="stackable"
						data-cy="radio:current-mileage"
						value={newMileage}
						onChange={(v) => setNewMileage(v)}
					/>
				</FormGroup>
				
			</Panel>
		</LayoutPageGap>
	);
	return <div>xxxx HandoverQuoteAndBuy</div>;
};

export default HandoverQuoteAndBuy;
