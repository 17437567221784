import React, { useState, useEffect, useMemo } from "react";

export const AffiliateWelcome = (props) => {
	const { affilate, sessionStorageKey, database } = props;

	["affilate", "database", "sessionStorageKey"].forEach((k) => {
		if (!(k in props)) {
			throw `Error in AffiliateWelcome -- missing "${k}" in props`;
		}
	});

	const [isModalOpen, setIsModalOpen] = useState(false);

	const configData = useMemo(
		function () {
			if (!affilate) return undefined;
			const searchKey = affilate.toLowerCase();

			if (!database[searchKey]) return undefined;

			return database[searchKey];
		},
		[affilate]
	);

	// EFFECT for enabling the MODAL
	useEffect(() => {
		if (!affilate) return;
		if (!configData) return;

		const sessionStoreageValue = JSON.parse(
			sessionStorage.getItem(sessionStorageKey) || "{}"
		);

		if (
			sessionStoreageValue?.affiliate === affilate &&
			sessionStoreageValue?.disableModal
		)
			return;

		setIsModalOpen(true);

		// Log to session storage that we DON'T want to show the modal again
		sessionStorage.setItem(
			sessionStorageKey,
			JSON.stringify({
				...sessionStoreageValue,
				affiliate: affilate,
				disableModal: true,
			})
		);
	}, [affilate]);

	if (isModalOpen && configData?.Modal) {
		const Modal = configData?.Modal;
		return (
			<Modal onClick={() => setIsModalOpen(false)}>
				{configData.modalData}
			</Modal>
		);
	}

	return null;
};
