import React from "react";

import { generateCypressProps } from "libraryFunctions/cypress";
import { TelephoneNumber } from "siteComponentsLibrary/Display";

export const ContactDetails = (props) => {

	// #3489
	return null;

	return (
		<div
			className="contact-details"
			{...generateCypressProps("contact-details", props)}
		>
			<div className="text-nowrap text-end text-green fw-bold">
				<i className="bi bi-telephone" />
				<TelephoneNumber
					phonenumber={require("config").default.lookups.telephoneNumbers.sales}
					className=""
					isLink={true}
					isStrong={true}
				/>
			</div>
			<div className="text-nowrap text-end fs-80" data-cy="hours-mon-fri">Mon - Fri: 9:00 - 17:30</div>
			{/*<div className="text-nowrap text-end fs-80" data-cy="hours-sat">Sat: 9:00 - 13:00</div>*/}
		</div>
	);
};

export default ContactDetails;
