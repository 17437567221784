import _ from "lodash";
import React, {
	useCallback,
	useMemo,
	useState,
	useEffect,
	useRef,
} from "react";

import {
	useDispatch as _useDispatch,
	useSelector as _useSelector,
} from "react-redux";

import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import hash from "object-hash";

import { createSelector } from "@reduxjs/toolkit";

import {
	database as migrateDatabase,
	generateTemplateNode,
} from "./utils/migration";
import templateUtils from "./utils/template";
import riskUtils from "./utils/risk";
import riskSalusUtils from "./utils/riskSalus";
import ReactDomServer from "react-dom/server";

//https://stackoverflow.com/questions/36517173/how-to-store-a-javascript-function-in-json
const JSONFunctions = {
	to: (obj) => {
		return JSON.stringify(obj, function (key, value) {
			if (typeof value === "function") {
				return "/Function(" + value.toString() + ")/";
			}
			return value;
		});
	},
	from: (json) => {
		return JSON.parse(json, function (key, value) {
			if (
				typeof value === "string" &&
				value.startsWith("/Function(") &&
				value.endsWith(")/")
			) {
				value = value.substring(10, value.length - 2);
				return (0, eval)("(" + value + ")");
			}
			return value;
		});
	},
	toPrint: (obj) => {
		const fnRecursive = (curData, level = 1) => {
			const strPadding = " ".repeat(level * 5);
			const strNewLine = "\r\n";

			// if (_.isString(curData)) return [`"`, curData, `"`];

			switch (typeof curData) {
				case "function":
					return [];
					return [curData.toString()];
			}

			if (_.isObject(curData)) {
				if (_.isArray(curData))
					return [
						strNewLine,
						strPadding,
						"[",
						...Object.entries(curData).flatMap(([key, data], i) => {
							return [
								i !== 0 && strNewLine,
								i !== 0 && strPadding,
								i !== 0 && ",",
								...fnRecursive(data, level + 1),
							].filter(Boolean);
						}),
						"]",
					];

				return [
					strNewLine,
					strPadding,
					"{",
					...Object.entries(curData).flatMap(([key, data], i) => {
						const subData = fnRecursive(data, level + 1);
						if (subData.length === 0) return [];

						return [
							strNewLine,
							strPadding,
							i !== 0 && ",",
							key,
							":",
							...subData,
						].filter(Boolean);
					}),

					"}",
				];
			}

			return [JSON.stringify(curData)];
			return "xxx";
		};

		return fnRecursive(obj);
	},
};

const generate = (args = {}) => {
	const { actions, selectors, template, rules } = args;

	return {
		searchPath: riskUtils.searchPath,
		migration: {
			database: migrateDatabase,
			outputInfo: () => {
				console.log("");
				console.log("*************************************");

				Object.entries(args).forEach(([key, data]) =>
					console.log(key, { data })
				);

				console.log("");

				if (template)
					console.log("template JSON:", {
						data: JSON.parse(JSONFunctions.to(template)),
					});

				if (rules) {
					console.log("rules JSON:", {
						data: JSON.parse(JSONFunctions.to(rules)),
					});

					console.groupCollapsed("rule PRINT DATA");

					const printData = JSONFunctions.toPrint(rules);
					// printData.forEach((x) => console.log(x));
					console.log(printData.join(""));
					console.groupEnd();
				}

				console.log("*************************************");
				console.log("");
			},
			salusToTemplate: async (salusData) => {
				// console.log("salusToTemplate", generateTemplateNode({}));
				if (!salusData) return;

				const fnRecursive = (curData, level) => {
					if (_.isArray(curData)) {
						if (curData.length === 0) return undefined;
						return fnRecursive(curData[0], level + 1);
						// return curData.map((x) => fnRecursive(x, level + 1));
					}

					if (_.isObject(curData)) {
						return Object.fromEntries(
							Object.entries(curData).map(([key, data]) => {
								if (_.isArray(data)) {
									if (data.length === 0) return [key, undefined];
									return [`${key}[]`, fnRecursive(data[0], level + 1)];
								}

								return [key, fnRecursive(data, level + 1)];
							})
						);
					}

					const hasValue = curData !== "" && curData !== undefined;
					const templateNode = generateTemplateNode({
						exampleValue: curData,
						isSubmit: true,
						ruleList: hasValue && ["isRequired"],
					});

					if (_.isEmpty(templateNode.salus)) {
						delete templateNode.salus;
					}

					return templateNode;
				};
				const retData = fnRecursive(salusData);
				// Strip out functions etc.
				return JSON.parse(JSON.stringify(retData));
			},
			templateToSalus: async (dispatch) => {
				if (!dispatch) throw `Error in templateToSalus() -- missing "dispatch"`;
				// Converts the template into a proper SALUS data
				// And loads it into the RISK STORE
				// (We need this as it also runs the RULES)

				const salusDataComposed = templateUtils.generateSalusData(template, {});
				await dispatch(actions.salusLoad({ salusData: salusDataComposed }));
			},
			convertOldToNew: () => {
				throw `Not yet implemented`;

				console.log("");
				console.log("convertOldToNew", { template, rules });
				const ruleData = rules;
				const templateData = template;

				const copyData = (function () {
					const fnParseRuleData = (node) => {
						if (_.isObject(node)) {
							if ("fn" in node) {
								return [
									JSON.stringify({
										fn: node.fnText,
										..._.omit(node, ["fn", "fnText"]),
									}),
								];
							}

							return [
								"{",
								...Object.entries(node).flatMap(([k, d]) => {
									return [k, ":", ...fnParseRuleData(d)];
								}),
								"}",
							];
						}
						return [node];
					};

					const fnOutputData = (node, path = []) => {
						const thisKey = path[path.length - 1];
						const nodeType = typeof node;

						if (false) {
							console.log(
								" ".repeat(path.length * 3),
								`(${thisKey})`,
								nodeType,
								{
									node: node,
									// definition: node && node.toString(),
									// isArray: _.isArray(node),
									// isObject: _.isObject(node),
								}
							);
						}

						switch (nodeType) {
							case "function":
								if (node) return [node.toString()];
							//return ["{",`["${k}"]` , node.toString(),"}"];
						}

						if (node === undefined) return ["undefined"];
						if (node === null) return ["null"];
						if (_.isString(node)) return [`\`${node}\``];

						if (_.isArray(node)) {
							const arrayData = node.flatMap((d, i) => {
								const data = fnOutputData(d, [...path, `k[${i}]`]);

								if (i === 0) return data;
								return [",", ...data];
							});

							// console.log("dddddd", arrayData);

							return ["[", ...arrayData, "]"];
						}

						if (_.isObject(node)) {
							if (node["$$typeof"] === Symbol.for("react.element")) {
								//https://stackoverflow.com/questions/34114679/convert-a-react-element-to-a-jsx-string
								// console.log("ddddddddddd", node, {
								//   renderToString: ReactDomServer.renderToString(node),
								//   renderToStaticMarkup: ReactDomServer.renderToStaticMarkup(node),
								// });

								return ["<>", ReactDomServer.renderToStaticMarkup(node), "</>"];
							}

							return [
								"{",
								...Object.entries(node).flatMap(([k, d]) => {
									return [
										`["${k}"]`,
										":",
										...fnOutputData(d, [...path, k]),
										",",
									];
								}),
								"}",
							];
						}
						return [node];
					};

					// const fnParseFunctionSet = (node) => {
					//   return Object.fromEntries(
					//     Object.entries(node).map(([k, d]) => {
					//       return [k, d];
					//     })
					//   );
					// };

					return [
						`import React from "react"`,
						`import { rules as ruleLibrarySite } from "moveToLibrary/database/stella/carRisk";`,
						"const ruleList =",
						...fnOutputData(ruleData),
						";",
						"const templateData = ",
						...fnOutputData(templateData),
						"export default {ruleList,templateData};",
					].join("\r\n");

					// return {
					//   ruleData: fnParseRuleData(ruleData),
					//   functionSet: fnParseFunctionSet(functionSet),
					// };
				})();

				console.groupCollapsed("MIGRATION COPY");
				console.log(copyData);
				// copyData.forEach((x) => console.log(x));
				console.groupEnd();
			},
		},
		debug: () => {
			console.groupCollapsed("DEBUG QUOTEANDBUY");
			console.log(args);
			console.groupEnd();
		},
		composeComponent: (Component, options = {}) => {
			if (!Component) {
				console.log("ERROR INFO:", { options });
				throw `Error in composeComponent -- missing Component`;
			}
			const {
				useDispatch = _useDispatch,
				useSelector = _useSelector,
				templatePropsPath = undefined,
				fnModifyValueInput = (v) => v,
				fnModifyValueOutput = (v) => v,
				propConfig = {},
			} = options;

			// ***************************************
			// ** SELECTOR (MEMOISED)
			// ***************************************
			const metaDataGenerate = (state, { path, pathList, debugLabel }) => {
				// if (debugLabel) console.log("DDDDDDDDDDDDDDD MEMO");
				if (path) {
					const retData = selectors.userData.risk.metaData(state, path);
					return retData;
				}

				if (pathList.length >= 1) {
					const metaDataArray = pathList.map((p) => {
						return {
							path: p,
							data: selectors.userData.risk.metaData(state, p),
						};
					});
					//NOTE: x and x.data could be empty
					return {
						hidden: metaDataArray.some((x) => x && x.data && x.data.hidden),
						errorShow: metaDataArray.some(
							(x) => x && x.data && x.data.errorShow
						),
						error: Object.fromEntries(
							metaDataArray
								.filter((x) => x && x.data && x.data.errorShow)
								.filter((x) => _.isObject(x.data.error))
								.flatMap((x) =>
									Object.entries(x.data.error).map(([k, d]) => [
										`${x.path} -- ${k}`,
										d,
									])
								)
						),
						value: undefined,
					};
				}
			};

			// ***************************************
			// ** RETURN COMPONENT
			// ***************************************

			const RetComponent = (props) => {
				const {
					onChange,
					value,
					hidden,
					error,
					showDebug = false,

					[templatePropsPath]: dummyRemoval,
					children,
					debugLabel,
					id,
					onBlur = async () => {},
					...otherProps
				} = props;
				const dispatch = useDispatch();

				const templateProps = templatePropsPath
					? _.get(props, templatePropsPath)
					: props;

				if (!templateProps) {
					console.log("ERROR INFO:", { props, templatePropsPath });
					throw `Error in composeComponent -- missing props.templateProps`;
				}
				const {
					path,
					pathList = [],
					group,
					label: labelOverride,
					helpText: helpTextOverride,
					helpTextFurther: helpTextFurtherOverride,
					autoRegister = false,
					...otherTemplateProps
				} = templateProps;

				const [_newId] = useState(
					(function () {
						if (id) return id;
						if (!path) return undefined;
						const itemName = path
							.split("/")
							.find((x, i, arr) => i === arr.length - 1);
						return ["component", itemName, _.uniqueId()].join("_");
					})()
				);
				// NOTE: We can allow for path and pathList to be blank

				const templateData = (function () {
					if (path) {
						if (!_.isString(path)) {
							console.log("ERROR INFO:", { path });
							throw `Error in composeComponent -- path is not a string`;
						}
						return templateUtils.getData(template, path.split("/"))?.data;
					}

					if (pathList.length >= 1) {
						return {
							label: labelOverride,
							helpText: helpTextOverride,
							helpTextFurther: helpTextFurtherOverride,
						};
					}

					return {
						label: labelOverride,
						helpText: helpTextOverride,
						helpTextFurther: helpTextFurtherOverride,
					};
				})();

				// #3046 https://nuvolar.eu/connecting-functional-components-to-redux-store-with-useselector-hook-react-memo/
				const metaData = useSelector(
					(state) => metaDataGenerate(state, { path, pathList, debugLabel }),
					_.isEqual
				);

				const debugData = (function () {
					if (!showDebug) return undefined;
					return metaData;
				})();

				// REGISTER ITEM
				{
					const registerHitlist = useSelector((state) => {
						if (!autoRegister) return [];
						return [path, ...pathList]
							.filter(Boolean)
							.filter(
								(path) => !selectors.userData.risk.isRegistered(state, path)
							);
					}, _.isEqual);

					React.useEffect(() => {
						const myFn = async () => {
							// return;
							if (!autoRegister) return;
							console.log("registerHitlist", { autoRegister, registerHitlist });

							if (registerHitlist.length === 0) return;

							await dispatch(
								actions.create({
									pathList: registerHitlist.map((path) => ({ path })),
									group: group,
								})
							);
						};
						myFn();
					}, []);
				}

				// REGISTER GROUPS
				React.useEffect(() => {
					if (!group) return;

					if (path)
						dispatch(actions.groupRegister({ path: path, group: group }));

					pathList.forEach((path) =>
						actions.groupRegister({ path: path, group: group })
					);
				}, []);

				if (debugLabel) {
					console.log("COMPOSE:", debugLabel, {
						path,
						pathList,
						group,
						props,
						otherProps,
						otherTemplateProps,
						metaData,
						templateData,
						composeOptions: options,
					});
				}

				const additionalProps = (function () {
					// const {} = propConfig;

					return Object.fromEntries(
						[
							{
								key: "error",
								value: metaData && metaData.error,
							},
							{
								key: "errorShow",
								value: metaData && metaData.errorShow,
							},
							{
								key: "hidden",
								value: metaData && metaData.hidden,
							},

							{
								key: "label",
								value: labelOverride || templateData?.label,
							},
							{
								key: "helpText",
								value: helpTextOverride || templateData?.helpText,
							},
							{
								key: "helpTextFurther",
								value: helpTextFurtherOverride || templateData?.helpTextFurther,
							},
							{
								key: "id",
								value: _newId,
							},
							{
								key: "debugData",
								value: debugData,
							},
						]
							.filter((x) => propConfig[x.key]) // Filter for only those items enabled
							.map(({ key, value }) => {
								if (value === undefined) return undefined;
								return [key, value];
							})
							.filter(Boolean)
					);
				})();

				return (
					<Component
						{...templateData?.html}
						{...otherProps}
						{...otherTemplateProps}
						onChange={async (v) => {
							if (!path) return;

							await dispatch(
								actions.updateValue({
									path: path,
									value: fnModifyValueOutput(v),
								})
							);
							await dispatch(
								actions.updateErrorShow({ path: path, value: true })
							);
						}}
						onBlur={async (...args) => {
							if (!path) return;

							await dispatch(
								actions.updateErrorShow({ path: path, value: true })
							);
							await onBlur(...args);
						}}
						{...additionalProps}
						value={metaData && fnModifyValueInput(metaData.value)}
					>
						{children}
					</Component>
				);
			};
			RetComponent.displayName = Component.displayName;

			return RetComponent;
		},
		compareSalus: async ({
			store,
			riskStore,
			quoteAndBuyStore,
			salusData,
			fnCompare = () => {
				throw `Not implemented compareSalus().fnCompare`;
			},
		}) => {
			// Compares old salus again new
			const fnRiskGetSalus = async (state, outputComponentSetKey = false) => {
				const riskStoreState = state[riskStore.storeName];

				const rawData = _.pick(riskStoreState, [
					"componentSet",
					"componentSet_reverseMapping",
					"componentSetOwnership",
					"componentSetOwnership_reverseMapping",
					"componentTag",
					"componentTag_reverseMapping",
					"value",
					"registered",
				]);

				if (!rawData) return;
				if (_.isEmpty(rawData)) return;
				if (_.isEmpty(rawData.value)) return;

				const salusData = riskStore.functions.salus.reduxToSalus(rawData, {
					outputComponentSetKey: outputComponentSetKey
						? "componentSet"
						: undefined,
				});
				if (!salusData) return undefined;
				return salusData.data;
			};

			const fnQuoteAndBuyGetSalus = async (state) => {
				const retData =
					await quoteAndBuyStore.selectors.userData.risk.salusData(state);

				return retData;
			};

			// function getDifference(a, b) {
			//   // return undefined;
			//   const isObject = (v) => v && typeof v === "object";

			//   return Object.assign(
			//     ...Array.from(
			//       new Set([...Object.keys(a), ...Object.keys(b)]),
			//       (k) => ({
			//         [k]:
			//           isObject(a[k]) && isObject(b[k])
			//             ? getDifference(a[k], b[k])
			//             : a[k] === b[k],
			//       })
			//     )
			//   );
			// }

			//**RESET */
			await new Promise((resolve) =>
				store.dispatch(riskStore.actions.reset({}, () => resolve()))
			);
			await store.dispatch(quoteAndBuyStore.actions.reset());

			// return { salusData, riskState, quoteAndBuyState, differences: {} };
			//** LOAD DATA */
			await new Promise((resolve) =>
				store.dispatch(riskStore.actions.loadRisk(salusData, () => resolve()))
			); //** "loadRisk()" is what's called on recall, so it DOESN'T treat items as updated upon load.

			await store.dispatch(
				quoteAndBuyStore.actions.salusLoad({ salusData: salusData })
			);

			// console.log("dddddddddd", {
			//   state,
			//   salusData,
			//   riskState,
			//   quoteAndBuyState,
			// });
			// throw `hhhhh`;
			//** GET DATA */
			const state = store.getState(); // DO this here to get the latest state

			const riskState = _.cloneDeep(await fnRiskGetSalus(state));
			const quoteAndBuyState = _.cloneDeep(await fnQuoteAndBuyGetSalus(state));

			// console.log("dddddddddd", {
			//   state,
			//   riskState,
			//   quoteAndBuyState,
			// });
			// throw `hhh`
			const differences = fnCompare(riskState, quoteAndBuyState);
			const quoteAndBuyStateErrorList = quoteAndBuyStore.selectors.errors.list(
				state,
				"Risk"
			);

			//**RESET */
			await new Promise((resolve) =>
				store.dispatch(riskStore.actions.reset({}, () => resolve()))
			);
			await store.dispatch(quoteAndBuyStore.actions.reset());

			//** RETURN */
			return {
				salusData,
				riskState,
				quoteAndBuyState,
				quoteAndBuyStateErrorList,
				differences,
			};
		},
		hooks: {
			useService: (fn, args = {}) => {
				const dictionaryStatus = {
					start: "START",
					running: "RUNNING",
					complete: "COMPLETE",
					error: "ERROR",
				};

				const {
					useDispatch = _useDispatch,
					useSelector = _useSelector,
					responseMappings = [],
					defaultState = dictionaryStatus.start,
					hasDataSelector = undefined,
				} = args;

				if (!hasDataSelector)
					throw `Error in useService -- missing "hasDataSelector()"`;

				const dispatch = useDispatch();

				const hasData = useSelector(hasDataSelector);

				const [status, setStatus] = useState(() => {
					if (hasData) return dictionaryStatus.complete;
					return dictionaryStatus.start;
				});

				useEffect(() => {
					if (hasData) setStatus(dictionaryStatus.complete);
				}, [hasData]);

				const functions = {
					reset: async () => {
						responseMappings
							.filter(Boolean)
							.filter((x) => x.path)
							.filter((x) => x.responsePath)
							.forEach(async (data = {}) => {
								console.log("useService()", "reseting", data.path);
								await dispatch(
									actions.updateErrorShow({
										path: data.path,
										value: false,
									})
								);
								await dispatch(
									actions.updateValue({
										path: data.path,
										value: undefined,
									})
								);
							});

						setStatus(dictionaryStatus.start);
					},
					run: async (...args) => {
						setStatus(dictionaryStatus.running);

						try {
							const response = await fn(...args);

							// Update RISK STORE
							responseMappings

								.filter(Boolean)
								.filter((x) => x.path)
								.filter((x) => x.responsePath)
								.map((x) => ({
									...x,
									responsePathLodash: x.responsePath.replaceAll("/", "."),
								}))
								.filter((x) => _.has(response, x.responsePathLodash))
								.forEach(async (data = {}) => {
									const { fnProcess = (v) => v } = data;

									const value = fnProcess(
										_.get(response, data.responsePathLodash)
									);
									console.log(
										"useService()",
										"Updating",
										data.path,
										"=",
										value
									);

									await dispatch(
										actions.updateValue({
											path: data.path,
											value: value,
										})
									);
								});

							setStatus(dictionaryStatus.complete);
						} catch (e) {
							setStatus(dictionaryStatus.error);
						}
					},
				};

				// console.log("useService HOOK", { fn, args });
				return {
					functions: functions,
					status: {
						isRunning: status === "RUNNING",
						isStart: status === "START",
						isComplete: status === "COMPLETE",
						isFound: status === "COMPLETE" && hasData,
						isMissing: status === "COMPLETE" && !hasData,
						isError: status === "ERROR",
					},
				};
			},
			useAffiliate: (refKey, updatePath, options = {}) => {
				if (!refKey) throw `Error in useAffiliate -- missing "refKey"`;
				if (!updatePath) throw `Error in useAffiliate -- missing "updatePath"`;

				const {
					useDispatch = _useDispatch,
					useSelector = _useSelector,
					onStart = async () => {},
					onUpdate = async () => {},
					verbose = false,
					updateOnBlankOnly = false,
					effectParams = [],
				} = options;

				const existingSavedValue = useSelector((state) =>
					selectors.userData.risk.value(state, updatePath)
				);

				const location = useLocation();
				const dispatch = useDispatch();
				const navigate = useNavigate();

				const fnLog = (...args) => {
					if (!verbose) return;
					console.log("useAffiliate()", ...args);
				};

				// This function ignores the case
				const fnFindKey = (object, key) =>
					Object.entries(object)
						.filter(([k]) => k.toLowerCase() === key.toLowerCase())
						.map(([k, v]) => v)
						.find(Boolean);

				useEffect(() => {
					const myFn = async () => {
						await onStart();

						if (updateOnBlankOnly && existingSavedValue) return;
						if (!location.search) return;

						const qsData = qs.parse(location.search, {
							ignoreQueryPrefix: true,
						});

						const foundValue = fnFindKey(qsData, refKey);

						if (!foundValue) return;

						fnLog("updating:", updatePath, "=", foundValue);

						await dispatch(
							actions.updateValue({
								path: updatePath,
								value: foundValue,
								createIfMissing: true,
							})
						);

						const newRoute = [
							location.pathname,
							createSearchParams(
								_.omitBy(
									qsData,
									(v, key) => key.toUpperCase() === refKey.toUpperCase()
								)
							).toString(),
						]
							.filter(Boolean)
							.join("?");

						fnLog("navigating to:", newRoute);

						await navigate(newRoute, { replace: true });

						await onUpdate();
					};

					myFn();
				}, [location.pathname, location.search, ...effectParams]);
			},

			useUpdater: (options = {}) => {
				const { useDispatch = _useDispatch } = options;
				const dispatch = useDispatch();

				return {
					update: async (path, value) => {
						await dispatch(
							actions.updateValue({
								path: path,
								value: value,
							})
						);
					},
				};
			},
			useSet: (path, options = {}) => {
				// e.g. additionalDrivers

				if (!path) throw `Error in useSet -- missing "path"`;

				const {
					useDispatch = _useDispatch,
					useSelector = _useSelector,
					framework = undefined,
				} = options;

				const dispatch = useDispatch();
				const [snapshotId] = useState(_.uniqueId(["useSet", path].join("_")));
				const snapshotControls = {
					add: async () => {
						await dispatch(actions.snapshotAdd(snapshotId));
					},
					restore: async () => {
						await dispatch(actions.snapshotRestore(snapshotId));
					},
					commit: async () => {
						await dispatch(actions.snapshotDelete(snapshotId));
					},
				};

				const treeData = useSelector(
					(state) => selectors.userData.risk.valueTree(state, path) || [],
					_.isEqual
				);
				const treeIdList = treeData.map((x) => x._id);

				// console.log("useSet", path, { template, treeData });

				useEffect(() => {
					//TIDY UP
					return async () => {
						// Will do a restore, if the snapshot exists
						await snapshotControls.restore();
					};
				}, []);

				const retControls = (function () {
					const _data = {};

					_data.generateId = () => {
						let retValue;
						do {
							retValue = _.uniqueId(["ID", new Date().getTime()].join("_"));
						} while (
							treeIdList &&
							treeIdList.length >= 1 &&
							treeIdList.includes(retValue)
						);

						return retValue;
					};

					_data.getPath = (subPath) => `${path}[${subPath}]`;
					_data.delete = async (id) => {
						const basePath = `${path}[${id}]`;
						await dispatch(
							actions.create({
								path: `${basePath}-`,
							})
						);
					};

					_data.itemControls = (id) => {
						const retData = {
							id: id,
							delete: async () => _data.delete(id),
							path: _data.getPath(id),
							generateItempath: (subpath) =>
								[_data.getPath(id), subpath].join("/"),
						};
						return retData;
					};

					_data.add = async (
						id = _data.generateId() //generate a default ID if needbe
					) => {
						const basePath = `${path}[${id}]`;

						await dispatch(
							actions.create({
								path: `${basePath}+`,
							})
						);

						const templateData = templateUtils.getData(
							template,
							basePath.split("/")
						);

						if (templateData?.data) {
							const fnProcessNode = (path, node) => {
								const retData = Object.entries(node)
									.flatMap(([k, v]) => {
										const subPath = [path, k].join("/");
										if (v._isTemplateDataNode)
											return [{ path: subPath, defaultValue: v.defaultValue }];
										if (k.endsWith("[]")) return [undefined];
										if (_.isArray(v)) return [undefined];
										if (_.isObject(v)) return fnProcessNode(subPath, v);

										return [undefined];
									})
									.filter(Boolean);

								return retData;
							};

							const pathList = fnProcessNode(basePath, templateData.data.items);

							if (pathList.length >= 1) {
								pathList.forEach((x) =>
									console.log("REGISTERING:", x.path, x.defaultValue)
								);
								await dispatch(
									actions.create({
										pathList: pathList,
									})
								);
							}
						}

						return _data.itemControls(id);
					};

					_data.treeData = treeData.map((x) => {
						return {
							values: _.omit(x, ["_id"]),
							id: x._id,
							controls: _data.itemControls(x._id),
						};
					});

					return _data;
				})();

				// ******************
				// FRAME WORK
				// ******************
				retControls.framework = (function () {
					const {
						List: ListTemplate = undefined,
						Edit: EditTemplate = undefined,
						Add: AddTemplate = undefined,
					} = framework;

					const [curItemControls, setCurItemControls] = useState(undefined);
					const [retView, setRetView] = useState("list");

					const fnItemSet = (id) => {
						const data = retControls.itemControls(id);
						if (!data) return;
						setCurItemControls(data);
						setRetView("edit");
					};

					const fnItemClear = () => {
						setCurItemControls(undefined);
						setRetView("list");
					};

					const fnAddItem = async () => {
						const newPath = retControls.generateId();
						const data = await retControls.add(newPath);
						setCurItemControls(data);

						await snapshotControls.add();

						setRetView("add");
					};

					const componentData = (function () {
						// selectors.errors.isValid(state, pathArray)

						const propsList = {
							listData: retControls.treeData.map((x) => {
								x.controls.edit = async () => {
									await snapshotControls.add();
									fnItemSet(x.id);
								};
								return x;
							}),
							fnAddItem: fnAddItem,
						};
						const propsOther = {
							data: {
								...curItemControls,
								fnCancel: async () => {
									await snapshotControls.restore();
									fnItemClear();
								},
								fnSave: async () => {
									await snapshotControls.commit();
									fnItemClear();
								},
							},
						};

						const List = useCallback(
							() => <ListTemplate {...propsList} />,
							[hash(propsList)]
						);
						const Add = useCallback(() => {
							const isValid = useSelector((state) =>
								selectors.errors.isValid(state, [`${curItemControls.path}/*`])
							);

							return <AddTemplate {...propsOther} isValid={isValid} />;
						}, [hash(propsOther)]);
						const Edit = useCallback(() => {
							const isValid = useSelector((state) =>
								selectors.errors.isValid(state, [`${curItemControls.path}/*`])
							);
							return <EditTemplate {...propsOther} isValid={isValid} />;
						}, [hash(propsOther)]);

						return { List, Add, Edit };
					})();

					return {
						path: path,
						components: componentData,
						currentView: retView,
						isList: retView === "list",
						isAdd: retView === "add",
						isEdit: retView === "edit",
						init: () => setRetView("list"),
						setItem: (id) => fnItemSet(id),
						addItem: () => fnAddItem(),
						reset: () => fnItemClear(),
					};
				})();

				return retControls;
			},
		},
	};
};

export default generate;
