import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";

import _ from "lodash";
import chalk from "chalk";

import siteConfig from "config";
const listenerMiddleware = createListenerMiddleware();
import createSagaMiddleWare from "redux-saga";
import applyCommonListeners from "moveToLibrary/redux/applyCommonListeners";

// **********************************************
// STORES
// **********************************************
import storeSession from "./data/session";
import storeStorage from "./data/storage";
import storeGapQuoteAndBuy from "./data/gap/quoteAndBuy";

const storeHitlist = [storeSession, storeStorage, storeGapQuoteAndBuy];

export const stores = {
	storage: storeStorage,
	session: storeSession,
	gapQuoteAndBuy: storeGapQuoteAndBuy,
};
// **********************************************
// SAGA
// **********************************************

const sagaMiddleware = createSagaMiddleWare({
	onError: (err) => {
		setTimeout(() => {
			// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/Error
			throw new Error(err, { cause: "SAGA" });
		}, 0);
	},
});

// **********************************************
// SAGA
// **********************************************

storeHitlist.forEach((k, i) => {
	if (!k) {
		throw `Error in store/storeData -- "index: ${i} is missing"`;
	}
});

const fnGenerateObj = (hitlist, key) => {
	try {
		return Object.fromEntries(
			hitlist
				.filter((data) => data[key])
				.map((data) => [data.storeName, data[key]])
		);
	} catch (e) {
		console.warn("ERROR DUMP:", { hitlist, key });
		throw e;
	}
};

// **********************************************
// EXPORTS
// **********************************************
export const selectors = fnGenerateObj(storeHitlist, "selectors");
export const components = fnGenerateObj(storeHitlist, "components");
export const actions = fnGenerateObj(storeHitlist, "actions");
export const hooks = fnGenerateObj(storeHitlist, "hooks");
export const classes = fnGenerateObj(storeHitlist, "classes");
export const listeners = fnGenerateObj(storeHitlist, "listeners");
export const reducers = {
	...fnGenerateObj(storeHitlist, "reducer"),
	...fnGenerateObj(storeHitlist, "reducers"), // legacy stores have "reducers" not "recuder"
};
export const helpers = {
	...fnGenerateObj(storeHitlist, "helpers"),
	...fnGenerateObj(storeHitlist, "functions"),
};
export const enhancers = storeHitlist
	.filter((data) => data.enhancer)
	.map((data) => data.enhancer);
// **********************************************
// SETUP
// **********************************************

export const store = configureStore({
	devTools: siteConfig.isDev,
	reducer: reducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(sagaMiddleware)
			.concat(listenerMiddleware.middleware),
	enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(enhancers),
});

storeHitlist.filter((x) => x.saga).forEach((x) => sagaMiddleware.run(x.saga));

// **************************************************
// LISTENERS
// *************************************************
applyCommonListeners(
	listenerMiddleware,
	{
		session: storeSession,
	},
	{
		consoleLog: (...args) => {
			console.log(chalk.blue.bold("LISTENER:"), ...args);
		},
	}
);

// **********************************************
// FINAL EXPORT
// **********************************************
console.groupCollapsed("storeData");
[
	"selectors",
	"components",
	"actions",
	"helpers",
	// "enhancers",
	"listeners",
	"hooks",
].forEach((k) => console.log(k, ":", eval(k)));
console.groupEnd();

export default store;
